import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'

const Footer = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))

  const lastModifiedDateString = poi?.updatedAt || poi?.createdAt
  const lastModifiedDate = new Date(lastModifiedDateString || '')

  const renderLatestUpdatedText = () =>
    `마지막 수정일 ${lastModifiedDate?.getFullYear()}년 ${
      lastModifiedDate?.getMonth() ? lastModifiedDate.getMonth() + 1 : 1
    }월 ${lastModifiedDate?.getDate()}일`

  return <UpdatedAt>{renderLatestUpdatedText()}</UpdatedAt>
}

const UpdatedAt = styled.p`
  padding: 1.25rem 1rem;
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.caption2Regular};
`

export default Footer
