import IconCheckRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCheckRegular'
import IconClockRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconClockRegular'
import IconDownloadRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconDownloadRegular'
import IconForwardRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconForwardRegular'
import { vars } from '@seed-design/design-token'

import { Spinner } from '@src/react-utils/components/Spinner'

import { S_Action, S_CouponCardActionBase, S_CouponCardActionName } from './style'
import { CouponEventType, CouponStateReturnValue, CouponType } from '../../types'

interface Props {
  status: CouponStateReturnValue
  disabled: boolean
  uiDisabled: boolean
  couponId: number
  target: CouponEventType

  bizId?: string
  couponType: CouponType

  onClickMoveToProductTab?: () => void
  onClickMoveToCouponDetail?: () => void
  onClickClaim?: () => void

  showAvailableProducts?: boolean
  hasProductsLoading?: boolean
  claimLoading?: boolean
  followingLoading?: boolean
}

const CouponCardUserAction = ({
  status,
  uiDisabled,

  disabled,

  onClickMoveToProductTab,
  onClickMoveToCouponDetail,
  onClickClaim,

  showAvailableProducts,
  hasProductsLoading,
  claimLoading,
  followingLoading,
}: Props) => {
  // const { push } = useCustomNavigator()
  // const { pageCode } = useGetPageCodeInfo()
  // const { openSnackbar } = useSnackbar()

  // const bizProfileName = useRecoilValue(bizProfileNameSelector(bizId ?? ''))
  // const isOnlineCoupon = couponType === 'ONLINE'
  // const showAvailableProducts = pageCode === 'business_profile.me.coupons' && isOnlineCoupon
  // const setCouponDownloadModalState = useSetRecoilState(couponFollowModalState)

  // const { loading: claimLoading, fetch: reqClaimCoupon } = useReqClaimCoupon(`${bizId}`, couponId)
  // const {
  //   isFollowing,
  //   toggleFollowing,
  //   isUpdating: followingLoading,
  // } = useFollowBizProfileWithInitialModal(bizId ?? '')

  // const { openActionModal } = useActionModalOpen({ zIndex: 9999 })

  // const hasProductsLoadingRef = useRef(false)
  // const hasProductsRef = useRef<{ hasProducts: boolean } | null>(null)

  // const handleMoveToProductTab = useCallback(async () => {
  //   if (!isOnlineCoupon) {
  //     return
  //   }

  //   try {
  //     if (hasProductsLoadingRef.current) {
  //       return
  //     }
  //     hasProductsLoadingRef.current = true

  //     if (!hasProductsRef.current) {
  //       const response = await commerceGraphqlAPI.reqProductTotalCount({
  //         bizId: bizId ?? '',
  //         sortType: SortType.Popular,
  //       })
  //       const totalCount = response.data.data.store?.products.totalCount
  //       const hasProducts = totalCount ? totalCount > 0 : false

  //       hasProductsRef.current = { hasProducts }
  //     }

  //     if (!hasProductsRef.current.hasProducts) {
  //       openSnackbar({
  //         text: '쿠폰을 사용할 수 있는 상품이 없어요.',
  //         style: { bottom: '0.5rem' },
  //       })
  //     } else {
  //       push('business_profile.home.viewer', {
  //         bizId: bizId ?? '',
  //         tab: BizHomeTabType.PRODUCT,
  //       })
  //     }
  //   } catch (err) {
  //     captureException(err)
  //   }

  //   hasProductsLoadingRef.current = false
  // }, [bizId, isOnlineCoupon, openSnackbar, push])

  // const handleMoveToCouponDetail = useCallback(() => {
  //   const isOfflineCoupon = couponType === CouponType.OFFLINE
  //   const isPreview = pageCode === 'business_profile.coupon.detail'

  //   if (disabled || isPreview || !isOfflineCoupon) {
  //     return
  //   }

  //   push('business_profile.coupon.detail', { couponBoxId: couponId.toString() })
  // }, [couponId, couponType, disabled, pageCode, push])

  // const handleClickClaim = (e: MouseEvent<HTMLButtonElement>) => {
  //   if (disabled) {
  //     return
  //   }
  //   e.stopPropagation()

  //   const handleCloseModal = async (close: () => Promise<void>) => {
  //     await close()
  //     setCouponDownloadModalState(false)
  //   }
  //   if (target === 'FOLLOWER_ONLY' && !isFollowing) {
  //     setCouponDownloadModalState(true)
  //     openActionModal({
  //       content: `${
  //         bizProfileName ? `${bizProfileName}의 ` : ''
  //       }단골이 되면 쿠폰을 받을 수 있어요. 단골을 맺고 쿠폰을 받으시겠어요?`,
  //       actions: [
  //         {
  //           title: '단골 맺고 쿠폰 받기',
  //           theme: ActionThemeType.STRONG,
  //           onClick: async (close) => {
  //             handleCloseModal(close)
  //             const result = await toggleFollowing()
  //             result === 'success' && reqClaimCoupon()
  //           },
  //         },
  //         {
  //           title: '취소',
  //           theme: ActionThemeType.NORMAL,
  //           onClick: (close) => {
  //             handleCloseModal(close)
  //           },
  //         },
  //       ],
  //       actionDirectionType: ActionDirectionType.COLUMN,
  //       onClickOverlay: () => setCouponDownloadModalState(false),
  //     })
  //   } else {
  //     reqClaimCoupon()
  //   }
  // }

  if (status.isUsed) {
    return (
      <S_Action uiDisabled={uiDisabled} disabled>
        <S_CouponCardActionBase>
          <IconCheckRegular width={24} height={24} color={vars.$scale.color.gray400} />
          <S_CouponCardActionName color={vars.$scale.color.gray400}>사용완료</S_CouponCardActionName>
        </S_CouponCardActionBase>
      </S_Action>
    )
  }

  if (status.isExpired) {
    return (
      <S_Action uiDisabled={uiDisabled} disabled>
        <S_CouponCardActionBase>
          <IconClockRegular width={24} height={24} color={vars.$scale.color.gray400} />

          <S_CouponCardActionName color={vars.$scale.color.gray400}>기한만료</S_CouponCardActionName>
        </S_CouponCardActionBase>
      </S_Action>
    )
  }
  if (status.isClaimed) {
    return (
      <>
        {showAvailableProducts ? (
          <S_Action onClick={onClickMoveToProductTab} uiDisabled={uiDisabled} disabled={disabled}>
            <S_CouponCardActionBase addOpacity={false}>
              <IconForwardRegular width={24} height={24} color={vars.$scale.color.carrot500} />
              {hasProductsLoading ? (
                <Spinner variant="white" size="small" />
              ) : (
                <S_CouponCardActionName color={vars.$scale.color.carrot500}>상품보기</S_CouponCardActionName>
              )}
            </S_CouponCardActionBase>
          </S_Action>
        ) : (
          <S_Action onClick={onClickMoveToCouponDetail} uiDisabled={uiDisabled} disabled={disabled}>
            <S_CouponCardActionBase addOpacity={true}>
              <IconCheckRegular width={24} height={24} color={vars.$scale.color.carrot500} />
              <S_CouponCardActionName color={vars.$scale.color.carrot500}>받은쿠폰</S_CouponCardActionName>
            </S_CouponCardActionBase>
          </S_Action>
        )}
      </>
    )
  }
  if (status.isLeak) {
    return (
      <S_Action uiDisabled={uiDisabled} disabled>
        <S_CouponCardActionBase>
          <IconDownloadRegular width={24} height={24} color={vars.$scale.color.gray400} />

          <S_CouponCardActionName color={vars.$scale.color.gray400}>쿠폰받기</S_CouponCardActionName>
        </S_CouponCardActionBase>
      </S_Action>
    )
  }
  if (status.isActiveStatus) {
    return (
      <S_Action uiDisabled={uiDisabled} disabled={disabled} onClick={onClickClaim}>
        {claimLoading || followingLoading ? (
          <Spinner variant="gray" size="small" width={18} height={18} />
        ) : (
          <S_CouponCardActionBase>
            <IconDownloadRegular width={24} height={24} color={vars.$scale.color.carrot500} />
            <S_CouponCardActionName color={vars.$scale.color.carrot500}>쿠폰받기</S_CouponCardActionName>
          </S_CouponCardActionBase>
        )}
      </S_Action>
    )
  }
  return null
}

export default CouponCardUserAction
