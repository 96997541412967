import { atom, selector } from 'recoil'

import { APP_BAR_HEIGHT } from '@src/stackflow/styles'
import { getSafeAreaValue } from '@src/styles/utils'

import { generateWinterSnackPageRecoilKey } from '../state/keys'

export const FILTER_ITEMS_HEIGHT = 56
export const CATEGORY_BUTTON_CONTAINER_HEIGHT = 40
export const DEFAULT_MAP_POI_CARD_HEIGHT = 182
export const DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS = 12
export const CARD_MARGIN = 16
export const SLIDE_MARGIN = 16
export const SLIDE_GAP = 32
export const SPACE_FOR_SHADOW = 12
export const SAFE_AREA = {
  getTop: () => parseInt(getSafeAreaValue('top').slice(0, getSafeAreaValue('top').length - 2)),
  getBottom: () => parseInt(getSafeAreaValue('bottom').slice(0, getSafeAreaValue('bottom').length - 2)),
}
export const SPACING_FOR_CENTER =
  DEFAULT_MAP_POI_CARD_HEIGHT +
  CARD_MARGIN +
  SAFE_AREA.getBottom() -
  (APP_BAR_HEIGHT + FILTER_ITEMS_HEIGHT + SAFE_AREA.getTop())

export const TEMP_FILTER_ITEMS_HEIGHT = selector<number>({
  key: generateWinterSnackPageRecoilKey('TEMP_FILTER_ITEMS_HEIGHT'),
  get: () => {
    return 56
  },
})

export const mapPoiCardHeightAtom = atom<number>({
  key: generateWinterSnackPageRecoilKey('mapPoiCardHeightAtom'),
  default: DEFAULT_MAP_POI_CARD_HEIGHT,
})

export const TEMP_SPACING_FOR_CENTER = selector<number>({
  key: generateWinterSnackPageRecoilKey('TEMP_SPACING_FOR_CENTER'),
  get: ({ get }) => {
    const mapPoiCardHeight = get(mapPoiCardHeightAtom)
    const FILTER_ITEMS_HEIGHT = get(TEMP_FILTER_ITEMS_HEIGHT)

    const calibration = {
      top: APP_BAR_HEIGHT + FILTER_ITEMS_HEIGHT + 0,
      bottom: mapPoiCardHeight + CARD_MARGIN,
    }

    const SPACING_FOR_CENTER = calibration.bottom + SAFE_AREA.getBottom() - (calibration.top + SAFE_AREA.getTop())

    return SPACING_FOR_CENTER
  },
})
