import styled from '@emotion/styled'

import { styleShape } from './shape-styles'
import { typography } from './styles'
import { convertStylesToSerialized } from './utils'
import { styleVariant } from './variant-style'

type Shape = 'square' | 'pill'
type Size = 'small' | 'medium' | 'large'
type Variant = 'basic' | 'primary' | 'success' | 'error' | 'accent'
type Type = 'normal' | 'outlined' | 'bold'
export interface BadgeProps {
  shape: Shape
  size: Size
  variant: Variant
  type: Type

  className?: string
  children: string
}

/**
 * https://www.figma.com/file/ty7UxJ61CVPeVU2Gf1LJGQ/App-Components?type=design&node-id=21145-39275&t=N8laNM4xmyG5MeMh-0
 */
export const Badge = (props: BadgeProps) => {
  return (
    <Container className={props.className} {...props}>
      {props.children}
    </Container>
  )
}

const Container = styled.div<Pick<BadgeProps, 'shape' | 'type' | 'size' | 'variant'>>([
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
  ({ shape, type, size, variant }) => style(shape, size, variant, type),
])

const style = (shape: Shape, size: Size, variant: Variant, type: Type) => {
  const _typography = convertStylesToSerialized(typography[size][type])
  const _shape = styleShape(shape, size)
  const _variant = styleVariant(variant, type)

  return [_shape, _typography, _variant]
}
