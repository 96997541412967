import type { AxiosRequestConfig } from 'axios'

export type NetworkHostEnvironment = 'alpha' | 'production'

export type NetworkHostsMap = Partial<Record<NetworkHostEnvironment, string>>
export type NetworkHost = NetworkHostsMap | string

export interface NetworkFetchConfig {
  host?: NetworkHost
  configs?: Omit<AxiosRequestConfig, 'baseURL'>
}

export function getBaseURL({
  defaultHosts,
  host,
  environment,
}: {
  defaultHosts: Required<NetworkHostsMap>
  host?: NetworkHost | null
  environment: NetworkHostEnvironment
}): string {
  if (host) {
    if (typeof host === 'string') {
      return host
    }
    return host[environment] ?? defaultHosts[environment]
  }
  return defaultHosts[environment]
}
