import { css } from '@emotion/react'

export const slickOverrideStyle = css`
  .slick-slider {
    height: 100%;
    .slick-slide {
      outline: none;
      > div > div {
        outline: none;
      }
    }
  }
  .slick-dots {
    pointer-events: none;
    bottom: 0px;
    height: 56px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 3.53%, rgba(0, 0, 0, 0.0001) 100%);
    background-blend-mode: multiply;
    padding-top: 34px;
    display: flex !important;
    justify-content: center;

    li {
      margin: 0;
      width: auto;
      height: auto;
      &.slick-active {
        button {
          opacity: 1;
        }
      }
      button {
        margin: 0 3.5px;
        color: #ffffff;
        opacity: 0.3;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: #ffffff;
        padding: 0;
        &:before {
          content: none;
        }
      }
    }
  }
`
