import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

import { useLogEvent } from '@src/react-utils/components/Analytics'
import { Button } from '@src/react-utils/components/Button'
import { useStepNavigator } from '@src/stackflow'

const NoPois = () => {
  const { stepPush } = useStepNavigator('winter_snack')
  const logEvent = useLogEvent()

  const handleClickAddReview = async () => {
    logEvent({
      name: 'click_write_review_button',
      params: {
        area: 'poi_card',
        view_type: 'map',
      },
    })

    stepPush({
      mode: 'edit',
    })
  }

  return (
    <Container>
      <div className="message">
        필터에 해당하는 장소가 없어요.
        <br /> 새로운 장소를 이웃에게 추천해보세요.
      </div>
      <Button className="button" fullwidth textSize="14" styleTheme="primary-filled" onClick={handleClickAddReview}>
        새로운 가게 등록하기
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .message {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.625rem 0 1.875rem;
    white-space: pre-line;
    text-align: center;
    ${vars.$semantic.typography.subtitle1Regular};
    color: ${vars.$scale.color.gray600};
  }
  .button {
    height: 40px;
  }
`
export default NoPois
