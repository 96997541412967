import { captureException } from '@sentry/react'
import { useCallback, useEffect } from 'react'
import { useRecoilCallback, useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'

import { getReactionsV2 } from '@src/apis/__generated__'
import { ReactionKey, reactionAtomFamily } from '@src/place-utils/reaction/store/reactions'
import { userInfoAtom } from '@src/place-utils/user/store'

import {
  nativeReviewsAtomFamily,
  nativeReviewsPaginationAtomFamily,
  nativeReviewsQuery,
  nativeReviewsQueryFamilyKey,
} from '../store'

export const useNativeReviews = () => {
  const { authToken: xAuthToken } = useRecoilValue(userInfoAtom)
  const familyKey = useRecoilValue(nativeReviewsQueryFamilyKey)

  const reviewsLoadable = useRecoilValueLoadable(nativeReviewsQuery(familyKey))

  const [nativeReviews, setNativeReview] = useRecoilState(nativeReviewsAtomFamily(familyKey))
  const [pagination, setPagination] = useRecoilState(nativeReviewsPaginationAtomFamily(familyKey))

  const resetReviews = useRecoilCallback(
    ({ reset, refresh }) =>
      () => {
        reset(nativeReviewsPaginationAtomFamily(familyKey))
        refresh(nativeReviewsQuery(familyKey))
        reset(nativeReviewsAtomFamily(familyKey))
      },
    [familyKey]
  )

  const fetchMore = useCallback(() => {
    if (pagination.hasNextPage) {
      setPagination((prev) => ({
        ...prev,
        page: prev.page + 1,
      }))
    }
  }, [pagination.hasNextPage, setPagination])

  const getMyReactions = useCallback(
    async (params: Pick<Parameters<typeof getReactionsV2>['0']['params'], 'resourceType' | 'resourceIds'>) => {
      try {
        const {
          data: { data },
        } = await getReactionsV2({
          params: {
            xAuthToken,
            ...params,
          },
        })

        return data.items
      } catch (e) {
        captureException(e)

        return []
      }
    },
    [xAuthToken]
  )
  const updateReactions = useRecoilCallback(
    ({ set }) =>
      (reactionKey: ReactionKey) => {
        set(reactionAtomFamily(reactionKey), (prev) => ({
          ...prev,
          isReactedByMe: true,
        }))
      },
    []
  )

  const useSync = () => {
    return useEffect(() => {
      if (reviewsLoadable.state !== 'hasValue') return

      const { contents } = reviewsLoadable

      setPagination((prev) =>
        prev.hasNextPage === contents.hasNextPage
          ? prev
          : {
              ...prev,
              hasNextPage: contents.hasNextPage,
            }
      )
      setNativeReview((prev) => {
        return prev.syncedPage === contents.page
          ? prev
          : {
              ...prev,
              items: [...prev.items, ...contents.items],
              syncedPage: contents.page,
            }
      })

      const reviewIds = contents.items.map(({ review }) => String(review.id))
      const poiIds = contents.items.map(({ poi }) => poi.id)

      getMyReactions({ resourceType: 'NATIVE_REVIEW', resourceIds: reviewIds }).then((myReactions) => {
        if (myReactions.length > 0) {
          myReactions.forEach(({ resourceId, resourceType, type }) => {
            updateReactions({ resourceId, resourceType, type })
          })
        }
      })
      getMyReactions({ resourceType: 'POI', resourceIds: poiIds }).then((myReactions) => {
        if (myReactions.length > 0) {
          myReactions.forEach(({ resourceId, resourceType, type }) => {
            updateReactions({ resourceId, resourceType, type })
          })
        }
      })

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewsLoadable.state])
  }

  const useResetOnLoad = (isTop: boolean) => {
    return useEffect(() => {
      if (isTop) {
        resetReviews()
      }

      document.addEventListener('visibilitychange', resetReviews)

      return () => {
        document.removeEventListener('visibilitychange', resetReviews)
      }
    }, [isTop])
  }

  return {
    key: familyKey,
    reviews: nativeReviews,
    hasNextPage: pagination.hasNextPage,
    fetchMore,
    fetchState: reviewsLoadable.state,
    reset: resetReviews,
    useSync,
    useResetOnLoad,
  }
}
