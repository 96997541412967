export const initClarity = () => {
  // CLARITY_PROJECT_ID is not injected in local webview
  if (!import.meta.env.CLARITY_PROJECT_ID) {
    return
  }

  const script = document.createElement('script')

  script.innerHTML = `(function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "${import.meta.env.CLARITY_PROJECT_ID}");`

  const head = document.querySelector('head')
  head?.appendChild(script)
}
