import { ActionableCallout, CalloutDescription, CalloutTitle } from '@daangn/sprout-components-callout'

import { IS_PROD } from '@src/constants/environmentConstants'
import { APP_SCHEME } from '@src/constants/url'
import { useNavigator } from '@src/stackflow'

import { REGION, SERVICE_NAME } from '../constants'

const NATIVE_REVIEW_SURVEY_ID = IS_PROD ? 12451 : 125
const NATIVE_REVIEW_SURVEY_SCHEME = `${APP_SCHEME}web/ad/user_surveys/${NATIVE_REVIEW_SURVEY_ID}`

export const SurveyCallout = () => {
  const { pushScheme } = useNavigator()

  const handleClick = () => {
    pushScheme(NATIVE_REVIEW_SURVEY_SCHEME)
  }

  return (
    <ActionableCallout variant="normal" width="100%" margin={'1rem 0'} onClick={handleClick}>
      <CalloutTitle>알려주세요</CalloutTitle>
      <CalloutDescription>
        {REGION} {SERVICE_NAME} 맛집에 제안하고 싶은 내용이 있다면 언제든 알려주세요 😋
      </CalloutDescription>
    </ActionableCallout>
  )
}
