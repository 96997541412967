import IconEditRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconEditRegular'
import IconRetryRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconRetryRegular'
import { vars } from '@seed-design/design-token'

import { S_Action, S_CouponCardActionBase, S_CouponCardActionName } from './style'
import { CouponStateReturnValue, CouponType } from '../../types'

interface Props {
  status: CouponStateReturnValue
  uiDisabled: boolean
  couponType: CouponType
  onClickReActiveCoupon?: () => void
  onClickMoveToCouponUpdate?: () => void
}

const CouponCardManagerAction = ({
  status,
  couponType,
  uiDisabled,
  onClickReActiveCoupon,
  onClickMoveToCouponUpdate,
}: Props) => {
  // const setReActiveCouponModalState = useSetRecoilState(couponReActiveModalState)
  // const reqLogEvent = useFirebaseLogEvent()

  // const { push } = useCustomNavigator()
  // const { combinedPageCode } = useGetPageCodeInfo()

  const color = uiDisabled ? vars.$scale.color.gray600 : vars.$scale.color.carrot500
  const isOfflineCoupon = couponType === 'OFFLINE'

  // const handleMoveToCouponUpdate = () => {
  //   if (disabled || !isOfflineCoupon) {
  //     return
  //   }

  //   if (bizId) {
  //     push('business_profile.coupon.update', {
  //       bizId,
  //       couponBoxId: couponId.toString(),
  //     })
  //   }

  //   reqLogEvent(LogEvent.CLICK_EDIT_COMMERCE_COUPON, {
  //     coupon_box_id: `${couponId}`,
  //     entry: combinedPageCode,
  //   })
  // }

  if (status.isInactiveStatus) {
    return (
      <S_Action uiDisabled={uiDisabled} onClick={onClickReActiveCoupon}>
        <S_CouponCardActionBase>
          <IconRetryRegular color={color} width={24} height={24} />
          <S_CouponCardActionName color={color}>다시발급</S_CouponCardActionName>
        </S_CouponCardActionBase>
      </S_Action>
    )
  }

  return (
    <S_Action uiDisabled={uiDisabled} onClick={onClickMoveToCouponUpdate}>
      <S_CouponCardActionBase addOpacity={!isOfflineCoupon}>
        <IconEditRegular color={color} width={24} height={24} />
        <S_CouponCardActionName color={color}>쿠폰수정</S_CouponCardActionName>
      </S_CouponCardActionBase>
    </S_Action>
  )
}

export default CouponCardManagerAction
