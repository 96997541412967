import { KarrotBridgeSchema } from '@daangn/karrotbridge/lib/__generated__/makeKarrotBridge'

import { karrotBridge, karrotBridgeCache } from '@src/bridge'
import { IS_LOCAL } from '@src/constants/environmentConstants'
import { tokenStorage } from '@src/js-utils/tokenStorage'

class Bridge {
  private bridge = karrotBridge

  constructor() {
    this.bridge = karrotBridge
  }

  getUserInfo = async (): Promise<KarrotBridgeSchema['ReqInfoUserResponse']> => {
    if (IS_LOCAL) {
      if (!import.meta.env.LOCAL_AUTH_TOKEN) throw new Error('LOCAL_AUTH_TOKEN does not exist in the environment')
      if (!import.meta.env.LOCAL_USER_ID) throw new Error('LOCAL_USER_ID does not exist in the environment')

      tokenStorage.setAuthToken(import.meta.env.LOCAL_AUTH_TOKEN)

      return {
        info: {
          user: {
            hashId: '',
            id: parseInt(import.meta.env.LOCAL_USER_ID, 10),
            nickname: '테빅',
            authToken: import.meta.env.LOCAL_AUTH_TOKEN,
            phone: '01089284723',
            profileImage: '',
          },
        },
      }
    }

    const response = await this.bridge.getUserInfo({})

    if (!response?.info?.user) {
      throw new Error('Karrot Bridge did not return expected type for UserInfo but falsy')
    }

    return response
  }

  getRegionInfo = async (): Promise<KarrotBridgeSchema['ReqInfoRegionResponse']> => {
    if (IS_LOCAL) {
      return {
        info: {
          region: {
            id: 366,
            name: '서초4동',
            fullname: '서울 서초구 서초4동',
            name1: '서울측별시',
            name2: '서초구',
            name3: '서초4동',
            name1Id: 1,
            name2Id: 362,
            name3Id: 366,
            parentId: 362,
            centerCoordinates: {
              latitude: 37.500072122029415,
              longitude: 127.03854258877928,
            },
          },
        },
      }
    }

    const response = await this.bridge.getRegionInfo({})

    if (!response?.info?.region) {
      throw new Error('Karrot Bridge did not return expected type for RegionInfo but falsy')
    }

    return response
  }

  getAppInfo = async (): Promise<KarrotBridgeSchema['ReqInfoAppResponse']> => {
    if (IS_LOCAL) {
      return {
        info: {
          app: {
            deviceIdentity: 'local',
            userAgent: window.navigator.userAgent,
            locale: 'ko-KR',
            appHost: 'api.alpha.kr.karrotmarket.com',
            country: 'KR',
            appVersionCode: '224800',
            appVersion: '22.48.0',
          },
        },
      }
    }

    const response = await this.bridge.getAppInfo({})

    if (!response?.info?.app) {
      throw new Error('Karrot Bridge did not return expected type for AppInfo but falsy')
    }

    return response
  }

  getCurrentPosition = async (): Promise<KarrotBridgeSchema['ReqGeolocationCurrentPositionResponse']> => {
    if (IS_LOCAL) {
      return {
        geolocation: {
          currentPosition: {
            position: {
              latitude: 37.566168,
              longitude: 126.978052,
            },
            timestamp: 0,
          },
          geolocationAccessResponse: {
            granted: true,
          },
        },
      }
    }

    const response = await this.bridge.getCurrentPosition({})

    if (!response?.geolocation) {
      throw new Error('Karrot Bridge did not return expected type for CurrentPosition but falsy')
    }

    karrotBridgeCache.setCurrentPosition(response)

    return response
  }
}

export const bridge = new Bridge()
