import IconSearchRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconSearchRegular'
import { useActivityParams } from '@stackflow/react'
import { useEffect } from 'react'

import type { ActivityParams as SearchPoiActivityParams } from '@src/pages/SearchPoi'
import { FormItemWrapper } from '@src/place-utils/widgets/form/FormItemWrapper'
import { TextInput } from '@src/react-utils/components/Input'
import { useNavigator } from '@src/stackflow'

import { EMPTY_SELECTED_POI, usePoiInputSelectionState } from './state'

interface Props {
  onClick?: () => void
  placeholder?: string
  label?: string
  className?: string
  searchPoiParams?: SearchPoiActivityParams
  /**
   * If UNSAFE_clearOnUnmount is false, poi input will NOT be cleared when component unmounts.
   * This is useful when you want to keep the poi input value when user navigates back to the page.
   * Make sure to use `usePoiInputSelectionState` to manually clear the value when you need to.
   * @default true
   */
  UNSAFE_clearOnUnmount?: boolean
}

const PoiInput = ({ onClick, placeholder, label, className, searchPoiParams, UNSAFE_clearOnUnmount = true }: Props) => {
  const { push } = useNavigator()
  const { poiId, poiName } = useActivityParams<{ poiId: string; poiName: string }>()

  const [{ name }, setSelectedPoiInfo] = usePoiInputSelectionState()

  const poiInputId = 'poi'

  const handleClickPoiInput = () => {
    onClick?.()

    push('search_poi', {
      ...searchPoiParams,
      variation: 'internal',
      create_flow: searchPoiParams?.create_flow ?? 'default',
    })
  }

  const handleClickClearPoiInput = () => {
    setSelectedPoiInfo(EMPTY_SELECTED_POI)
  }

  useEffect(() => {
    if (poiId && poiName) {
      setSelectedPoiInfo({
        id: poiId,
        name: poiName,
      })
    }

    return () => {
      if (UNSAFE_clearOnUnmount) {
        setSelectedPoiInfo(EMPTY_SELECTED_POI)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormItemWrapper id={poiInputId} label={label ?? '장소 검색'} className={className}>
      <TextInput
        id={poiInputId}
        placeholder={placeholder ?? '추천할 장소를 검색해주세요.'}
        value={name}
        readOnly
        onClick={handleClickPoiInput}
        onClearInputValue={handleClickClearPoiInput}
        appendRight={<IconSearchRegular width={22} height={22} />}
        type="search"
      />
    </FormItemWrapper>
  )
}

export default PoiInput
