import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { Modal, ModalProps } from '@stackflow/plugin-basic-ui'
import { Fragment, MouseEvent } from 'react'

import { Button } from '../Button'

type ButtonAlign = 'row' | 'column'
interface Props extends Omit<ModalProps, 'children'> {
  header?: string
  contents: string
  CTAButton: {
    label: string
    onClick: () => void
  }
  subButton?: {
    label: string
    onClick: () => void
  }
  buttonAlign?: ButtonAlign
}

/**
 * @deprecated Sprout의 dialog 모듈을 사용해주세요.
 */
export const AlertDialog = ({
  header,
  contents,
  CTAButton: CTA,
  subButton,
  buttonAlign = 'column',
  onOutsideClick,
}: Props) => {
  const handleClickOutside = (e: MouseEvent<HTMLElement>) => {
    if (typeof onOutsideClick === 'function' && !!onOutsideClick) {
      e.preventDefault()
      onOutsideClick(e)
    } else {
      return undefined
    }
  }
  return (
    <Modal onOutsideClick={handleClickOutside}>
      <Container>
        {!!header && <Header>{header}</Header>}
        <Contents>
          {contents.split('\n').map((text, idx) => (
            <Fragment key={idx}>
              {text}
              <br />
            </Fragment>
          ))}
        </Contents>
        <Actions buttonAlign={buttonAlign}>
          <CTAButton styleTheme="primary-filled" onClick={CTA.onClick} buttonAlign={buttonAlign}>
            {CTA.label}
          </CTAButton>
          {!!subButton && (
            <SubButton
              styleTheme={buttonAlign === 'row' ? 'secondary' : 'text'}
              onClick={subButton.onClick}
              buttonAlign={buttonAlign}>
              {subButton.label}
            </SubButton>
          )}
        </Actions>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  padding: 1.25rem 1rem;
`
const Header = styled.h3`
  margin: 0 0 0.375rem;

  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.title3Bold};
`
const Contents = styled.p`
  margin: 0 0 1rem;
  word-break: keep-all;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.subtitle1Regular};

  br:last-of-type {
    display: none;
  }
`
const Actions = styled.div<{
  buttonAlign: ButtonAlign
}>`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  flex-direction: ${({ buttonAlign }) => buttonAlign};
`
const CTAButton = styled(Button)<{
  buttonAlign: ButtonAlign
}>`
  width: 100%;
  order: ${({ buttonAlign }) => (buttonAlign === 'row' ? 2 : 1)};
`

const SubButton = styled(Button)<{
  buttonAlign: ButtonAlign
}>`
  width: 100%;
  order: ${({ buttonAlign }) => (buttonAlign === 'row' ? 1 : 2)};
`
