import { useActivityParams } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { defaultFilter } from './useFilter'
import { usePoiVariables } from './usePois'
import { filteredPoisSelectorFamily } from '../state'
import { WinterSnackParams } from '../WinterSnackPage'

export const useFilteredPois = () => {
  const { filterId } = useActivityParams<WinterSnackParams>()

  const poisVariables = usePoiVariables()

  return useRecoilValue(
    filteredPoisSelectorFamily({
      ...poisVariables,
      filterId: filterId ?? defaultFilter.id,
    })
  )
}
