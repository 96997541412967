import { getPoiImagesV2 } from '@daangn/local-business-network/lib/poi'
import { atomFamily, selectorFamily } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'

import { detailPagePrefix } from '../constants/recoilKey'

const prefix = `${detailPagePrefix}/image`
const generateKey = generateUniqueKey(prefix)

/**
 * Global scope
 * Global scoped store shares cache globally.
 * Given the same key or variable or dependent state, the same value will be returned.
 */
export const imagesByPoiIdPaginationFamily = atomFamily({
  key: generateKey(['pagination', 'family']),
  default: {
    page: 1,
    perPage: 20,
  },
})

export const imagesByPoiIdHasNextPageFamily = atomFamily({
  key: generateKey(['hasNextPage', 'family']),
  default: true,
})

export const imagesByPoiIdSelectorFamily = selectorFamily({
  key: generateKey('images'),
  get:
    (id: string) =>
    async ({ get }) => {
      if (id === '') {
        return { items: [], hasNextPage: false, total: 0 }
      }

      const { page, perPage } = get(imagesByPoiIdPaginationFamily(id))

      // CHECK WITH SERVER: check if page used, if not add pagination, response as void asked qoo for help
      const response = await getPoiImagesV2({
        params: {
          id,
          page,
          perPage,
        },
      })

      return response.data
    },
})
