import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { MY_WATCHES_SCHEME } from '@src/constants/url'
import { josa } from '@src/js-utils/language-korean'
import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'
import { useResetPlaceWatch } from '@src/place-utils/watch/hooks'
import { WatchPlaceIdDto } from '@src/place-utils/watch/store'
import { WatchButton as BaseWatchButton } from '@src/place-utils/watch/WatchButton'
import { useToast } from '@src/react-utils/components/Toast'
import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../../hooks'
import { watchPlaceByIdSelectorFamily } from '../../state'

interface Props {
  placeWatchId: WatchPlaceIdDto
}
const WatchButton = ({ placeWatchId }: Props) => {
  const logEvent = useAnalytics()
  const { showToast } = useToast()
  const { asyncPushScheme } = useNavigator()

  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId: placeWatchId.targetId }))

  const resetWatch = useResetPlaceWatch()

  const resetWatches = useRecoilCallback(({ refresh }) => () => {
    refresh(watchPlaceByIdSelectorFamily(placeWatchId.targetId))
    resetWatch()
  })

  const handleAddWatch = () => {
    logEvent('click_watch_button', {
      watch_type: poi?.bizAccountId ? 'biz_account' : 'poi',
      watch_type_id: poi?.bizAccountId ?? poi?.id,
      is_already_watched: false,
    })

    if (poi) {
      showToast({
        text: `${josa(poi.name, '을/를')} 관심 가게에 추가했어요.`,
        duration: 'short',
        button: {
          text: '관심 가게 보기',
          onClickButton: async () => {
            await asyncPushScheme(MY_WATCHES_SCHEME)

            resetWatches()
          },
        },
      })
    }
  }

  const handleRemoveWatch = () => {
    logEvent('click_watch_button', {
      watch_type: poi?.bizAccountId ? 'biz_account' : 'poi',
      watch_type_id: poi?.bizAccountId ?? poi?.id,
      is_already_watched: true,
    })
  }

  return (
    <StyledWatchButton
      placeWatchId={placeWatchId}
      labels={{
        default: '관심',
        watched: '관심',
      }}
      onAddWatch={handleAddWatch}
      onRemoveWatch={handleRemoveWatch}
      UNSAFE_heartButtonStyle={{ margin: '0 0 0.25rem' }}
      UNSAFE_iconStyle={{ strokeColor: vars.$scale.color.gray900 }}
    />
  )
}

const StyledWatchButton = styled(BaseWatchButton)`
  background: none;
  border: none;
  flex: 1 100%;
  height: 3rem;
  margin: 0 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${vars.$scale.color.gray700};
  ${vars.$semantic.typography.caption1Regular};

  &:not(:last-child) {
    border-right: 0.0625rem solid ${vars.$semantic.color.divider1};
  }
`

export default WatchButton
