import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { memo } from 'react'

interface Props {
  currentStep: number
  totalStep: number
  omitLastStep?: boolean
}
export const StepInfo = memo(({ currentStep, totalStep, omitLastStep }: Props) => {
  if (omitLastStep) {
    if (currentStep === totalStep) {
      return null
    } else {
      return (
        <Info>
          {currentStep}/{totalStep - 1}
        </Info>
      )
    }
  }

  return (
    <Info>
      {currentStep}/{totalStep}
    </Info>
  )
})

const Info = styled.span([
  vars.$semantic.typography.label2Regular,
  {
    margin: '0 0.5rem',
    color: vars.$scale.color.gray900,
  },
])
