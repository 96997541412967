import { useFormik } from 'formik'
import { useCallback } from 'react'

import { FormItemWrapper } from '@src/place-utils/widgets/form/FormItemWrapper'
import type { Field, OperatingInformation } from '@src/place-utils/widgets/form/types'
import BusinessHoursInput from '@src/react-utils/components/Input/BusinessHours/BusinessHoursInput'
import BusinessWeekdaysInput from '@src/react-utils/components/Input/BusinessHours/BusinessWeekdaysInput'

interface Props {
  field: Field<'operatingInformation'>
  operatingInformation: OperatingInformation
  setFieldValue: ReturnType<typeof useFormik>['setFieldValue']
  errorMessage?: string
}

export const OperatingTimeInput = ({ operatingInformation, field, setFieldValue, errorMessage }: Props) => {
  const { isRequired } = field
  const { weekdayIds, businessHours } = operatingInformation

  const handleToggleWeekdays = useCallback(
    (weekdayId: number) => {
      const toggledWeekdayIndex = weekdayIds.findIndex((id) => id === weekdayId)
      const newWeekdayIds =
        toggledWeekdayIndex === -1 ? [...weekdayIds, weekdayId] : weekdayIds.filter((id) => id !== weekdayId)

      setFieldValue('operatingInformation', {
        ...operatingInformation,
        weekdayIds: newWeekdayIds,
      })
    },
    [operatingInformation, setFieldValue, weekdayIds]
  )

  const handleChangeHoursFrom = useCallback(
    (fromOrTo: 'from' | 'to', hours: string) => {
      const newBusinessHours = [
        fromOrTo === 'from' ? hours : businessHours[0],
        fromOrTo === 'to' ? hours : businessHours[1],
      ]

      setFieldValue('operatingInformation', {
        ...operatingInformation,
        businessHours: newBusinessHours,
      })
    },
    [businessHours, setFieldValue, operatingInformation]
  )

  return (
    <>
      <FormItemWrapper id="operating-weekday" label="운영 요일" isOptional={!isRequired}>
        <BusinessWeekdaysInput selectedWeekdays={weekdayIds ?? []} onToggleWeekdays={handleToggleWeekdays} />
      </FormItemWrapper>
      <FormItemWrapper id="operating-hours" label="운영 시간" isOptional={!isRequired} errorMessage={errorMessage}>
        <BusinessHoursInput
          businessHours={businessHours}
          onChangeFrom={handleChangeHoursFrom.bind(null, 'from')}
          onChangeTo={handleChangeHoursFrom.bind(null, 'to')}
        />
      </FormItemWrapper>
    </>
  )
}
