import { Image } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import isEqual from 'lodash/isEqual'
import { ReactNode, useMemo, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'

import { useCurrentPosition } from '@src/place-utils/location/hooks'
import { usePoiInputSelectionState } from '@src/place-utils/poi/PoiInput/state'
import { checkInRegionInfoAtom } from '@src/place-utils/region/store'
import { useRefetchReviewsForAllSort } from '@src/place-utils/review'
import { myPoiReviewSelectorFamily } from '@src/place-utils/review/store/poiReview'
import { Button } from '@src/react-utils/components/Button'

import { ActivityParams } from '.'
import { useAnalytics } from './hook'
import { contentAtom, imageIdsAtom } from './state'

export interface SubmitParams {
  poiId: string
  bizAccountId?: string
  content: string
  imageIds: string[]
  coordinates?: string
}

interface Props {
  poiId?: string
  initialValues?: {
    content: string
    images: Image[]
  }
  onSubmit: (params: SubmitParams) => Promise<void>
  children: ReactNode
}

export const SubmitButton = ({ initialValues, onSubmit, ...props }: Props) => {
  const params = useActivityParams<ActivityParams>()
  const logEvent = useAnalytics()
  const { position } = useCurrentPosition()
  const [selectedPoiInfo] = usePoiInputSelectionState()
  const { id, bizAccountId } = selectedPoiInfo

  const poiId = props.poiId || id || params.poiId || ''

  const refetchReviews = useRefetchReviewsForAllSort(poiId, undefined)
  const refreshMyReview = useRecoilRefresher_UNSTABLE(myPoiReviewSelectorFamily({ poiId }))

  const checkInRegionInfo = useRecoilValue(checkInRegionInfoAtom)
  const imageIds = useRecoilValue(imageIdsAtom)
  const content = useRecoilValue(contentAtom)

  const [sendingReview, setSendingReview] = useState(false)

  const isSubmitDisable = useMemo(() => {
    return (
      !poiId ||
      sendingReview ||
      (initialValues?.content === content && isEqual(initialValues?.images?.map((image) => image.id), imageIds)) ||
      content.length === 0
    )
  }, [content, imageIds, initialValues?.content, initialValues?.images, poiId, sendingReview])

  const handleSubmit = async () => {
    if (isSubmitDisable || !poiId) return

    setSendingReview(true)

    try {
      const userPosition = position
      const regionCenter = checkInRegionInfo.centerCoordinates
      const coordinates = userPosition || regionCenter

      await onSubmit({
        poiId,
        bizAccountId,
        content,
        imageIds,
        coordinates: coordinates ? `${coordinates?.latitude},${coordinates?.longitude}` : undefined,
      })

      refetchReviews()
      refreshMyReview()

      logEvent('click_submit_review_button')
    } catch {
      setSendingReview(false)
    }
  }

  return (
    <StyledButton styleTheme="text" disabled={isSubmitDisable} onClick={handleSubmit}>
      완료
    </StyledButton>
  )
}

const StyledButton = styled(Button)<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? vars.$scale.color.gray400 : vars.$semantic.color.primary)};
  font-weight: ${({ disabled }) => (disabled ? 400 : 700)};
`
