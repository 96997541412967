// import { suggestCreateV2 } from '@daangn/local-business-network/lib/poi'
import { receive } from '@stackflow/compat-await-push'
import { useActivityParams } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { usePoiInputSelectionState } from '@src/place-utils/poi/PoiInput/state'
import { userInfoAtom } from '@src/place-utils/user/store'
import { useActivity, useNavigator } from '@src/stackflow'
import { Coordinates } from '@src/types/global'

import { Callout } from './Callout'
import { TextOnly } from './TextOnly'
import { useAnalytics } from '../../hooks'
import { queryAtom } from '../../state/query'

interface Props {
  type?: 'callout' | 'text-only'
  create_flow?: 'default' | 'simplified'
}
export const CreatePoiCallout = ({ type = 'callout', create_flow = 'default' }: Props) => {
  const logEvent = useAnalytics()
  const { enteredBy } = useActivity()
  const { entry } = useActivityParams<{
    entry?: string
  }>()
  const { push, pop } = useNavigator()

  const { authToken } = useRecoilValue(userInfoAtom)
  const query = useRecoilValue(queryAtom)
  const [, setSelectedRecoilState] = usePoiInputSelectionState()

  const handleClick = async () => {
    logEvent('click_suggest_new_poi_button', {
      area: 'list',
      type,
      create_flow,
    })
    // 쿼리 후 데이터 확인 결과 create_flow는 사용하지 않는 것으로 보임.
    // if (create_flow === 'simplified') {
    //   const receivedData = await receive<{ coordinates: Coordinates }>({
    //     activityId: push('pin_address', {}).activityId,
    //   })
    //
    //   const name = query
    //   const coordinates = receivedData.coordinates
    //
    //   const { data } = await ((params: any) => {
    //     return {} as any
    //   })({
    //     params: {
    //       suggestCreationBody: {
    //         name,
    //         coordinates,
    //         applyTemporarily: true,
    //       },
    //       xAuthToken: authToken,
    //     },
    //   })
    //
    //   const poiId = data.poi!.id || data.poiId!
    //
    //   setSelectedRecoilState({ id: poiId, name })
    //
    //   pop()
    // } else {
    push('legacy_create_poi_suggestion', {
      name: query,
      variation: enteredBy.activityName === 'poi_review' || entry === 'poi_review' ? 'writeReview' : undefined,
    })
    // }
  }

  return type === 'text-only' ? (
    <TextOnly poi={{ name: query }} onClick={handleClick} />
  ) : (
    <Callout onClick={handleClick} />
  )
}
