import { ActivityComponentType } from '@stackflow/react'

import { CheckinAlertDialog } from './CheckinAlertDialog'
import { DeleteConfirmAlertDialog } from './DeleteConfirmAlertDialog'
import { FrequencyPickerBottomSheet } from './FrequencyPickerBottomSheet'
import { MenuFilterBottomSheet } from './ListFilter/Menu'
import { RegionLevelTwoFilterBottomSheet } from './ListFilter/RegionLevelTwo'
import { ResidencePeriodFilterBottomSheet } from './ListFilter/ResidencePeriod'
import { VisitCountFilterBottomSheet } from './ListFilter/VisitCount'
import { NativeReviewActionSheet } from './NativeReviewActionSheet'
import { ReactionDisplayBottomSheet } from './ReactionDisplayBottomSheet'
import { YearPickerBottomSheet } from './YearPickerBottomSheet'
import { YearPickerBottomSheetForList } from './YearPickerBottomSheetForList'
import { FilterId } from '../store'

export type ModalType =
  | 'form-year-picker'
  | 'form-frequency-picker'
  | 'list-review-more-actions'
  | 'list-confirm-delete-review'
  | 'list-year-picker'
  | 'list-checkin'
  | 'reaction-display'
export type NativeReviewModalParams = {
  modalId: ModalType | FilterId
  reviewId?: string
  poiId?: string
}

export const NativeReviewModal: ActivityComponentType<NativeReviewModalParams> = ({
  params: { modalId, ...params },
}) => {
  const reviewId = params.reviewId ? +params.reviewId : undefined
  return (
    <>
      {(() => {
        switch (modalId) {
          case 'form-year-picker':
            return <YearPickerBottomSheet />
          case 'form-frequency-picker':
            return <FrequencyPickerBottomSheet />
          case 'list-year-picker':
            return <YearPickerBottomSheetForList reviewId={reviewId} />
          case 'list-menu-filter':
            return <MenuFilterBottomSheet />
          case 'list-residence-period-filter':
            return <ResidencePeriodFilterBottomSheet />
          case 'list-visit-count-filter':
            return <VisitCountFilterBottomSheet />
          case 'list-region-level-two-filter':
            return <RegionLevelTwoFilterBottomSheet />
          case 'list-review-more-actions':
            return <NativeReviewActionSheet reviewId={reviewId} />
          case 'list-confirm-delete-review':
            return <DeleteConfirmAlertDialog />
          case 'list-checkin':
            return <CheckinAlertDialog />
          case 'reaction-display':
            if (!params.poiId) return null
            return <ReactionDisplayBottomSheet poiId={params.poiId} />
          default:
            return null
        }
      })()}
    </>
  )
}
