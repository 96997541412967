import { deletePoiReview } from '@daangn/local-business-network/lib/legacyReview'
import { captureException } from '@sentry/react'
import { useActivityParams } from '@stackflow/react'
import { ComponentProps, useCallback, useMemo } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'

import { karrotBridge } from '@src/bridge'
import { REPORT_WEBVIEW_BASE_URL } from '@src/constants/url'
import { useRefetchReviewsForAllSort } from '@src/place-utils/review/hooks'
import { useBusinessReviewRoutingManager } from '@src/place-utils/review/hooks/useBusinessReviewRoutingManager'
import { myPoiReviewSelectorFamily } from '@src/place-utils/review/store/poiReview'
import { userInfoAtom } from '@src/place-utils/user/store'
import { ActionSheet } from '@src/react-utils/components/ActionSheet'
import { useToast } from '@src/react-utils/components/Toast'
import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'
import { interestedSingleReviewAtom } from '../state'

type ActionButtonsType = ComponentProps<typeof ActionSheet>['actionButtons']

export const SingleReviewActionSheet = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const { pop } = useNavigator()
  const logEvent = useAnalytics()
  const { showToast } = useToast()
  const { openUpdateReviewPage } = useBusinessReviewRoutingManager()

  const userInfo = useRecoilValue(userInfoAtom)
  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId }))
  const userReviewId = myReview?.id

  const refreshReviews = useRefetchReviewsForAllSort(poiId, undefined)
  const refreshMyReview = useRecoilRefresher_UNSTABLE(myPoiReviewSelectorFamily({ poiId }))

  const { reviewId, reviewUserId } = useRecoilValue(interestedSingleReviewAtom)

  const handleClose = useCallback(() => {
    pop()
  }, [pop])

  const handleRemoveReviewItem = useCallback(async () => {
    logEvent('click_remove_review_button', {
      area: 'card_more_options',
      review_id: reviewId,
    })

    try {
      await deletePoiReview({
        params: {
          reviewId: parseInt(reviewId, 10),
        },
      })
      showToast({
        text: '후기가 삭제 되었어요.',
        duration: 'short',
      })

      refreshReviews()
      refreshMyReview()
    } catch (err) {
      captureException(err)
      showToast({
        text: '후기 삭제에 실패했어요. 잠시 후 다시 시도해주세요.',
        duration: 'short',
      })
    } finally {
      handleClose()
    }
  }, [handleClose, logEvent, refreshMyReview, refreshReviews, reviewId, showToast])

  const handleReport = useCallback(async () => {
    logEvent('click_report_review_button', {
      area: 'card_more_options',
      review_id: reviewId,
    })

    handleClose()

    const reportURL = `${REPORT_WEBVIEW_BASE_URL}/?kind=biz_review&review_id=${reviewId}`

    karrotBridge.pushRouter({
      router: {
        remote: reportURL,
        navbar: false,
        scrollable: false,
      },
    })
  }, [handleClose, logEvent, reviewId])

  const handleClickUpdate = useCallback(async () => {
    logEvent('click_edit_review_button', {
      area: 'card_more_options',
      review_id: userReviewId,
    })

    pop()

    openUpdateReviewPage({ reviewId: userReviewId })
  }, [logEvent, openUpdateReviewPage, pop, userReviewId])

  const myReviewActions: ActionButtonsType = useMemo(
    () => [
      {
        label: '수정하기',
        onClick: handleClickUpdate,
      },
      {
        label: '삭제하기',
        type: 'destructive',
        onClick: handleRemoveReviewItem,
      },
    ],
    [handleClickUpdate, handleRemoveReviewItem]
  )

  const otherUserReviewActions: ActionButtonsType = useMemo(
    () => [
      {
        label: '신고하기',
        type: 'destructive',
        onClick: handleReport,
      },
    ],
    [handleReport]
  )

  return (
    <ActionSheet
      onOutsideClick={handleClose}
      dismissButton={{
        label: '닫기',
        onClick: handleClose,
      }}
      actionButtons={reviewUserId === Number(userInfo?.id) ? myReviewActions : otherUserReviewActions}
    />
  )
}
