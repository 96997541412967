import { selectorFamily } from 'recoil'

import { generateKey } from '@src/js-utils/localStorage/generateKey'
import { watchPOIInfosByIdMapperSelectorFamily } from '@src/place-utils/watch/store'

export const watchPlaceByIdSelectorFamily = selectorFamily({
  key: generateKey('watchPlaceById'),
  get:
    (poiId: string) =>
    async ({ get }) => {
      const poiWatchInfoMapper = get(watchPOIInfosByIdMapperSelectorFamily([poiId]))

      return poiWatchInfoMapper[poiId]
    },
})
