import { useLayoutEffect } from 'react'

import { useMap } from './useMap'

const useEventListenerEffect = (target: any, eventName: string, callback?: () => void) => {
  const { kakao } = useMap()

  useLayoutEffect(() => {
    if (!kakao?.maps || !target || !callback) return

    kakao.maps.event.addListener(target, eventName, callback)

    return () => {
      kakao.maps.event.removeListener(target, eventName, callback)
    }
  }, [callback, eventName, kakao, kakao?.maps.event, target])
}

export default useEventListenerEffect
