import { captureException } from '@sentry/react'
import debounce from 'lodash/debounce'
import { useMemo } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { createReactionV2, deleteReactionV2 } from '@src/apis/__generated__'
import { userInfoAtom } from '@src/place-utils/user/store'
import { errorMessage } from '@src/react-utils/components/Error/constants'
import { useToast } from '@src/react-utils/components/Toast'

import { ReactionKey, reactionAtomFamily } from '../store/reactions'

export const useReaction = ({ resourceType, resourceId, type }: ReactionKey) => {
  const { showToast } = useToast()
  const { authToken: xAuthToken } = useRecoilValue(userInfoAtom)
  const { isReactedByMe, totalCount, reactedUsers } = useRecoilValue(
    reactionAtomFamily({ resourceType, resourceId, type })
  )

  const activate = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const {
            data: {
              data: { agreedCount, upvoteCount, matjibCount },
            },
          } = await createReactionV2({
            params: {
              xAuthToken,
              createReactionBody: { resourceId, resourceType, type },
            },
          })

          set(reactionAtomFamily({ resourceType, resourceId, type }), (prev) => ({
            ...prev,
            isReactedByMe: true,
            totalCount:
              type === 'AGREED'
                ? agreedCount
                : type === 'UPVOTE'
                ? upvoteCount
                : type === 'MATJIB'
                ? matjibCount
                : prev.totalCount,
          }))
        } catch (err) {
          captureException(err)

          showToast({
            text: errorMessage.PLEASE_RETRY_MESSAGE,
            duration: 'short',
          })
        }
      },
    [resourceId, resourceType, showToast, type, xAuthToken]
  )

  const deactivate = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const {
            data: {
              data: { agreedCount, upvoteCount, matjibCount },
            },
          } = await deleteReactionV2({
            params: {
              xAuthToken,
              deleteReactionBody: { resourceId, resourceType, type },
            },
          })

          set(reactionAtomFamily({ resourceType, resourceId, type }), (prev) => ({
            ...prev,
            isReactedByMe: false,
            totalCount:
              type === 'AGREED'
                ? agreedCount
                : type === 'UPVOTE'
                ? upvoteCount
                : type === 'MATJIB'
                ? matjibCount
                : prev.totalCount,
          }))
        } catch (err) {
          captureException(err)

          showToast({
            text: errorMessage.PLEASE_RETRY_MESSAGE,
            duration: 'short',
          })
        }
      },
    [resourceId, resourceType, showToast, type, xAuthToken]
  )

  const handleUpvoteFetching = useRecoilCallback(
    () =>
      ({ prevUpvote }: { prevUpvote: boolean }) => {
        const nextUpvote = !prevUpvote

        nextUpvote ? activate() : deactivate()
      },
    [activate, deactivate]
  )

  const debouncedHandleUpvoteFetching = useMemo(() => debounce(handleUpvoteFetching, 300), [handleUpvoteFetching])

  const handleClick = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        set(reactionAtomFamily({ resourceType, resourceId, type }), (prev) => ({
          ...prev,
          isReactedByMe: !prev.isReactedByMe,
          totalCount: prev.totalCount + (prev.isReactedByMe ? -1 : 1),
        }))

        const prevUpvote =
          snapshot.getLoadable(reactionAtomFamily({ resourceType, resourceId, type })).getValue().isReactedByMe ?? false

        debouncedHandleUpvoteFetching({ prevUpvote })
      },
    [resourceType, resourceId, type, debouncedHandleUpvoteFetching]
  )

  return {
    isReactedByMe,
    totalCount,
    reactedUsers,
    handleReaction: handleClick,
  }
}
