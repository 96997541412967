import { captureException } from '@sentry/react'
import { atomFamily, selectorFamily } from 'recoil'

import { communityApi } from '@src/apis'
import { PoiTaggedCommentsResponse } from '@src/apis/types/communityApi'

import { generateKey } from './recoilKey'

const poiTaggedCommentsSelectorFamily = selectorFamily<
  PoiTaggedCommentsResponse,
  Parameters<typeof communityApi.getPoiTaggedComments>['0']
>({
  key: generateKey('poiTaggedCommentsSelectorFamily'),
  get:
    ({ storyArticleId, cursor, limit }) =>
    async () => {
      try {
        const response = await communityApi.getPoiTaggedComments({ storyArticleId, cursor, limit })

        return response.data
      } catch (err) {
        captureException(err)

        throw err
      }
    },
})

type Comments = PoiTaggedCommentsResponse['comments']
export const allCommentsAtomFamily = atomFamily<Comments, { storyArticleId: number }>({
  key: generateKey('allCommentsAtomFamily'),
  default: selectorFamily({
    key: 'allCommentsAtomFamily/Default',
    get:
      ({ storyArticleId }) =>
      ({ get }) => {
        const getComments = ({ cursor, comments }: { cursor?: string; comments: Comments }): Comments => {
          const next = get(poiTaggedCommentsSelectorFamily({ storyArticleId, cursor }))

          comments = [...comments, ...next.comments]

          if (next.hasNext) {
            return getComments({ cursor: next.endCursor ?? undefined, comments })
          } else {
            return comments
          }
        }

        return getComments({ cursor: undefined, comments: [] })
      },
  }),
})
