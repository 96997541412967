import { HelpBubbleAnchor, SeedHelpBubbleProps } from '@daangn/sprout-components-help-bubble'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { ComponentType, ReactEventHandler } from 'react'

import { clickAfterDim } from '@src/styles/others'

const DEFAULT_HELP_BUBBLE_DESCRIPTION = '사진을 추가해 보세요!'
interface HelpBubble extends Omit<SeedHelpBubbleProps, 'children' | 'description'> {
  description?: string
}

interface Props {
  onClick: ReactEventHandler
  imageCount: number
  maxCount: number
  disabled: boolean
  iconComponent: ComponentType<{ isMax: boolean }>
  helpBubble?: HelpBubble
}
export const ImageSelectButton = ({
  disabled,
  imageCount,
  maxCount,
  onClick,
  iconComponent: IconComponent,
  helpBubble,
}: Props) => {
  const handleClick: ReactEventHandler = (e) => {
    onClick(e)
  }

  return helpBubble ? (
    <HelpBubbleAnchor
      description={DEFAULT_HELP_BUBBLE_DESCRIPTION}
      positioning={{
        placement: 'right',
      }}
      {...helpBubble}>
      <Button onClick={handleClick} disabled={disabled}>
        <IconComponent isMax={disabled} />
        <ImageCount disabled={disabled}>
          <ImageUploadedCount active={!!imageCount && !disabled}>{imageCount}</ImageUploadedCount>/{maxCount}
        </ImageCount>
      </Button>
    </HelpBubbleAnchor>
  ) : (
    <Button onClick={handleClick} disabled={disabled}>
      <IconComponent isMax={disabled} />
      <ImageCount disabled={disabled}>
        <ImageUploadedCount active={!!imageCount && !disabled}>{imageCount}</ImageUploadedCount>/{maxCount}
      </ImageCount>
    </Button>
  )
}

const Button = styled.button`
  text-align: center;
  background-color: ${vars.$scale.color.gray00};
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px ${vars.$scale.color.gray400};
  height: 64px;
  width: 64px;
  min-width: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  transition: background-color 200ms;
  ${clickAfterDim}

  :disabled {
    background-color: ${vars.$scale.color.gray100};
  }

  svg path {
    transition: fill 200ms;
  }
`
const ImageCount = styled.span<{ disabled: boolean }>`
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ disabled }) => (disabled ? vars.$scale.color.gray500 : vars.$scale.color.gray600)};

  transition: color 200ms;
`
const ImageUploadedCount = styled.span<{ active: boolean }>`
  color: ${({ active }) => (active ? vars.$scale.color.gray600 : 'inherit')};
`
