import styled from '@emotion/styled'
import { ChangeEvent, ComponentProps, ReactEventHandler, useCallback, useMemo, useRef } from 'react'

import { ImageSelectButton } from './ImageSelectButton'
import { ImageUploadBox } from './ImageUploadBox'
import { ImageUploadPicture } from './useImageUpload'

type ImageSelectButtonProps = ComponentProps<typeof ImageSelectButton>

interface Props extends Pick<ImageSelectButtonProps, 'disabled' | 'maxCount' | 'iconComponent' | 'helpBubble'> {
  pictures: ImageUploadPicture[]
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void
  onClickDeletePictureItem: (id: string) => void
  onClickPictureItem?: (item: ImageUploadPicture, idx: number) => void
  onClickImageSelectButton?: ImageSelectButtonProps['onClick']
}
export const ImageScrollView = ({
  pictures,
  disabled: propsDisabled,
  maxCount = 10,
  iconComponent,
  helpBubble,
  onFileChange,
  onClickPictureItem,
  onClickDeletePictureItem,
  onClickImageSelectButton,
}: Props) => {
  const fileInputEl = useRef<HTMLInputElement>(null)
  const disabled = useMemo(() => pictures.length >= maxCount, [pictures, maxCount])

  const handleClickFileInput: ReactEventHandler = useCallback(
    (e) => {
      e.preventDefault()
      fileInputEl.current?.click()

      onClickImageSelectButton?.(e)
    },
    [onClickImageSelectButton]
  )

  return (
    <Base>
      <ImageSelectionWrapper>
        <FileInput
          disabled={disabled || propsDisabled}
          onChange={onFileChange}
          ref={fileInputEl}
          type="file"
          accept="image/*"
          multiple
          hidden
        />
        <ImageSelectButton
          onClick={handleClickFileInput}
          imageCount={pictures.length}
          disabled={disabled}
          iconComponent={iconComponent}
          maxCount={maxCount}
          helpBubble={helpBubble}
        />
        {pictures.map((picture, idx) => {
          const { id, url, loading } = picture
          return (
            <UploadImageWrapper key={id}>
              <ImageUploadBox
                id={id}
                url={url}
                loading={loading}
                onClickDelete={onClickDeletePictureItem}
                onClick={() => onClickPictureItem?.(picture, idx)}
              />
            </UploadImageWrapper>
          )
        })}
      </ImageSelectionWrapper>
    </Base>
  )
}

const Base = styled.div`
  overflow-x: scroll;
  transform: translateZ(0);
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }
`
const ImageSelectionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  padding: 6px 16px 0;
`

const UploadImageWrapper = styled.div`
  margin: 0 4px;
`
const FileInput = styled.input`
  display: none;
`
