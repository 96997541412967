import styled from '@emotion/styled'
import { useActivityParams } from '@stackflow/react'
import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

// import { IS_PROD } from '@src/constants/environmentConstants'
import { isDateWithinAPeriod } from '@src/js-utils/date'
import { localStorage, LOCAL_STORAGE_KEYS } from '@src/js-utils/localStorage'
import { useNavigator } from '@src/stackflow'

import { BoundarySearchButton } from './BoundarySearchButton'
import Map from './Map'
import { WINTER_SNACK_SERVICE_END_DATE } from '../constants/date'
import { EventBanner } from '../EventBanner'
import { isMapBoundChangedAtom } from '../state/map'
import { WinterSnackParams } from '../WinterSnackPage'

export const MapContents = () => {
  const activityParams = useActivityParams<WinterSnackParams>()
  const { push } = useNavigator()

  const isMapBoundChanged = useRecoilValue(isMapBoundChangedAtom)

  // 22-23 wrap up (겨울 간식 지도 총결산) bottom sheet
  useEffect(() => {
    // if (IS_PROD) return

    const START_DATE = '2023-03-21 08:00'
    const isEventPeriod = isDateWithinAPeriod({
      startDate: START_DATE,
      endDate: WINTER_SNACK_SERVICE_END_DATE,
    })
    if (isEventPeriod) {
      localStorage.getItem(LOCAL_STORAGE_KEYS.WINTER_SNACK_22_23_WRAP_UP_BOTTOM_SHEET).then((value) => {
        if (!(typeof value === 'string' && value === 'hidden')) {
          setTimeout(() => {
            push('winter_snack_modal', { modalId: '22-23-wrap-up' })
          }, 500)
        }
      })
    }
  }, [push])

  return (
    <Container>
      <TopArea>
        <EventBanner viewType="map" />
        {activityParams.mode !== 'edit' && isMapBoundChanged && <BoundarySearchButton />}
      </TopArea>
      <Map initialZoomLevel={5} />
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
`
const TopArea = styled.div`
  z-index: 2;
  width: 0;
  height: fit-content;
  position: absolute;
  transform: translate3d(50vw, 0, 0);
  top: 0.75rem;
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  align-items: center;
`
