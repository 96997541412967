import styled from '@emotion/styled'
import { ActivityComponentType } from '@stackflow/react'
import { ComponentProps } from 'react'
import { useRecoilValue } from 'recoil'

import { Screen } from '@src/stackflow'
import { safeAreaPadding } from '@src/styles/safeArea'

import { Section } from './components/Section'
import { Content } from './Content'
import { ReviewEditProvider } from './contexts/ReviewEditProvider'
import { Images } from './Images'
import { SubmitButton } from './SubmitButton'
import { VisitCountDropdown } from './VisitCountDropdown'
import { nativeReviewSelectorFamily } from '../store'

interface NativeReviewEditActivityParams {
  reviewId: string
}
export const NativeReviewEditPage: ActivityComponentType<NativeReviewEditActivityParams> = ({
  params: { reviewId },
}) => {
  const { review } = useRecoilValue(nativeReviewSelectorFamily(+reviewId))

  if (!review) throw new Error('review not found')

  return (
    <Screen
      appBar={{
        border: false,
        title: '추천 글 수정',
      }}>
      <ReviewEditProvider
        reviewId={+reviewId}
        initialFormValue={{
          poi: {
            id: review.poi.id || review.poiId,
            visitCount: review.visitCount,
          },
          review: {
            imageIds: (review.images as unknown as { id: string; url: string }[]).map((image) => image.id),
            content: review.content,
          },
        }}>
        <Form
          initialValue={{
            content: review.content,
            images: review.images as unknown as { id: string; url: string }[],
            visitCount: review.visitCount,
          }}
        />
        <ButtonGroup>
          <SubmitButton />
        </ButtonGroup>
      </ReviewEditProvider>
    </Screen>
  )
}

interface FormProps {
  initialValue: {
    content: string
    images: { id: string; url: string }[]
    visitCount: ComponentProps<typeof VisitCountDropdown>['initialValue']['visitCount']
  }
}
const Form = ({ initialValue: { content, images, visitCount } }: FormProps) => {
  return (
    <Container>
      <Section label="이 맛집을 왜 추천하시나요?">
        <Content initialValue={{ content }} />
        <Images initialValue={{ images }} />
      </Section>
      <Section label="이 맛집 몇 번 정도 가보셨어요?">
        <VisitCountDropdown initialValue={{ visitCount }} />
      </Section>
    </Container>
  )
}

const Container = styled.div({
  padding: '1.25rem 1rem 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
})
const ButtonGroup = styled.div([
  {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    position: 'fixed',
    bottom: 0,
    padding: '1rem',
  },
  safeAreaPadding('bottom', '1rem'),
])
