import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

import { APP_SCHEME, COMMUNITY_WEB_PROFILE_BASE_URL } from '@src/constants/url'
import { createQueryString } from '@src/js-utils/url'
import { ReactionState } from '@src/place-utils/reaction/store'
import { Avatar } from '@src/react-utils/components/Avatar'
import { useNavigator } from '@src/stackflow'

import { TOP_TIER_BADGE_YEARS_OF_RESIDENCE } from '../../constants'
import { useAnalytics } from '../../hooks'
import { CrownBadge } from '../../List/Review/UserProfile/BadgeCrown'
import { getYearsOfResidence } from '../../utils'

type Props = NonNullable<ReactionState['reactedUsers']>[0]
export const UserProfile = ({
  id,
  nickname,
  profileImageURL,
  residenceInfo: { region, startYearOfResidence },
}: Props) => {
  const yearsOfResidence = getYearsOfResidence(startYearOfResidence)

  const { pushScheme } = useNavigator()
  const logEvent = useAnalytics()

  const handleClick = () => {
    logEvent('click_user_profile', {
      user_id: id,
      residence_year: yearsOfResidence,
    })

    const queryString = createQueryString({
      remote: encodeURIComponent(`${COMMUNITY_WEB_PROFILE_BASE_URL}/profile/${id}`),
      navbar: 'false',
      scrollable: 'false',
    })
    const scheme = `${APP_SCHEME}community-web/router${queryString}`

    pushScheme(scheme)
  }

  return (
    <Container onClick={handleClick}>
      <Avatar src={profileImageURL || null} size="small" alt={nickname} />
      <ProfileData>
        <UserInfo>
          <Nickname>{nickname}</Nickname>
        </UserInfo>
        <AdditionalData>
          <Tag>
            {region.name} {yearsOfResidence}년 거주
          </Tag>
          {yearsOfResidence >= TOP_TIER_BADGE_YEARS_OF_RESIDENCE && <CrownBadge label="상위 10%" />}
        </AdditionalData>
      </ProfileData>
    </Container>
  )
}

const Container = styled.div({
  padding: '0.75rem 1rem',
  display: 'flex',
  alignItems: 'center',
  gap: '0.375rem',

  ':first-of-type': {
    margin: '0.44rem 0 0',
  },
})
const ProfileData = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.125rem',
})
const UserInfo = styled.div({})
const Nickname = styled.span([
  {
    color: vars.$scale.color.gray900,
  },
  vars.$semantic.typography.label3Bold,
])
const AdditionalData = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
})
const Tag = styled.span([
  {
    color: vars.$scale.color.gray600,
  },
  vars.$semantic.typography.caption1Regular,
])
