import { captureException } from '@sentry/react'
import { send } from '@stackflow/compat-await-push'
import { useCallback, useRef } from 'react'
import { useRecoilValue, useRecoilCallback } from 'recoil'

import { createNativeUserInfoV2 } from '@src/apis/__generated__/poi'
import { removeDuplicatesByKey } from '@src/js-utils/array'
import { josa } from '@src/js-utils/language-korean'
import { checkInRegionInfoAtom } from '@src/place-utils/region/store'
import { userInfoAtom } from '@src/place-utils/user/store'
import { BottomSheet } from '@src/react-utils/components/BottomSheet'
import { errorMessage } from '@src/react-utils/components/Error/constants'
import { Picker } from '@src/react-utils/components/Picker'
import { useToast } from '@src/react-utils/components/Toast'
import { useActivity, useNavigator } from '@src/stackflow'

import { useAnalytics } from '../../hooks'
import { myResidenceInfoAtom } from '../../store'
import { getYearsMap } from '../../utils'

export type YearPickerBottomSheetForListData = {
  selectedYear: number
}

export const YearPickerBottomSheet = () => {
  const { id: activityId } = useActivity()
  const { pop } = useNavigator()
  const logEvent = useAnalytics()
  const { showToast } = useToast()

  const { authToken: xAuthToken } = useRecoilValue(userInfoAtom)
  const { name: checkinRegionName, id } = useRecoilValue(checkInRegionInfoAtom)

  const yearsMap = getYearsMap(1900)
  const values = Object.values(yearsMap).map((x) => x.text)
  const initialIndex = values.length - 1

  const selectedRef = useRef(yearsMap[initialIndex].value)

  const handleChange = (index: number) => {
    selectedRef.current = yearsMap[index].value
  }

  const handleCreateResidenceInfo = useRecoilCallback(
    ({ set }) =>
      async ({
        id,
        startYearOfResidence,
      }: Parameters<typeof createNativeUserInfoV2>[number]['params']['createNativeUserInfoBody']['residenceInfo']) => {
        try {
          const {
            data: { data: newResidenceInfo },
          } = await createNativeUserInfoV2({
            params: {
              xAuthToken,
              createNativeUserInfoBody: {
                residenceInfo: { id, startYearOfResidence },
              },
            },
          })

          set(myResidenceInfoAtom, (prev) => removeDuplicatesByKey([...prev, ...newResidenceInfo], 'regionId'))
        } catch (e) {
          captureException(e)

          showToast({
            text: errorMessage.TEMPORARY_NOT_WORKING_MESSAGE,
            duration: 'short',
          })

          return
        }
      },
    [showToast, xAuthToken]
  )
  const handleConfirm = useCallback(async () => {
    const selectedYear = selectedRef.current

    await handleCreateResidenceInfo({ id, startYearOfResidence: selectedYear })

    logEvent('click_residence_year_bottom_sheet')

    const data: YearPickerBottomSheetForListData = { selectedYear }
    send({ activityId, data })
    pop()
  }, [activityId, handleCreateResidenceInfo, id, logEvent, pop])

  return (
    <BottomSheet
      title={`지금 계신 ${josa(checkinRegionName, '은/는')}\n언제부터 거주하셨어요?`}
      contents={<Picker initIndex={initialIndex} values={values} onChange={handleChange} />}
      CTAButton={{
        label: '확인',
        onClick: handleConfirm,
      }}
      options={{
        useDividerBetweenHeaderAndContent: true,
        contentsOverflow: 'hidden',
      }}
    />
  )
}
