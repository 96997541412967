import { ImpressionObserver } from '@daangn/webview-impression'
import styled from '@emotion/styled'
import { useActivityParams } from '@stackflow/react'
import { useRef } from 'react'
import { useRecoilValue } from 'recoil'

import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'
import { ReviewInducement } from '@src/place-utils/review/ReviewInducement'
import { Section as SectionComponent } from '@src/react-utils/components/Layout'

import { useAnalytics } from '../../hooks'

export const EmptyReviewSection = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const logEvent = useAnalytics()

  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))

  const contentRef = useRef<HTMLDivElement>(null)

  const handleClickWriteReview = async () => {
    if (!poi) return

    logEvent('click_write_profile_review_button', {
      area: 'home_tab',
    })
  }

  return (
    <ImpressionObserver
      observeTargetRef={contentRef}
      log={{
        name: 'show_write_profile_review_button',
        params: {
          area: 'home_tab_no_review',
        },
      }}>
      <Section ref={contentRef}>
        <ReviewInducement poiId={poiId} onClickStarRating={handleClickWriteReview} area="home_tab" />
      </Section>
    </ImpressionObserver>
  )
}

const Section = styled(SectionComponent)({
  padding: '2rem 1rem',
})
