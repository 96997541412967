import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useRecoilValue } from 'recoil'

import { REGION } from '@src/pages/NativeReview/constants'
import { EXPERIMENT_enhancedReactionSelector } from '@src/place-utils/__generated__/experiments/enhancedReaction'
import { ReactedUser } from '@src/place-utils/reaction/store/reactions'
import { AvatarGroup } from '@src/react-utils/components/AvatarGroup'
import { useNavigator } from '@src/stackflow'

interface Props {
  poiId: string
  reactionCount: number
  reactedUsers: {
    nickname: string
    profileImageURL?: string
    residenceInfo: ReactedUser['residenceInfo']
  }[]
  onClick: () => void
}
export const Display = ({ poiId, reactedUsers, reactionCount, onClick }: Props) => {
  const { push } = useNavigator()

  const enhancedReactionExperimentSegment = useRecoilValue(EXPERIMENT_enhancedReactionSelector)

  const handleClick = () => {
    onClick()
    push('native_review_modal', { modalId: 'reaction-display', poiId })
  }

  const renderText = () => {
    switch (enhancedReactionExperimentSegment) {
      case 'treatment-A': {
        return (
          <Text>
            <span className="highlight">
              {REGION} 이웃 {reactionCount}명
            </span>
            이 맛집이라고 했어요.
          </Text>
        )
      }
      case 'treatment-C': {
        if (reactedUsers.length === 1) {
          return (
            <Text emphasis={enhancedReactionExperimentSegment === 'treatment-C'}>
              <span className="highlight">{reactedUsers[0].nickname}</span>
              님도 맛집이라고 했어요.
            </Text>
          )
        } else {
          const residencyYearsSorted = reactedUsers.sort((userA, userB) => {
            return userA.residenceInfo?.startYearOfResidence - userB.residenceInfo?.startYearOfResidence
          })

          return (
            <Text emphasis={enhancedReactionExperimentSegment === 'treatment-C'}>
              <span className="highlight">{residencyYearsSorted[0].nickname}</span>님 외{' '}
              <span className="highlight">{reactionCount - 1}명</span>도 맛집이라고 했어요.
            </Text>
          )
        }
      }
      default: {
        console.warn('Must not render Display component')

        return null
      }
    }
  }

  const avatarCount = enhancedReactionExperimentSegment === 'treatment-C' ? 3 : 5

  return (
    <Wrapper onClick={handleClick}>
      <AvatarGroup
        size="xxsmall"
        limit={avatarCount}
        avatars={[
          ...reactedUsers.slice(0, avatarCount).map(({ profileImageURL, nickname }) => ({
            src: profileImageURL ?? null,
            alt: nickname,
          })),
        ]}
      />
      {renderText()}
    </Wrapper>
  )
}

const Wrapper = styled('div')({
  display: 'inline-flex',
  alignItems: 'flex-start',
  gap: '0.25rem',
})
const Text = styled('span')<{ emphasis?: boolean }>([
  vars.$semantic.typography.caption1Regular,
  {
    color: vars.$scale.color.gray600,
    '.highlight': {
      ...vars.$semantic.typography.caption1Bold,
    },
  },
  ({ emphasis }) =>
    emphasis && {
      color: vars.$scale.color.gray700,
    },
])
