import { BoxButton } from '@daangn/sprout-components-button'
import IconExpandMoreRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconExpandMoreRegular'
import { receive } from '@stackflow/compat-await-push'
import { useState } from 'react'

import { useLogEvent } from '@src/react-utils/components/Analytics'
import { errorMessage } from '@src/react-utils/components/Error/constants'
import { useToast } from '@src/react-utils/components/Toast'
import { useNavigator } from '@src/stackflow'

import { visitCountMap, VisitCountMap } from '../../Modal/FrequencyPickerBottomSheet'
import { convertVisitCountToText } from '../../utils'
import { Layout, Title, Dropdown } from '../components'
import { useStepForm } from '../hooks/useStepForm'
import type { VisitCount as VisitCountType } from '../types/restaurant-review-form'

const SELECT_VISIT_COUNT_PLACEHOLDER = '방문 횟수를 선택해주세요.'

export const VisitCount = () => {
  const logEvent = useLogEvent()

  const { push } = useNavigator()
  const {
    formValue: { poi },
    submitForm,
    updateForm,
    go,
  } = useStepForm()
  const { showToast } = useToast()

  const visitCountMapper = Object.values(visitCountMap)
  const visitCountItem = visitCountMapper.find((item) => item.value === poi?.visitCount)
  const [selectedVisitCount, setSelectedVisitCount] = useState<{
    value?: VisitCountMap[0]['value']
    text: VisitCountMap[0]['text']
  }>({
    value: visitCountItem?.value,
    text: convertVisitCountToText(visitCountItem?.value),
  })

  const handleClick = async () => {
    const { value } = await receive<{ value: VisitCountType }>({
      activityId: push('native_review_modal', { modalId: 'form-frequency-picker' }).activityId,
    })

    setSelectedVisitCount({
      value,
      text: convertVisitCountToText(value),
    })
    updateForm({
      poi: {
        visitCount: value,
      },
    })
  }
  const handleSubmit = async () => {
    try {
      await submitForm()

      logEvent({ name: 'click_submit_review_button', params: {} })
      go.next()
    } catch {
      showToast({
        text: errorMessage.PLEASE_RETRY_MESSAGE,
        duration: 'short',
      })
    }
  }

  return (
    <Layout>
      <Title>
        마지막 질문이에요 🎉
        <br />이 맛집 몇 번 정도 가보셨어요?
      </Title>
      <Dropdown
        placeholder={SELECT_VISIT_COUNT_PLACEHOLDER}
        suffix={<IconExpandMoreRegular width={24} height={24} onClick={handleClick} />}
        onClick={handleClick}
        value={selectedVisitCount.text}
      />
      <BoxButton
        isDisabled={!selectedVisitCount.value}
        variant="primary"
        size="xlarge"
        width="100%"
        onClick={handleSubmit}>
        완료
      </BoxButton>
    </Layout>
  )
}
