import { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import { useScreenInstance } from '@src/react-utils/hooks/useScreenInstance'

import { subprofileUserIdAtomFamily } from '../state/others'
import {
  syncedUserPostPageAtom,
  userPostsAtom,
  userPostsMetaAtom,
  userPostsPaginationAtom,
  userPostsQuerySelectorFamily,
  userPostsSortByAtom,
} from '../state/userPost'

export const useSyncUserPosts = () => {
  const screenInstance = useScreenInstance()
  const userId = useRecoilValue(subprofileUserIdAtomFamily(screenInstance))
  const sortBy = useRecoilValue(userPostsSortByAtom(userId))
  const userPostsLoadable = useRecoilValueLoadable(userPostsQuerySelectorFamily(userId))
  const [userPosts, setUserPosts] = useRecoilState(userPostsAtom({ userId, sortBy }))
  const setUserPostsMeta = useSetRecoilState(userPostsMetaAtom({ userId, sortBy }))
  const fetchPagination = useRecoilValue(userPostsPaginationAtom({ sortBy, userId }))
  const [syncedPage, setSyncedPage] = useRecoilState(syncedUserPostPageAtom({ sortBy, userId }))

  useEffect(() => {
    if (userPostsLoadable.state !== 'hasValue' || fetchPagination.page === syncedPage || !userPostsLoadable.contents)
      return

    setUserPosts([...(userPosts ?? []), ...(userPostsLoadable.contents.data || [])])
    setUserPostsMeta(userPostsLoadable.contents.meta)
    setSyncedPage(fetchPagination.page)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPostsLoadable])
}
