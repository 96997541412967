import { StarRatingDisplay } from '@daangn/business-review-ui'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

import { Skeleton } from '@src/react-utils/components/Loading'
import { Spacing } from '@src/styles/Spacing'

import { PROFILE_AREA_HEIGHT } from '../constants'

interface Props {
  name: string
  regionName?: string
  category?: string
  starRating: number | null
  reviewCount: number
}
const Profile = ({ name, regionName, category, starRating, reviewCount }: Props) => {
  return (
    <Base>
      <Container>
        <Name>{name}</Name>
        <Tags>
          {[regionName, category].filter(Boolean).map((text, idx) => (
            <Tag key={idx}>{text}</Tag>
          ))}
        </Tags>
        <Tags>
          {typeof starRating === 'number' && starRating > 0 && (
            <StarRatingDisplay size={'small'} type="with-text" rating={starRating} />
          )}
          {reviewCount > 0 && <Tag>후기 {reviewCount}</Tag>}
        </Tags>
      </Container>
    </Base>
  )
}

const ProfileLoading = () => {
  return (
    <Base>
      <Skeleton width={140} height={26} />
      <Spacing size={4} />
      <Skeleton width={100} height={20} />
    </Base>
  )
}

const Base = styled.div({
  padding: '1.5rem 1rem',
  minHeight: `calc(${PROFILE_AREA_HEIGHT} * 0.0625rem)`,
  alignItems: 'flex-start',
  backgroundColor: vars.$semantic.color.paperDefault,
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > :not(:last-child) {
    margin: 0 0 0.125rem;
  }
`
const Name = styled.h1`
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.title2Bold}
`
const Tags = styled.div`
  display: flex;

  & > :not(:last-child) {
    margin-right: 0.25rem;
  }

  & > {
    &:not(:first-of-type) {
      &::before {
        content: '⸱';
        width: 0.188rem;
        height: 0.875rem;
        margin-right: 0.25rem;
      }
    }
  }
`
const Tag = styled('div')([
  vars.$semantic.typography.caption1Regular,
  {
    color: vars.$scale.color.gray600,
  },
])

export default Profile
export { ProfileLoading }
