import { updatePoiReview, createPoiReview } from '@daangn/local-business-network/lib/legacyReview'
import { CreateReviewResponse, ReviewDtoV2 } from '@daangn/local-business-network/lib/poi'
import { Suspense } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'

import { bizReviewLegacyApi } from '@src/apis'
import { Payload as BizReviewRequestPayload } from '@src/apis/BizReviewLegacy'
import type { Review as BizReviewType } from '@src/apis/types/BizReview'
import { usePoiInputSelectionState } from '@src/place-utils/poi/PoiInput/state'
import { checkInRegionInfoAtom } from '@src/place-utils/region/store'
import { useRefetchReviewsForAllSort } from '@src/place-utils/review'
import { myPoiReviewSelectorFamily } from '@src/place-utils/review/store/poiReview'
import { userInfoAtom } from '@src/place-utils/user/store'
import { useToast } from '@src/react-utils/components/Toast'
import { useNavigator, Screen } from '@src/stackflow'

import { reviewableBizStatusSelectorFamily } from './state/bizReview'
import { SubmitButton, SubmitParams } from './Submit'
import WritePoiReview from './WritePoiReview'

export const WritePoiReviewPage = () => {
  return (
    <Screen
      appBar={{
        title: '장소 추천하기',
        appendRight: () => {
          return (
            <Suspense>
              <PageSubmitButton />
            </Suspense>
          )
        },
      }}>
      <WritePoiReview />
    </Screen>
  )
}

const PageSubmitButton = () => {
  const { showToast } = useToast()
  const [{ id, bizAccountId: selectedBizAccountId }] = usePoiInputSelectionState()
  const { pop } = useNavigator()
  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId: id }))
  const refreshMyReview = useRecoilRefresher_UNSTABLE(myPoiReviewSelectorFamily({ poiId: id }))
  const refetchReviews = useRefetchReviewsForAllSort(id)

  const userInfo = useRecoilValue(userInfoAtom)
  const checkInRegionInfo = useRecoilValue(checkInRegionInfoAtom)
  const { reviewId: myBizReviewId } = useRecoilValue(reviewableBizStatusSelectorFamily(selectedBizAccountId ?? ''))

  const handlePoiReview = async ({ params }: { params: SubmitParams }) => {
    const isNewReview = !myReview

    try {
      const response = isNewReview
        ? await createPoiReview({
            params: {
              legacyCreatePoiReviewRequest: {
                ...params,
                tagIds: [],
              },
            },
          })
        : await updatePoiReview({
            params: {
              reviewId: myReview.id,
              legacyUpdatePoiReviewRequest: {
                ...params,
                tagIds: [],
              },
            },
          })

      handleSubmitSuccess({ type: 'POI_REVIEW', submittedReview: response.data, isNewReview })
    } catch (err) {
      handleSubmitFailure({ isNewReview })

      throw err
    }
  }

  const handleBizProfileReview = async ({
    bizAccountId,
    content,
    imageIds,
  }: {
    bizAccountId: string
    content: string
    imageIds: string[]
  }) => {
    const isNewReview = !myBizReviewId
    const payload: BizReviewRequestPayload = {
      business_account_id: bizAccountId,
      content,
      picture_ids: imageIds,
      tag_ids: [],
      region_id: String(checkInRegionInfo.id),
      review_type: 'BUSINESS_PROFILE',
    }
    try {
      const resp = isNewReview
        ? await bizReviewLegacyApi.create(payload)
        : await bizReviewLegacyApi.update(myBizReviewId, payload)

      handleSubmitSuccess({ type: 'BIZ_REVIEW', submittedReview: resp.data.data, isNewReview })
    } catch (err) {
      handleSubmitFailure({ isNewReview })

      throw err
    }
  }

  type ReviewType = 'BIZ_REVIEW' | 'POI_REVIEW'
  const handleSubmitSuccess = async <T extends ReviewType>({
    isNewReview,
  }: {
    type: T
    submittedReview: T extends 'BIZ_REVIEW' ? BizReviewType : CreateReviewResponse | ReviewDtoV2
    isNewReview: boolean
  }) => {
    showToast({
      text: `${
        isNewReview
          ? `소중한 후기 감사해요. ${userInfo?.nickname}님 덕분에 동네 정보가 풍부해졌어요.`
          : `후기를 수정 했어요.`
      }`,
      duration: isNewReview ? 'long' : 'short',
    })

    refreshMyReview()
    refetchReviews()

    pop()
  }

  const handleSubmitFailure = ({ isNewReview }: { isNewReview: boolean }) => {
    showToast({
      text: `후기 ${isNewReview ? '등록' : '수정'}에 실패했어요. 다시 한번 시도해주세요.`,
      duration: 'long',
    })
  }

  const handleSubmit = async (params: SubmitParams) => {
    return params.bizAccountId
      ? handleBizProfileReview({
          bizAccountId: params.bizAccountId,
          content: params.content,
          imageIds: params.imageIds,
        })
      : handlePoiReview({ params })
  }

  return <SubmitButton onSubmit={handleSubmit}>완료</SubmitButton>
}
