import { Fragment } from 'react'

export function renderMultilineText(text: string) {
  return text.split('\n').map((text, idx) => (
    <Fragment key={idx}>
      {text}
      <br />
    </Fragment>
  ))
}
