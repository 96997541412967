const copyTextWithVirtualTextArea__DEPRECATED = ({ text, onSuccess }: { text: string; onSuccess: () => void }) => {
  const textField = document.createElement('textarea')
  textField.innerText = text
  textField.style.cssText = 'position: absolute; top: 0; opacity: 0;'
  document.body.appendChild(textField)
  textField.select()
  const isSuccessful = document.execCommand('copy')
  textField.remove()
  if (!isSuccessful) {
    throw new Error(`document.execCommand('copy') failed`)
  }
  onSuccess()
}

export const clipboard = {
  writeText: ({ text, onSuccess, onFailure }: { text: string; onSuccess: () => void; onFailure?: () => void }) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          onSuccess()
        })
        .catch((error) => {
          console.log(error)
          onFailure ? onFailure() : copyTextWithVirtualTextArea__DEPRECATED({ text, onSuccess })
        })
    } else {
      copyTextWithVirtualTextArea__DEPRECATED({ text, onSuccess })
    }
  },
}
