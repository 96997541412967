import { getPoiReviewsByIds, getPoiReviewsByPoiId } from '@daangn/local-business-network/lib/legacyReview'
import { captureException } from '@sentry/react'
import { selectorFamily } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'
import { userInfoAtom } from '@src/place-utils/user/store'

const generateKey = generateUniqueKey('poiReview')

type PoiReviewRequestParameters = Parameters<typeof getPoiReviewsByIds>['0']['params']
type PoiReviewByUserIdRequestParameters = Parameters<typeof getPoiReviewsByPoiId>['0']['params']

export const poiReviewSelectorFamily = selectorFamily({
  key: generateKey('poiReviewSelectorFamily'),
  get: (params: PoiReviewRequestParameters) => async () => {
    try {
      const { data } = await getPoiReviewsByIds({ params })

      return data
    } catch (error) {
      captureException(error)

      throw error
    }
  },
})

export const myPoiReviewSelectorFamily = selectorFamily({
  key: generateKey('myPoiReviewSelectorFamily'),
  get:
    (selectorKey: { poiId: string }) =>
    async ({ get }) => {
      if (!selectorKey.poiId) return undefined

      const userInfo = get(userInfoAtom)

      const myUserId = userInfo.id
      const params = {
        page: 1,
        perPage: 20,
        sort: 'LATEST_CREATION',
        poiId: parseInt(selectorKey.poiId, 10),
        userId: myUserId,
      } as PoiReviewByUserIdRequestParameters
      const { data: myReview } = await getPoiReviewsByPoiId({ params })

      myReview.items = myReview.items.filter((review: any) => review.poiId === params.poiId)

      return myReview.items[0]
    },
})
