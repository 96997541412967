// import { AsyncStateKey, getAsyncState } from './async-util'
import { getDateByDayLast } from './date-util'
import type { ClientcouponStatus, CouponBoxByType, CouponBoxResponse, CouponStateReturnValue } from '../types'

export const getNextCouponMapper = (
  prevMap: { [couponBoxId: string]: CouponBoxResponse },
  nextMap: { [couponBoxId: string]: CouponBoxResponse }
) => {
  const _nextMap = Object.values(nextMap).reduce<{ [couponBoxId: string]: CouponBoxResponse }>(
    (summary, couponBoxItem) => {
      summary[couponBoxItem.id] = { ...(prevMap[couponBoxItem.id] || null), ...couponBoxItem }
      return summary
    },
    {}
  )
  return { ...prevMap, ..._nextMap }
}

// https://daangn.slack.com/archives/C01U4SFJS7N/p1664176421215839?thread_ts=1664169662.523899&cid=C01U4SFJS7N
//  status는 INACTIVE > EXPIRED > LACK_OF_QUANTITY의 우선 순위를 가짐
//  따라서 아래 if 조건의 순서를 지켜야 함.
const getUserSideCouponStatus = (coupon: CouponBoxByType): ClientcouponStatus => {
  // UserStatus {
  //   CAN_USE // 발급 받았으며, 사용 가능
  //   IS_USED // 발급 받았으며, 사용함
  //   INACTIVE_COUPON // 발급자에 의해 쿠폰 자체가 비활성화 되어 있거나, CS에 의해 정지된 상태
  //   IS_EXPIRED // 발급 받지 않았으며, 만료됨
  //   NO_QUANTITY // 발급 받지 않았으며, 수량 없음
  //   CAN_CLAIM // 발급 가능함
  //   CLAIMED_BUT_EXPIRED // 유저가 쿠폰을 받았는데 만료됨.
  // }

  const { user_status, coupon_condition, used_at } = coupon

  // 쿠폰이 INACTIVE 또는 EXPIRED인 경우에도 유저는 발급받은 쿠폰을 사용할 수 있어야한다.
  // used_at, claimed_at 같은 상태는 v1에서만 사용하고, api 버전을 v2로 올리면 user는 user_status만 바라보도록 수정
  if (used_at) {
    return 'USED'
  }

  switch (user_status) {
    case 'IS_USED':
      return 'USED'
    case 'CAN_USE':
      return 'CLAIMED'
    default:
      if (coupon_condition === 'NOT_ACTIVE') {
        return 'INACTIVE'
      }
      if (coupon_condition === 'LACK_OF_QUANTITY') {
        return 'LEAK'
      }
      return 'ACTIVE'
  }
}
const getManagerSideCouponStatus = (coupon: CouponBoxByType): ClientcouponStatus => {
  const { coupon_condition } = coupon

  switch (coupon_condition) {
    case 'NOT_ACTIVE':
      return 'INACTIVE'
    case 'LACK_OF_QUANTITY':
      return 'LEAK'
    case 'DELETED':
      return 'DELETED'
    default:
      return 'ACTIVE'
  }
}

export const getCouponStatus = (coupon: CouponBoxByType, isManager: boolean): CouponStateReturnValue => {
  const statusCode = isManager ? getManagerSideCouponStatus(coupon) : getUserSideCouponStatus(coupon)

  const expiresAtDate = coupon.expires_at ? getDateByDayLast(coupon.expires_at) : null
  const isExpired = expiresAtDate ? Date.now() >= +expiresAtDate : false

  // status조건에서 EXPIRED가 없으므로 ACTIVE는 EXPIRED가 아닐 때 가능.
  const isActiveStatus = !isExpired && statusCode === 'ACTIVE' ? true : false

  const isLeak = statusCode === 'LEAK'

  const isInactiveStatus = statusCode === 'INACTIVE'

  const isClaimed = statusCode === 'CLAIMED'

  const isUsed = statusCode === 'USED'

  const isDeleted = statusCode === 'DELETED'

  const disabled = isDeleted || isInactiveStatus || isExpired || (!isClaimed && isLeak)

  return {
    isActiveStatus,
    isInactiveStatus,
    isExpired,
    isLeak,
    isUsed,
    isClaimed,
    isDeleted,
    disabled,
  }
}

export const isCouponUserViewUiDisabled = (couponStateReturnValue: CouponStateReturnValue) => {
  const { isClaimed, isActiveStatus } = couponStateReturnValue
  if (isClaimed || isActiveStatus) {
    return false
  }

  return true
}

export const isCouponManagerViewUiDisabled = (couponStateReturnValue: CouponStateReturnValue) => {
  const { isExpired, isInactiveStatus, isLeak, isDeleted } = couponStateReturnValue
  if (isExpired || isInactiveStatus || isLeak || isDeleted) {
    return true
  }

  return false
}

export const normalizeCouponList = (coupons: CouponBoxResponse[]) => {
  return coupons.reduce<{
    ids: number[]
    mapper: { [key: string]: CouponBoxResponse }
  }>(
    (summary, couponBoxItem) => {
      summary.ids.push(couponBoxItem.id)
      summary.mapper[couponBoxItem.id] = normalizeCouponByType(couponBoxItem)

      return summary
    },
    { ids: [], mapper: {} }
  )
}

// export const normalizeCouponBookCouponList = (couponBookCouponList: CouponBookCouponsItemDto[]) => {
//   return couponBookCouponList.reduce<{
//     couponMap: Record<string, CouponBoxResponse>
//     couponBookCouponList: CouponBookCouponListItem[]
//   }>(
//     (summary, current) => {
//       const ids: number[] = []
//       current.couponBoxDtoList.forEach((coupon) => {
//         ids.push(coupon.id)
//         summary.couponMap[coupon.id] = coupon
//       })

//       summary.couponBookCouponList.push({
//         businessAccount: current.businessAccount,
//         coupons: ids,
//         hasNext: current.hasNext,
//         totalElements: current.totalElements,
//         asyncState: getAsyncState(AsyncStateKey.FULFILLED),
//         page: -1,
//       })

//       return summary
//     },
//     { couponMap: {}, couponBookCouponList: [] }
//   )
// }

export const normalizeCouponByType = (coupon: CouponBoxResponse) => {
  switch (coupon.type) {
    case 'ONLINE':
      return {
        ...coupon,
        min_condition_price: coupon.min_condition_price || 0,
        discount_amount: coupon.discount_amount || 0,
      }
    case 'THIRD_BARCODE':
      return {
        ...coupon,
        description: coupon.description || '',
        brand_logo_image_uri: coupon.brand_logo_image_uri || '',
        coupon_detail_image_uri: coupon.coupon_detail_image_uri || '',
        code: coupon.code || '',
        category_id: coupon.category_id || 0,
        region_id: coupon.region_id || 0,
      }
    default:
      return {
        ...coupon,
        category_id: coupon.category_id || 0,
        region_id: coupon.region_id || 0,
        description: coupon.description || '',
      }
  }
}
