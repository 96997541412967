import { useLoadableFamilyPaginate } from '@daangn/recoil-loadable'
import { useMemo } from 'react'

import { reviewsByPoiIdAndUserIdLoadableFamily } from '../store'

export const useRefetchReviewsForAllSort = (poiId: string, userId?: number) => {
  const { refetch: refetchLatestCreationSort } = useLoadableFamilyPaginate({
    loadable: reviewsByPoiIdAndUserIdLoadableFamily,
    variables: { poiId: parseInt(poiId, 10), sort: 'LATEST_CREATION', userId },
    fetchPolicy: 'store-or-network',
  })

  const { refetch: refetchHighestScoreSort } = useLoadableFamilyPaginate({
    loadable: reviewsByPoiIdAndUserIdLoadableFamily,
    variables: { poiId: parseInt(poiId, 10), sort: 'HIGHEST_SCORE', userId },
    fetchPolicy: 'store-or-network',
  })

  return useMemo(
    () => () => {
      refetchLatestCreationSort()
      refetchHighestScoreSort()
    },
    [refetchHighestScoreSort, refetchLatestCreationSort]
  )
}
