import { useEffect } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useResetRecoilState } from 'recoil'

import { useScreenInstance } from '@src/react-utils/hooks/useScreenInstance'

import { bizReviewPostsReactionQuery, myReactionsMapperAtomFamily, reactionStore } from '../state'
import { subprofileUserIdAtomFamily } from '../state/others'

export const useCleanupReactions = () => {
  const screenInstance = useScreenInstance()
  const userId = useRecoilValue(subprofileUserIdAtomFamily(screenInstance))
  const resetUserPosts = useResetRecoilState(reactionStore(userId))
  const refreshMyReactions = useResetRecoilState(myReactionsMapperAtomFamily(userId))
  const refreshMyBizReviewReactions = useRecoilRefresher_UNSTABLE(bizReviewPostsReactionQuery(userId))

  useEffect(() => {
    return () => {
      resetUserPosts()
      refreshMyReactions()
      refreshMyBizReviewReactions()
    }
  }, [refreshMyBizReviewReactions, refreshMyReactions, resetUserPosts, userId])
}
