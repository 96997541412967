import { karrotBridge } from '@src/bridge'
import { EmptyOrError } from '@src/react-utils/components'
import { useActivity, useNavigator } from '@src/stackflow'

export const EmptyPage = () => {
  const { pop } = useNavigator()
  const { isRoot } = useActivity()

  const close = () => {
    if (isRoot) {
      karrotBridge.closeRouter({})
    } else {
      pop()
    }
  }

  return (
    <EmptyOrError
      body={'아직 남긴 동네 가게 후기가 없어요.'}
      CTAButton={{
        label: '뒤로가기',
        onClick: close,
      }}
    />
  )
}
