import { useActivityParams } from '@stackflow/react'
import { useCallback, useMemo } from 'react'

import { ActionSheet } from '@src/react-utils/components/ActionSheet'
import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'

export const PoiOptionsActionSheet = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const logEvent = useAnalytics()
  const { pop, push } = useNavigator()

  const handleClose = useCallback(() => {
    pop()
  }, [pop])

  const sheets = useMemo(
    () => [
      {
        label: '정보 수정 제안',
        onClick: () => {
          logEvent('click_suggest_edit_info_button', {
            area: 'navbar_more_options',
          })
          pop()
          push('update_poi_suggestion', { poiId })
        },
      },
    ],
    [logEvent, poiId, pop, push]
  )

  return (
    <ActionSheet
      dismissButton={{
        label: '닫기',
        onClick: handleClose,
      }}
      actionButtons={sheets}
      onOutsideClick={handleClose}
    />
  )
}
