import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { MouseEventHandler, ReactNode } from 'react'

export type ButtonTheme =
  | 'primary-filled'
  | 'primary-low'
  | 'primary-outlined'
  | 'secondary'
  | 'secondary-low'
  | 'secondary-outlined'
  | 'text'
  | 'text-link'
type ButtonSize = '18' | '16' | '14' | '11'

export interface Props {
  className?: string
  styleTheme: ButtonTheme
  textSize?: ButtonSize
  fullwidth?: boolean
  disabled?: boolean
  form?: string
  type?: 'button' | 'reset' | 'submit'
  children: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const Button = ({
  className,
  styleTheme = 'text',
  textSize = '16',
  fullwidth = false,
  disabled = false,
  type,
  children,
  form,
  onClick,
}: Props) => {
  return (
    <StyledButton
      className={className}
      styleTheme={styleTheme}
      textSize={textSize}
      fullwidth={fullwidth}
      disabled={disabled}
      type={type}
      form={form}
      onClick={onClick}>
      {children}
    </StyledButton>
  )
}

const StyledButton = styled.button<{
  styleTheme: ButtonTheme
  fullwidth: boolean
  textSize: ButtonSize
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  svg {
    margin: 0 4px 0 0;
  }
  ${({ fullwidth }) => (fullwidth ? 'width: 100%;' : '')}
  ${({ styleTheme }) => mapThemeToStyle(styleTheme)}
  ${({ textSize }) => mapSizeToStyle(textSize)}
`

// styles for difference size
const mapSizeToStyle = (textSize: ButtonSize) => {
  switch (textSize) {
    case '18':
      return LargeText
    case '16':
      return MediumText
    case '14':
      return SmallText
    case '11':
      return ExtraSmallText
    default:
      return MediumText
  }
}

const LargeText = css`
  border-radius: 6px;
  padding: 11px 20px;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.03em;
`
const MediumText = css`
  border-radius: 5px;
  padding: 8.5px 16px;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.02em;
`

const SmallText = css`
  border-radius: 4px;
  padding: 8.5px 14px;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
`
const ExtraSmallText = css`
  border-radius: 3px;
  padding: 4px 10px;
  font-size: 11px;
  line-height: 135%;
  letter-spacing: -0.02em;
`

// styles for difference theme
const mapThemeToStyle = (theme: ButtonTheme) => {
  switch (theme) {
    case 'primary-filled':
      return PrimaryFilled
    case 'primary-low':
      return PrimaryLow
    case 'primary-outlined':
      return PrimaryOutlined
    case 'secondary':
      return Secondary
    case 'secondary-low':
      return SecondaryLow
    case 'secondary-outlined':
      return SecondaryOutlined
    case 'text':
      return Text
    case 'text-link':
      return TextLink
    default:
      return Text
  }
}

const PrimaryFilled = css`
  color: ${vars.$semantic.color.onPrimary};
  background-color: ${vars.$semantic.color.primary};
  border: none;
  &:active {
    background-color: ${vars.$semantic.color.primaryPressed};
  }

  &:disabled {
    color: ${vars.$scale.color.gray500};
    background-color: ${vars.$scale.color.gray300};
  }
`
const PrimaryLow = css`
  color: ${vars.$semantic.color.primary};
  background-color: ${vars.$semantic.color.primaryLow};
  border: none;
  &:active {
    background-color: ${vars.$semantic.color.primaryLowPressed};
  }

  &:disabled {
    color: ${vars.$scale.color.gray500};
    background-color: ${vars.$scale.color.gray300};
  }
`
const PrimaryOutlined = css`
  color: ${vars.$semantic.color.primary};
  background-color: transparent;
  border: solid 1px ${vars.$semantic.color.primary};
  // &:active,
  // &:hover {
  //   background-color: ${vars.$semantic.color.primaryLowPressed};
  // }
  &:disabled {
    border: solid 1px ${vars.$semantic.color.divider2};
    color: ${vars.$scale.color.gray400};
  }
`
const Secondary = css`
  color: ${vars.$scale.color.gray900};
  background-color: ${vars.$semantic.color.secondaryLow};
  border: none;

  &:active {
    background-color: ${vars.$scale.color.gray300};
  }
`
const SecondaryLow = css`
  color: ${vars.$scale.color.gray900};
  background-color: ${vars.$semantic.color.secondaryLow};
  border: none;

  &:active {
    background-color: ${vars.$scale.color.gray300};
  }
`
const SecondaryOutlined = css`
  color: ${vars.$scale.color.gray900};
  background-color: transparent;
  border: solid 1px ${vars.$semantic.color.divider3};
  // &:active,
  // &:hover {
  //   background-color: ${vars.$semantic.color.grayPressed};
  // }
  &:disabled {
    color: ${vars.$scale.color.gray400};
    border: solid 1px ${vars.$semantic.color.divider2};
  }
`
const Text = css`
  color: ${vars.$scale.color.gray700};
  background-color: transparent;
  border: none;
  // &:active,
  // &:hover {
  //   background-color: ${vars.$semantic.color.grayPressed};
  // }
  &:disabled {
    color: ${vars.$scale.color.gray400};
  }
`
const TextLink = css`
  color: ${vars.$semantic.color.secondary};
  background-color: transparent;
  border: none;
  // &:active,
  // &:hover {
  //   background-color: ${vars.$scale.color.blueAlpha100};
  // }
  &:disabled {
    color: ${vars.$scale.color.gray400};
  }
`

export default Button
