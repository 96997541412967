import { atom } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'

export const writePoiReviewKey = generateUniqueKey('writePoi')

export const imageIdsAtom = atom({
  key: writePoiReviewKey('imageIds'),
  default: [] as string[],
})

export const contentAtom = atom({
  key: writePoiReviewKey('content'),
  default: '',
})
