import { getDateDiffFromNow } from '@daangn/local-business-kit'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

import { PROFILE_PLACEHOLDER } from '@src/constants/image'

import { RecommendBadge } from './RecommendBadge'

interface UserProfileProps {
  nickname: string
  profileImage?: string
  regionName?: string
  createdAt: string
  updatedAt?: string
  isRecommended?: boolean
}
interface Props extends UserProfileProps {
  className?: string
}
export const UserProfile = ({
  nickname,
  profileImage,
  regionName,
  createdAt,
  updatedAt,
  isRecommended,
  className,
}: Props) => {
  const _updatedAt =
    updatedAt && updatedAt !== createdAt
      ? `${getDateDiffFromNow(new Date(updatedAt)).text} 전 수정`
      : `${getDateDiffFromNow(new Date(createdAt)).text} 전`
  const info = [regionName, _updatedAt]

  return (
    <Container className={className}>
      <ProfileImage src={profileImage || PROFILE_PLACEHOLDER} />
      <Data>
        <Title>
          <Name>{nickname}</Name>
          {isRecommended && <RecommendBadge />}
        </Title>
        <InfoList>
          {info
            .filter((info) => Boolean(info))
            .map((info, idx) => {
              return <Info key={idx}>{info}</Info>
            })}
        </InfoList>
      </Data>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
`
const ProfileImage = styled.img`
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  align-self: flex-start;
`
const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`
const Title = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
`
const Name = styled.span`
  height: 1.25rem;
  width: fit-content;
  font-weight: 700;
  font-size: 0.875rem;
  color: ${vars.$scale.color.gray900};
  line-height: 143%;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

const InfoList = styled.ul`
  display: inline-flex;

  & > :not(:last-child) {
    margin-right: 0.25rem;
  }
`

const Info = styled.li`
  height: 1.25rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 0.813rem;
  color: ${vars.$scale.color.gray600};
  line-height: 154%;
  letter-spacing: -0.02em;

  &:not(:first-of-type) {
    &::before {
      content: '⸱';
      width: 0.188rem;
      height: 0.875rem;
      margin-right: 0.25rem;
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 700;
      font-size: 0.625rem;
      line-height: 140%;
      color: ${vars.$scale.color.gray600};
    }
  }
`
