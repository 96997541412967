import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import axios, { AxiosError } from 'axios'
import { ReactNode } from 'react'
import { FallbackProps } from 'react-error-boundary'

import { karrotBridge } from '@src/bridge'
import { useActivity, useNavigator } from '@src/stackflow'
import { Spacing } from '@src/styles/Spacing'

import { errorMessage } from './constants'
import { getPoiErrorStatusCode } from './modules'

interface PoiErrorResponse {
  error: string
  statusCode: number
  message: string
}
export type AxiosPoiError = AxiosError<PoiErrorResponse>
export type ErrorType = Error | AxiosPoiError

type ConfirmActionType = 'close' | 'retry'

interface Props {
  message?: ReactNode
  confirmType?: ConfirmActionType
  confirmButton?: ReactNode
  error: ErrorType
  resetErrorBoundary: FallbackProps['resetErrorBoundary']
  onConfirm?: () => void
}

function ErrorUI({ confirmType, message, confirmButton, resetErrorBoundary, error, onConfirm }: Props) {
  const { isRoot } = useActivity()
  const { pop } = useNavigator()
  const isAxiosError = axios.isAxiosError(error)

  const getErrorMessage = () => {
    const { AUTHENTICATION_MESSAGE, PLEASE_RETRY_MESSAGE, TEMPORARY_NOT_WORKING_MESSAGE, NOT_FOUND_POI_MESSAGE } =
      errorMessage

    if (!error || !isAxiosError) {
      return TEMPORARY_NOT_WORKING_MESSAGE
    }

    const statusCode = getPoiErrorStatusCode(error)
    if (error.config.url?.includes('/api/v2/pois/') && statusCode === '404') {
      return NOT_FOUND_POI_MESSAGE
    }

    if (!statusCode) {
      return TEMPORARY_NOT_WORKING_MESSAGE
    }

    if (statusCode === '401') {
      return AUTHENTICATION_MESSAGE
    }

    if (statusCode[0] === '4') {
      return TEMPORARY_NOT_WORKING_MESSAGE
    } else if (statusCode[0] === '5') {
      return PLEASE_RETRY_MESSAGE
    }
  }

  const renderConfirmButton = () => {
    const confirmButtomObject = {
      close: (
        <StyledConfirmButton
          onClick={() => {
            onConfirm?.()
            if (isRoot) {
              karrotBridge.closeRouter({})
            } else {
              pop()
            }
          }}>
          닫기
        </StyledConfirmButton>
      ),
      retry: (
        <StyledConfirmButton
          onClick={() => {
            onConfirm?.()
            resetErrorBoundary()
          }}>
          다시 시도하기
        </StyledConfirmButton>
      ),
    }

    if (confirmType) {
      return confirmButtomObject[confirmType]
    }

    // MEMO: 서버 에러의 경우(5xx)는 리로드를 몇번 하고 닫기로 바꾼다던지의 대응이 필요해서 우선 주석처리 해놔요!
    // const statusCode = getPoiErrorStatusCode(error)
    // if (statusCode && statusCode[0] === '5') {
    //   return confirmButtomObject['retry']
    // } else {
    //   return confirmButtomObject['close']
    // }
    return confirmButtomObject['close']
  }

  return (
    <Container>
      <StyledMessage>{message || getErrorMessage()}</StyledMessage>
      <Spacing size={15} />
      {confirmButton || renderConfirmButton()}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${vars.$semantic.color.paperDefault};
`
const StyledMessage = styled.div`
  ${vars.$semantic.typography.subtitle1Regular};
  color: ${vars.$scale.color.gray600};
  white-space: pre-line;
  text-align: center;
`
const StyledConfirmButton = styled.button`
  background: none;
  border: none;
  ${vars.$semantic.typography.subtitle1Regular};
  border-radius: 6px;
  border: 1px solid ${vars.$scale.color.gray300};
  min-width: 180px;
  padding: 7px 0;
`

ErrorUI.ConfirmButton = StyledConfirmButton
export default ErrorUI
