import { ActivityComponentType } from '@stackflow/react'

import { FailedVisitBottomSheet } from './Modal/FailedVisitBottomSheet'
import OptionsBottomSheet from './Modal/OptionsBottomSheet'
import { WrapUpBottomSheet } from './Modal/WrapUpBottomSheet'

type ModalType = 'failedVisitBottomSheet' | 'poiOptionsBottomSheet' | '22-23-wrap-up'
export type WinterSnackModalParams = {
  modalId: ModalType
  selectedPoiId?: string
  filterId?: string
}

const WinterSnackModal: ActivityComponentType<WinterSnackModalParams> = ({ params: { modalId } }) => {
  return (
    <>
      {(() => {
        switch (modalId) {
          default:
            return null
          case 'failedVisitBottomSheet':
            return <FailedVisitBottomSheet />
          case 'poiOptionsBottomSheet':
            return <OptionsBottomSheet />
          case '22-23-wrap-up':
            return <WrapUpBottomSheet />
        }
      })()}
    </>
  )
}

export default WinterSnackModal
