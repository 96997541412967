import { watchV2, unwatchV2 } from '@daangn/local-business-network/lib/businessAccount'

import { createWatchV2, deleteWatchV2 } from '@src/apis/__generated__/poi'

import { WatchPlaceIdDto } from '../store'

export const addWatchApi = async (watchPlaceId: WatchPlaceIdDto, authToken: string) => {
  switch (watchPlaceId.targetType) {
    case 'POI':
      await createWatchV2({
        params: {
          xAuthToken: authToken,
          createWatchBodyV2: {
            poiId: watchPlaceId.targetId,
          },
        },
      })
      break
    case 'BA':
      await watchV2({
        params: {
          businessAccountId: parseInt(watchPlaceId.targetId, 10),
        },
      })
      break
    default:
      throw new Error(`Invalid watchPlaceId targetType ${watchPlaceId.targetType}. Only POI and BA are allowed.`)
  }
}

export const removeWatchApi = async (watchPlaceId: WatchPlaceIdDto, authToken: string) => {
  switch (watchPlaceId.targetType) {
    case 'POI':
      await deleteWatchV2({
        params: {
          xAuthToken: authToken,
          deleteWatchBodyV2: { poiId: watchPlaceId.targetId },
        },
      })
      break
    case 'BA':
      await unwatchV2({
        params: {
          businessAccountId: parseInt(watchPlaceId.targetId, 10),
        },
      })
      break
    default:
      throw new Error(`Invalid watchPlaceId targetType ${watchPlaceId.targetType}. Only POI and BA are allowed.`)
  }
}
