import { ThumbsUp } from '@daangn/business-review-ui'
import { captureException } from '@sentry/react'
import debounce from 'lodash/debounce'
import { useMemo, useRef, useState, useLayoutEffect } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { createReactionV2, deleteReactionV2 } from '@src/apis/__generated__'
import { convertResourceTypeToSourceType } from '@src/place-utils/reaction/utils/convertResourceTypeToSourceType'
import { userInfoAtom } from '@src/place-utils/user/store'
import { errorMessage } from '@src/react-utils/components/Error/constants'
import { useToast } from '@src/react-utils/components/Toast'

import { useAnalytics } from './hooks'
import { Reaction, reactionItemSelector } from './state'

interface Props {
  resourceType: Reaction['resourceType']
  resourceId: Reaction['resourceId']
  type: Reaction['type']
}

const UpvoteButton = ({ resourceType, resourceId, type }: Props) => {
  const logEvent = useAnalytics()
  const { showToast } = useToast()

  /**
   * client reaction state
   */
  const reactionRecoilState = reactionItemSelector({ resourceType, resourceId, type })

  const userInfo = useRecoilValue(userInfoAtom)
  const reaction = useRecoilValue(reactionRecoilState)

  const isTouchedRef = useRef<boolean>(false)

  /**
   * synced state with server data
   */
  const [syncedReaction, setSyncedReaction] = useState<{
    isUpvoted: boolean
    upvoteCount: number
  }>({
    isUpvoted: false,
    upvoteCount: 0,
  })

  const handleUpvoteFetching = useRecoilCallback(
    ({ set }) =>
      ({ prevUpvote }: { prevUpvote: boolean }) => {
        const nextUpvote = !prevUpvote

        if (syncedReaction.isUpvoted === nextUpvote) return

        const upvote = async () => {
          try {
            await createReactionV2({
              params: {
                xAuthToken: userInfo!.authToken,
                createReactionBody: {
                  resourceId,
                  resourceType,
                  type,
                },
              },
            })

            setSyncedReaction((prev) => ({
              isUpvoted: true,
              upvoteCount: prev.upvoteCount + 1,
            }))

            /** @deprecated */
            logEvent('click_upvote_button', {
              source_type: convertResourceTypeToSourceType(resourceType),
              source_id: resourceId,
            })

            const _resource = convertResourceTypeToSourceType(resourceType)
            if (_resource === 'poi_review') {
              logEvent('click_review_like_button', {
                review_id: resourceId,
              })
            }
            if (_resource === 'community_post' || _resource === 'community_comment') {
              logEvent('click_story_like_button', {
                source_type: _resource === 'community_post' ? 'article' : 'comment',
                source_id: resourceId,
              })
            }
          } catch (err) {
            captureException(err)
            set(reactionRecoilState, (prev) => {
              return {
                ...prev,
                isReactedByMe: false,
                reactionCount: syncedReaction.upvoteCount,
              }
            })
            showToast({
              text: errorMessage.PLEASE_RETRY_MESSAGE,
              duration: 'short',
            })
          }
        }

        const cancelUpvote = async () => {
          try {
            await deleteReactionV2({
              params: {
                xAuthToken: userInfo!.authToken,
                deleteReactionBody: {
                  resourceId,
                  resourceType,
                  type,
                },
              },
            })

            setSyncedReaction((prev) => ({
              isUpvoted: false,
              upvoteCount: prev.upvoteCount - 1,
            }))

            logEvent('click_cancel_upvote_button', {
              source_type: convertResourceTypeToSourceType(resourceType),
              source_id: resourceId,
            })
          } catch (err) {
            captureException(err)
            set(reactionRecoilState, (prev) => {
              return {
                ...prev,
                isReactedByMe: true,
                reactionCount: syncedReaction.upvoteCount,
              }
            })
            showToast({
              text: errorMessage.PLEASE_RETRY_MESSAGE,
              duration: 'short',
            })
          }
        }

        nextUpvote ? upvote() : cancelUpvote()
      },
    [logEvent, reactionRecoilState, resourceId, resourceType, showToast, syncedReaction, type, userInfo]
  )

  const debouncedHandleUpvoteFetching = useMemo(() => debounce(handleUpvoteFetching, 300), [handleUpvoteFetching])

  const handleUpvote = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        if (!isTouchedRef.current) {
          isTouchedRef.current = true
        }
        const prevUpvote = snapshot.getLoadable(reactionRecoilState).getValue().isReactedByMe

        set(reactionRecoilState, (prev) => {
          return {
            ...prev,
            isReactedByMe: prevUpvote ? false : true,
            reactionCount: prevUpvote ? prev.reactionCount + -1 : prev.reactionCount + 1,
          }
        })

        debouncedHandleUpvoteFetching({ prevUpvote })
      },
    [debouncedHandleUpvoteFetching, reactionRecoilState]
  )

  useLayoutEffect(() => {
    /**
     * set initial server data to state for sync
     */
    if (isTouchedRef.current === true) return

    setSyncedReaction({
      isUpvoted: reaction.isReactedByMe,
      upvoteCount: reaction.reactionCount,
    })
  }, [reaction])

  return <ThumbsUp count={reaction.reactionCount} isSelected={reaction.isReactedByMe} onClick={handleUpvote} />
}

export default withErrorBoundary(UpvoteButton, {
  fallbackRender: () => null,
})
