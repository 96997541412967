import { StarRatingDisplay as StarRatingDisplayComponent } from '@daangn/business-review-ui'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'

export const StarRatingDisplay = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))
  const starRating = poi?.starRating

  if (!starRating) {
    return null
  }
  return (
    <Base>
      <StarRatingDisplayComponent size="large" type="with-text" rating={starRating} />
      <Description>{poi?.region?.name ?? ''} 근처 동네인증한 이웃의 별점을 우선으로 반영해요.</Description>
    </Base>
  )
}

const Base = styled('div')({
  display: 'flex',
  padding: '0.75rem 1rem 0.5rem 1rem',
  flexFlow: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '0.125rem',
  alignSelf: 'stretch',
})
const Description = styled('p')([
  vars.$semantic.typography.caption1Regular,
  {
    color: vars.$scale.color.gray600,
  },
])
