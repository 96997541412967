import styled from '@emotion/styled'
import { CSSProperties, ReactNode, useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'

interface Props {
  maxLength: number
  focusedCardIndex: number
  children: ReactNode
  className?: string
  slideMargin?: number
  slideGap?: number
  height?: number
  onSwipeCardEnd: (cursor: number) => void
}

function Slides({
  maxLength,
  focusedCardIndex,
  slideMargin = 16,
  slideGap = 8,
  onSwipeCardEnd,
  children,
  ...props
}: Props) {
  const [cursor, setCursor] = useState(() => Math.max(0, focusedCardIndex))
  const [cameraPosition, setCameraPosition] = useState(0)
  const swipeBreakpoint = 50
  const [swiping, setSwiping] = useState(false)

  function handleSwipeEnd(calcValidCursor: (cursor: number) => number) {
    setCursor(calcValidCursor)
    setCameraPosition(0)
    onSwipeCardEnd(calcValidCursor(cursor))
  }

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      if (eventData.dir === 'Left' || eventData.dir === 'Right') {
        setSwiping(true)
        setCameraPosition(-eventData.deltaX)
      }
    },
    onSwipedLeft: (eventData) => {
      if (Math.abs(eventData.deltaX) > swipeBreakpoint) {
        setTimeout(() => {
          const getValidCursor = (cursor: number) => Math.min(cursor + 1, maxLength)
          handleSwipeEnd(getValidCursor)
        })
      } else {
        setCameraPosition(0)
      }
      setSwiping(false)
    },
    onSwipedRight: (eventData) => {
      if (Math.abs(eventData.deltaX) > swipeBreakpoint) {
        setTimeout(() => {
          const getValidCursor = (cursor: number) => Math.max(cursor - 1, 0)
          handleSwipeEnd(getValidCursor)
        })
      } else {
        setCameraPosition(0)
      }
      setSwiping(false)
    },
    onSwiped: (eventData) => {
      if (eventData.dir === 'Up' || eventData.dir === 'Down') {
        setCameraPosition(0)
        setSwiping(false)
      }
    },
  })

  useEffect(() => {
    if (focusedCardIndex === -1) return

    setCursor(Math.max(0, focusedCardIndex))
  }, [focusedCardIndex])

  const containerStyle = {
    '--cursor': cursor,
    '--camera-pos': `${-cameraPosition}px`,
    '--camera-transition': swiping ? 'none' : 'transform 0.3s ease',
    '--slide-margin': `${slideMargin}px`,
    '--slide-gap': `${slideGap}px`,
  } as CSSProperties

  return (
    <Container {...handlers} style={containerStyle} {...props}>
      <Track>
        <Camera>{children}</Camera>
      </Track>
    </Container>
  )
}

const Container = styled.div<Pick<Props, 'height'>>`
  width: 100%;
  height: ${({ height = 172 }) => `${height}px`};
  overflow: hidden;
`

const Track = styled.div`
  position: relative;
  transition: var(--camera-transition);
  // 트렉을 "카드 넓이"와 "카드 간격" 하나를 더한 값(+)을 "선택된 인덱스(cursor)" 번(*) 좌측(-x)으로 이동한다.
  transform: translateX(calc((-100% + var(--slide-margin) * 2 - var(--slide-gap)) * var(--cursor) + var(--camera-pos)));
  height: 100%;
`

const Camera = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: flex-end;
`

export default Slides
