import { captureException } from '@sentry/react'
import { selectorFamily } from 'recoil'

import { communityApi } from '@src/apis'
import { appInfoAtom } from '@src/place-utils/device/store'
import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'

import { subjectIdMap } from '../constants/subject-id'
import { getNativeURL } from '../utils'

export const storyArticleWritingURLSelectorFamily = selectorFamily<
  Awaited<ReturnType<typeof communityApi.getArticleWritingURL>>['data'],
  Pick<Parameters<typeof communityApi.getArticleWritingURL>[number], 'writingGuide'> & {
    poiId: string
    subject?: keyof typeof subjectIdMap
  }
>({
  key: 'story-article-writing-URL-selector-family',
  get:
    ({ subject, poiId, writingGuide }) =>
    async ({ get }) => {
      const subjectId = subject ? subjectIdMap[subject] : undefined

      try {
        const { userAgent } = get(appInfoAtom)
        const {
          data: { targetUri },
        } = await communityApi.getArticleWritingURL({
          userAgent,
          serviceType: 'poi',
          subjectId,
          poiId: +poiId,
          writingGuide,
        })

        return {
          targetUri,
        }
      } catch (err) {
        captureException(err)

        const poi = get(poiByIdSelectorFamily({ poiId }))
        const name = poi?.name
        const address = poi?.address || poi?.jibunAddress || undefined

        const nativeScheme = getNativeURL.articleWriting({
          interestId: subjectId,
          poi: { id: poiId, name, address },
        })

        return {
          targetUri: nativeScheme,
        }
      }
    },
})

export const storyArticleDetailURLSelectorFamily = selectorFamily<{ targetURI: string }, { articleId: number }>({
  key: 'story-article-detail-URL-selector-family',
  get:
    ({ articleId }) =>
    async ({ get }) => {
      try {
        const { userAgent } = get(appInfoAtom)
        const { data } = await communityApi.getArticleDetailURL({
          userAgent,
          serviceType: 'poi',
          articleIds: [articleId],
        })
        return { targetURI: data[articleId].targetUri }
      } catch (err) {
        captureException(err)

        const nativeScheme = getNativeURL.articleDetail({ articleId })

        return { targetURI: nativeScheme }
      }
    },
})
