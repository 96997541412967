import { TextField, SeedTextFieldProps } from '@daangn/sprout-components-text-field'
import { css } from '@emotion/css'

interface Props {
  onClick: () => void
  suffix: SeedTextFieldProps['suffix']
  placeholder: SeedTextFieldProps['placeholder']
  value: SeedTextFieldProps['value']
}
export const Dropdown = ({ onClick, suffix, placeholder, value }: Props) => {
  return (
    <TextField
      variant="outlined"
      size="medium"
      placeholder={placeholder}
      suffix={suffix}
      ref={(ref) => {
        if (!ref) return

        ref.onclick = onClick
      }}
      isReadOnly
      value={value}
      width="100%"
      UNSAFE_className={dropdownStyleOverride}
    />
  )
}

const dropdownStyleOverride = css({
  '& > div[data-part=head] ': {
    margin: 0,
  },
  '& > div[data-part=field]': {
    background: 'transparent !important',
    margin: 0,
  },
  '& > div[data-part=foot]': {
    margin: 0,
  },
})
