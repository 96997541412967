import { atom, useRecoilState } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'

export const writePoiReviewKey = generateUniqueKey('writePoi')

export const EMPTY_SELECTED_POI = {
  id: '',
  name: '',
  bizAccountId: undefined,
}

export interface SelectedPoiInfo {
  id: string
  name: string
  bizAccountId?: string
}
export const selectedPoiInfoAtom = atom<SelectedPoiInfo>({
  key: writePoiReviewKey('selectedPoiInfo'),
  default: EMPTY_SELECTED_POI,
})

export const usePoiInputSelectionState = () => {
  return useRecoilState(selectedPoiInfoAtom)
}
