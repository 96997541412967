import { useLoadableFamilyPaginate } from '@daangn/recoil-loadable'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import {
  Review as ReviewComponent,
  myPoiReviewSelectorFamily,
  reviewsByPoiIdAndUserIdLoadableFamily,
} from '@src/place-utils/review'
import { SectionHeader as SectionHeaderComponent } from '@src/react-utils/components'
import { Button as ButtonComponent } from '@src/react-utils/components/Button'
import { Section as SectionComponent } from '@src/react-utils/components/Layout'

import { EmptyReviewSection } from './EmptyReviewSection'
import { useAnalytics, useTab } from '../../hooks'
import UpvoteButton from '../../UpvoteButton'

const REVIEW_COUNT = 2

export const ReviewSection = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const { setTab } = useTab({ poiId })
  const logEvent = useAnalytics()

  const { value: reviewsStore } = useLoadableFamilyPaginate({
    loadable: reviewsByPoiIdAndUserIdLoadableFamily,
    variables: { poiId: parseInt(poiId, 10), sort: 'HIGHEST_SCORE', userId: undefined },
    fetchPolicy: 'store-or-network',
  })

  const filteredReviewList = useMemo(
    () => (reviewsStore?.items ?? []).filter((review) => review.content),
    [reviewsStore]
  )

  const handleClickMoreReviews = () => {
    logEvent('click_view_more_review_button')
    setTab('posts', 'review')
  }

  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId }))
  const userReviewId = myReview?.id

  if (!reviewsStore) return null

  const isEmpty = (reviewsStore.total ?? 0) <= 0 || reviewsStore.items.length <= 0
  const hasUserReview = !!userReviewId

  if (isEmpty) {
    return <EmptyReviewSection />
  }
  return (
    <>
      {!hasUserReview && <EmptyReviewSection />}
      {filteredReviewList.length > 0 ? (
        <Section>
          <SectionHeader type="basic" text="후기" />
          {filteredReviewList.slice(0, REVIEW_COUNT).map((review, idx) => (
            <Review
              area="home_tab"
              key={idx}
              data={review}
              options={{
                contentTextLineCount: 3,
              }}
              thumbsUp={{
                render: () => (
                  <UpvoteButton resourceType={'POI_REVIEW'} resourceId={String(review.id)} type={'UPVOTE'} />
                ),
              }}
            />
          ))}
          {reviewsStore?.total && reviewsStore.total > REVIEW_COUNT && filteredReviewList.length >= REVIEW_COUNT && (
            <Button styleTheme="secondary-low" textSize="14" fullwidth onClick={handleClickMoreReviews}>
              후기 더보기
            </Button>
          )}
        </Section>
      ) : null}
    </>
  )
}

const Section = styled(SectionComponent)({
  paddingLeft: 0,
  paddingRight: 0,
})
const SectionHeader = styled(SectionHeaderComponent)({
  paddingLeft: '1rem',
  paddingRight: '1rem',
})
const Button = styled(ButtonComponent)({
  width: 'calc(100% - 2rem)',
  marginLeft: '1rem',
  marginRight: '1rem',
})
const Review = styled(ReviewComponent)([
  {
    position: 'relative',

    '& + &': {
      ':after': {
        content: '""',
        display: 'block',
        background: vars.$semantic.color.divider1,
        position: 'absolute',
        top: 0,
        left: '1rem',
        width: 'calc(100% - 2rem)',
        height: '1px',
      },
    },
  },
])
