import { FormikErrors } from 'formik'

import type { Field, FormValues } from '../types'

export const validateAddress = (value: FormValues['address'], field?: Field<'address'>) => {
  const errors = {} as FormikErrors<FormValues>

  if (field) {
    if (field.isRequired && (!value || (!value.roadAddress && !value.coordinates && !value.jibunAddress))) {
      errors['address'] = { roadAddress: '주소를 입력해주세요.' }
    }
  }

  return errors
}
