import { useActivityParams } from '@stackflow/react'
import { useEffect } from 'react'

import { useActivity, useNavigator } from '@src/stackflow'
import { ActivitiesType } from '@src/stackflow/activities'

import type { ActivityParams } from './index'

export const SearchPoiRedirection = () => {
  const { replace } = useNavigator()
  const { name, context } = useActivity()
  const activityParams = useActivityParams<ActivityParams>()
  const path = (context as Record<'path', string | undefined>)?.path

  useEffect(() => {
    /**
     * redirect /tagPoi to /searchPoi
     */
    if ((name as keyof ActivitiesType) === 'search_poi_communityTag' || path?.includes('tagPoi')) {
      type ModifiedActivityParams = ActivityParams & {
        entry?: string
        referrer?: string
      }
      const params: ModifiedActivityParams = {
        ...activityParams,
        entry: (activityParams as ModifiedActivityParams).entry || (activityParams as ModifiedActivityParams).referrer,
      }
      replace('search_poi', params, { animate: false })
    }
  }, [activityParams, name, path, replace])

  return null
}
