import isNull from 'lodash/isNull'
import { atomFamily, selectorFamily } from 'recoil'

import { watchBAInfosByIdMapperSelectorFamily } from './bizAccount'
import { watchPOIInfosByIdMapperSelectorFamily } from './POI'
import { generateWatchRecoilKey } from './recoilKey'

export type WatchPlaceIdDtoTargetType = 'POI' | 'BA'

export type WatchPlaceIdDto = {
  targetType: WatchPlaceIdDtoTargetType
  targetId: string
}

export const placeIdsMonitoringWatchStatusAtomFamily = atomFamily<string[], WatchPlaceIdDto['targetType']>({
  key: generateWatchRecoilKey(['monitoring', 'watchStatus']),
  default: [],
})

export const monitoredPlaceWatchedSelectorFamily = selectorFamily({
  key: generateWatchRecoilKey(['monitoring', 'watched']),
  get:
    (placeWatchId: WatchPlaceIdDto) =>
    ({ get }) => {
      switch (placeWatchId.targetType) {
        case 'POI': {
          const poiIds = get(placeIdsMonitoringWatchStatusAtomFamily('POI'))
          const poiWatchMapper = get(watchPOIInfosByIdMapperSelectorFamily(poiIds))

          return poiWatchMapper[placeWatchId.targetId]
        }
        case 'BA': {
          const baIds = get(placeIdsMonitoringWatchStatusAtomFamily('BA'))
          const baWatchMapper = get(watchBAInfosByIdMapperSelectorFamily(baIds))

          return baWatchMapper[placeWatchId.targetId]
        }
      }
    },
})

export const watchedPlacesByUserMutationAtomFamily = atomFamily<
  {
    isWatched: boolean
  } | null,
  WatchPlaceIdDto
>({
  key: generateWatchRecoilKey(['watchedByUser', 'mutation']),
  default: null,
})

export const isPlaceWatchedSelectorFamily = selectorFamily<boolean, WatchPlaceIdDto>({
  key: generateWatchRecoilKey('isPlaceWatched'),
  get:
    (placeWatchId) =>
    ({ get }) => {
      const api = get(monitoredPlaceWatchedSelectorFamily(placeWatchId))
      const mutation = get(watchedPlacesByUserMutationAtomFamily(placeWatchId))

      return !isNull(mutation) ? mutation.isWatched : api
    },
})

export * from './bizAccount'
export * from './POI'
