/* eslint-disable import/order */
/* eslint-disable no-restricted-imports */
/* tslint:disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact:
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosRequestConfig, AxiosResponse } from 'axios'
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../configs/common'

import { CreateWatchBodyV2, CreateWatchV2201Response, DeleteWatchBodyV2, GetWatchesV2200Response } from '../../models'
/**
 * WatchesApi - axios parameter creator
 * @export
 */

/**
 * createWatchV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type CreateWatchV2Params = {
  xAuthToken: string
  createWatchBodyV2: CreateWatchBodyV2
}
/**
 *
 * @summary 관심 생성
 */
const createWatchV2AxiosParamCreator = async (
  // @ts-ignore
  params: CreateWatchV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/watches`
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any
  if (params['xAuthToken'] !== undefined && params['xAuthToken'] !== null) {
    localVarHeaderParameter['x-auth-token'] = String(params['xAuthToken'])
  }

  localVarHeaderParameter['Content-Type'] = 'application/json'

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }
  localVarRequestOptions.data = params['createWatchBodyV2'] || ''

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}
/**
 * deleteWatchV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type DeleteWatchV2Params = {
  xAuthToken: string
  deleteWatchBodyV2: DeleteWatchBodyV2
}
/**
 *
 * @summary 관심 해제
 */
const deleteWatchV2AxiosParamCreator = async (
  // @ts-ignore
  params: DeleteWatchV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/watches`
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'DELETE', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any
  if (params['xAuthToken'] !== undefined && params['xAuthToken'] !== null) {
    localVarHeaderParameter['x-auth-token'] = String(params['xAuthToken'])
  }

  localVarHeaderParameter['Content-Type'] = 'application/json'

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }
  localVarRequestOptions.data = params['deleteWatchBodyV2'] || ''

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}
/**
 * getWatchesV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type GetWatchesV2Params = {
  userId: number
  xAuthToken: string
  poiIds?: Array<string>
}
/**
 *
 * @summary 관심 조회
 */
const getWatchesV2AxiosParamCreator = async (
  // @ts-ignore
  params: GetWatchesV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/watches`
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any
  if (params['userId'] !== undefined) {
    localVarQueryParameter['userId'] = params['userId']
  }

  if (params['poiIds']) {
    localVarQueryParameter['poiIds'] = params['poiIds']
  }

  if (params['xAuthToken'] !== undefined && params['xAuthToken'] !== null) {
    localVarHeaderParameter['x-auth-token'] = String(params['xAuthToken'])
  }

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}

/**
 *
 * @summary 관심 생성
 */
const createWatchV2Fp = async ({
  params,
  options,
}: {
  params: CreateWatchV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<CreateWatchV2201Response>> => {
  const localVarAxiosArgs = await createWatchV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}
/**
 *
 * @summary 관심 해제
 */
const deleteWatchV2Fp = async ({
  params,
  options,
}: {
  params: DeleteWatchV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<void>> => {
  const localVarAxiosArgs = await deleteWatchV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}
/**
 *
 * @summary 관심 조회
 */
const getWatchesV2Fp = async ({
  params,
  options,
}: {
  params: GetWatchesV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<GetWatchesV2200Response>> => {
  const localVarAxiosArgs = await getWatchesV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}

export const createWatchV2 = ({ params, options }: { params: CreateWatchV2Params; options?: AxiosRequestConfig }) => {
  return createWatchV2Fp({ params, options })
}
export const deleteWatchV2 = ({ params, options }: { params: DeleteWatchV2Params; options?: AxiosRequestConfig }) => {
  return deleteWatchV2Fp({ params, options })
}
export const getWatchesV2 = ({ params, options }: { params: GetWatchesV2Params; options?: AxiosRequestConfig }) => {
  return getWatchesV2Fp({ params, options })
}
