import { useActivityParams } from '@stackflow/react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { storyArticleWritingURLSelectorFamily } from '@src/place-utils/community/store'
import { userInfoAtom } from '@src/place-utils/user/store'
import { AlertDialog } from '@src/react-utils/components/AlertDialog'
import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'
import { ActivityParams } from '../PoiDetailPage'

interface Props {
  onConfirm?: () => void
  onClose?: () => void
}

const ConfirmWriteStoryDialog = ({ onClose, onConfirm }: Props) => {
  const logEvent = useAnalytics()
  const { pop, pushScheme } = useNavigator()
  const { poiId } = useActivityParams<ActivityParams>()

  const user = useRecoilValue(userInfoAtom)

  const handleClose = () => {
    logEvent('click_cancel_write_community_post_button')
    onClose?.()
    pop()
  }

  const handleConfirm = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        logEvent('click_confirm_write_community_post_button')
        onConfirm?.()
        pop()

        const { targetUri } = await snapshot.getPromise(storyArticleWritingURLSelectorFamily({ poiId }))

        pushScheme(targetUri)
      },
    [logEvent, onConfirm, poiId, pop, pushScheme]
  )

  return (
    <AlertDialog
      contents={`게시글을 쓰면 동네생활 탭에 ${user?.nickname}님의 장소 추천 글이 보여요.`}
      CTAButton={{
        label: '글쓰기',
        onClick: handleConfirm,
      }}
      subButton={{
        label: '취소',
        onClick: handleClose,
      }}
      buttonAlign="row"
      onOutsideClick={handleClose}
    />
  )
}

export default ConfirmWriteStoryDialog
