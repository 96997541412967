import styled from '@emotion/styled'
import IconChevronRightRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightRegular'
import IconNewtopicFill from '@karrotmarket/karrot-ui-icon/lib/react/IconNewtopicFill'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { MouseEventHandler } from 'react'

import { Poi as PoiType } from '@src/apis/__generated__/poi'
import defaultPoiImage from '@src/assets/images/poi_img_map.png'
import { karrotBridge } from '@src/bridge'
import { BIZ_PROFILE_APP_BASE_URL } from '@src/constants/url'
import { formatDistanceInMeterToString } from '@src/js-utils/coordinate'
import { WEEK_IN_MS } from '@src/js-utils/date'
import { createQueryString } from '@src/js-utils/url'
import { EllipsisText } from '@src/react-utils/components/Map/EllipsisText'
import { useActivity, useNavigator } from '@src/stackflow'

import { useAnalytics } from '../../hooks'

interface Props {
  poi: PoiType
  distance?: number
  index: number
}

const Poi = ({ poi, distance, index }: Props) => {
  const logEvent = useAnalytics()
  const { referrer } = useActivityParams<{ referrer: string }>()
  const { id, name, category, createdAt, thumbnail, bizAccountId } = poi
  const { push } = useNavigator()
  const { name: activityName } = useActivity()

  const showNewBadge = createdAt && new Date().valueOf() - new Date(createdAt).valueOf() <= WEEK_IN_MS

  const handleRouteToDetailPage: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()

    logEvent('click_poi', {
      type: bizAccountId ? 'biz_account' : 'poi',
      type_id: bizAccountId ?? id,
      index,
    })

    if (bizAccountId) {
      const queryString = createQueryString({
        referrer: referrer ?? `place.${activityName}`,
        entry: `place.${activityName}`,
      })

      karrotBridge.pushRouter({
        router: {
          remote: `${BIZ_PROFILE_APP_BASE_URL}/biz_accounts/${bizAccountId}/viewer/home${queryString}`,
          navbar: false,
          scrollable: false,
        },
      })
    } else {
      push('poi_detail', { poiId: id })
    }
  }

  return (
    <Card onClick={handleRouteToDetailPage}>
      <Image>
        <img className="thumbnail-image" alt="thumbnail-image" src={thumbnail?.url || defaultPoiImage} />
      </Image>
      <Content>
        <Text>
          <Title className="title">
            {name}
            {showNewBadge && (
              <IconNewtopicFill
                className="newBadge"
                width="16px"
                height="16px"
                color={vars.$semantic.color.primary}
                fill={vars.$semantic.color.primary}
              />
            )}
          </Title>
          {(category || poi.region?.name || distance) && (
            <Information>
              {category?.name}
              {category && (poi.region?.name || distance) && <span className="bull">&bull;</span>}
              {distance ? formatDistanceInMeterToString(distance) : !!poi.region?.name && poi.region?.name}
            </Information>
          )}
        </Text>
        <IconChevronRightRegular color={vars.$scale.color.gray500} width={24} height={24} />
      </Content>
    </Card>
  )
}

const Card = styled.div`
  position: relative;
  display: flex;
  align-items: left;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid ${vars.$semantic.color.divider2};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
`

const Image = styled.div`
  height: 62px;
  flex-basis: 62px;

  .thumbnail-image {
    object-fit: cover;
    width: 62px;
    height: 62px;
    min-width: 62px;
    min-height: 62px;
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 62px);
  padding: 0 1rem 0 0;
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: calc(100% - 54px);
  padding: 10px 0 10px 14px;

  .newBadge {
    margin: 0 0 0 2px;
  }
`

const Title = styled(EllipsisText)`
  ${vars.$semantic.typography.bodyM2Regular}
  width: 100%;
  height: 20px;
  svg {
    transform: translateY(4px);
  }
`

const Information = styled(EllipsisText)`
  ${vars.$semantic.typography.caption1Regular}
  min-width: 30px;
  height: 20px;
  color: ${vars.$scale.color.gray600};

  .bull {
    margin: 0 2px 2px;
    color: ${vars.$scale.color.gray600};
    font-weight: 100;
  }
`

export default Poi
