import styled from '@emotion/styled'
import { useActivityParams } from '@stackflow/react'
import { useCallback, useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'

import { useLogEvent } from '@src/react-utils/components/Analytics'
import { KakaoMap, useKakaoMap } from '@src/react-utils/components/Map/KakaoMap'

import { Marker } from './Marker'
import { useSelectedComment } from '../hooks/useSelectedComment'
import { ActivityParams } from '../index'
import { allCommentsAtomFamily } from '../state'

const INITIAL_ZOOM_LEVEL = 5

export default () => {
  const logEvent = useLogEvent()
  const { storyArticleId } = useActivityParams<ActivityParams>()
  const { spotlightPoiOnMap } = useSelectedComment()
  const { getLevel } = useKakaoMap()

  const comments = useRecoilValue(allCommentsAtomFamily({ storyArticleId: +storyArticleId }))
  // 같은 POI가 여러번 태깅 되었을 경우, 마커가 겹쳐서 보이는 것을 방지하기 위해 뒤에서부터 중복된 POI 를 제거한다.
  // 지도에서 마커 클릭시, 제일 먼저 등록된 댓글만 보여준다. (카드 뷰에서는 POI의 중복태깅 여부와 상관 없이 모든 댓글을 보여준다.)
  const commentsWithUniquePoi = [
    ...new Map([...comments].reverse().map((comment) => [comment['poi']['id'], comment])).values(),
  ].reverse()

  const zoomLevelRef = useRef<number>(INITIAL_ZOOM_LEVEL)

  const handleClickMarker = ({ commentId }: { commentId: string }) => {
    logEvent({
      name: 'click_map_pin',
      params: { poi_id: comments.find((comment) => comment.id.toString() === commentId)?.poi.id ?? null },
    })

    spotlightPoiOnMap({ commentId, shouldPanTo: true })
  }

  const handleDragEnd = useCallback(() => {
    logEvent({ name: 'click_dragend_map', params: {} })
  }, [logEvent])

  const handleZoomChanged = useCallback(() => {
    const previousLevel = zoomLevelRef.current
    const currentLevel = getLevel()

    if (previousLevel === currentLevel) return

    logEvent({
      name: previousLevel < currentLevel ? 'click_zoom_out_map' : 'click_zoom_in_map',
      params: {
        previous_level: previousLevel,
        current_level: currentLevel,
      },
    })

    zoomLevelRef.current = getLevel()
  }, [getLevel, logEvent])

  useEffect(() => {
    spotlightPoiOnMap({ commentId: comments[0].id.toString(), shouldPanTo: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MapContainer
      center={comments[0].poi.coordinates}
      zoomable={true}
      draggable={true}
      level={INITIAL_ZOOM_LEVEL}
      onDragEnd={handleDragEnd}
      onZoomChanged={handleZoomChanged}>
      {commentsWithUniquePoi.map((comment) => {
        return (
          <Marker
            key={comment.poi.id}
            commentId={comment.id.toString()}
            poi={comment.poi}
            onClick={handleClickMarker}
          />
        )
      })}
    </MapContainer>
  )
}

const MapContainer = styled(KakaoMap)`
  width: 100%;
  height: 100%;
`
