import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { memo } from 'react'

import { ReactionButton, ReactionButtonProps } from '@src/place-utils/reaction/ReactionButton'

interface Props {
  reviewId: string
  onClick: ({ isAlreadyClicked }: { isAlreadyClicked: boolean }) => void
}
export const UpvoteButton = memo(({ reviewId, onClick }: Props) => {
  const handleClick: ReactionButtonProps['onClick'] = ({ isAlreadyClicked }) => {
    onClick({ isAlreadyClicked })
  }

  return (
    <ReactionButton
      resourceId={reviewId}
      resourceType="NATIVE_REVIEW"
      type="UPVOTE"
      onClick={handleClick}
      label={{
        render: ({ totalCount }) => (
          <Label>🤗 도움돼요{totalCount > 0 && <span className="highlight"> {totalCount}</span>}</Label>
        ),
      }}
    />
  )
})

const Label = styled.span({
  '.highlight': {
    color: vars.$semantic.color.primary,
  },
})
