import styled from '@emotion/styled'
import { captureException } from '@sentry/react'
import { useReducer } from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'

import { BoundarySearchButton as BoundarySearchButtonComponent } from '@src/react-utils/components/Map'
import { useKakaoMap } from '@src/react-utils/components/Map/KakaoMap'
import { useToast } from '@src/react-utils/components/Toast'

import { CARD_HEIGHT, FLOATING_LAYER_HEIGHT } from '../constants/styles'
import { useSearch } from '../hooks'
import { isMapBoundChangedAtom, focusedPoiIdAtom } from '../state/map'

export const BoundarySearchButton = () => {
  const { getBounds } = useKakaoMap()
  const { showToast } = useToast()
  const { searchByBounds } = useSearch()

  const focusedPoiId = useRecoilValue(focusedPoiIdAtom)

  const resetIsMapBoundsChanged = useResetRecoilState(isMapBoundChangedAtom)
  const resetFocusedPoiId = useResetRecoilState(focusedPoiIdAtom)

  const [isLoading, toggleIsLoading] = useReducer((prev) => !prev, false)

  const handleBoundarySearch = async () => {
    try {
      toggleIsLoading()

      const bounds = getBounds()

      if (!bounds?.bottomLeft.latitude || !bounds.topRight.latitude) throw new Error('failed to get map bounds')

      const { result } = await searchByBounds(bounds)

      resetIsMapBoundsChanged()
      resetFocusedPoiId()

      if (result.length === 0) {
        showToast({ text: '이 지역 재검색 결과가 없어요.', duration: 'short', bottomMargin: FLOATING_LAYER_HEIGHT })
      }
    } catch (error) {
      captureException(error)
      showToast({
        text: '잠시후 다시 시도해주세요.',
        duration: 'short',
        bottomMargin: focusedPoiId ? CARD_HEIGHT + 32 + 16 : FLOATING_LAYER_HEIGHT,
      })
    } finally {
      toggleIsLoading()
    }
  }

  return <Button onClick={handleBoundarySearch} isLoading={isLoading} />
}

const Button = styled(BoundarySearchButtonComponent)`
  white-space: nowrap;
`
