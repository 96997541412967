import { getUserCouponBoxV1 } from '@daangn/local-business-network/lib/coupon'
import { captureException } from '@sentry/react'
import { selectorFamily } from 'recoil'

import { getDistanceBetweenCoordinates } from '@src/js-utils/coordinate'
import { generateUniqueKey } from '@src/js-utils/key'
import { checkInRegionInfoAtom } from '@src/place-utils/region/store'

import type { BizAccountExtraInfo } from '../types'
import { getCouponStatus } from '../utils/coupon-util'

const generateKey = generateUniqueKey('couponBoxAPI')

export const couponBoxSelectorFamily = selectorFamily({
  key: generateKey('couponBoxSelectorFamily'),
  get:
    ({ couponBoxId }: { couponBoxId: number }) =>
    async () => {
      try {
        if (!couponBoxId) return undefined

        const { data } = await getUserCouponBoxV1({ params: { couponBoxId } })

        return data
      } catch (error) {
        captureException(error)

        throw error
      }
    },
})

export type CouponBoxBizCoordinatesState =
  | {
      key: 'NOT_SETTLED'
    }
  | {
      key: 'VISIBLE' | 'HIDDEN'
      data: {
        meterDistance: number
        label: string
        coordinates: BizAccountExtraInfo['coordinates']
      }
    }

const TEN_KILOMETER = 10000
export const couponBoxBizCoordinatesSelector = selectorFamily<CouponBoxBizCoordinatesState, number>({
  key: 'CouponBoxBizCoordinatesSelector',
  get:
    (couponBoxId) =>
    ({ get }) => {
      const coordinates = get(couponBoxSelectorFamily({ couponBoxId }))?.data?.coupon_box.business_account?.coordinates
      const { centerCoordinates: appCoordinates } = get(checkInRegionInfoAtom)

      if (!coordinates?.lat || !coordinates?.lng || !appCoordinates?.latitude || !appCoordinates.longitude) {
        return {
          key: 'NOT_SETTLED',
        }
      }

      try {
        const { meterDistance, text } = getDistanceBetweenCoordinates(
          appCoordinates.latitude,
          appCoordinates.longitude,
          coordinates.lat,
          coordinates.lng
        )
        const visible = meterDistance < 701

        return {
          key: visible ? 'VISIBLE' : 'HIDDEN',
          data: {
            coordinates,
            label: meterDistance > TEN_KILOMETER ? '10km+' : text ?? '',
            meterDistance,
          },
        } as CouponBoxBizCoordinatesState
      } catch (e) {
        return {
          key: 'NOT_SETTLED',
        }
      }
    },
})

/**
 * 쿠폰 렌더링 여부 체크
 */
export const shouldRenderCouponSelectorFamily = selectorFamily<
  boolean,
  { couponBoxId: number; isManager: boolean; forceRenderInactive?: boolean; isRenderBlock?: boolean }
>({
  key: generateKey('should-render-coupon-selector-family'),
  get:
    ({ couponBoxId, isManager, forceRenderInactive = false, isRenderBlock = false }) =>
    ({ get }) => {
      const coupon = get(couponBoxSelectorFamily({ couponBoxId }))?.data?.coupon_box
      const couponStatus = coupon && getCouponStatus(coupon, isManager)

      if (
        !coupon || // 쿠폰이 없거나 (for ts check)
        !couponStatus || // 쿠폰이 없거나
        (!forceRenderInactive && !isManager && couponStatus.isInactiveStatus) || // 유저가 비활성화 된 쿠폰을 보고있거나
        (!isManager &&
          isRenderBlock &&
          (couponStatus.isInactiveStatus || couponStatus.isLeak || couponStatus.isExpired || couponStatus.isDeleted)) // render block 을 시킬 상태에 포함된 쿠폰인 경우에는
      ) {
        // 렌더링 하지 않겠다
        return false
      }

      return true
    },
})
