import styled from '@emotion/styled'
import IconCloseRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCloseRegular'
import { vars } from '@seed-design/design-token'
import { BottomSheet } from '@stackflow/plugin-basic-ui'

import { ReactComponent as WinterSnackHeroSVG } from '@src/assets/images/winter_snack_hero.svg'
import { GO_SCHEME } from '@src/constants/url'
import { localStorage, LOCAL_STORAGE_KEYS } from '@src/js-utils/localStorage'
import { useLogEvent } from '@src/react-utils/components/Analytics'
import { Button } from '@src/react-utils/components/Button'
import { useNavigator } from '@src/stackflow'
import { safeAreaPadding } from '@src/styles/safeArea'

import { WINTER_SNACK_SERVICE_END_DATE } from '../constants/date'

export const WrapUpBottomSheet = () => {
  const logEvent = useLogEvent()
  const { pop } = useNavigator()
  const fadeOutData = new Date(WINTER_SNACK_SERVICE_END_DATE)

  const handleClose = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.WINTER_SNACK_22_23_WRAP_UP_BOTTOM_SHEET, 'hidden')
    pop()
  }

  const handleClickCTA = () => {
    logEvent({ name: 'click_event_bottom_sheet_CTA', params: {} })

    handleClose()

    setTimeout(() => {
      window.location.href = `${GO_SCHEME}place/winter-snack/22-23-wrap-up?referrer=winter_snack_map.bottom_sheet`
    }, 300)
  }
  return (
    <BottomSheet>
      <Container>
        <Header>
          <Title>
            겨울간식지도는{' '}
            <span>
              {fadeOutData.getMonth() + 1}월 {fadeOutData.getDate()}일
            </span>
            까지 볼 수 있어요
          </Title>
          <CloseButton onClick={handleClose}>
            <IconCloseRegular />
          </CloseButton>
        </Header>
        <Description>
          다시 만날 날을 기다리며,
          <br />
          겨울 간식 지도 총결산을 확인해보세요!
        </Description>
        <HeroImage>
          <WinterSnackHeroSVG />
        </HeroImage>
        <CTAButton styleTheme="primary-filled" onClick={handleClickCTA}>
          겨울 간식 지도 총결산 보기
        </CTAButton>
      </Container>
    </BottomSheet>
  )
}

const Container = styled.div([
  {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem 1rem 0',
  },
  safeAreaPadding('bottom'),
])
const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const Title = styled.div([
  vars.$semantic.typography.title3Bold,
  {
    color: vars.$scale.color.gray900,
    span: {
      color: vars.$semantic.color.primary,
    },
  },
])
const CloseButton = styled.button({
  alignSelf: 'flex-start',
  width: '1.5rem',
  minWidth: '1.5rem',
  height: '1.5rem',
  minHeight: '1.5rem',
  color: vars.$scale.color.gray900,
})
const Description = styled.div([
  vars.$semantic.typography.subtitle1Regular,
  {
    margin: '0.5rem 0 0',
  },
])
const HeroImage = styled.div([
  {
    alignSelf: 'center',
    margin: '2.25rem auto',
  },
])
const CTAButton = styled(Button)([
  vars.$semantic.typography.label1Bold,
  {
    margin: '0.75rem 0 0.5rem',
    padding: '0.875rem 0',
    width: '100%',
  },
])
