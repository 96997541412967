import IconWriteRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconWriteRegular'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { useBusinessReviewRoutingManager } from '@src/place-utils/review/hooks/useBusinessReviewRoutingManager'
import { myPoiReviewSelectorFamily } from '@src/place-utils/review/store/poiReview'
import { useScreenInstance } from '@src/react-utils/hooks/useScreenInstance'

import QuickMenuButton from './QuickMenuButton'
import { useAnalytics } from '../../hooks'
import { postsTabAtomFamily, tabAtomFamily } from '../../state'

interface Props {
  poiId: string
}

const WriteReviewButton = ({ poiId }: Props) => {
  const logEvent = useAnalytics()
  const screenInstance = useScreenInstance()
  const { openCreateReviewPage, openUpdateReviewPage } = useBusinessReviewRoutingManager()

  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId }))
  const setTab = useSetRecoilState(tabAtomFamily(screenInstance))
  const setPostTab = useSetRecoilState(postsTabAtomFamily(screenInstance))

  const handleClickWriteReview = async () => {
    /** @deprecated */
    logEvent('click_write_review_button', {
      area: 'quick_menu',
    })
    logEvent('click_write_profile_review_button', {
      area: 'quick_menu',
    })

    openCreateReviewPage({ poiId })

    setTab('posts')
    setPostTab('review')
  }

  const handleClickEditReview = async () => {
    logEvent('click_edit_review_button', {
      area: 'quick_menu',
      review_id: myReview?.id,
    })

    openUpdateReviewPage({ reviewId: myReview?.id })

    setTab('posts')
    setPostTab('review')
  }

  return (
    <QuickMenuButton
      onClick={myReview?.id ? handleClickEditReview : handleClickWriteReview}
      Icon={IconWriteRegular}
      label={`후기 ${myReview?.id ? '수정' : '작성'}`}
    />
  )
}

export default WriteReviewButton
