import { installCommunityWebDriver, makeKarrotBridge as makeCommunityBridge } from '@daangn/community-web-bridge'
import type { CommunityWebBridgeDriver } from '@daangn/community-web-bridge/lib/installCommunityWebBridgeDriver'
import { makeKarrotBridge } from '@daangn/karrotbridge'
import type { KarrotBridgeDriver } from '@daangn/karrotbridge/lib/installKarrotBridgeDriver'

export type Bridge = ReturnType<typeof makeKarrotBridge<KarrotBridgeDriver>>
type CommunityBridge = ReturnType<typeof makeCommunityBridge<CommunityWebBridgeDriver>>

// NOTE: communityBridge에서 karrotBridge를 덮어쓰는 부분이 있기 때문에 브릿지 초기화 순서를 유지해야 해요.
const { driver: communityDriver } = installCommunityWebDriver()
export const communityBridge: CommunityBridge = makeCommunityBridge({
  driver: communityDriver,
})
