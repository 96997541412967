import { useCallback } from 'react'

const DEFAULT_PADDED_MARGIN = 10

type ScrollDirection = 'vertical' | 'horizontal'

const useScrollElementIntoView = () => {
  return useCallback<
    (
      scrollElement: HTMLElement,
      targetElement: HTMLElement,
      scrollDirection?: ScrollDirection,
      options?: {
        paddingMarginStart?: number
        paddingMarginEnd?: number
      }
    ) => void
  >((scrollElement, targetElement, scrollDirection, options) => {
    const paddingMarginStart = options?.paddingMarginStart || DEFAULT_PADDED_MARGIN
    const paddingMarginEnd = options?.paddingMarginEnd || DEFAULT_PADDED_MARGIN

    if (scrollDirection === 'vertical') {
      const currentScroll = scrollElement.scrollTop
      const lowScrollLimit = targetElement.offsetTop
      const highScrollLimit = targetElement.offsetTop + targetElement.offsetHeight - scrollElement.offsetHeight

      scrollElement.scrollTop =
        currentScroll > lowScrollLimit
          ? lowScrollLimit - paddingMarginStart
          : currentScroll < highScrollLimit
          ? highScrollLimit + paddingMarginEnd
          : currentScroll
    } else {
      const currentScroll = scrollElement.scrollLeft
      const lowScrollLimit = targetElement.offsetLeft
      const highScrollLimit = targetElement.offsetLeft + targetElement.offsetWidth - scrollElement.offsetWidth

      scrollElement.scrollLeft =
        currentScroll > lowScrollLimit
          ? lowScrollLimit - paddingMarginStart
          : currentScroll < highScrollLimit
          ? highScrollLimit + paddingMarginEnd
          : currentScroll
    }
  }, [])
}

export default useScrollElementIntoView
