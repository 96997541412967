import { Category } from '@daangn/local-business-network/lib/poi'
import { MouseEvent } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { bridge } from '@src/bridge'
import SearchPage from '@src/pages/SearchCategory/SearchCategory'
import { selectedCategoryAtom } from '@src/place-utils/category/CategoryInput/state'
import { withVisualViewport } from '@src/react-utils/hocs/withVisualViewport'
import { Screen } from '@src/stackflow'

import { useAnalytics } from './hooks'
import { searchTextAtomFamily } from './state'

const BizProfileSearchCategoryPage = () => {
  const logEvent = useAnalytics()

  const searchText = useRecoilValue(searchTextAtomFamily('BIZPROFILE'))
  const setSelectedCategory = useSetRecoilState(selectedCategoryAtom)

  const handleSelectCategory = (category: Category) => {
    setSelectedCategory(category)

    bridge.analytics.log({
      name: 'click_all_category_search_results',
      params: { from: 'biz_profile', keyword: searchText },
    })
    logEvent('click_select_category_button', {
      query: searchText,
      category_id: category.id,
    })
    bridge.stream.emit('select_category', { category })
    bridge.router.close()
  }

  const handleClickCloseButton = (e: MouseEvent) => {
    e.stopPropagation()

    bridge.router.close()
  }

  return (
    <>
      <Screen
        appBar={{
          title: '카테고리 검색',
          closeButton: {
            onClick: handleClickCloseButton,
          },
        }}>
        <SearchPage onClickSearchResult={handleSelectCategory} clientName="BIZPROFILE" />
      </Screen>
    </>
  )
}

export default withVisualViewport(BizProfileSearchCategoryPage)
