import { pick } from '@src/js-utils/object'

type QuestionId = 'open-hours' | 'real-time-info' | 'price' | 'other-review' | 'product-or-service' | 'etc'
type TargetAudience = 'community' | 'owner'
type Questions = Record<
  QuestionId,
  {
    text: string
    target: {
      audience: TargetAudience[]
      unavailableReason?: string
    }
    writingGuide: string
  }
>

const ALL_ASK_COMMUNITY_QUESTIONS: Questions = Object.freeze({
  'open-hours': {
    text: '영업시간이 궁금해요',
    target: {
      audience: ['community', 'owner'],
    },
    writingGuide: '가게 영업시간이나 지금 영업 중인지 이웃에게 물어보세요.',
  },
  'real-time-info': {
    text: '실시간 정보가 궁금해요',
    target: {
      audience: ['community', 'owner'],
    },
    writingGuide: '지금 줄 서는지, 가게 상황은 어떤지 실시간 정보를 이웃에게 물어보세요.',
  },
  price: {
    text: '가격/견적이 궁금해요',
    target: {
      audience: ['community', 'owner'],
    },
    writingGuide: '가게의 가격이나 견적을 이웃에게 물어보세요.',
  },
  'other-review': {
    text: '다른 후기가 궁금해요',
    target: {
      audience: ['community'],
      unavailableReason: '후기는 이웃들에게만 질문할 수 있어요.',
    },
    writingGuide: '다른 이웃의 가게 후기를 물어보세요.',
  },
  etc: {
    text: '기타 질문',
    target: {
      audience: ['community', 'owner'],
    },
    writingGuide: '가게에 대한 자유로운 질문을 이웃에게 물어보세요.',
  },
  'product-or-service': {
    text: '상품/서비스가 궁금해요',
    target: {
      audience: ['community', 'owner'],
    },
    writingGuide: '가게의 상품이나 서비스 이용 경험을 이웃에게 물어보세요.',
  },
})

export const ASK_COMMUNITY_QUESTIONS = pick(ALL_ASK_COMMUNITY_QUESTIONS, [
  'price',
  'open-hours',
  'product-or-service',
  'other-review',
  'etc',
])
