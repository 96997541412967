import styled from '@emotion/styled'

import CouponCardManagerAction from './CouponCardManagerAction'
import CouponCardUserAction from './CouponCardUserAction'
import { CouponEventType, CouponStateReturnValue, CouponType } from '../../types'

interface CouponCardActionsProps {
  isManager: boolean
  status: CouponStateReturnValue
  couponId: number
  disabled: boolean
  uiDisabled: boolean
  target: CouponEventType
  couponType: CouponType
  bizId?: string
}

const CouponCardActions = ({
  isManager,
  status,
  couponId,
  disabled,
  uiDisabled,
  couponType,
  bizId,
  target,
}: CouponCardActionsProps) => {
  return (
    <Base>
      {isManager ? (
        <CouponCardManagerAction
          status={status}
          couponType={couponType}
          // couponId={couponId}
          // disabled={disabled}
          uiDisabled={uiDisabled}
          // bizId={bizId}
        />
      ) : (
        <CouponCardUserAction
          status={status}
          couponId={couponId}
          disabled={disabled}
          uiDisabled={uiDisabled}
          bizId={bizId}
          target={target}
          couponType={couponType}
        />
      )}
    </Base>
  )
}

const Base = styled.div``

export default CouponCardActions
