import { suggestCreationV1, getRegionV2 } from '@daangn/local-business-network/lib/businessAccount'
import { Poi } from '@daangn/local-business-network/lib/poi'
import { captureException } from '@sentry/react'
import { useActivityParams } from '@stackflow/react'
import { MouseEvent, useCallback, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { bridge } from '@src/bridge'
import { checkInRegionInfoAtom } from '@src/place-utils/region/store'
import { userInfoAtom } from '@src/place-utils/user/store'
import { FormPage } from '@src/place-utils/widgets/form/FormPage'
import { Field, SuggestionRequestBody } from '@src/place-utils/widgets/form/types'
import { useToast } from '@src/react-utils/components/Toast'

import { useAnalytics } from './hooks'
import { errorMessage } from '../../react-utils/components/Error/constants'

interface Props {
  onSubmit: (poi: Poi | null) => void
}

const CreateSuggestion = ({ onSubmit }: Props) => {
  const logEvent = useAnalytics()
  const { showToast } = useToast()
  const { variation, name } = useActivityParams<{ variation: string; name?: string }>()

  const userInfo = useRecoilValue(userInfoAtom)

  const userRegionInfo = useRecoilValue(checkInRegionInfoAtom)
  const { referrer } = useActivityParams<{ referrer: string }>()

  const fetchGetRegion = useCallback(async (address: string) => {
    try {
      const data = await getRegionV2({
        params: {
          address,
        },
      }).then((res) => res.data.data!)

      if (!data.id) throw new Error('지역 정보를 가져오는데 실패했어요.')

      return data.id
    } catch (e) {
      throw e
    }
  }, [])

  const handleSubmit = useCallback(
    async (requestBody: Partial<SuggestionRequestBody>) => {
      const AUTH_TOKEN_ERROR = 'AUTH_TOKEN_ERROR'

      try {
        if (!userInfo?.authToken) throw new Error(AUTH_TOKEN_ERROR)

        const regionId = await fetchGetRegion(requestBody.address || requestBody.jibunAddress || '')

        const resp = await suggestCreationV1({
          params: {
            suggestPoiCreationRequest: {
              referrer,
              userRegionId: Number(userRegionInfo.id),
              regionId: Number(regionId),
              name: requestBody.name,
              categoryId: requestBody?.categoryId,
              roadAddress: requestBody?.address,
              jibunAddress: requestBody?.jibunAddress,
              addressExtra: requestBody?.addressExtra,
              coordinate: requestBody.coordinates && {
                x: requestBody.coordinates.longitude,
                y: requestBody.coordinates.latitude,
              },
              categorySuggestionId: requestBody?.categorySuggestionId,
            },
          },
        })

        bridge.analytics.log({ name: 'click_poi_create_suggest_complete' })
        bridge.analytics.log({
          name: 'click_tagging_poi_register',
        })
        logEvent('click_submit_form_button')

        if (!resp.data.poi) {
          captureException(new Error('생성 요청에서 poi가 없었습니다.'))
        }

        onSubmit(resp.data.poi)
      } catch (err) {
        captureException(err)

        if (err instanceof Error) {
          if (err.message === AUTH_TOKEN_ERROR) {
            return showToast({
              text: errorMessage.AUTHENTICATION_MESSAGE,
              duration: 'long',
            })
          }
        }

        bridge.toast.open({ body: errorMessage.PLEASE_RETRY_MESSAGE })
      }
    },
    [
      logEvent,
      onSubmit,
      showToast,
      userInfo?.authToken,
      referrer,
      userRegionInfo
    ]
  )

  const fields = useMemo(() => {
    const defaultFields: Field<'name' | 'address' | 'categoryId'>[] = [
      {
        id: 'name',
        isRequired: true,
      },
      {
        id: 'address',
        isRequired: true,
      },
      {
        id: 'categoryId',
      },
    ]

    if (variation === 'winterSnack') {
      const winterSnackFields: Field<'name' | 'winterSnackMenu' | 'address' | 'photos'>[] = [
        {
          id: 'name',
          isRequired: true,
        },
        {
          id: 'winterSnackMenu',
          isRequired: true,
        },
        {
          id: 'address',
          isRequired: true,
        },
        {
          id: 'photos',
        },
      ]

      return winterSnackFields
    }

    return defaultFields
  }, [variation])

  return (
    <FormPage
      title="장소 등록"
      fields={fields}
      initialValues={{ name }}
      onClickAddressSearch={() => {
        bridge.analytics.log({
          name: 'click_tagging_address_register',
          params: { from: 'search' },
        })
        logEvent('click_search_address_input')
      }}
      onClickPinAddress={
        window.kakao
          ? (e: MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              bridge.analytics.log({
                name: 'click_tagging_address_register',
                params: { from: 'map' },
              })
              logEvent('click_pin_address_button')
            }
          : undefined
      }
      onSubmit={handleSubmit}
    />
  )
}

export default CreateSuggestion
