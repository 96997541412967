import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'
import { poiToPlaceWatchId } from '@src/place-utils/watch/utils'
import WatchButtonPlaceholder from '@src/place-utils/watch/WatchButton/Placeholder'
import { Skeleton } from '@src/react-utils/components/Loading'

import { WatchButton, CallButton, WriteReviewButton } from './QuickMenuButtons'
import { Button } from './QuickMenuButtons/QuickMenuButton'
import { QUICK_MENU_HEIGHT } from '../constants'

const QuickMenu = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))
  const brandAccountId = poi?.brandBaId
  const phoneNumber = poi?.phone

  return (
    <Container>
      {phoneNumber && !brandAccountId && <CallButton phoneNumber={phoneNumber} />}
      {poi ? <WatchButton placeWatchId={poiToPlaceWatchId(poi)} /> : <WatchButtonPlaceholder />}
      <WriteReviewButton poiId={poiId} />
    </Container>
  )
}

const QuickMenuLoading = () => {
  return (
    <Container>
      {Array.from({ length: 3 }).map((_, idx) => (
        <Button key={idx}>
          <Skeleton
            width={24}
            height={24}
            style={{
              marginBottom: '0.25rem',
            }}
          />
          <Skeleton width={50} height={20} />
        </Button>
      ))}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: calc(${QUICK_MENU_HEIGHT} * 0.0625rem);
  display: inline-flex;
  background-color: ${vars.$semantic.color.paperDefault};
`

export default QuickMenu
export { QuickMenuLoading }
