import { AlertDialog, DialogContainer } from '@daangn/sprout-components-dialog'

interface Props {
  onConfirm: () => void
}

const CategorySuggestionModal = ({ onConfirm }: Props) => {
  return (
    <DialogContainer>
      <AlertDialog
        description="소중한 제안 감사해요. 제안을 참고해 개선에 반영할게요."
        primaryActionLabel="확인"
        onPrimaryAction={onConfirm}
      />
    </DialogContainer>
  )
}

export default CategorySuggestionModal
