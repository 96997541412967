import { CSSProperties } from 'react'

import type { BadgeProps } from './index'
import { convertStylesToSerialized } from './utils'

type Size = BadgeProps['size']
type Shape = BadgeProps['shape']

const pill: Record<Size, CSSProperties> = {
  small: {
    padding: '0.125rem 0.25rem',
    borderRadius: '62.5rem',
  },
  medium: {
    padding: '0.125rem 0.375rem',
    borderRadius: '62.5rem',
  },
  large: {
    padding: '0.25rem 0.5rem',
    borderRadius: '62.5rem',
  },
}

const square: Record<Size, CSSProperties> = {
  small: {
    padding: '0.125rem 0.25rem',
    borderRadius: '0.125rem',
  },
  medium: {
    padding: '0.125rem 0.375rem',
    borderRadius: '0.125rem',
  },
  large: {
    padding: '0.25rem 0.5rem',
    borderRadius: '0.25rem',
  },
}

export const styleShape = (shape: Shape, size: Size) => {
  let styles: CSSProperties

  switch (shape) {
    case 'pill':
      styles = pill[size]
      break
    case 'square':
      styles = square[size]
      break
  }

  return convertStylesToSerialized(styles)
}
