import styled from '@emotion/styled'
import IconThumbUpFill from '@karrotmarket/karrot-ui-icon/lib/react/IconThumbUpFill'
import IconThumbUpRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconThumbUpRegular'
import { vars } from '@seed-design/design-token'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { addItemToArray, removeItemByProperty } from '@src/js-utils/array'
import { useMyCheckinRegionResidenceInfo } from '@src/pages/NativeReview/hooks'
import { EXPERIMENT_enhancedReactionSelector } from '@src/place-utils/__generated__/experiments/enhancedReaction'
import { reactionAtomFamily, ReactionKey } from '@src/place-utils/reaction/store'
import { userInfoAtom } from '@src/place-utils/user/store'

import { AgreedButton } from '../Buttons'

interface Props extends ReactionKey {
  onClick: ({ isAlreadyClicked }: { isAlreadyClicked: boolean }) => void
}
export const Button = ({ onClick, ...reactionKey }: Props) => {
  const setReaction = useSetRecoilState(reactionAtomFamily(reactionKey))
  const { region, startYearOfResidence } = useMyCheckinRegionResidenceInfo()

  const { id, nickname, profileImage } = useRecoilValue(userInfoAtom)
  const enhancedReactionExperimentSegment = useRecoilValue(EXPERIMENT_enhancedReactionSelector)

  const addMyReaction = () => {
    setReaction((prev) => {
      const prevReactedUsers = [...(prev.reactedUsers ?? [])]
      const reactedUsers = addItemToArray(
        prevReactedUsers,
        {
          id,
          nickname,
          profileImageURL: profileImage,
          residenceInfo: {
            region,
            startYearOfResidence: startYearOfResidence!,
          },
        },
        'first'
      )

      return {
        ...prev,
        reactedUsers,
      }
    })
  }

  const removeMyReaction = () => {
    setReaction((prev) => {
      const prevReactedUsers = [...(prev.reactedUsers ?? [])]
      const reactedUsers = removeItemByProperty(prevReactedUsers, 'id', id)

      return {
        ...prev,
        reactedUsers,
      }
    })
  }

  const handleClick = ({ isAlreadyClicked }: { isAlreadyClicked: boolean }) => {
    onClick({ isAlreadyClicked })

    if (isAlreadyClicked) {
      removeMyReaction()
    } else {
      addMyReaction()
    }
  }

  return (
    <AgreedButton
      {...reactionKey}
      onClick={({ isAlreadyClicked }) => handleClick({ isAlreadyClicked })}
      label={{
        render: ({ isReactedByMe, totalCount }) => (
          <Label>
            {isReactedByMe ? (
              <IconThumbUpFill width="1rem" height="1rem" />
            ) : (
              <IconThumbUpRegular width="1rem" height="1rem" />
            )}
            여기 맛집이에요
            {enhancedReactionExperimentSegment === 'treatment-C' && totalCount > 0 && (
              <span className="highlight"> {totalCount}</span>
            )}
          </Label>
        ),
      }}
    />
  )
}

const Label = styled.span({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.25rem',
  '.highlight': {
    color: vars.$semantic.color.primary,
  },
})
