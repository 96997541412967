import debounce from 'lodash/debounce'
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'

import nearbySdk from '@src/apis/NearybySDK'
import { bridge } from '@src/bridge'

import { useInternalWatchMutation } from './useInternalWatchMutation'
import { isPlaceWatchedSelectorFamily, WatchPlaceIdDto } from '../store'

interface Props {
  placeWatchId: WatchPlaceIdDto
  onClick?: (isAddWatch: boolean) => void
  onAddWatch?: () => void
  onRemoveWatch?: () => void
}

export function useWatch({ placeWatchId, onClick, onAddWatch, onRemoveWatch }: Props) {
  const { addWatch, removeWatch } = useInternalWatchMutation(placeWatchId)

  const isWatched = useRecoilValue(isPlaceWatchedSelectorFamily(placeWatchId))

  const [watched, setWatched] = useState(isWatched)
  const watchClickedRef = useRef(false)

  const handleClickWatch: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    e.preventDefault()

    watchClickedRef.current = true

    onClick?.(watched)
    setWatched(!watched)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedReqToggleWatch = useCallback(
    debounce(async (setToWatched: boolean) => {
      if (setToWatched) {
        onAddWatch?.()
        addWatch()
      } else {
        onRemoveWatch?.()
        removeWatch()
      }

      watchClickedRef.current = false

      nearbySdk.emit('place.poi.bookmark', {
        isBookmarked: setToWatched,
        poiId: placeWatchId.targetId,
        service: 'place',
      })

      bridge.stream.emit('place.poi.watch', {
        poiId: placeWatchId.targetId,
        isWatched: setToWatched,
      })
    }, 300),
    [placeWatchId]
  )

  useEffect(() => {
    if (!watchClickedRef.current) return

    debouncedReqToggleWatch(watched)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watched])

  useEffect(() => {
    if (isWatched === watched) return

    setWatched(isWatched)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWatched])

  return {
    handleClickWatch,
    watched,
  }
}
