import Request from './Request'
import { PoiTaggedCommentsResponse } from './types/communityApi'

export default class CommunityApi extends Request {
  getPoiTaggedComments = async ({
    storyArticleId,
    cursor,
    limit,
  }: {
    storyArticleId: number
    cursor?: string
    limit?: number
  }) => {
    return this.req.get<PoiTaggedCommentsResponse>(`/api/v1/articles/${storyArticleId}/comments/poi-tagged`, {
      headers: {
        ...this.getUserAuthHeader(),
      },
      params: {
        cursor,
        limit,
      },
    })
  }

  getArticleWritingURL = async ({
    serviceType,
    subjectId,
    poiId,
    writingGuide,
  }: {
    userAgent: string
    serviceType: 'poi' | 'biz_profile'
    subjectId?: number
    poiId?: number
    writingGuide?: string
  }) => {
    return this.req.get<{ targetUri: string }>('/api/v1/mobile/ui/agora/article-writing/entry', {
      headers: {
        ...this.getUserAuthHeader(),
      },
      params: { serviceType, subjectId, poiId, writingGuide },
    })
  }

  getArticleDetailURL = async ({
    serviceType,
    articleIds,
  }: {
    userAgent: string
    serviceType: 'poi' | 'biz_profile'
    articleIds: number[]
  }) => {
    return this.req.get<Record<number, { targetUri: string }>>('/api/v1/mobile/ui/agora/article-detail/entry', {
      headers: {
        ...this.getUserAuthHeader(),
      },
      params: { serviceType, articleIds },
      paramsSerializer: (params) => {
        const searchParams = new URLSearchParams()

        for (const key in params) {
          if (Array.isArray(params[key])) {
            params[key].forEach((value: number) => {
              searchParams.append(key, value.toString())
            })
          } else {
            searchParams.append(key, params[key])
          }
        }

        return searchParams.toString()
      },
    })
  }
}
