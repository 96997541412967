import styled from '@emotion/styled'
import debounce from 'lodash/debounce'
import { ComponentType, useEffect, useState } from 'react'

export const withVisualViewport = <P,>(Component: ComponentType<P>) => {
  const WithVisualViewport = (props: P & object) => {
    const [viewport, setViewport] = useState<number>(window.visualViewport?.height || 0)

    useEffect(() => {
      if (!window.visualViewport) return

      const resizeVisualViewport = () => {
        if (!window.visualViewport) return
        setViewport(window.visualViewport.height)
      }
      const debounced = debounce(resizeVisualViewport, 100)
      window.visualViewport.addEventListener('resize', debounced)

      return () => {
        if (!window.visualViewport) return
        window.visualViewport.removeEventListener('resize', debounced)
      }
    }, [])

    return (
      <StyledVisualViewport height={viewport}>
        <StyledScrollableArea>
          <Component {...props} />
        </StyledScrollableArea>
      </StyledVisualViewport>
    )
  }
  return WithVisualViewport
}

// Account navbar height (3.5rem) when setting visual viewport area
const StyledVisualViewport = styled.div<{ height: number }>`
  width: 100%;
  height: ${(props) => `calc(${props.height}px - 3.5rem)`};
  overflow: hidden;
`

const StyledScrollableArea = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`
