import { CategoryType } from '@daangn/local-business-webview-hub-sdk/lib/plugins/category/types'
import IconSearchRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconSearchRegular'
import { useActivityParams } from '@stackflow/react'
import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react'
import { useRecoilState } from 'recoil'

import { localBusinessHubSdk } from '@src/bridge'
import { CategoryInputState, selectedCategoryAtom } from '@src/place-utils/category/CategoryInput/state'
import { TextInput } from '@src/react-utils/components/Input'
import { useActivity } from '@src/stackflow'

interface Props {
  onChangeCategory: (category: CategoryInputState | null) => void
}

export const CategoryInput = ({ onChangeCategory }: Props) => {
  const [selectedCategory, setSelectedCategory] = useRecoilState(selectedCategoryAtom)
  const { entry } = useContext(categoryInputContext)
  const { referrer } = useActivityParams<{ referrer?: string }>()
  const { name: activityName } = useActivity()

  const handleClearInputValue = () => {
    setSelectedCategory(null)
    onChangeCategory(null)
  }
  const categoryTypes = useMemo<CategoryType[]>(() => {
    if (entry === 'communityTag') return ['BIZPROFILE', 'POI']
    if (entry === 'search') return ['BIZPROFILE']
    if (entry === 'updateSuggestion') return ['POI']
    return []
  }, [entry])

  const handleClickInput = () => {
    const entryName = `place.${activityName}`

    localBusinessHubSdk.categoryPlugin
      .openCategorySelectWebviewWithSubscribeStream({
        category_types: categoryTypes,
        user_type: 'viewer',
        referrer: referrer || '',
        entry: entryName,
      })
      .then(({ category, categorySuggestionPromise }) => {
        setSelectedCategory(category)

        categorySuggestionPromise.then(({ suggestionInfo }) => {
          setSelectedCategory((prev) => ({ ...prev, categorySuggestionId: suggestionInfo.id }))
        })
      })
  }

  useEffect(() => {
    if (!selectedCategory) return

    onChangeCategory({ ...selectedCategory })
  }, [selectedCategory, onChangeCategory])

  useEffect(() => {
    return () => {
      setSelectedCategory(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TextInput
      type="text"
      id="categoryName"
      name="categoryName"
      width="100%"
      value={selectedCategory?.name ?? ''}
      placeholder="업종을 검색해주세요."
      readOnly
      onClick={handleClickInput}
      onClearInputValue={handleClearInputValue}
      appendRight={<IconSearchRegular width={22} height={22} />}
    />
  )
}

const categoryInputContext = createContext<{ entry: CategoryInputEntry }>({ entry: '' })
export const CategoryInputProvider = ({ children, entry }: { children: ReactNode; entry: CategoryInputEntry }) => {
  return <categoryInputContext.Provider value={{ entry }}>{children}</categoryInputContext.Provider>
}

export type CategoryInputEntry = 'search' | 'communityTag' | ''
