import { captureException } from '@sentry/react'
import { selector } from 'recoil'

import { bridge } from '@src/apis/Bridge'
import { IS_LOCAL } from '@src/constants/environmentConstants'
import { localStorage } from '@src/js-utils/localStorage'
import { Coordinates } from '@src/types/global'

import { generateKey } from './generate-key'

type AccessStatus = 'granted' | 'denied' | 'loading'
type CurrentPosition<T extends AccessStatus> = {
  access: T
  position: T extends 'granted' ? Coordinates : undefined
  timestamp: number | undefined
}

export const getDeniedCurrentPosition: () => CurrentPosition<'denied'> = () => ({
  access: 'denied',
  position: undefined,
  timestamp: Date.now(),
})

export const getLoadingCurrentPosition: () => CurrentPosition<'loading'> = () => ({
  access: 'loading',
  position: undefined,
  timestamp: Date.now(),
})

export const currentPositionSelector = selector<
  CurrentPosition<'granted'> | CurrentPosition<'denied'> | CurrentPosition<'loading'>
>({
  key: generateKey('current-position-atom'),
  get: () =>
    (async () => {
      if (IS_LOCAL) {
        const currentUserPositionOff = (await localStorage.getItem('USE_CURRENT_USER_POSITION')) === 'false'

        if (currentUserPositionOff) {
          /**
           * 로컬에서는 현재 위치를 가져오지 못하도록 처리
           */
          return getDeniedCurrentPosition()
        } else {
          /**
           * 로컬에서는 현재 위치를 임의로 설정
           */
          return {
            access: 'granted',
            position: {
              latitude: 37.50261221207654,
              longitude: 127.02480110495101,
            },
            timestamp: Date.now(),
          }
        }
      }

      try {
        const karrotBridgeCurrentPosition = await bridge.getCurrentPosition()

        const positionInfo = karrotBridgeCurrentPosition.geolocation.currentPosition

        if (!positionInfo) {
          return getDeniedCurrentPosition()
        }

        const { position, timestamp } = positionInfo
        const isAccessGranted = !!position.latitude && !!position.longitude

        const currentPosition:
          | Omit<CurrentPosition<'granted'>, 'timestamp'>
          | Omit<CurrentPosition<'denied'>, 'timestamp'> = isAccessGranted
          ? { access: 'granted', position }
          : { access: 'denied', position: undefined }

        return {
          ...currentPosition,
          timestamp,
        }
      } catch (error) {
        captureException(error)

        return {
          access: 'denied',
          position: undefined,
          timestamp: Date.now(),
        }
      }
    })(),
})
