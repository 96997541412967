import { setFetchConfig } from '@daangn/local-business-network/lib/poi'
import type { AxiosInterceptorManager } from 'axios'

import { APP_ENV } from '@src/constants/environmentConstants'
import { POI_SERVER_BASE_URL } from '@src/constants/url'

import { setFetchConfig as setFetchConfig2 } from './__generated__/setFetchConfig'
import { authTokenInjector, requestIdInjector, userAgentInjector } from './utils/interceptors'

const domainSpecificInjector: Parameters<AxiosInterceptorManager<any>['use']>[0] = (config) => {
  if (!config.headers) {
    config.headers = {}
  }
  config.baseURL = POI_SERVER_BASE_URL

  return config
}

// config for local-business-network
const poiAxiosInstance = setFetchConfig(APP_ENV as 'production' | 'alpha')

poiAxiosInstance.interceptors.request.use(requestIdInjector)
poiAxiosInstance.interceptors.request.use(authTokenInjector)
poiAxiosInstance.interceptors.request.use(userAgentInjector)
poiAxiosInstance.interceptors.request.use(domainSpecificInjector)

// config for locally generated poi api
const poiAxiosInstance2 = setFetchConfig2(APP_ENV as 'production' | 'alpha')

poiAxiosInstance2.interceptors.request.use(requestIdInjector)
poiAxiosInstance2.interceptors.request.use(authTokenInjector)
poiAxiosInstance2.interceptors.request.use(userAgentInjector)
poiAxiosInstance2.interceptors.request.use(domainSpecificInjector)
