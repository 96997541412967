import { Poi } from '@daangn/local-business-network/lib/poi'
import { ActivityComponentType, useActivityParams } from '@stackflow/react'
import { useCallback } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { bizSDK } from '@src/bizSDK'
import { emitPoiTaggingDataToStream } from '@src/place-utils/community/utils'
import { usePoiInputSelectionState } from '@src/place-utils/poi/PoiInput/state'
import { userInfoAtom } from '@src/place-utils/user/store'
import { errorMessage } from '@src/react-utils/components/Error/constants'
import { useToast } from '@src/react-utils/components/Toast'
import { useNavigator } from '@src/stackflow'

import { SearchPoi } from './SearchPoi'
import { BizReviewStatus, myBizReviewSelectorFamily } from '../WritePoiReview/state/bizReview'

export type ActivityParams = {
  keywords?: string
  variation?: 'communityTag' | 'internal'
  create_flow?: 'default' | 'simplified'
  /**
   * @name 리전아이디
   * @description 리전아이디를 쿼리파람에 전달하는 경우, 유저의 체크인 리전보다 전달된 리전이 우선시돼요.
   */
  region?: string
}
// https://www.notion.so/daangn/WIP-c86b1975277e44eb92add7307710f5c0
export const SearchPoiPage: ActivityComponentType<ActivityParams> = ({ params: { variation } }) => {
  const { entry } = useActivityParams<{ entry: string }>()
  const { push, pop } = useNavigator()

  const { showToast } = useToast()
  const [, setSelectedRecoilState] = usePoiInputSelectionState()

  const userInfo = useRecoilValue(userInfoAtom)

  const handleSelectPoi = useCallback(
    ({ poiId, poiName, bizAccountId }: { poiId: string; poiName: string; bizAccountId?: string }) => {
      setSelectedRecoilState({
        id: poiId,
        name: poiName,
        bizAccountId,
      })

      pop()
    },
    [pop, setSelectedRecoilState]
  )

  const handleBizManager = useCallback(
    async ({ poiId, poiName, bizAccountId }: { poiId: string; poiName: string; bizAccountId: string }) => {
      const AUTH_TOKEN_ERROR = 'AUTH_TOKEN_ERROR'

      try {
        if (!userInfo?.authToken) throw new Error(AUTH_TOKEN_ERROR)
        await bizSDK.profileAPI.reqIsValidBizAccountMember(bizAccountId, userInfo?.authToken)

        push('search_poi_modal', { modalId: 'BizManagerSelfReviewAlert', poiId })
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === AUTH_TOKEN_ERROR) {
            return showToast({
              text: errorMessage.AUTHENTICATION_MESSAGE,
              duration: 'long',
            })
          }
        }

        /** execute if current user is not one of the biz-managers */
        handleSelectPoi({ poiId, poiName, bizAccountId })
      }
    },
    [handleSelectPoi, showToast, push, userInfo?.authToken]
  )

  const handleBizReview = useCallback(
    ({
      status,
      poiId,
      poiName,
      bizAccountId,
    }: {
      status: BizReviewStatus
      poiId: string
      poiName: string
      bizAccountId: string
    }) => {
      switch (status) {
        case 'BLOCKED':
          return push('search_poi_modal', { modalId: 'BlockedBizReviewUpdateDialog', poiId })
        case 'POST_STOPPED':
          return push('search_poi_modal', { modalId: 'BlockedBizReviewAlert', poiId })
        case 'BLOCKED_DELETED':
          return push('search_poi_modal', { modalId: 'BlockedBizReviewAlert', poiId })
        case 'NORMAL':
        case 'DELETED':
          return handleBizManager({ poiId, poiName, bizAccountId })
        case 'ERROR':
          return showToast({
            text: errorMessage.TEMPORARY_NOT_WORKING_MESSAGE,
            duration: 'long',
          })
      }
    },
    [handleBizManager, showToast, push]
  )

  /**
   * @description onSelectPoi action for internal use (opening search_poi page from same webview)
   */
  const handleClickSelectPoi = useRecoilCallback(
    ({ snapshot }) =>
      async (poi: Poi) => {
        const { id: poiId, name: poiName, bizAccountId } = poi

        if (entry === 'poi_review' && bizAccountId) {
          const { status } = await snapshot.getPromise(myBizReviewSelectorFamily(bizAccountId))

          return handleBizReview({ status, poiId: poiId!, poiName: poiName!, bizAccountId })
        }

        handleSelectPoi({ poiId: poi.id!, poiName: poi.name!, bizAccountId: poi.bizAccountId ?? undefined })
      },
    [handleBizReview, entry, handleSelectPoi]
  )

  return (
    <>
      {(() => {
        switch (variation) {
          case 'internal':
            return <SearchPoi onSelectPoi={handleClickSelectPoi} />
          /**
           * @deprecated 'communityTag' variation is deprecated. Without any variation, bridge action is used by default.
           */
          case 'communityTag':
          default:
            return <SearchPoi onSelectPoi={emitPoiTaggingDataToStream} />
        }
      })()}
    </>
  )
}
