import { css } from '@emotion/react'
import styled from '@emotion/styled'
import IconChevronRightRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightRegular'
import IconCloseRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCloseRegular'
import IconHelpcenterRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconHelpcenterRegular'
import IconLoudspeakerRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconLoudspeakerRegular'
import IconReportRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconReportRegular'
import IconWarningRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconWarningRegular'
import { vars } from '@seed-design/design-token'
import { SVGProps } from 'react'

type CalloutType = 'basic' | 'action_link' | 'action_close'
interface Props<T extends CalloutType> {
  type: T
  state: 'normal' | 'tip' | 'warning' | 'danger'
  contents: string
  title?: string
  link?: {
    text: string
    href: string
  }
  onClick?: () => void
  useIcon?: boolean
  action?: T extends Extract<CalloutType, 'action_link' | 'action_close'> ? () => void : never
  className?: string
}
export const Callout = <T extends CalloutType>({
  type,
  state,
  title,
  contents,
  link,
  useIcon = false,
  onClick,
  action,
  ...props
}: Props<T>) => {
  const renderIcon = () => {
    if (useIcon) {
      return (
        <IconWrapper>
          <Icon
            state={state}
            svgProps={{
              width: '1.25rem',
              height: '1.25rem',
              color: 'inherit',
            }}
          />
        </IconWrapper>
      )
    }
  }

  const renderAction = () => {
    if (type === 'action_link' || type === 'action_close') {
      return (
        <ActionButton onClick={action}>
          <ActionIcon
            type={type}
            svgProps={{
              width: '1.125rem',
              height: '1.125rem',
              color: 'inherit',
            }}
          />
        </ActionButton>
      )
    }
  }

  return (
    <Container type={type} state={state} onClick={onClick} className={props.className}>
      {renderIcon()}
      <Body>
        {title && <span>{title}</span>}
        {contents}
        {link && <a href={link.href}>{link.text}</a>}
      </Body>
      {renderAction()}
    </Container>
  )
}

const Container = styled.div<Pick<Props<CalloutType>, 'state' | 'type'>>`
  width: 100%;
  padding: ${({ type }) => {
    switch (type) {
      default:
      case 'basic':
        return '1rem 1.125rem'
      case 'action_link':
        return '1rem 0.75rem 1rem 1.125rem'
      case 'action_close':
        return '1rem 1rem 1rem 1.125rem'
    }
  }};
  display: flex;
  border-radius: 0.625rem;

  ${({ state }) => [color({ state }), backgroundColor({ state })]};
`
const IconWrapper = styled.span`
  margin: 0 0.625rem 0 0;
  color: inherit;
`
const Body = styled.p`
  color: inherit;
  ${vars.$semantic.typography.bodyM2Regular};

  span {
    margin: 0 0.5rem 0 0;
    color: inherit;
    ${vars.$semantic.typography.bodyM2Bold};
  }

  a {
    margin: 0 0 0 0.5rem;
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 0.2rem;
  }
`
const ActionButton = styled.button`
  margin-left: auto;
  padding: 0 0 0 0.625rem;
  color: inherit;
`

const backgroundColor = ({ state }: Pick<Props<CalloutType>, 'state'>) => {
  return css`
    background-color: ${(() => {
      switch (state) {
        default:
        case 'normal':
          return vars.$scale.color.gray100
        case 'tip':
          return vars.$scale.color.blueAlpha100
        case 'warning':
          return vars.$scale.color.yellowAlpha100
        case 'danger':
          return vars.$scale.color.redAlpha50
      }
    })()};
  `
}
const color = ({ state }: Pick<Props<CalloutType>, 'state'>) => {
  return css`
    color: ${(() => {
      switch (state) {
        default:
        case 'normal':
          return vars.$scale.color.gray900
        case 'tip':
          return vars.$scale.color.blue950
        case 'warning':
          return vars.$scale.color.yellow950
        case 'danger':
          return vars.$scale.color.red950
      }
    })()};
  `
}

interface IconProps {
  state: Props<CalloutType>['state']
  svgProps: SVGProps<SVGSVGElement>
}
const Icon = ({ state, svgProps }: IconProps) => {
  switch (state) {
    case 'normal':
      return <IconWarningRegular {...svgProps} />
    case 'tip':
      return <IconHelpcenterRegular {...svgProps} />
    case 'warning':
      return <IconLoudspeakerRegular {...svgProps} />
    case 'danger':
      return <IconReportRegular {...svgProps} />
  }
}

interface ActionIconProps {
  type: Extract<CalloutType, 'action_link' | 'action_close'>
  svgProps: SVGProps<SVGSVGElement>
}
const ActionIcon = ({ type, svgProps }: ActionIconProps) => {
  switch (type) {
    case 'action_close':
      return <IconCloseRegular {...svgProps} />
    case 'action_link':
      return <IconChevronRightRegular {...svgProps} />
  }
}
