import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'

import { useToast } from '@src/react-utils/components/Toast'

import { currentPositionSelector, getDeniedCurrentPosition, getLoadingCurrentPosition } from '../store'

export const useCurrentPosition = () => {
  const { showToast } = useToast()

  const { contents, state } = useRecoilValueLoadable(currentPositionSelector)
  const refreshCurrentPosition = useRecoilRefresher_UNSTABLE(currentPositionSelector)

  const update = () => {
    refreshCurrentPosition()
  }

  const handleOnAccessDenied = () => {
    showToast({
      text: '기기 설정에서 위치 기능을 켜주세요.',
      duration: 'short',
    })
  }

  switch (state) {
    case 'hasError':
      return {
        ...getDeniedCurrentPosition(),
        update,
        handleOnAccessDenied,
      }
    case 'loading':
      return {
        ...getLoadingCurrentPosition(),
        update,
        handleOnAccessDenied,
      }
    case 'hasValue': {
      const { timestamp, ...currentPosition } = contents
      return {
        ...currentPosition,
        timestamp,
        update,
        handleOnAccessDenied,
      }
    }
  }
}
