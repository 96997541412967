import { Coordinates } from '@src/types/global'

import { radiusOfEarth } from './constants'
import { hav, toRad } from '../math'

export function haversineDistance(a: Coordinates, b: Coordinates) {
  const aLat = toRad(Array.isArray(a) ? a[1] : a.latitude)
  const bLat = toRad(Array.isArray(b) ? b[1] : b.latitude)
  const aLng = toRad(Array.isArray(a) ? a[0] : a.longitude)
  const bLng = toRad(Array.isArray(b) ? b[0] : b.longitude)

  const ht = hav(bLat - aLat) + Math.cos(aLat) * Math.cos(bLat) * hav(bLng - aLng)
  return 2 * radiusOfEarth * Math.asin(Math.sqrt(ht))
}
