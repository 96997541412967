import styled from '@emotion/styled'
import IconGpsRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconGpsRegular'
import { vars } from '@seed-design/design-token'
import { MouseEvent, MouseEventHandler } from 'react'

import { useCurrentPosition } from '@src/place-utils/location/hooks'

import { FloatingButton } from '../Button'

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>
  onAccessDenied?: () => void
  onAccessGranted?: () => void
  bottomSpacing: number
  className?: string
}
export const GPSButton = ({ onClick, onAccessDenied, onAccessGranted, bottomSpacing, ...props }: Props) => {
  const { access, update, handleOnAccessDenied } = useCurrentPosition()

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    onClick(e)
    update()

    if (access === 'denied') {
      onAccessDenied?.()
      handleOnAccessDenied()
    } else if (access === 'granted') {
      onAccessGranted?.()
    }
  }

  return (
    <Button
      bottomSpacing={bottomSpacing}
      className={props.className}
      icon={<IconGpsRegular width="1.5rem" height="1.5rem" color={vars.$scale.color.gray900} />}
      onClick={handleClick}
    />
  )
}

const Button = styled(FloatingButton)<{ bottomSpacing: number }>`
  transform: translate3d(0, -${({ bottomSpacing }) => bottomSpacing}px, 0);
  transition: transform 200ms;
`
