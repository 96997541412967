import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useCallback } from 'react'

interface ItemProps {
  index: number
  value: string | number
  height: number
  isPicked: boolean
  onUnpickedItemClick: (newPickedIndex: number) => void
}

export const Item = ({ index, value, height, isPicked, onUnpickedItemClick }: ItemProps) => {
  const onClick = useCallback(() => {
    if (!isPicked) {
      onUnpickedItemClick(index)
    }
  }, [index, isPicked, onUnpickedItemClick])

  return (
    <Container height={height} onClick={onClick}>
      <Text isPicked={isPicked}>{value}</Text>
    </Container>
  )
}

const Container = styled.div<{ height: number }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: ${(props) => props.height}px;
`

const Text = styled.div<{ isPicked: boolean }>`
  ${(props) =>
    props.isPicked
      ? `
          ${vars.$semantic.typography.h3};
          color: ${vars.$scale.color.gray900};
          font-weight: bold;
          font-size: 34px;
        `
      : `
          ${vars.$semantic.typography.title1Regular};
          color: ${vars.$scale.color.gray300};
          font-size: 24px;
        `}

  transition: all 0.05s ease-in;
`
