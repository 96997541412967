import { canvasToBlob } from './canvasToBlob'

export const toBlobPromise = (
  data: HTMLCanvasElement,
  contentType: string,
  useCustomToBlobFuction?: boolean
): Promise<Blob> =>
  new Promise((resolve, reject) => {
    const toBlobCallback = (blob: Blob | null) => (blob ? resolve(blob) : reject('blob does not exist'))

    if (useCustomToBlobFuction) {
      if (!canvasToBlob) {
        reject('canvasToBlob does not exist')
        return
      }

      canvasToBlob(data, toBlobCallback, contentType)
      return
    }

    data.toBlob(toBlobCallback, contentType)
  })

export const FILE_RESIZE_OPTIONS = {
  maxWidth: 1600,
  maxHeight: 1600,
  canvas: true,
  cover: false,
  crop: false,
}

export const validateFileFormat = (file?: File) => {
  return file && /^image\/(png|jpg|jpeg)/.test(file.type)
}
