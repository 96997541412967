export function extractValuesByKey<T extends { [key: string]: any }, K extends keyof T>(
  obj: T,
  keysToExtract: K[]
): Array<T[K]> {
  const values: Array<T[K]> = []

  for (const key in obj) {
    if (keysToExtract.includes(key as unknown as K)) {
      values.push(obj[key as keyof T] as T[K])
    }
  }

  return values
}
