import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

export const CurrentPositionDot = () => {
  return (
    <UserPosition className="userPosition">
      <div className="userPosition-background"></div>
      <div className="userPosition-dot"></div>
    </UserPosition>
  )
}

const UserPosition = styled.div`
  @keyframes ping {
    0% {
      transform: scale3d(1, 1, 1);
    }
    12.5% {
      transform: scale3d(0.7, 0.7, 1);
    }
    25% {
      transform: scale3d(1, 1, 1);
    }
    37.5% {
      transform: scale3d(0.7, 0.7, 1);
    }
    50% {
      transform: scale3d(1, 1, 1);
    }
    62.5% {
      transform: scale3d(0.7, 0.7, 1);
    }
    75% {
      transform: scale3d(1, 1, 1);
    }
    87.5% {
      transform: scale3d(0.7, 0.7, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
  .userPosition-dot {
    position: relative;
    height: 16px;
    width: 16px;
    /** @todo: seed-design */
    background-color: #2b6eff;
    border-radius: 8px;
    border: solid 2px ${vars.$static.color.staticWhite};
    display: block;
  }
  .userPosition-background {
    content: '';
    height: 48px;
    width: 48px;
    background: rgba(43, 110, 255, 0.25);
    border-radius: 24px;
    position: absolute;
    display: block;
    top: -16px;
    left: -16px;
    transform: scale3d(0, 0, 1);
    animation-duration: 6s;
    animation-name: ping;
  }
  .content {
    z-index: 2;
    position: fixed;
    bottom: 0;
    bottom: constant(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
    left: 0;
    right: 0;
    margin: 16px 0 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`
