import { setFetchConfig as setBusinessAccountFetchConfig } from '@daangn/local-business-network/lib/businessAccount'
import type { AxiosInterceptorManager } from 'axios'

import { APP_ENV } from '@src/constants/environmentConstants'
import { BIZ_ACCOUNT_BASE_URL } from '@src/constants/url'

import { authTokenInjector, requestIdInjector, userAgentInjector } from './utils/interceptors'

const baAxiosInstance = setBusinessAccountFetchConfig(APP_ENV as 'production' | 'alpha')

const domainSpecificInjector: Parameters<AxiosInterceptorManager<any>['use']>[0] = (config) => {
  if (!config.headers) {
    config.headers = {}
  }
  config.baseURL = BIZ_ACCOUNT_BASE_URL

  return config
}

baAxiosInstance.interceptors.request.use(requestIdInjector)
baAxiosInstance.interceptors.request.use(authTokenInjector)
baAxiosInstance.interceptors.request.use(userAgentInjector)
baAxiosInstance.interceptors.request.use(domainSpecificInjector)
