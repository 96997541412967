import styled from '@emotion/styled'
import IconLocationRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconLocationRegular'
import IconSearchRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconSearchRegular'
import { captureException } from '@sentry/react'
import isEmpty from 'lodash/isEmpty'
import { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import { kakaLocalApi } from '@src/apis'
import { Button } from '@src/react-utils/components/Button'
import { AddressPicker, addressPickerAtom } from '@src/react-utils/components/Input/AddressInput/store/addressPicker'
import { useNavigator } from '@src/stackflow'

import TextInput from '../TextInput'

export interface Props {
  addressExtra: string
  onChangeAddress: (address: AddressPicker & { addressExtra?: string }) => void
  onClickAddressSearch?: () => void
  onClickPinAddress?: (e: MouseEvent<HTMLButtonElement>) => void
  onChangeAddressExtra?: (e: ChangeEvent<HTMLInputElement>) => void
}

const AddressInput = ({
  addressExtra,
  onChangeAddress,
  onClickAddressSearch,
  onClickPinAddress,
  onChangeAddressExtra,
}: Props) => {
  const { push } = useNavigator()

  const [searchAddress, setAddressPicker] = useRecoilState(addressPickerAtom)

  const [displayAddress, setDisplayAddress] = useState<{ value: string; visibility: 'visible' | 'hidden' }>({
    value: '',
    visibility: 'visible',
  })

  const handleFocusAddressInput = useCallback(() => {
    onClickAddressSearch?.()
    push('search_address', {})
  }, [onClickAddressSearch, push])

  const handleClickPinAddress = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      onClickPinAddress?.(e)
      push('pin_address', {})
    },
    [onClickPinAddress, push]
  )

  useEffect(() => {
    if (!searchAddress) return

    const { roadAddress, jibunAddress, coordinates } = searchAddress
    const address = roadAddress ?? jibunAddress
    const extra = addressExtra ?? ''

    onChangeAddress({ ...searchAddress, addressExtra: extra })

    if (address) {
      setDisplayAddress({ value: address, visibility: 'visible' })

      return
    }

    if (coordinates) {
      ;(async function fetchAddress() {
        try {
          const resp = await kakaLocalApi.getAddressFromCoord(coordinates.latitude, coordinates.longitude)
          const result = resp?.[0]

          const { road_address: roadAddress, address: jibunAddress } = result ?? {}
          const address = roadAddress?.address_name || jibunAddress.address_name

          if (isEmpty(result) || !address) {
            throw new Error('Coordinates로 카카오에서 주소를 받아오는것에 실패했어요.')
          }

          onChangeAddress({
            coordinates,
            roadAddress: roadAddress?.address_name,
            jibunAddress: jibunAddress.address_name,
            zipNumber: jibunAddress.zip_code,
            addressExtra: extra,
          })
          setDisplayAddress({ value: address, visibility: 'visible' })
        } catch (err) {
          captureException(err)
          setDisplayAddress({ value: '', visibility: 'hidden' })
        }
      })()

      return
    }

    setDisplayAddress({ value: '', visibility: 'visible' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChangeAddress, searchAddress])

  useEffect(() => {
    return () => {
      setAddressPicker(null)
    }
  }, [setAddressPicker])

  return (
    <>
      {displayAddress.visibility === 'visible' && (
        <AddressInputField
          id="displayAddress"
          value={displayAddress.value}
          placeholder="주소를 검색해주세요."
          onFocus={handleFocusAddressInput}
          onChange={() => {}}
          width="100%"
          appendRight={<IconSearchRegular width={22} height={22} />}
        />
      )}
      <AddressExtraInputField
        id="addressExtra"
        name="addressExtra"
        width="100%"
        placeholder="상세 주소를 입력해주세요. (선택)"
        value={addressExtra}
        onChange={onChangeAddressExtra}
      />
      {!window.kakao ? null : displayAddress.visibility === 'hidden' ? (
        <AddressSearchButton
          styleTheme="secondary-low"
          fullwidth
          textSize="14"
          type="button"
          onClick={handleFocusAddressInput}>
          <IconSearchRegular className="icon" />
          검색으로 주소 찾기
        </AddressSearchButton>
      ) : (
        <AddressSearchButton
          styleTheme="secondary-low"
          fullwidth
          textSize="14"
          type="button"
          onClick={handleClickPinAddress}>
          <IconLocationRegular className="icon" />
          지도에서 주소 찾기
        </AddressSearchButton>
      )}
    </>
  )
}

const AddressInputField = styled(TextInput)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0 0.75rem;
`
const AddressExtraInputField = styled(TextInput)`
  margin: 0 0 0.75rem;
`
const AddressSearchButton = styled(Button)`
  .icon {
    width: 1rem;
    height: 1rem;
  }
`

export default AddressInput
