export function getMatchingValues<T>(array1: T[], array2: T[]): { values: T[]; hasMatches: boolean } {
  const uniqueValues = new Set(array1)
  const matchingValues: T[] = []
  let hasMatches = false
  for (const value of array2) {
    if (uniqueValues.has(value)) {
      matchingValues.push(value)
      hasMatches = true
    }
  }
  return { values: matchingValues, hasMatches }
}
