import { SortTab } from '@daangn/local-business-kit'
import styled from '@emotion/styled'
import IconWriteRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconWriteRegular'
import { vars } from '@seed-design/design-token'
import { useMemo } from 'react'

import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'
import { useStory } from '../hooks/useStory'

interface Props {
  poiId: string
}
const Options = ({ poiId }: Props) => {
  const logEvent = useAnalytics()
  const { push } = useNavigator()

  const { sortingOption, sortBy } = useStory({ poiId })

  const handleClickWriteCommunityPost = () => {
    logEvent('click_write_community_post_button', {
      area: 'community_tab',
    })

    push('poi_detail_modal', { poiId, modalId: 'confirmWriteStoryDialog' })
  }

  const tabs = useMemo(
    () => [
      {
        key: 'createdAt',
        label: '최신순',
        onClick: () => {
          /** @deprecated */
          logEvent('click_sort_by_latest_button', {
            source: 'community',
          })
          logEvent('click_profile_story_tab_order_button', {
            order_filter_from: 'likes_count',
            order_filter_to: 'created_at',
          })
          sortBy('createdAt')
        },
      },
      {
        key: 'upvoteCount',
        label: '도움순',
        onClick: () => {
          /** @deprecated */
          logEvent('click_sort_by_helpful_button', {
            source: 'community',
          })
          logEvent('click_profile_story_tab_order_button', {
            order_filter_from: 'created_at',
            order_filter_to: 'likes_count',
          })
          sortBy('upvoteCount')
        },
      },
    ],
    [sortBy, logEvent]
  )

  return (
    <Container>
      <SortTab tabs={tabs} initialTabKey={sortingOption ?? 'createdAt'} />
      <WriteReview onClick={handleClickWriteCommunityPost}>
        <IconWriteRegular width={'1rem'} height={'1rem'} />
        동네생활에 추천
      </WriteReview>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 1rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background: ${vars.$semantic.color.paperDefault};
`
const WriteReview = styled.a`
  display: inline-flex;
  align-items: center;
  text-align: right;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 143%;
  letter-spacing: -0.02em;
  color: ${vars.$semantic.color.primary};

  & > svg {
    margin: 0 0.25rem 0 0;
  }
`

export default Options
