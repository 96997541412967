// By Chat GPT-3
export function range(start: number, end?: number, step = 1): number[] {
  if (end === undefined) {
    return rangeImpl(0, start, step)
  } else {
    return rangeImpl(start, end, step)
  }
}

function rangeImpl(start: number, end: number, step: number): number[] {
  return start >= end ? [] : [start, ...rangeImpl(start + step, end, step)]
}
