import { atom } from 'recoil'

import { karrotBridge } from '@src/bridge'
import { IS_LOCAL } from '@src/constants/environmentConstants'

import { generateKey } from './generate-key'

type AppInfoType = Awaited<ReturnType<(typeof karrotBridge)['getAppInfo']>>['info']['app']

export const appInfoAtom = atom<AppInfoType>({
  key: generateKey('app-info-atom'),
  default: (async () => {
    if (IS_LOCAL) {
      return {
        deviceIdentity: 'local',
        userAgent: window.navigator.userAgent,
        locale: 'ko-KR',
        appHost: 'api.alpha.kr.karrotmarket.com',
        country: 'KR',
        appVersionCode: '224800',
        appVersion: '22.48.0',
      }
    }

    return (await karrotBridge.getAppInfo({})).info.app
  })(),
})
