import { captureException } from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'

import { EvenLogWrapper } from './EventLogWrapper'
import { YearPickerBottomSheet } from './YearPickerBottomSheet'

interface Props {
  reviewId?: number
}
export const YearPickerBottomSheetForList = ({ reviewId }: Props) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        captureException(error.message)

        return <YearPickerBottomSheet />
      }}>
      <EvenLogWrapper reviewId={reviewId}>
        <YearPickerBottomSheet />
      </EvenLogWrapper>
      <YearPickerBottomSheet />
    </ErrorBoundary>
  )
}
