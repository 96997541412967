import { useCallback } from 'react'

import { useLogEvent } from '@src/react-utils/components/Analytics'

const useAnalytics = () => {
  const logEvent = useLogEvent()

  return useCallback(
    (name: string, params?: { [key: string]: any }) => {
      const _params = { ...params }

      logEvent({ name, params: _params })
    },

    [logEvent]
  )
}

export default useAnalytics
