import { FilterPicker } from './FilterPicker'
import { VISIT_COUNT_FILTER_TO_VISIT_COUNT_THRESHOLD_MAPPER } from '../../constants'
import { useAnalytics } from '../../hooks'

export const VisitCountFilterBottomSheet = () => {
  const logEvent = useAnalytics()

  const handleClickOption = (id: string) => {
    logEvent('click_visit_count_filter_detail', {
      visit_count: VISIT_COUNT_FILTER_TO_VISIT_COUNT_THRESHOLD_MAPPER[parseInt(id, 10) - 1],
    })
  }
  return (
    <FilterPicker
      id="list-visit-count-filter"
      title="방문 횟수"
      options={VISIT_COUNT_FILTER_TO_VISIT_COUNT_THRESHOLD_MAPPER.map(({ name, filterId }) => ({
        name,
        id: filterId.toString(),
      }))}
      onClickOption={handleClickOption}
    />
  )
}
