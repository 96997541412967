import styled from '@emotion/styled'
import IconChevronRightRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightRegular'
import { vars } from '@seed-design/design-token'

import { activeEffect } from '@src/styles/utils'

interface Props {
  poi: {
    name: string
  }
  onClick: () => void
}
export const TextOnly = ({ poi, onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      <Body>
        <Caption>찾고 있는 장소가 없나요?</Caption>
        <Guide>
          <PoiName>&lsquo;{poi.name}&rsquo; </PoiName>직접 등록하기
        </Guide>
      </Body>
      <Icon />
    </Container>
  )
}

const Container = styled.div([
  activeEffect(),
  {
    padding: '0.75rem 1rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
])
const Body = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
})
const Caption = styled.p([
  vars.$semantic.typography.caption1Regular,
  {
    color: vars.$scale.color.gray600,
  },
])
const Guide = styled.p([
  vars.$semantic.typography.subtitle1Regular,
  {
    color: vars.$scale.color.gray900,
  },
])
const PoiName = styled.span([
  vars.$semantic.typography.subtitle1Bold,
  {
    color: vars.$semantic.color.accent,
  },
])
const Icon = styled(IconChevronRightRegular)({
  marginLeft: 'auto',
  width: '1.125rem',
  height: '1.125rem',
})
