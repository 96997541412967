import { useMemo } from 'react'

import bluePinIcon from '@src/assets/images/ic_blue_pin.svg'
import bluePinIconSelected from '@src/assets/images/ic_blue_pin_selected.svg'
import grayPinIcon from '@src/assets/images/ic_gray_pin.svg'
import grayPinIconSelected from '@src/assets/images/ic_gray_pin_selected.svg'
import pinIcon from '@src/assets/images/ic_pin.svg'
import pinIconSelected from '@src/assets/images/ic_pin_selected.svg'
import watchedPinIcon from '@src/assets/images/ic_pin_watch.png'
import watchedPinIconSelected from '@src/assets/images/ic_pin_watch_selected.svg'

import { useMap } from './useMap'

type Color = 'carrot' | 'blue' | 'gray'

interface Props {
  color: Color
  size?: {
    width: number
    height: number
  }
  offset?: {
    x: number
    y: number
  }
}

export const colorToMarkerIconMapper = {
  carrot: {
    selected: pinIconSelected,
    default: pinIcon,
  },
  blue: {
    selected: bluePinIconSelected,
    default: bluePinIcon,
  },
  gray: {
    selected: grayPinIconSelected,
    default: grayPinIcon,
  },
} as Record<
  Color,
  {
    selected: string
    default: string
  }
>
const useMarkerImage = ({ color, size, offset }: Props) => {
  const { width, height } = size ?? {}
  const { x: offsetX, y: offsetY } = offset ?? {}

  const { kakao } = useMap()

  const defaultMarker = useMemo(() => {
    if (!kakao) return

    const src = colorToMarkerIconMapper[color].default
    const size = new kakao.maps.Size(width ?? 36, height ?? 36)
    const offset = new kakao.maps.Point(offsetX ?? 36 / 2, offsetY ?? 36 - 3.6)

    return new kakao.maps.MarkerImage(src, size, { offset })
  }, [color, height, kakao, offsetX, offsetY, width])

  const selectedMarker = useMemo(() => {
    if (!kakao) return

    const src = colorToMarkerIconMapper[color].selected
    const size = new kakao.maps.Size(width ?? 62, height ?? 62)
    const _offsetX = offsetX ? offsetX : width ? width / 2 : 62 / 2
    const _offsetY = offsetY ? offsetY : height ? height : 62 - 6.19
    const offset = new kakao.maps.Point(_offsetX, _offsetY)

    return new kakao.maps.MarkerImage(src, size, { offset })
  }, [color, height, kakao, offsetX, offsetY, width])

  const watchedMarker = useMemo(() => {
    if (!kakao) return

    const src = watchedPinIcon
    const size = new kakao.maps.Size(width ?? 30, height ?? 30)
    const offset = new kakao.maps.Point(offsetX ?? 16, offsetY ?? 14)

    return new kakao.maps.MarkerImage(src, size, { offset })
  }, [height, kakao, offsetX, offsetY, width])

  const selectedWatchedMarker = useMemo(() => {
    if (!kakao) return

    const src = watchedPinIconSelected
    const size = new kakao.maps.Size(width ?? 62, height ?? 62)
    const _offsetX = offsetX ? offsetX : width ? width / 2 : 62 / 2
    const _offsetY = offsetY ? offsetY : height ? height : 62 - 6.19
    const offset = new kakao.maps.Point(_offsetX, _offsetY)

    return new kakao.maps.MarkerImage(src, size, { offset })
  }, [height, kakao, offsetX, offsetY, width])

  return {
    markerImage: {
      default: defaultMarker,
      selected: selectedMarker,
      watched: watchedMarker,
      selectedWatched: selectedWatchedMarker,
    },
  }
}

export default useMarkerImage
