import { BoxButton } from '@daangn/sprout-components-button'
import styled from '@emotion/styled'
import { useEffect, useCallback } from 'react'
import { useRecoilValue } from 'recoil'

import PoiInputComponent from '@src/place-utils/poi/PoiInput/PoiInput'
import { usePoiInputSelectionState } from '@src/place-utils/poi/PoiInput/state'
import { useToast } from '@src/react-utils/components/Toast'
import { renderMultilineText } from '@src/react-utils/utils'
import { useNavigator } from '@src/stackflow'

import { myNativeReviewsSelector } from '../../store'
import { Layout, Title } from '../components'
import { useStepForm } from '../hooks/useStepForm'

export const RestaurantRecommendation = () => {
  const { push } = useNavigator()
  const {
    go,
    formValue: {
      user: { region, nickname },
      ...formValue
    },
    updateForm,
  } = useStepForm()
  const { showToast } = useToast()

  const [poi] = usePoiInputSelectionState()
  const reviews = useRecoilValue(myNativeReviewsSelector)

  const onClickNext = useCallback(() => {
    const isAlreadyRecommended = reviews.some((review) => review.poi.id === poi.id)

    if (isAlreadyRecommended) {
      showToast({
        text: '이미 추천한 맛집이에요.',
        button: {
          text: '다른 맛집 찾기',
          onClickButton: () =>
            push('search_poi', { variation: 'internal', create_flow: 'simplified', region: region.id.toString() }),
        },
        duration: 'short',
      })
    } else {
      go.next()
    }
  }, [go, poi.id, push, region.id, reviews, showToast])

  useEffect(() => {
    const isDifferentPoi = formValue.poi?.id !== poi.id

    if (isDifferentPoi) {
      updateForm({
        poi: {
          visitCount: null,
        },
        review: null,
      })
    }

    updateForm({
      poi: {
        id: poi.id,
        name: poi.name,
      },
    })
  }, [formValue.poi?.id, poi, updateForm])

  return (
    <Layout>
      <Title>{renderMultilineText(`${nickname}님,\n${region.name} 현지인들이 자주 가는\n맛집 추천해주세요! 🙌`)}</Title>
      <PoiInput
        label=""
        placeholder="추천할 맛집을 검색해주세요."
        searchPoiParams={{ create_flow: 'simplified', region: region.id.toString() }}
        UNSAFE_clearOnUnmount={false}
      />
      <BoxButton isDisabled={!poi.name} variant="primary" size="xlarge" width="100%" onClick={onClickNext}>
        다음
      </BoxButton>
    </Layout>
  )
}

const PoiInput = styled(PoiInputComponent)({
  margin: 0,
})
