import { ReactionDto } from '@daangn/local-business-network/lib/poi'

export const convertResourceTypeToSourceType = (resourceType: ReactionDto['resourceType'] | 'BIZ_REVIEW') =>
  resourceType === 'STORY_COMMENT'
    ? 'community_comment'
    : resourceType === 'STORY_ARTICLE'
    ? 'community_post'
    : resourceType === 'BIZ_REVIEW'
    ? 'biz_review'
    : 'poi_review'
