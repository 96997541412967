import { VisitCount } from '../Form/types/restaurant-review-form'
import { visitCountMap } from '../Modal/FrequencyPickerBottomSheet'

const SELECT_VISIT_COUNT_PLACEHOLDER = '방문 횟수를 선택해주세요.'

export const convertVisitCountToText = (visitCount?: VisitCount) => {
  if (!visitCount) {
    return SELECT_VISIT_COUNT_PLACEHOLDER
  } else {
    const visitCountMapper = Object.values(visitCountMap)
    const visitCountItem = visitCountMapper.find((item) => item.value === visitCount)

    return visitCountItem?.text || SELECT_VISIT_COUNT_PLACEHOLDER
  }
}
