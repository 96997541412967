import styled from '@emotion/styled'
import { ReactNode, useLayoutEffect, useRef, useState } from 'react'

interface Props {
  className?: string
  children: ReactNode
}
export const Layout = ({ children, className }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [offsetHeight, setOffsetHeight] = useState(0)

  useLayoutEffect(() => {
    setOffsetHeight(ref.current?.offsetHeight ?? 0)
  }, [])

  return (
    <Wrapper ref={ref} offsetHeight={offsetHeight} className={className}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ offsetHeight: number }>(({ offsetHeight }) => ({
  position: 'relative',
  top: `calc(40% - (${offsetHeight}px) / 2)`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '1rem',
  margin: '0 1rem',
}))
