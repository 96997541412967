import { atom } from 'recoil'

import { SearchAddressListItem } from '@src/js-utils/searchAddress'
import { Coordinates } from '@src/types/global'

export type AddressPicker = SearchAddressListItem & {
  coordinates?: Coordinates
}

export const addressPickerAtom = atom<null | AddressPicker>({
  key: 'addressPickerAtom',
  default: null,
})
