import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { ReactNode } from 'react'

import { withSuspense } from '@src/react-utils/components/Suspense'

import { useReaction } from './hooks'

type UseReactionParameters = Parameters<typeof useReaction>[number]
export interface ReactionButtonProps extends UseReactionParameters {
  label:
    | string
    | {
        render?: ({ isReactedByMe, totalCount }: { isReactedByMe: boolean; totalCount: number }) => ReactNode
      }
  onClick: ({ isAlreadyClicked }: { isAlreadyClicked: boolean }) => Promise<{ activateReaction?: boolean }> | void
  className?: string
}
export const ReactionButton = withSuspense(
  ({ resourceType, resourceId, type, label, onClick, className }: ReactionButtonProps) => {
    const { isReactedByMe, totalCount, handleReaction } = useReaction({ resourceType, resourceId, type })

    const handleClick = async () => {
      const res = await onClick?.({ isAlreadyClicked: isReactedByMe })

      const useReaction = (res && typeof res === 'object' && res.activateReaction) ?? true

      if (useReaction) {
        handleReaction()
      }
    }
    return (
      <Button onClick={handleClick} isSelected={isReactedByMe} className={className}>
        {label &&
          (typeof label === 'string' ? (
            label
          ) : 'render' in label && label.render ? (
            <>{label.render({ isReactedByMe, totalCount })}</>
          ) : null)}
      </Button>
    )
  }
)

const Button = styled.button<{ isSelected: boolean }>([
  vars.$semantic.typography.label3Bold,
  {
    width: 'fit-content',
    padding: '0.5rem 0.875rem',
    borderRadius: '6.25rem',
    backgroundColor: vars.$scale.color.gray100,
    color: vars.$scale.color.gray900,
  },
  ({ isSelected }) =>
    isSelected && {
      backgroundColor: vars.$semantic.color.primaryLow,
      color: vars.$semantic.color.primary,
    },
])

// display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     gap: '0.25rem',
