import { Poi } from '@daangn/local-business-network/lib/poi'

import { bridge } from '@src/bridge'
import { CategoryInputProvider } from '@src/place-utils/category'
import { emitPoiTaggingDataToStream } from '@src/place-utils/community/utils'

import CreateSuggestion from './CreateSuggestion'

const CreateSuggestionForSearch = () => {
  const handleSubmitComplete = (poi: Poi | null) => {
    if (poi) {
      emitPoiTaggingDataToStream(poi, 'ONLY_WEBVIEW_SELECT_POI_TAG_FROM_WEBVIEW')
    } else {
      bridge.toast.open({ body: '생성하신 장소를 태깅하는데 실패했습니다.' })
    }

    const DELAY_FOR_STREAM_EVENT_EMITTING = 300
    setTimeout(() => bridge.router.close(), DELAY_FOR_STREAM_EVENT_EMITTING)
  }

  return (
    <CategoryInputProvider entry="search">
      <CreateSuggestion onSubmit={handleSubmitComplete} />
    </CategoryInputProvider>
  )
}

export default CreateSuggestionForSearch
