import styled from '@emotion/styled'
import { useCallback, useState } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

interface Props {
  url: string
  disabled: boolean
}

const MIN_SCALE = 1
const SCALE_OPTIONS = {
  maxScale: 5,
  minScale: MIN_SCALE,
}
const PANNING_BLOCK_SCALE_EDGE = MIN_SCALE + 0.25

const ImageZoom = ({ disabled, url }: Props) => {
  const [blockPanning, setBlockPanning] = useState(true)
  const handleZoomChange = useCallback(
    ({ scale }: any) => {
      if (scale > PANNING_BLOCK_SCALE_EDGE) {
        blockPanning && setBlockPanning(false)
      } else if (scale < PANNING_BLOCK_SCALE_EDGE) {
        !blockPanning && setBlockPanning(true)
      }
    },
    [blockPanning]
  )
  return (
    <div>
      <TransformWrapper
        options={{
          ...SCALE_OPTIONS,
          disabled,
        }}
        onZoomChange={handleZoomChange}
        defaultScale={1}
        pan={{ disabled: disabled || blockPanning, padding: false }}>
        <TransformComponent>
          <ImageWrapper>
            <Image src={url} />
          </ImageWrapper>
        </TransformComponent>
      </TransformWrapper>
    </div>
  )
}

const ImageWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
`
const Image = styled.img`
  display: block;
  width: 100vw;
  max-height: 100%;
  object-fit: contain;
`

export default ImageZoom
