import { IS_PROD } from '../environmentConstants'

export const BIG_PICTURE_BASE_URL = IS_PROD
  ? 'https://image.kr.karrotmarket.com'
  : 'https://image.alpha.kr.karrotmarket.com'

export const BIZ_ACCOUNT_BASE_URL = IS_PROD ? 'https://ba.kr.karrotmarket.com' : 'https://ba.alpha.kr.karrotmarket.com'

export const COUPON_BASE_URL = IS_PROD
  ? 'https://commerce-coupon.kr.karrotmarket.com'
  : 'https://commerce-coupon.alpha.kr.karrotmarket.com'

export const BIZ_POST_BASE_URL = IS_PROD
  ? 'https://business-post.kr.karrotmarket.com'
  : 'https://business-post.alpha.kr.karrotmarket.com'

export const POI_SERVER_BASE_URL = IS_PROD ? `https://poi.kr.karrotmarket.com` : `https://poi.alpha.kr.karrotmarket.com`

export const COMMUNITY_SERVER_BASE_URL = IS_PROD
  ? 'https://community-api.kr.karrotmarket.com'
  : 'https://community-api.alpha.kr.karrotmarket.com'

export const NEARBY_SERVER_BASE_URL = IS_PROD
  ? 'https://nearby.kr.karrotmarket.com'
  : 'https://nearby.alpha.kr.karrotmarket.com'

export const BUSINESS_FEED_BASE_URL = IS_PROD
  ? 'https://business-feed.kr.karrotmarket.com'
  : 'https://business-feed.alpha.kr.karrotmarket.com'

export const EXPERIMENTATION_PLATFORM_BASE_URL = IS_PROD
  ? 'https://experimentation-platform.kr.karrotmarket.com'
  : 'https://experimentation-platform.alpha.kr.karrotmarket.com'

export const BUSINESS_REVIEW_BASE_URL = IS_PROD
  ? 'https://business-review.kr.karrotmarket.com'
  : 'https://business-review.alpha.kr.karrotmarket.com'

export const LOCAL_PROFILE_BASE_URL = IS_PROD
  ? 'https://local-profile-api.kr.karrotmarket.com'
  : 'https://local-profile-api.alpha.kr.karrotmarket.com'
