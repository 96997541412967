import { captureException } from '@sentry/react'
import { atomFamily, selector, selectorFamily } from 'recoil'

import { getNativeReviewV2, getNativeUserInfoV2 } from '@src/apis/__generated__/poi'
import { currentPositionSelector } from '@src/place-utils/location/store'
import { checkInRegionInfoAtom } from '@src/place-utils/region/store'
import { userInfoAtom } from '@src/place-utils/user/store'

import { generateKey } from './key'
import { nativeReviewFilterAtomFamily } from './list'
import {
  MENU_FILTER_TO_CURATION_CATEGORY_ID_MAPPER,
  REGION_LEVEL_TWO_FILTER_TO_REGION_LEVEL_TWO_MAPPER,
  RESIDENCY_PERIOD_FILTER_TO_RESIDENCY_YEARS,
  VISIT_COUNT_FILTER_TO_VISIT_COUNT_THRESHOLD_MAPPER,
} from '../constants'

export type NativeReviewRecoilFamilyKey = {
  residencyYears?: Parameters<typeof getNativeReviewV2>[0]['params']['residencyYears']
  visitCountThreshold?: Parameters<typeof getNativeReviewV2>[0]['params']['visitCountThreshold']
  curationCategoryId?: number
  regionLevelTwoId?: number
  orderBy?: Parameters<typeof getNativeReviewV2>[0]['params']['orderBy']
}

export const nativeInfoSelectorFamily = selectorFamily({
  key: generateKey('native-info-selector-family'),
  get: (userId: number) => async () => {
    try {
      const {
        data: { data },
      } = await getNativeUserInfoV2({ params: { userId } })

      return data
    } catch (error) {
      captureException(error)
    }
  },
})

export const nativeReviewsQueryFamilyKey = selector<NativeReviewRecoilFamilyKey>({
  key: generateKey('native-reviews-query-family-key'),
  get: ({ get }) => {
    const menuFilter = get(nativeReviewFilterAtomFamily('list-menu-filter'))
    const residencePeriodFilter = get(nativeReviewFilterAtomFamily('list-residence-period-filter'))
    const visitCountFilter = get(nativeReviewFilterAtomFamily('list-visit-count-filter'))
    const regionLevelTwoFilter = get(nativeReviewFilterAtomFamily('list-region-level-two-filter'))

    return {
      curationCategoryId: menuFilter?.value
        ? MENU_FILTER_TO_CURATION_CATEGORY_ID_MAPPER[parseInt(menuFilter.value.id, 10) - 1].key
        : undefined,
      residencyYears: residencePeriodFilter?.value
        ? RESIDENCY_PERIOD_FILTER_TO_RESIDENCY_YEARS[parseInt(residencePeriodFilter.value.id, 10) - 1].key
        : undefined,
      visitCountThreshold: visitCountFilter?.value
        ? VISIT_COUNT_FILTER_TO_VISIT_COUNT_THRESHOLD_MAPPER[parseInt(visitCountFilter.value.id, 10) - 1].key
        : undefined,
      regionLevelTwoId: regionLevelTwoFilter?.value
        ? REGION_LEVEL_TWO_FILTER_TO_REGION_LEVEL_TWO_MAPPER[parseInt(regionLevelTwoFilter.value.id, 10)].key
        : undefined,
      orderBy: 'REVIEW_SCORE_AND_DISTANCE_WEIGHT',
    }
  },
})
export const nativeReviewsQuery = selectorFamily({
  key: generateKey('native-reviews-query'),
  get:
    ({
      residencyYears,
      visitCountThreshold,
      curationCategoryId,
      regionLevelTwoId,
      orderBy,
    }: NativeReviewRecoilFamilyKey) =>
    async ({ get }) => {
      const { authToken: xAuthToken } = get(userInfoAtom)
      const checkInRegion = get(checkInRegionInfoAtom)
      const userPosition = get(currentPositionSelector)
      const { page, perPage } = get(
        nativeReviewsPaginationAtomFamily({
          residencyYears,
          visitCountThreshold,
          curationCategoryId,
          regionLevelTwoId,
          orderBy,
        })
      )

      const { data } = await getNativeReviewV2({
        params: {
          xAuthToken,
          residencyYears,
          visitCountThreshold,
          curationCategoryId,
          regionId: regionLevelTwoId,
          orderBy,
          // 825: 인천광역시 리젼 id
          userCoordinates:
            checkInRegion.name1Id === 825 && userPosition?.position
              ? `${userPosition.position.latitude},${userPosition.position.longitude}`
              : undefined,
          page,
          perPage,
        },
      })

      return {
        items: data.items,
        hasNextPage: data.hasNextPage,
        page: page,
      }
    },
})

export const nativeReviewsPaginationAtomFamily = atomFamily<
  { page: number; perPage: number; hasNextPage: boolean },
  NativeReviewRecoilFamilyKey
>({
  key: generateKey('native-reviews-pagination-atom-family'),
  default: {
    page: 1,
    perPage: 10,
    hasNextPage: false,
  },
})

export type NativeReviewItem = Awaited<ReturnType<typeof getNativeReviewV2>>['data']['items'][number]
export const nativeReviewsAtomFamily = atomFamily<
  {
    items: NativeReviewItem[]
    syncedPage: number
    hasNextPage: boolean
  },
  NativeReviewRecoilFamilyKey
>({
  key: generateKey('native-reviews-atom-family'),
  default: {
    items: [],
    hasNextPage: false,
    syncedPage: 0,
  },
})
