import { XCommunityPostCard } from '@daangn/local-business-kit'
import { getPostsV2, GetPostsV2200Response } from '@daangn/local-business-network/lib/poi'
import { atom, atomFamily, selectorFamily } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'
import { userInfoAtom } from '@src/place-utils/user/store'

import { detailPagePrefix } from '../constants/recoilKey'

const generateKey = generateUniqueKey([detailPagePrefix, 'community-post'])

type PostsRequestSortType = 'createdAt' | 'upvoteCount'
type PostsResponseType = GetPostsV2200Response

/**
 * Global scope
 * Global scoped store shares cache globally.
 * Given the same key or variable or dependent state, the same value will be returned.
 */
const storyQuery = selectorFamily({
  key: generateKey('storyQuery'),
  get:
    ({
      poiId,
      sortingOption,
      page,
      perPage,
    }: {
      poiId: string
      sortingOption: PostsRequestSortType
      page: number
      perPage: number
    }) =>
    async ({ get }) => {
      const userInfo = get(userInfoAtom)

      if (poiId === '' || !userInfo) {
        return {
          data: [],
          meta: DEFAULT_META_INFO,
        }
      }

      const response = await getPostsV2({
        params: {
          page,
          perPage,
          poiId,
          sortBy: sortingOption,
          xAuthToken: userInfo.authToken,
        },
      })

      return response.data
    },
})

type PageSerializeParam = { poiId: string; screenInstance: string; sortingOption: PostsRequestSortType }
/**
 * Page scope
 */
const communityPostsSortingOptionAtomFamily = atomFamily<
  PostsRequestSortType,
  Omit<PageSerializeParam, 'sortingOption'>
>({
  key: generateKey('sorting-option'),
  default: 'createdAt',
})

const DEFAULT_META_INFO: PostsResponseType['meta'] = {
  page: 0,
  perPage: 10,
  total: -1,
  hasNextPage: false,
  totalPages: -1,
}
const communityPostsPaginationAtom = atomFamily<{ page: number; perPage: number }, PageSerializeParam>({
  key: generateKey('pagination'),
  default: {
    page: 1,
    perPage: DEFAULT_META_INFO['perPage'],
  },
})

const communityPostsAtomFamily = atomFamily<PostsResponseType['data'], PageSerializeParam>({
  key: generateKey('community-posts'),
  default: [],
})

const communityArticlesSelectorFamily = selectorFamily({
  key: generateKey('community-articles'),
  get:
    (param: PageSerializeParam) =>
    ({ get }) => {
      const posts = get(communityPostsAtomFamily(param))
      return posts.filter((post) => post.type === 'STORY_ARTICLE')
    },
})

const communityCommentsSelectorFamily = selectorFamily({
  key: generateKey('community-articles'),
  get:
    (param: PageSerializeParam) =>
    ({ get }) => {
      const posts = get(communityPostsAtomFamily(param))
      return posts.filter((post) => post.type === 'STORY_COMMENT')
    },
})

const communityPostsStaticMetaAtomFamily = atomFamily<
  { perPage: number; total: number; totalPages?: number },
  PageSerializeParam
>({
  key: generateKey('community-posts-static-meta'),
  default: {
    perPage: DEFAULT_META_INFO['perPage'],
    total: DEFAULT_META_INFO['total'],
    totalPages: DEFAULT_META_INFO['totalPages'],
  },
})

const communityPostsDynamicMetaAtomFamily = atomFamily<{ page: number; hasNextPage: boolean }, PageSerializeParam>({
  key: generateKey('community-posts-dynamic-meta'),
  default: {
    page: DEFAULT_META_INFO['page'],
    hasNextPage: DEFAULT_META_INFO['hasNextPage'],
  },
})

export {
  communityPostsPaginationAtom,
  communityPostsAtomFamily,
  communityArticlesSelectorFamily,
  communityCommentsSelectorFamily,
  communityPostsStaticMetaAtomFamily,
  communityPostsDynamicMetaAtomFamily,
  communityPostsSortingOptionAtomFamily,
  storyQuery,
}
export type { PostsRequestSortType, PageSerializeParam as CommunityPostsPageSerializeParam }

export const interestedSingleStoryAtom = atom<{
  postId?: string
  resourceType?: XCommunityPostCard['data']['type']
  resourceId?: string
  index: number
}>({
  key: generateKey('interestedSingleStory'),
  default: undefined,
})
