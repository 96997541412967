import { usePoiInputSelectionState } from '@src/place-utils/poi/PoiInput/state'
import { AlertDialog } from '@src/react-utils/components/AlertDialog'
import { useNavigator } from '@src/stackflow'

interface Props {
  id: string
  name: string
  bizAccountId?: string
}
export const BlockedBizReviewUpdateDialog = ({ id, name, bizAccountId }: Props) => {
  const { pop } = useNavigator()
  const [, setSelectedRecoilState] = usePoiInputSelectionState()

  const handleSelectPoi = () => {
    setSelectedRecoilState({ id, name, bizAccountId })

    pop(2)
  }

  return (
    <AlertDialog
      contents={`작성한 후기가 신고되어 ${name}에 쓴 후기를 수정해도 바로 보이지 않아요.`}
      CTAButton={{
        label: '후기 수정',
        onClick: handleSelectPoi,
      }}
      subButton={{
        label: '취소',
        onClick: pop,
      }}
      buttonAlign="row"
    />
  )
}
