import * as Sentry from '@sentry/react'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { bridge } from '@src/bridge'
import { tokenStorage } from '@src/js-utils/tokenStorage'

import { requestIdInjector, userAgentInjector } from './utils/interceptors'

export default class Request {
  public req: AxiosInstance
  constructor(domain: string, options?: AxiosRequestConfig) {
    this.req = axios.create({
      baseURL: domain,
      ...options,
    })

    this.req.interceptors.request.use(userAgentInjector)
    this.req.interceptors.request.use(requestIdInjector, (error) => {
      Sentry.captureException(error)
      return error
    })

    if (!tokenStorage.getAuthToken()) {
      bridge.info.user().then((info) => {
        if (!info) return
        tokenStorage.setAuthToken(info.authToken)
      })
    }
  }

  getUserAuthHeader = () => {
    const userAuthToken = tokenStorage.getAuthToken()
    return {
      'X-Auth-Token': userAuthToken,
    }
  }

  generateApiHeaders = (authToken: string, otherHeaders: { [key: string]: string } = {}) => ({
    headers: {
      'X-Auth-Token': authToken,
      ...otherHeaders,
    },
  })
}
