import { getNativeReviewByIdV2 } from '@daangn/local-business-network/lib/poi'
import { captureException } from '@sentry/react'
import { selectorFamily } from 'recoil'

import { getNativeReviewByUserV2 } from '@src/apis/__generated__/poi'

import { generateKey } from './key'

export const nativeReviewSelectorFamily = selectorFamily({
  key: generateKey('native-review-selector-family'),
  get: (reviewId: number) => async () => {
    try {
      const {
        data: { data },
      } = await getNativeReviewByIdV2({ params: { id: reviewId } })

      return data
    } catch (err) {
      captureException(err)

      throw err
    }
  },
})

export const userNativeReviewsSelectorFamily = selectorFamily({
  key: generateKey('user-native-review-selector-family'),
  get: (userId: number) => async () => {
    const {
      data: { data: reviews },
    } = await getNativeReviewByUserV2({ params: { userId } })

    return reviews
  },
})
