import { Poi } from '@daangn/local-business-network/lib/poi'

import { karrotBridge } from '@src/bridge'

type StreamName = 'SELECT_POI_TAG_FROM_WEBVIEW' | 'ONLY_WEBVIEW_SELECT_POI_TAG_FROM_WEBVIEW' // 둘이 뭐가 다른가요? -> https://daangn.slack.com/archives/C04KDBFQSAD/p1698988421939109

//www.notion.so/daangn/c86b1975277e44eb92add7307710f5c0?pvs=4#3998ab1c8ad0494f9d1713edbaae6671
interface EmittedData {
  id: string
  name: string
  address: {
    road: string
    jibun: string
    extra: string
  }
}

interface Deprecated_EmittedData {
  poi_id: string
  poi_name: string
}

/**
 * @description onSelectPoi action for external webview (e.g. community tagging)
 */
export const emitPoiTaggingDataToStream = (poi: Poi, streamName?: StreamName) => {
  const emittedData: EmittedData & Deprecated_EmittedData = {
    id: poi.id,
    name: poi.name,
    address: {
      road: poi.address || '',
      jibun: poi.jibunAddress || '',
      extra: poi.addressExtra || '',
    },

    /**
     * @deprecated use `id` instead of `poi_id` (kept for backward compatibility)
     */
    poi_id: poi.id,
    /**
     * @deprecated use `name` instead of `poi_name` (kept for backward compatibility)
     */
    poi_name: poi.name,
  }

  const eventName: StreamName = streamName ?? 'SELECT_POI_TAG_FROM_WEBVIEW'

  karrotBridge.emitToStream({
    stream: {
      eventName,
      data: JSON.stringify(emittedData),
    },
  })
}
