import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useRecoilValue } from 'recoil'

import { reactionAtomFamily } from '@src/place-utils/reaction/store'
import { BottomSheet } from '@src/react-utils/components/BottomSheet'
import { useNavigator } from '@src/stackflow'

import { Contents } from './Contents'
import { REGION } from '../../constants'

interface Props {
  poiId: string
}
export const ReactionDisplayBottomSheet = ({ poiId }: Props) => {
  const { pop } = useNavigator()
  const { totalCount, reactedUsers } = useRecoilValue(
    reactionAtomFamily({ resourceId: poiId, resourceType: 'POI', type: 'MATJIB' })
  )

  return (
    <BottomSheet
      title={{
        render: () => (
          <Title>
            여기 맛집이라고 한 {REGION} 이웃 <span className="highlight">{totalCount}</span>
          </Title>
        ),
      }}
      contents={
        <Contents
          totalCount={totalCount}
          users={reactedUsers ?? []}
          resourceId={poiId}
          resourceType="POI"
          type="MATJIB"
          sortBy="residenceYear"
        />
      }
      closeButton={{
        onClick: pop,
      }}
      options={{
        useDividerBetweenHeaderAndContent: true,
        useContentsSafeAreaPadding: true,
        UNSTABLE_overrideMaxHeight: '500px',
      }}
    />
  )
}

const Title = styled.h1([
  vars.$semantic.typography.title2Bold,
  {
    color: vars.$scale.color.gray900,

    '.highlight': {
      color: vars.$semantic.color.primary,
    },
  },
])
