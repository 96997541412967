import styled from '@emotion/styled'
import IconAndroidShareRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconAndroidShareRegular'
import IconIosShareRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconIosShareRegular'
import IconMoreVertFill from '@karrotmarket/karrot-ui-icon/lib/react/IconMoreVertFill'
import { vars } from '@seed-design/design-token'

import { IS_ANDROID } from '@src/constants/environmentConstantsWithBridge'

interface Props {
  type?: 'share' | 'more'
  activatedStatusColor?: string
  onClick?: () => void
  disabled?: boolean
}

const icons = {
  share: IS_ANDROID ? IconAndroidShareRegular : IconIosShareRegular,
  more: IconMoreVertFill,
}

const IconButton = ({ type, activatedStatusColor, onClick, disabled }: Props) => {
  const color = !disabled ? activatedStatusColor : vars.$scale.color.gray600
  const Icon = type && icons[type]

  return (
    <Button
      onClick={() => {
        if (!disabled) {
          onClick?.()
        }
      }}>
      {Icon && <Icon color={color} height="100%" width="100%" />}
    </Button>
  )
}

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
`

export default IconButton
