import { Poi } from '@daangn/local-business-network/lib/poi'

import { bridge } from '@src/bridge'
import { CategoryInputProvider } from '@src/place-utils/category'
import { emitPoiTaggingDataToStream } from '@src/place-utils/community/utils'

import CreateSuggestion from './CreateSuggestion'

const CreateSuggestionForCommunityTagPage = () => {
  const handleSubmitComplete = (poi: Poi | null) => {
    if (poi) {
      emitPoiTaggingDataToStream(poi)
    } else {
      bridge.toast.open({ body: '생성하신 장소를 태깅하는데 실패했습니다.' })
      bridge.router.close()
    }
  }

  return (
    <CategoryInputProvider entry="communityTag">
      <CreateSuggestion onSubmit={handleSubmitComplete} />
    </CategoryInputProvider>
  )
}

export default CreateSuggestionForCommunityTagPage
