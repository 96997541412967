import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useReducer, useState } from 'react'
import { createPortal } from 'react-dom'
import { useRecoilValue } from 'recoil'

import { IS_LOCAL, IS_PROD } from '@src/constants/environmentConstants'
import { localStorage } from '@src/js-utils/localStorage'

import { NativeReviewDevTools } from './NativeReviewDevTools'
import { useAnalyticsLogOff, useCurrentUserPositionOff, useHistoryLogOff, useSentryLogOff } from './state'
import { debugUserAtom } from './state/debug-user'
import { getVConsole } from './vConsole'
import { ActionSheet } from '../ActionSheet'

const _DevTools = () => {
  const [isOpen, toggleIsOpen] = useReducer((prev) => !prev, false)
  const [analyticsLogOff, setAnalyticsLogOff] = useAnalyticsLogOff()
  const [historyLogOff, setHistoryLogOff] = useHistoryLogOff()
  const [sentryLogOff, setSentryLogOff] = useSentryLogOff()
  const [currentUserPositionOff, setCurrentUserPositionOff] = useCurrentUserPositionOff()

  const [isNativeReviewDevToolsOpen, setIsNativeReviewDevToolsOpen] = useState(false)

  const handleResetTooltips = async () => {
    localStorage.clear()
    toggleIsOpen()
  }

  const handleToggleAnalyticsLog = () => {
    setAnalyticsLogOff(!analyticsLogOff)

    toggleIsOpen()
  }

  const handleToggleHistoryLog = () => {
    setHistoryLogOff(!historyLogOff)

    toggleIsOpen()
  }

  const handleToggleSentryLog = () => {
    setSentryLogOff(!sentryLogOff)

    toggleIsOpen()
  }

  const handleResetVConsolePosition = () => {
    const vConsole = getVConsole()

    vConsole.setSwitchPosition(20, 20)

    toggleIsOpen()
  }

  const handleToggleCurrentUserPosition = () => {
    setCurrentUserPositionOff(!currentUserPositionOff)

    toggleIsOpen()
  }

  return (
    <>
      {createPortal(
        <>
          <Button onClick={toggleIsOpen}>DEV</Button>
          {isOpen && (
            <ActionSheet
              onOutsideClick={toggleIsOpen}
              dismissButton={{
                label: '닫기',
                onClick: toggleIsOpen,
              }}
              actionButtons={[
                {
                  label: '토박이 실험',
                  onClick: () => setIsNativeReviewDevToolsOpen(true),
                },
                { label: 'reset all tooltips', onClick: handleResetTooltips },
                { label: `로그 ${analyticsLogOff ? '켜기' : '끄기'}`, onClick: handleToggleAnalyticsLog },
                { label: `히스토리 ${historyLogOff ? '켜기' : '끄기'}`, onClick: handleToggleHistoryLog },
                { label: `Sentry ${sentryLogOff ? '켜기' : '끄기'}`, onClick: handleToggleSentryLog },
                {
                  label: `유저 현재 위치 ${currentUserPositionOff ? '켜기' : '끄기'}`,
                  onClick: handleToggleCurrentUserPosition,
                },
                {
                  label: `vConsole 위치 초기화`,
                  onClick: handleResetVConsolePosition,
                },
              ]}
            />
          )}
          {isNativeReviewDevToolsOpen && (
            <NativeReviewDevTools
              onClose={() => {
                setIsNativeReviewDevToolsOpen(false)
                toggleIsOpen()
              }}
            />
          )}
        </>,
        document.body
      )}
    </>
  )
}

const Button = styled.button`
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 3rem;
  height: 2rem;
  border: none;
  background: ${vars.$scale.color.greenAlpha200};
  color: ${vars.$scale.color.grayAlpha200};
  border-top-right-radius: 0.5rem;
`

export const DevTools = () => {
  const { isDebugUiVisible, isDeveloper } = useRecoilValue(debugUserAtom)

  const showVConsole = (isDeveloper && !IS_LOCAL) || !IS_PROD
  const showDevTools = isDeveloper || isDebugUiVisible || !IS_PROD

  if (showVConsole) {
    const vConsole = getVConsole()

    vConsole.showSwitch()
  }

  return showDevTools ? <_DevTools /> : null
}
