import { createContext } from 'react'

export interface IToast {
  text: string
  duration: 'short' | 'long' | number
  button?: {
    text: string
    onClickButton?: () => void
  }
  icon?: 'check' | 'warning'
  onRemove?: () => void
  /**
   * set custom bottom margin (in px) to prevent toast from covering underneath action buttons
   */
  bottomMargin?: number
}

const ToastContext = createContext<{
  toasts: { [id: string]: IToast }
  addToast: (Toast: IToast) => void
  removeToast: (toastId: string) => void
}>({
  toasts: {},
  addToast: (toast: IToast) => {
    console.log(toast)
  },
  removeToast: (toastId: string) => {
    console.log(toastId)
  },
})

export default ToastContext
