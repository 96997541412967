import { Category } from '@daangn/local-business-network/lib/poi'
import { atom } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'

export type CategoryInputState = Partial<Category> & { categorySuggestionId?: number }

const generateCategoryInputRecoilKey = generateUniqueKey('categoryInput')

export const selectedCategoryAtom = atom<null | CategoryInputState>({
  key: generateCategoryInputRecoilKey('selectedCategory'),
  default: null,
})

export const servicedFromAtom = atom({
  key: generateCategoryInputRecoilKey('servicedFrom'),
  default: '',
})
