import { getPoiReviewsByUserId } from '@daangn/local-business-network/lib/legacyReview'
import { captureException } from '@sentry/react'
import { selectorFamily } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'

const generateKey = generateUniqueKey('userReview')

type UserReviewRequestParameters = Parameters<typeof getPoiReviewsByUserId>['0']['params']

export const userReviewSelectorFamily = selectorFamily({
  key: generateKey('userReviewSelectorFamily'),
  get: (params: UserReviewRequestParameters) => async () => {
    try {
      const { data } = await getPoiReviewsByUserId({ params })

      return data
    } catch (error) {
      captureException(error)

      throw error
    }
  },
})
