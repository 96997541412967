import { useScrollToTopEffect } from '@daangn/stackflow-plugin-scroll-to-top'
import { vars } from '@seed-design/design-token'
import { AppScreen, useAppScreen, AppScreenProps } from '@stackflow/plugin-basic-ui'
import { ReactNode } from 'react'

import { THEME } from './styles'

const appBarDefaultStyle: Pick<NonNullable<AppScreenProps['appBar']>, 'borderColor' | 'textColor' | 'iconColor'> = {
  borderColor: THEME === 'cupertino' ? vars.$semantic.color.divider3 : vars.$semantic.color.divider2,
  textColor: vars.$scale.color.gray900,
  iconColor: vars.$scale.color.gray900,
}

export const Screen = ({ appBar, children, ...appScreenProps }: AppScreenProps) => {
  return (
    <AppScreen
      appBar={
        appBar
          ? {
              ...appBarDefaultStyle,
              ...appBar,
            }
          : undefined
      }
      {...appScreenProps}>
      <ScrollToTop>{children}</ScrollToTop>
    </AppScreen>
  )
}

const ScrollToTop = ({ children }: { children: ReactNode }) => {
  const { scroll } = useAppScreen()

  useScrollToTopEffect(() => {
    scroll({ top: 0 })
  }, [])

  return <>{children}</>
}
