import { FilterPicker } from './FilterPicker'
import { REGION_LEVEL_TWO_FILTER_TO_REGION_LEVEL_TWO_MAPPER } from '../../constants'
import { useAnalytics } from '../../hooks'

export const RegionLevelTwoFilterBottomSheet = () => {
  const logEvent = useAnalytics()

  const handleClickOption = (id: string) => {
    logEvent('click_region_level_two_filter_detail', {
      region_id: REGION_LEVEL_TWO_FILTER_TO_REGION_LEVEL_TWO_MAPPER[parseInt(id, 10) - 1].key,
    })
  }
  return (
    <FilterPicker
      id="list-region-level-two-filter"
      title="지역을 선택해주세요"
      options={REGION_LEVEL_TWO_FILTER_TO_REGION_LEVEL_TWO_MAPPER.map(({ name, filterId }) => ({
        name,
        id: filterId.toString(),
      }))}
      onClickOption={handleClickOption}
    />
  )
}
