import './poiConfig'
import './bizAccountConfig'
import './couponConfig'
import './bizPostConfig'
import './legacyReviewConfig'
import './localProfileConfig'

import {
  JUSO_BASE_URL,
  BIG_PICTURE_BASE_URL,
  BUSINESS_REVIEW_BASE_URL,
  COMMUNITY_SERVER_BASE_URL,
  NEARBY_SERVER_BASE_URL,
  EXPERIMENTATION_PLATFORM_BASE_URL,
} from '@src/constants/url'

import BigPictureAPI from './BigPicture'
import BizReviewLegacyApi from './BizReviewLegacy'
import CommunityApi from './communityApi'
import ExperimentalPlatform from './ExperimentationPlatform'
import JusoAPI from './Juso'
import KakaLocalAPI from './KakaoLocal'
import NearbyApi from './nearbyApi'

export const nearbyApi = new NearbyApi(NEARBY_SERVER_BASE_URL)
export const communityApi = new CommunityApi(COMMUNITY_SERVER_BASE_URL)
export const bizReviewLegacyApi = new BizReviewLegacyApi(BUSINESS_REVIEW_BASE_URL)
export const experimentalPlatformApi = new ExperimentalPlatform(EXPERIMENTATION_PLATFORM_BASE_URL)

export const bigPictureApi = new BigPictureAPI(BIG_PICTURE_BASE_URL, {
  headers: {
    'Content-Type': 'application/json',
  },
})
export const kakaLocalApi = new KakaLocalAPI()
export const jusoApi = new JusoAPI(JUSO_BASE_URL)
