import { Review as BusinessReview, type ReviewProps as BusinessReviewProps } from '@daangn/business-review-kit'
import { type LegacyPoiReviewResponse } from '@daangn/local-business-network/lib/legacyReview'
import { type ReactNode, forwardRef } from 'react'
import { useRecoilValue } from 'recoil'

import { karrotBridge } from '@src/bridge'
import { ImageDetailSlider } from '@src/react-utils/components/Image'
import { useImageSliderState } from '@src/react-utils/hooks/useImageSliderState'

import { userInfoAtom } from '../../user/store'
import { businessReviewAnalyticsManager } from '../businessReviewAnalyticsManager'
import { useBusinessReviewRoutingManager } from '../hooks/useBusinessReviewRoutingManager'

interface ReviewProps {
  className?: string
  data: LegacyPoiReviewResponse
  onClickUserProfileMore?: ({ reviewId, reviewerId }: { reviewId: string; reviewerId: number }) => void
  thumbsUp?: {
    render: () => ReactNode
  }
  options?: BusinessReviewProps['options']

  area: string
}
export const Review = forwardRef<HTMLDivElement, ReviewProps>(({ data, ...props }, ref) => {
  const { user: author } = data

  const { openUserProfilePage } = useBusinessReviewRoutingManager()
  const { handleCloseDetailImage, handleSelectDetailImage, selectedImageIdx } = useImageSliderState()

  const userId = useRecoilValue(userInfoAtom).id

  if (!author?.id) {
    return null
  }

  const isAuthor = userId === author.id

  // User Profile
  const handleClickUserProfile = () => {
    openUserProfilePage({ userId: author.id.toString() })
  }
  const handleClickUserProfileMore = () => {
    props.onClickUserProfileMore?.({ reviewId: data.id + '', reviewerId: author.id })
  }

  return (
    <>
      <BusinessReview
        ref={ref}
        analyticsManager={businessReviewAnalyticsManager}
        reviewId={String(data.id)}
        className={props.className}
        bridge={karrotBridge}
        viewer={isAuthor ? 'author' : 'others'}
        status={{ type: 'default' }}
        profile={{
          avatar: author.profileImageUrl
            ? { src: author.profileImageUrl, alt: `${author.nickname}님의 프로필` }
            : undefined,
          name: author.nickname,
          type: 'user',
          tags: [
            author.displayRegionName ? `${author.displayRegionName} 인증 ${author.displayRegionCheckinsCount}회` : '',
          ].filter(Boolean),
          onClick: handleClickUserProfile,
          onClickMore: props.onClickUserProfileMore ? handleClickUserProfileMore : undefined,
        }}
        content={{
          starRating: data?.starRating ?? undefined,
          createdAt: new Date(data.createdAt),
          text: data.content,
          images:
            data.images.length > 0
              ? data.images.map((img, idx) => ({ src: img.url, alt: `사진 ${idx + 1}` }))
              : undefined,
          tags: data.tagNames.map((tag) => ({ label: tag, type: 'basic' })),
          onClickImage(e, { index }) {
            e.stopPropagation()
            handleSelectDetailImage(index)
          },
        }}
        interactions={props.thumbsUp ? [{ type: 'thumbs-up', render: props.thumbsUp.render }] : undefined}
        options={props.options}
      />

      {data.images.length > 0 && (
        <ImageDetailSlider
          onClose={handleCloseDetailImage}
          initialIndex={selectedImageIdx}
          images={data.images.map((img) => img.url)}
        />
      )}
    </>
  )
})
