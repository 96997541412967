import { Poi } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { Fragment } from 'react'

import { karrotBridge } from '@src/bridge'
import { PERMALINK_BASE_URL } from '@src/constants/url'

import IconButton from './IconButton'
import NavbarMoreButton from './NavbarMoreButton'
import { useAnalytics } from '../hooks'

interface Props {
  poiData: Poi
  iconColor?: string
}

const NavbarButtons = ({ poiData, iconColor }: Props) => {
  const { publicId, name } = poiData

  const logEvent = useAnalytics()

  const handleClickShare = () => {
    /** @deprecated */
    logEvent('click_share_button', {
      area: 'navbar',
    })
    logEvent('click_share_profile_button', {
      area: 'navbar',
    })

    karrotBridge.share({
      share: {
        url: `${PERMALINK_BASE_URL}/kr/pois/${publicId}`,
        text: name,
      },
    })
  }

  return (
    <Container>
      {[
        {
          id: 'share',
          node: <IconButton type="share" activatedStatusColor={iconColor} onClick={handleClickShare} />,
        },
        {
          id: 'moreOptions',
          node: <NavbarMoreButton iconColor={iconColor} />,
        },
      ].map(({ id, node }) => (
        <Fragment key={id}>{node}</Fragment>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > :not(:first-of-type) {
    margin: 0 0 0 1rem;
  }
`

export default NavbarButtons
