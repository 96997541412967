import { atomFamily } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'

import { detailPagePrefix } from '../constants/recoilKey'

const prefix = `${detailPagePrefix}/tabs`
const generateKey = generateUniqueKey(prefix)

/**
 * Page scope
 */
type TabKey = 'home' | 'posts'
interface Tab {
  key: TabKey
  label: string
}
const tabs = [
  {
    key: 'home' as const,
    label: '홈',
  },
  {
    key: 'posts' as const,
    label: '후기',
  },
]

const tabAtomFamily = atomFamily<TabKey, string>({
  key: generateKey('tab'),
  default: tabs[0]['key'],
})

type PostsTabKey = 'review' | 'story'
const postsTabAtomFamily = atomFamily<PostsTabKey, string>({
  key: generateKey('postsTab'),
  default: 'review',
})

export type { TabKey, PostsTabKey }
export { tabAtomFamily, postsTabAtomFamily }

export type { Tab }
export { tabs }
