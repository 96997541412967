import { captureException } from '@sentry/react'

import { KakaoAddressDocument } from './types/kakaoLocal'

/**
 *
 * https://apis.map.kakao.com/web/documentation/#services_Status
 * - OK : 검색 결과 있음
 * - ZERO_RESULT : 정상적으로 응답 받았으나 검색 결과는 없음
 * - ERROR : 서버 응답에 문제가 있는 경우
 *
 */
type STATUS = 'OK' | 'ZERO_RESULT' | 'ERROR'

export default class KakaLocalAPI {
  private geocoder: any

  constructor() {
    if (!window?.kakao?.maps) return
    this.geocoder = new window.kakao.maps.services.Geocoder()
  }

  /**
   *
   * https://apis.map.kakao.com/web/documentation/#services_Geocoder_coord2Address
   */
  getQuerySearchAddress = ({ query, page, size }: { query: string; page?: number; size?: number }) => {
    return new Promise<KakaoAddressDocument[]>((resolve, rejects) => {
      try {
        const addr = query
        const callback = (result: KakaoAddressDocument[], status: STATUS) => {
          if (status === 'OK') {
            resolve(result)
          }

          if (status === 'ZERO_RESULT') {
            resolve(result)
          }

          if (status === 'ERROR') {
            captureException('kakao server error: geocoder.addressSearch failed')
            rejects('Kakao server error')
          }
        }
        const options = { page, size }

        this.geocoder.addressSearch(addr, callback, options)
      } catch (error) {
        captureException('kakao server error: geocoder.addressSearch failed')
        rejects(error)
      }
    })
  }

  /**
   *
   * https://apis.map.kakao.com/web/documentation/#services_Geocoder_coord2Address
   */
  getAddressFromCoord = (latitude: number, longitude: number) => {
    return new Promise<KakaoAddressDocument[]>((resolve, rejects) => {
      try {
        const x = longitude
        const y = latitude
        const callback = (result: KakaoAddressDocument[], status: STATUS) => {
          if (status === 'OK') {
            resolve(result)
          }

          if (status === 'ZERO_RESULT') {
            resolve(result)
          }

          if (status === 'ERROR') {
            captureException('kakao server error: geocoder.coord2Address failed')
            rejects('Kakao server error')
          }
        }

        this.geocoder.coord2Address(x, y, callback)
      } catch (error) {
        captureException('kakao server error: geocoder.coord2Address failed')
        rejects(error)
      }
    })
  }
}
