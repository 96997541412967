import type { AxiosInstance } from 'axios'

import { NetworkFetchConfig, NetworkHostEnvironment, getBaseURL, NetworkHostsMap } from './configs/requestConfig'
import { setFetchConfig as setBaseFetchConfig } from '../__generated__/poi/common'

const HOSTS: Required<NetworkHostsMap> = {
  production: 'https://poi.kr.karrotmarket.com',
  alpha: 'https://poi.alpha.kr.karrotmarket.com',
}

export function setFetchConfig(environment: NetworkHostEnvironment, config: NetworkFetchConfig = {}): AxiosInstance {
  const { host, configs } = config
  const baseURL = getBaseURL({ defaultHosts: HOSTS, host, environment })

  return setBaseFetchConfig({
    ...configs,
    baseURL,
  })
}
