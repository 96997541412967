import styled from '@emotion/styled'
import { useActivityParams } from '@stackflow/react'
import { Fragment, useLayoutEffect, useRef } from 'react'

import { Divider as DividerComponent } from '@src/react-utils/components/Divider'
import { InfiniteScrollObserver } from '@src/react-utils/components/InfiniteScroll'
import useScrollElementIntoView from '@src/react-utils/hooks/useScrollElementIntoView'
import { useStepNavigator } from '@src/stackflow'

import { ActivityParams } from '.'
import { ListNoReview } from './NoReview'
import { Review } from './Review'
import { SurveyCallout } from './SurveyCallout'
import { useNativeReviews } from '../hooks'

const SURVEY_CALLOUT_POSITION = 12

export const List = () => {
  const { highlight_review_id: highlightReviewId } = useActivityParams<ActivityParams>()
  const { reviews, hasNextPage, useSync, fetchMore, fetchState } = useNativeReviews()
  const scrollIntoView = useScrollElementIntoView()
  const { stepReplace } = useStepNavigator('native_review_list')

  const scrollElementRef = useRef<HTMLDivElement>(null)
  const listItemElementsRef = useRef<Record<string, HTMLDivElement>>({})

  useSync()

  useLayoutEffect(() => {
    if (
      !highlightReviewId ||
      !scrollElementRef.current?.parentElement ||
      !listItemElementsRef.current[highlightReviewId]
    )
      return

    scrollIntoView(scrollElementRef.current.parentElement, listItemElementsRef.current[highlightReviewId], 'vertical')
    stepReplace({ highlight_review_id: undefined })
  }, [highlightReviewId, reviews.items, scrollIntoView, stepReplace])

  if (reviews.items.length === 0 && fetchState === 'hasValue') return <ListNoReview />

  return (
    <Container ref={scrollElementRef}>
      {reviews.items.map((item, index) => (
        <Fragment key={item.review.id}>
          <Review
            nativeReview={item}
            index={index}
            ref={(element) => {
              if (!element) return

              listItemElementsRef.current[item.review.id] = element
            }}
          />
          {index === SURVEY_CALLOUT_POSITION - 2 ? <SurveyCallout /> : <Divider />}
        </Fragment>
      ))}
      {hasNextPage && <InfiniteScrollObserver onIntersecting={fetchMore} />}
    </Container>
  )
}

const Container = styled.div({
  margin: '1rem 0 4rem',
})
const Divider = styled(DividerComponent)({
  ':last-of-type': {
    display: 'none',
  },
})
