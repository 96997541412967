import styled from '@emotion/styled'
import IconGroupchatKingFill from '@karrotmarket/karrot-ui-icon/lib/react/IconGroupchatKingFill'
import { vars } from '@seed-design/design-token'

const CROWN_ICON_SIZE = 15

interface Props {
  label?: string
}
export const CrownBadge = ({ label }: Props) => {
  return (
    <Container>
      <IconGroupchatKingFill width={CROWN_ICON_SIZE} height={CROWN_ICON_SIZE} />
      {label && <Label>{label}</Label>}
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  color: vars.$semantic.color.primary,
  gap: '0.125rem',
})
const Label = styled.span([vars.$semantic.typography.caption2Bold])
