import { Poi } from '@daangn/local-business-network/lib/poi'
import { useActivityParams } from '@stackflow/react'

import { InfiniteScrollObserver } from '@src/react-utils/components/InfiniteScroll'

import { PoiCard } from './PoiCard'
import { CreatePoiCallout } from '../components'
import { useAnalytics, usePoisLoadableFamilyPaginate } from '../hooks'
import type { ActivityParams } from '../index'

const CALLOUT_POSITION = 11

interface Props {
  onSelectPoi: (poi: Poi) => void
}
export const Result = ({ onSelectPoi }: Props) => {
  const { create_flow } = useActivityParams<{ create_flow?: ActivityParams['create_flow'] }>()
  const logEvent = useAnalytics()

  const {
    value: { items: pois, hasNextPage },
    loadMore,
  } = usePoisLoadableFamilyPaginate()

  const handleClickPoiCard = (index: number) => (poi: Poi) => {
    logEvent('click_select_poi_button', {
      poi_id: poi.id,
      position: index + 1,
      page: Math.ceil((index + 1) / 20),
      area: 'list',
    })
    onSelectPoi(poi)
  }

  const sliceLength = pois.length < 20 ? 20 : CALLOUT_POSITION - 1
  return (
    <>
      {pois.slice(0, sliceLength).map((poi: Poi, index: number) => (
        <PoiCard key={poi.id} poi={poi} onClick={handleClickPoiCard(index)} />
      ))}
      <CreatePoiCallout
        type={create_flow === 'simplified' ? 'text-only' : 'callout'}
        create_flow={create_flow === 'simplified' ? 'simplified' : 'default'}
      />
      {pois.slice(sliceLength).map((poi: Poi, index: number) => (
        <PoiCard key={poi.id} poi={poi} onClick={handleClickPoiCard(index)} />
      ))}
      {hasNextPage && <InfiniteScrollObserver onIntersecting={loadMore} />}
    </>
  )
}
