import { atom, selector } from 'recoil'

import { userInfoAtom } from '@src/place-utils/user/store/user-info'

const segmentNames = ['control', 'treatment-A', 'treatment-B', 'treatment-C'] as const
type Type_SegmentName = (typeof segmentNames)[number]
type Type_Segment = Array<{
  name: Type_SegmentName
  label: string
  selector: Array<number>
}>

const segments: Type_Segment = [
  { name: 'control', label: '대조군 (도움돼요+맛집이에요 1차)', selector: [0] },
  { name: 'treatment-A', label: '실험군 A (맛집이에요 2차)', selector: [1] },
  { name: 'treatment-B', label: '실험군 B (맛집이에요 1차 ONLY)', selector: [2] },
  { name: 'treatment-C', label: '실험군 C (맛집이에요 2차 IMPROVED)', selector: [3] },
]

const segmentForceAtom = atom<Type_SegmentName | undefined>({
  key: 'DO_NOT_USE_enhancedReaction-segment-force-atom',
  default: undefined,
})

const segmentSelector = selector<Type_SegmentName>({
  key: 'EXPERIMENT_enhancedReaction-selector',
  get: ({ get }) => {
    const force = get(segmentForceAtom)
    if (typeof force !== 'undefined') return force

    const userId = get(userInfoAtom).id
    const remainder = userId % 4

    const fallback = segments[0].name
    const segment = segments.find(({ selector }) => selector.includes(remainder))?.name

    return segment ?? fallback
  },
})

export type EXPERIMENT_enhancedReactionSegmentNameType = Type_SegmentName
export const EXPERIMENT_enhancedReactionSegments = segments
export const EXPERIMENT_enhancedReactionSelector = segmentSelector
export const DO_NOT_USE_enhancedReactionSegmentForceAtom = segmentForceAtom
