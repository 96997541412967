import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { ReactNode } from 'react'

interface Props {
  label?: string
  children: ReactNode
}

export const Section = ({ label, children }: Props) => {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      {children}
    </Container>
  )
}

const Container = styled.div({})
const Label = styled.label([
  vars.$semantic.typography.label3Bold,
  {
    display: 'block',
    margin: '0 0 0.75rem',
    color: vars.$scale.color.gray900,
  },
])
