import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { CSSProperties, memo } from 'react'
import { useRecoilValue } from 'recoil'

import { Filters } from './Filters'
import { ReviewStatusCallout } from './ReviewStatusCallout'
import { myReviewCountByStatusSelector } from '../store'

export const TopArea = memo(() => {
  const showReviewStatusCallout = useRecoilValue(myReviewCountByStatusSelector).pendingCount > 0
  const lastComponent = showReviewStatusCallout ? 'callout' : 'filter'

  return (
    <>
      <Wrapper sticky margin={'0 !important'} isLast={lastComponent === 'filter'}>
        <Filters />
      </Wrapper>
      {showReviewStatusCallout && (
        <Wrapper margin={'0.5rem 0 1rem 0'} isLast={lastComponent === 'callout'}>
          <ReviewStatusCallout />
        </Wrapper>
      )}
    </>
  )
})

const Wrapper = styled.div<{ margin?: CSSProperties['margin']; sticky?: boolean; isLast?: boolean }>([
  {
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: vars.$semantic.color.paperDefault,
  },
  ({ margin, isLast }) => ({
    margin,
    marginBottom: isLast ? '1.5rem' : undefined,
  }),
  ({ sticky }) =>
    sticky && {
      zIndex: 1,
      position: 'sticky',
      top: 0,
    },
])
