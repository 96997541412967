import { useCallback } from 'react'

import { useLogEvent } from '@src/react-utils/components/Analytics'

import { useSessionTimerContext } from '../hoc/withSessionTimer'

export const useAnalytics = () => {
  const logEvent = useLogEvent()
  const sessionTimer = useSessionTimerContext()

  return useCallback(
    (name: string, params?: { [key: string]: any }) => {
      const _params = {
        visit_session_id: sessionTimer.visitSessionId,
        session_duration_ms: new Date().getTime() - sessionTimer.initialTime.getTime(),
        ...params,
      }

      logEvent({ name, params: _params })
    },
    [sessionTimer.visitSessionId, sessionTimer.initialTime, logEvent]
  )
}
