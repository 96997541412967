import { Map as KarrotMap, Marker, Pin, useMap } from '@daangn/karrotmaps-react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { Suspense, useCallback, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { Button as ButtonComponent } from '@src/react-utils/components/Button'
import { Coordinates } from '@src/types/global'

import { openKarrotMap } from './utils/open-karrot-map'

export interface StaticMapProps {
  target: {
    name: string
    address: string
    coordinates: Coordinates
  }
  onClickMap?: () => void
  onLoadError?: () => void
  onLoadSuccess?: () => void
  className?: string
}

export const MapComponents = ({
  target,
  onLoadError,
  onLoadSuccess,
}: Pick<StaticMapProps, 'target' | 'onLoadSuccess' | 'onLoadError'>) => {
  const { coordinates } = target
  const { current: map } = useMap()

  useEffect(() => {
    if (!map) return

    map.on('load', onLoadSuccess)
    map.on('error', onLoadError)
  }, [map, onLoadError, onLoadSuccess])

  return (
    <Marker
      position={{
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      }}
      anchor="bottom">
      <Pin variation="pin-default-single" />
    </Marker>
  )
}

export const StaticMap = ({ onLoadSuccess, onLoadError, onClickMap, target, className }: StaticMapProps) => {
  const { coordinates, name, address } = target

  const handleOnLoad = useCallback(() => {
    onLoadSuccess?.()
  }, [onLoadSuccess])

  const handleOnError = useCallback(() => {
    onLoadError?.()
  }, [onLoadError])

  const handleClick = useCallback(() => {
    onClickMap?.()
    openKarrotMap({ coordinates, name, address })
  }, [address, coordinates, name, onClickMap])

  return (
    <ErrorBoundary fallbackRender={() => <FallbackButton onClick={handleClick} />} onError={handleOnError}>
      <Suspense>
        <Map
          className={className}
          options={{
            center: {
              lat: coordinates.latitude,
              lng: coordinates.longitude,
            },
            centerOffset: { x: 0, y: 20 },
            interactive: false, // map cannot be interacted with (zoom, pan, etc.)
          }}
          onClick={handleClick}>
          <MapComponents target={target} onLoadSuccess={handleOnLoad} onLoadError={handleOnError} />
        </Map>
      </Suspense>
    </ErrorBoundary>
  )
}

const Map = styled(KarrotMap)({
  height: '7.5rem',
  width: '100%',
  border: `1px solid ${vars.$scale.color.grayAlpha50}`,
  borderRadius: '0.375rem',

  '& canvas': {
    borderRadius: '0.375rem',
  },
})

const FallbackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button onClick={onClick} styleTheme={'secondary-outlined'}>
      지도에서 위치보기
    </Button>
  )
}

const Button = styled(ButtonComponent)({
  width: '100%',
  marginBottom: '0.75rem',
})
