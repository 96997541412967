import { send } from '@stackflow/compat-await-push'
import { useRef } from 'react'

import { BottomSheet } from '@src/react-utils/components/BottomSheet'
import { Picker } from '@src/react-utils/components/Picker'
import { useActivity, useNavigator } from '@src/stackflow'

import type { VisitCount } from '../Form/types/restaurant-review-form'

export type VisitCountMap = Record<number, { value: VisitCount; text: string }>
export const visitCountMap: VisitCountMap = [
  { value: 'VISIT_1', text: '1번' },
  { value: 'VISIT_2_TO_4', text: '2~4번' },
  { value: 'VISIT_5_TO_9', text: '5번 이상' },
  { value: 'VISIT_10_TO_29', text: '10번 이상' },
  { value: 'VISIT_30_OR_MORE', text: '30번 이상' },
]

export const FrequencyPickerBottomSheet = () => {
  const { id: activityId } = useActivity()
  const { pop } = useNavigator()

  const values = Object.values(visitCountMap).map((x) => x.text)
  const initialIndex = 0

  const selectedRef = useRef(visitCountMap[initialIndex].value)

  const handleChange = (index: number) => {
    selectedRef.current = visitCountMap[index].value
  }

  const handleConfirm = () => {
    send({ activityId, data: { value: selectedRef.current } })
    pop()
  }

  return (
    <BottomSheet
      contents={<Picker initIndex={0} values={values} onChange={handleChange} />}
      CTAButton={{
        label: '확인',
        onClick: handleConfirm,
      }}
      options={{
        contentsOverflow: 'hidden',
      }}
    />
  )
}
