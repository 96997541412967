import { vars } from '@seed-design/design-token'
import { memo } from 'react'

export interface HeartIconProps {
  fillColor?: string
  strokeColor?: string
  size?: number
}
export const HeartIcon = memo(
  ({ fillColor = 'transparent', size = 24, strokeColor = vars.$scale.color.gray600 }: HeartIconProps) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: 'block' }}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.64745 4.92907C5.76444 2.6239 9.25891 2.6239 11.3759 4.92907L11.3766 4.92982L11.9867 5.59583L12.5975 4.92907C14.7144 2.6239 18.2089 2.6239 20.3259 4.92907L20.3273 4.93056C22.4529 7.2569 22.1011 10.7352 20.3826 13.0822C17.1706 17.4771 13.0104 20.2847 12.4785 20.6346C12.1811 20.8302 11.8004 20.83 11.5047 20.6421C10.961 20.2968 6.63349 17.4734 3.57913 13.0659C1.95766 10.7262 1.50892 7.25769 3.64745 4.92907Z"
          fill={fillColor}
          style={{ transition: 'fill 150ms' }}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.64721 4.92907C5.7642 2.6239 9.25867 2.6239 11.3757 4.92907L11.3763 4.92982L11.9864 5.59583L12.5972 4.92907C14.7142 2.6239 18.2087 2.6239 20.3257 4.92907L20.327 4.93056C22.4526 7.2569 22.1009 10.7352 20.3823 13.0822C17.1704 17.4771 13.0101 20.2847 12.4782 20.6346C12.1809 20.8302 11.8002 20.83 11.5045 20.6421C10.9608 20.2968 6.63324 17.4734 3.57889 13.0659C1.95742 10.7262 1.50868 7.25769 3.64721 4.92907ZM10.1968 6.01088C8.71376 4.39649 6.30853 4.39663 4.82566 6.01132C3.36419 7.6027 3.53544 10.1942 4.89397 12.1545C7.37646 15.7368 10.7867 18.2297 11.9791 19.0387C13.1444 18.2231 16.4634 15.7328 19.0905 12.1381C20.4914 10.2255 20.62 7.62414 19.1466 6.01063C17.6637 4.39663 15.2589 4.39672 13.7761 6.01088C13.7759 6.01103 13.7762 6.01073 13.7761 6.01088L12.5763 7.32057C12.4248 7.486 12.2108 7.5802 11.9864 7.5802C11.7621 7.5802 11.5481 7.486 11.3965 7.32057L10.1968 6.01088C10.1967 6.01073 10.1969 6.01102 10.1968 6.01088Z"
          fill={strokeColor}
          style={{ transition: 'fill 150ms' }}
        />
      </svg>
    )
  }
)
