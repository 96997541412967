import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { Button } from '@src/react-utils/components/Button'

import { myReviewCountByStatusSelector, nativeReviewFilterAtomFamily } from '../store'

export const ListNoReview = () => {
  const { pendingCount } = useRecoilValue(myReviewCountByStatusSelector)

  const handleResetFilter = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(nativeReviewFilterAtomFamily('list-menu-filter'))
        reset(nativeReviewFilterAtomFamily('list-residence-period-filter'))
        reset(nativeReviewFilterAtomFamily('list-visit-count-filter'))
        reset(nativeReviewFilterAtomFamily('list-region-level-two-filter'))
      },
    []
  )

  return (
    <Container hasCallout={pendingCount > 0}>
      <Content>
        <Text>
          필터에 해당하는 게시글이 없어요. <br /> 필터를 바꾸거나 초기화해 보세요.
        </Text>
        <S_Button styleTheme="secondary-low" onClick={handleResetFilter}>
          필터 초기화
        </S_Button>
      </Content>
    </Container>
  )
}

const Container = styled.div<{ hasCallout: boolean }>(
  {
    height: 'calc(100% - 57.5px)',
    padding: '0 0 40px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({ hasCallout }) => ({
    height: hasCallout ? 'calc(100% - 70px - 57.5px - 1.5rem)' : 'calc(100% - 57.5px)',
  })
)

const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 38,
})

const Text = styled.div([
  vars.$semantic.typography.subtitle1Regular,
  {
    textAlign: 'center',
    color: vars.$scale.color.gray600,
  },
])

const S_Button = styled(Button)({
  padding: '0.625rem 1.375rem',
})
