import styled from '@emotion/styled'
import { memo, ReactNode, useRef } from 'react'

import { useInfiniteScroll } from '@src/react-utils/hooks'
import type { IUseInfiniteScroll } from '@src/react-utils/hooks/useInfiniteScroll'

import { Spinner } from '../Spinner'

interface IInfiniteScrollObserver extends Omit<IUseInfiniteScroll, 'ref'> {
  children?: ReactNode
}
const InfiniteScrollObserver = ({ onIntersecting, options, children }: IInfiniteScrollObserver) => {
  const ref = useRef<HTMLDivElement>(null)

  useInfiniteScroll({ ref, onIntersecting, options })

  return <Observer ref={ref}>{children ?? <Spinner size="small" />}</Observer>
}

const Observer = styled.div({
  padding: '1rem 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export default memo(InfiniteScrollObserver)
