import { KarrotBridgeSchema } from '@daangn/karrotbridge/lib/__generated__/makeKarrotBridge'
import { atom } from 'recoil'

import { bridge } from '@src/apis/Bridge'
import { IS_LOCAL } from '@src/constants/environmentConstants'

import { generateKey } from './generate-key'

type CheckInRegionInfoType = KarrotBridgeSchema['ReqInfoRegionResponse']['info']['region']

type SAMPLE_REGION_NAME = '서초4동' | '전북 전주시 덕진구 고랑동'
const SAMPLE_REGIONS: Record<SAMPLE_REGION_NAME, CheckInRegionInfoType> = {
  서초4동: {
    id: 366,
    name: '서초4동',
    fullname: '서울 서초구 서초4동',
    name1: '서울측별시',
    name2: '서초구',
    name3: '서초4동',
    name1Id: 1,
    name2Id: 362,
    name3Id: 366,
    parentId: 362,
    centerCoordinates: {
      latitude: 37.500072122029415,
      longitude: 127.03854258877928,
    },
  },
  '전북 전주시 덕진구 고랑동': {
    id: 6865,
    name: '고랑동',
    fullname: '전북 전주시 덕진구 고랑동',
    name1: '전라북도',
    name2: '전주시 덕진구',
    name3: '고랑동',
    name1Id: 2488,
    name2Id: 2509,
    name3Id: 6865,
    parentId: 2509,
    // 잘못된 center-coordinates. 실제로는 전주시 덕진구의 중심좌표가 아님.
    centerCoordinates: {
      latitude: 37.500072122029415,
      longitude: 127.03854258877928,
    },
  },
}
export const checkInRegionInfoAtom = atom<CheckInRegionInfoType>({
  key: generateKey('checkin-region-info-atom'),
  default: (async () => {
    if (IS_LOCAL) {
      return SAMPLE_REGIONS['서초4동']
    }

    return (await bridge.getRegionInfo()).info.region
  })(),
})
