import { ActivityComponentType } from '@stackflow/react'
import isEmpty from 'lodash/isEmpty'
import { ComponentProps } from 'react'
import { useRecoilValue } from 'recoil'

import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'
import { PageLoading } from '@src/react-utils/components/Loading'

import { AskTargetAudienceBottomSheet } from './Modal/AskTargetAudienceBottomSheet'
import ClaimOwnershipDialog from './Modal/ClaimOwnershipDialog'
import ConfirmWriteStoryDialog from './Modal/ConfirmWriteStoryDialog'
import { PoiOptionsActionSheet } from './Modal/PoiOptionsActionSheet'
import { SingleReviewActionSheet } from './Modal/SingleReviewActionSheet'
import { SingleStoryActionSheet } from './Modal/SingleStoryActionSheet'
import { DialogType, BottomSheetType } from './types'

export type ModalActivityParams = {
  poiId: string
  modalId: DialogType | BottomSheetType
  askCommunityQuestionId?: ComponentProps<typeof AskTargetAudienceBottomSheet>['questionId']
}

const PoiDetailModal: ActivityComponentType<ModalActivityParams> = ({
  params: { poiId, modalId, askCommunityQuestionId },
}) => {
  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))

  if (!poi || isEmpty(poi)) return <PageLoading />

  if (poi.bizAccountId) return null

  return (
    <>
      {modalId === 'confirmWriteStoryDialog' && <ConfirmWriteStoryDialog />}
      {modalId === 'claimOwnershipDialog' && <ClaimOwnershipDialog poiName={poi.name ?? ''} />}
      {modalId === 'poiOptionsBottomSheet' && <PoiOptionsActionSheet />}
      {modalId === 'singleReviewOptionsBottomSheet' && <SingleReviewActionSheet />}
      {modalId === 'singleStoryOptionsBottomSheet' && <SingleStoryActionSheet />}
      {modalId === 'ask-target-audience-bottom-sheet' && (
        <AskTargetAudienceBottomSheet poiId={poiId} questionId={askCommunityQuestionId!} />
      )}
    </>
  )
}

export default PoiDetailModal
