type Options = {
  applyEllipsis?: boolean
}
export function truncateText(text: string, length: number, options?: Options) {
  const { applyEllipsis } = options ?? {}

  if (text.length <= length) {
    return text
  }

  return text.substring(0, length) + (applyEllipsis ? '\u2026' : '')
}
