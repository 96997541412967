import { useImageSliderState } from '@src/react-utils/hooks/useImageSliderState'

import ImageDetailSlider from './ImageDetailSlider'
import ImageSlider, { ImageSliderProps } from './ImageSlider'

type Props = Omit<ImageSliderProps, 'onClickDetail'> & {
  onClickSlider?: () => void
}

const ImageContainer = ({ images, height, sliderRef, onClickSlider }: Props) => {
  const { handleCloseDetailImage, handleSelectDetailImage, selectedImageIdx } = useImageSliderState()

  return (
    <>
      <ImageSlider
        images={images}
        height={height}
        onClickDetail={(index: number) => {
          handleSelectDetailImage(index)
          onClickSlider?.()
        }}
        sliderRef={sliderRef}
      />
      <ImageDetailSlider onClose={handleCloseDetailImage} initialIndex={selectedImageIdx} images={images} />
    </>
  )
}

export default ImageContainer
