export const getDateByDayLast = (when?: Date | string | null) => {
  const date = when ? new Date(when) : new Date()
  date.setHours(23, 59, 59)

  return date
}

export const getFormatNumber = (number: number) => {
  return `0${number}`.slice(-2)
}

export const getDateFormatWithPad = (date: Date) => {
  const year = `${date.getFullYear()}`
  const month = getFormatNumber(date.getMonth() + 1)
  const _date = getFormatNumber(date.getDate())
  const hour = getFormatNumber(date.getHours())
  const minute = getFormatNumber(date.getMinutes())

  return {
    year,
    month,
    date: _date,
    hour,
    minute,
  }
}
