import { searchCategoriesV2, SearchCategoriesV2200Response } from '@daangn/local-business-network/lib/poi'
import { atomFamily, selectorFamily } from 'recoil'

import { range } from '@src/js-utils/array'
import { generateUniqueKey } from '@src/js-utils/key'
import { userInfoAtom } from '@src/place-utils/user/store'

const generateSearchCategoryKey = generateUniqueKey('searchCategory')

type SearchCategoryKey = {
  searchText: string
  clientName: SearchCategoriesClientName
}

export const searchTextAtomFamily = atomFamily<string, SearchCategoriesClientName>({
  key: generateSearchCategoryKey('searchText'),
  default: '',
})

export const paginationAtomFamily = atomFamily<{ page: number; perPage: number }, SearchCategoryKey>({
  key: generateSearchCategoryKey(['categoriesBySearchText', 'pagination', 'atomFamily']),
  default: {
    page: 0,
    perPage: 10,
  },
})

const DEFAULT_RESPONSE = {
  items: [] as NonNullable<SearchCategoriesV2200Response['items']>,
  hasNextPage: false,
  total: 0,
}

export type SearchCategoriesClientName = 'BIZPROFILE' | 'POI'
export const categoriesBySearchTextQuery = selectorFamily<
  SearchCategoriesV2200Response,
  { clientName: SearchCategoriesClientName; searchText: string; page: number; perPage: number }
>({
  key: generateSearchCategoryKey(['categoriesBySearchText', 'response', 'selector']),
  get:
    ({ clientName, searchText, page, perPage }) =>
    async ({ get }) => {
      if (!searchText || !page) {
        return DEFAULT_RESPONSE
      }

      const userInfo = get(userInfoAtom)

      // REMINDER: why need current form type?
      const { data } = await searchCategoriesV2({
        params: {
          keyword: searchText,
          page,
          perPage,
          xAuthToken: userInfo?.authToken,
          ...(clientName === 'BIZPROFILE' ? { clientNames: ['BIZPROFILE'], leafOnly: true } : {}),
        },
      })

      return {
        items: data.items ?? [],
        hasNextPage: data.hasNextPage ?? false,
        total: data.total ?? 0,
      }
    },
})

export const categoriesBySearchTextPaginatedSelectorFamily = selectorFamily({
  key: generateSearchCategoryKey(['categoriesBySearchText', 'paginated', 'selector']),
  get:
    (clientName: SearchCategoriesClientName) =>
    async ({ get }) => {
      const searchText = get(searchTextAtomFamily(clientName))
      const { page, perPage } = get(paginationAtomFamily({ searchText, clientName }))

      const categoriesBySearchText = range(page)
        .map((number) => number + 1)
        .map((page) => get(categoriesBySearchTextQuery({ searchText, clientName, page, perPage })))
        .reduce((acc, cur) => {
          return {
            ...acc,
            ...cur,
            items: [...acc.items, ...cur.items],
          }
        }, DEFAULT_RESPONSE)

      return categoriesBySearchText
    },
})
