import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useEffect, useRef, useState } from 'react'

import { BigPictureInstance } from '@src/apis/types/bigPicture'

interface Props {
  bigPictureImage?: BigPictureInstance
  src?: string
  alt?: string
  width?: number
  height?: number
}

const ImageCenterCover = ({ bigPictureImage, src, alt, width, height }: Props) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  const imageContainerRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    if (!imageRef.current) return

    const adjustPhoto = (width: number, height: number) => {
      const image = imageRef.current
      if (!imageContainerRef.current) return

      const containerWidth = imageContainerRef.current.offsetWidth
      const containerHeight = imageContainerRef.current.offsetHeight
      const containerWidthToHeightRatio = imageContainerRef.current.offsetWidth / imageContainerRef.current.offsetHeight

      if (!image) return

      const imageWidthToHeightRatio = width / height

      const computedCoverDirection = imageWidthToHeightRatio <= containerWidthToHeightRatio ? 'width' : 'height'

      const computedOverflowPixel =
        computedCoverDirection === 'height'
          ? (width * containerHeight) / height - containerWidth
          : (height * containerWidth) / width - containerHeight
      const translatePixels = computedOverflowPixel / 2

      const computedTransform =
        computedCoverDirection === 'height'
          ? `translateX(${translatePixels * -1}px)`
          : `translateY(${translatePixels * -1}px)`
      image.style[computedCoverDirection] = '100%'
      image.style.transform = computedTransform

      setImageLoaded(true)
    }

    const image = imageRef.current

    const handleImageLoad = () => {
      if (!bigPictureImage && src) {
        adjustPhoto(image.width, image.height)
      } else if (bigPictureImage) {
        adjustPhoto(bigPictureImage.width, bigPictureImage.height)
      }
    }

    if (image.complete) {
      handleImageLoad()
    } else {
      image.addEventListener('load', handleImageLoad)

      return () => {
        image.removeEventListener('load', handleImageLoad)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledImageCenterCover ref={imageContainerRef} height={height} width={width} imageLoaded={imageLoaded}>
      <img ref={imageRef} src={src} alt={alt || '이미지'} />
      {!imageLoaded && (
        <Skeleton>
          <div className="skeleton" />
        </Skeleton>
      )}
    </StyledImageCenterCover>
  )
}

const StyledImageCenterCover = styled.div<{
  height?: number
  width?: number
  imageLoaded: boolean
}>`
  position: relative;
  display: block;
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  overflow: hidden;
`

const Skeleton = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${vars.$scale.color.gray00};

  @keyframes skeleton-gradient {
    0% {
      background-color: rgba(165, 165, 165, 0.1);
    }

    50% {
      background-color: rgba(165, 165, 165, 0.3);
    }

    100% {
      background-color: rgba(165, 165, 165, 0.1);
    }
  }

  .skeleton {
    background-color: ${vars.$scale.color.gray300};
    width: 100%;
    height: 100%;
    -webkit-animation: skeleton-gradient 1.8s infinite ease-in-out;
    animation: skeleton-gradient 1.8s infinite ease-in-out;
  }
`

export default ImageCenterCover
