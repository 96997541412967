import { useEffect } from 'react'
import { Loadable, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import { useScreenInstance } from '@src/react-utils/hooks/useScreenInstance'

import {
  bizReviewPostsReactionQuery,
  myReactionsMapperAtomFamily,
  Reaction,
  typeFilteredPostsReactionQuery,
} from '../state'
import { subprofileUserIdAtomFamily } from '../state/others'

const useSyncReactionsLoadable = (loadable: Loadable<Reaction[]>) => {
  const screenInstance = useScreenInstance()
  const userId = useRecoilValue(subprofileUserIdAtomFamily(screenInstance))

  const setMyReactionsMapper = useSetRecoilState(myReactionsMapperAtomFamily(userId))

  useEffect(() => {
    if (loadable.state !== 'hasValue') return
    const newReactions = loadable.contents.reduce(
      (mapper, reaction) => {
        return {
          ...mapper,
          [`${reaction.resourceType}${reaction.resourceId}`]: reaction,
        }
      },
      {} as { [key: string]: Reaction }
    )

    setMyReactionsMapper((prevState) => ({
      ...prevState,
      ...newReactions,
    }))
  }, [loadable, setMyReactionsMapper])
}

export const useSyncMyReactionMapper = () => {
  const screenInstance = useScreenInstance()
  const userId = useRecoilValue(subprofileUserIdAtomFamily(screenInstance))

  const myArticleReactionsLoadable = useRecoilValueLoadable(
    typeFilteredPostsReactionQuery({ postType: 'STORY_ARTICLE', userId })
  )
  const myCommentReactionsLoadable = useRecoilValueLoadable(
    typeFilteredPostsReactionQuery({ postType: 'STORY_COMMENT', userId })
  )
  const myReviewReactionsLoadable = useRecoilValueLoadable(
    typeFilteredPostsReactionQuery({ postType: 'POI_REVIEW', userId })
  )
  const myBizReviewReactionsLoadable = useRecoilValueLoadable(bizReviewPostsReactionQuery(userId))

  useSyncReactionsLoadable(myArticleReactionsLoadable)
  useSyncReactionsLoadable(myCommentReactionsLoadable)
  useSyncReactionsLoadable(myReviewReactionsLoadable)
  useSyncReactionsLoadable(myBizReviewReactionsLoadable)
}
