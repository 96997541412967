export const uniqByArrayObject = <T extends { [key: string]: any }, K extends keyof T>(array: T[], key: K) => {
  const data: T[] = []
  const flags = []

  const l = array.length

  for (let i = 0; i < l; i++) {
    if (flags[array[i][key]]) continue
    flags[array[i][key]] = true
    data.push(array[i])
  }

  return data
}
