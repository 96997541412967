import { Poi } from '@daangn/local-business-network/lib/poi'

import { WeekOfDay } from '@src/apis/types/poi'
import {
  WEEK_ID_TEXT_MAPPER,
  WEEK_ID_VALUE_MAPPER,
} from '@src/react-utils/components/Input/BusinessHours/BusinessWeekdaysInput'

import { convertTimeToText } from './convertTimeToText'

export const displayBusinessOpenSchedule = (openingHours: Poi['openingHours']) => {
  const openingHoursObject = openingHours
    .slice()
    .sort((openingHourA, openingHourB) => {
      return WEEK_ID_VALUE_MAPPER.indexOf(openingHourA.weekOfDay) - WEEK_ID_VALUE_MAPPER.indexOf(openingHourB.weekOfDay)
    })
    .reduce(
      (result, openingHour) => {
        const { openingTime, closingTime, weekOfDay } = openingHour
        const trimTime = (time: string) => time.split(':').slice(0, 2).join(':')
        if (!openingTime && !closingTime) {
          return {
            ...result,
            noTime: (result?.noTime || []).concat([weekOfDay]),
          }
        } else {
          const hours = `${openingTime ? convertTimeToText(trimTime(openingTime)) : ''}~ ${
            closingTime ? convertTimeToText(trimTime(closingTime)) : ''
          }`
          return {
            ...result,
            [hours]: (result?.[hours] || []).concat([weekOfDay]),
          }
        }
      },
      {} as { [time: string]: WeekOfDay[] }
    )

  const openingHoursOrderArray = Object.keys(openingHoursObject).sort((hourKeyA: string, hourKeyB: string) => {
    return (
      WEEK_ID_VALUE_MAPPER.indexOf(openingHoursObject[hourKeyA][0]) -
      WEEK_ID_VALUE_MAPPER.indexOf(openingHoursObject[hourKeyB][0])
    )
  })

  return openingHoursOrderArray.reduce((openingHoursText, hour) => {
    const weekIdArray = openingHoursObject[hour].map((weekValue) => WEEK_ID_VALUE_MAPPER.indexOf(weekValue))
    if (hour === 'noTime') {
      return (
        openingHoursText +
        weekIdArray
          .map((id) => WEEK_ID_TEXT_MAPPER[id])
          .reduce((result, text, index, array) => `${result}${text || ''}${index === array.length - 1 ? '' : ', '}`, '')
      )
    } else {
      return (
        weekIdArray
          .map((id) => WEEK_ID_TEXT_MAPPER[id])
          .reduce(
            (result, text, index, array) => `${result}${text || ''}${index === array.length - 1 ? ' ' : ', '}`,
            ''
          ) +
        hour +
        openingHoursText
      )
    }
  }, '')
}
