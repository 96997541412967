import { getQueryParam } from '@src/js-utils/url'
import { useActivity } from '@src/stackflow'

import { businessReviewRoutingManager } from '../businessReviewRoutingManager'

const PLACE_SERVICE_NAME = 'place'

export const useBusinessReviewRoutingManager = () => {
  const referrer = getQueryParam('referrer') ?? ''
  const activityName = useActivity().name
  const screenName = [PLACE_SERVICE_NAME, activityName].join('.')

  const openCreateReviewPage = ({ poiId, starRating }: { poiId: string; starRating?: number }) =>
    businessReviewRoutingManager.openCreateReviewPage({
      type: 'POI',
      poi_id: poiId,
      star_rating: starRating,
      referrer: referrer,
      screen_name: screenName,
    })

  const openUpdateReviewPage = ({ reviewId }: { reviewId: string }) =>
    businessReviewRoutingManager.openUpdateReviewPage({
      review_id: reviewId,
      referrer: referrer,
      screen_name: screenName,
    })

  const openUserProfilePage = ({ userId }: { userId: string }) =>
    businessReviewRoutingManager.openUserProfilePage({
      user_id: userId,
      referrer: referrer,
      screen_name: screenName,
    })

  return {
    openCreateReviewPage,
    openUpdateReviewPage,
    openUserProfilePage,
  }
}
