import { css } from '@emotion/react'
import styled from '@emotion/styled'
import IconRemoveCircleFill from '@karrotmarket/karrot-ui-icon/lib/react/IconRemoveCircleFill'
import { vars } from '@seed-design/design-token'
import { memo, MouseEvent, useCallback, useEffect } from 'react'

import { useBoolean } from '@src/react-utils/hooks/useBoolean'

import { Spinner } from '../../Spinner'

interface Props {
  url: string
  loading: boolean
  id: string
  onClickDelete: (id: string) => void
  onClick?: () => void
  size?: 'lg' | 'md'
}

export const ImageUploadBox = memo(
  ({ id, loading, url, onClickDelete, size = 'lg', onClick }: Props) => {
    const [imageLoaded, onLoaded, clearLoaded] = useBoolean()
    const [hide, setHide] = useBoolean()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => clearLoaded(), [url])

    const onContentsHide = useCallback(() => hide && onClickDelete(id), [hide, id, onClickDelete])
    const handleDeleteButton = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        e.preventDefault()
        setHide()
      },
      [setHide]
    )
    return (
      <Base onClick={onClick} hide={hide} onTransitionEnd={onContentsHide}>
        <UploadImageRadiusWrapper size={size}>
          <UploadImage src={url} onLoad={onLoaded} onError={() => onClickDelete(id)} />
          {(loading || !imageLoaded) && (
            <LoadingWrapper>
              <Spinner variant="white" size="small" />
            </LoadingWrapper>
          )}
        </UploadImageRadiusWrapper>
        <DeleteButton onClick={handleDeleteButton}>
          <IconBackground>
            <div className="background" />
            <IconRemoveCircleFill fill={vars.$scale.color.gray700} height={24} width={24} />
          </IconBackground>
        </DeleteButton>
      </Base>
    )
  },
  (prevProps, nextProps) => prevProps.loading === nextProps.loading && prevProps.id === nextProps.id
)

const Base = styled.div<{ hide: boolean }>`
  position: relative;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 200ms;

  @keyframes fadeOn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: fadeOn 200ms;
`
const UploadImageRadiusWrapper = styled.div<Required<Pick<Props, 'size'>>>`
  position: relative;
  ${({ size }) =>
    size === 'lg'
      ? css`
          height: 64px;
          width: 64px;
          border-radius: 5px;
        `
      : css`
          height: 48px;
          width: 48px;
          border-radius: 4px;
        `}
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    ${({ size }) =>
      size === 'lg'
        ? css`
            height: 62px;
            width: 62px;
            border-radius: 5px;
          `
        : css`
            height: 46px;
            width: 46px;
            border-radius: 4px;
          `}
    border: solid 1px ${vars.$scale.color.grayAlpha50};
  }
`

const UploadImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /** @todo: seed-design */
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
`
const DeleteButton = styled.button`
  position: absolute;
  right: -8px;
  top: -8px;
  width: 24px;
  height: 24px;
`

const IconBackground = styled.div`
  .background {
    position: absolute;
    width: 18px;
    height: 18px;
    background: ${vars.$scale.color.gray00};
    border-radius: 9px;
    transform: translate(3px, 3px);
  }
  svg {
    z-index: 1;
    transform: translate(0, 0);
  }
`
