import { useRecoilCallback } from 'recoil'

import { WatchPlaceIdDto, placeIdsMonitoringWatchStatusAtomFamily } from '../store'

// MUST USE THIS HOOK TO REGISTER POI AND BA IDS TO SYNC WITH SERVER
export const useAddMonitoringWatchPlaceIds = () => {
  return useRecoilCallback(
    ({ set }) =>
      (placeWatchIds: WatchPlaceIdDto[]) => {
        const poiIds = placeWatchIds
          .filter((placeWatchId) => placeWatchId.targetType === 'POI')
          .map((placeWatchId) => placeWatchId.targetId)
        const baIds = placeWatchIds
          .filter((placeWatchId) => placeWatchId.targetType === 'BA')
          .map((placeWatchId) => placeWatchId.targetId)

        set(placeIdsMonitoringWatchStatusAtomFamily('POI'), (prev) => [...new Set([...prev, ...poiIds])])
        set(placeIdsMonitoringWatchStatusAtomFamily('BA'), (prev) => [...new Set([...prev, ...baIds])])
      },
    []
  )
}
