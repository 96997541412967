import { getDateDiffFromNow } from '@daangn/local-business-kit'
import { Poi } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { memo, useMemo } from 'react'

import { KakaoCustomOverlay, KakaoMarker, useKakaoMarkerImage } from '@src/react-utils/components/Map/KakaoMap'

import { WinterSnackParams } from '../WinterSnackPage'

interface Props {
  poi: Poi
  onClick: (poi: Poi) => void
}
const Marker = ({ poi, onClick }: Props) => {
  const { selectedPoiId, mode } = useActivityParams<WinterSnackParams>()

  const { markerImage: openedMarkerImage } = useKakaoMarkerImage({ color: 'carrot' })
  const { markerImage: closedMarkerImage } = useKakaoMarkerImage({ color: 'gray' })

  const coordinates = poi.coordinates
  interface Marker {
    image: any
    zIndex: number
  }

  const isSelected = selectedPoiId === poi.id
  const isOpen = poi.operatingStatus?.status === 'open'
  const contributedAt = poi.operatingStatus?.contributedAt ?? ''

  const marker = useMemo<Marker>(() => {
    const markerImage = isOpen ? openedMarkerImage : closedMarkerImage

    return isSelected && mode !== 'edit'
      ? { image: markerImage.selected, zIndex: 3 }
      : { image: markerImage.default, zIndex: isOpen ? 2 : 1 }
  }, [isOpen, openedMarkerImage, closedMarkerImage, isSelected, mode])

  if (!poi || !coordinates) return null

  return (
    <>
      <KakaoMarker
        position={{
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        }}
        image={marker.image}
        onClick={onClick.bind(null, poi)}
        zIndex={marker.zIndex}
      />
      {isSelected && mode !== 'edit' && isOpen && (
        <KakaoCustomOverlay
          position={{
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
          }}
          zIndex={marker.zIndex}>
          <LatestVisit>{getDateDiffFromNow(new Date(contributedAt)).text} 전 방문</LatestVisit>
        </KakaoCustomOverlay>
      )}
    </>
  )
}

const LatestVisit = styled.div`
  transform: translate3d(0px, -76px, 0px);

  padding: 0.375rem 0.625rem;
  background-color: ${vars.$semantic.color.primary};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2.5rem;
  color: ${vars.$semantic.color.onPrimary};
  ${vars.$semantic.typography.label5Bold};
`

export default memo(Marker)
