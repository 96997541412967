import { selectorFamily } from 'recoil'

import { getWatchesV2 } from '@src/apis/__generated__'
import { userInfoAtom } from '@src/place-utils/user/store'

import { generateWatchRecoilKey } from './recoilKey'

export const watchPOIInfosByIdSelectorFamily = selectorFamily({
  key: generateWatchRecoilKey('watchPOIInfosById'),
  get:
    (ids: string[]) =>
    async ({ get }) => {
      const userInfo = get(userInfoAtom)

      if (!userInfo) return null

      if (!ids.length) return []
      const response = await getWatchesV2({
        params: {
          userId: userInfo.id,
          xAuthToken: userInfo.authToken,
          poiIds: ids,
        },
        options: {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      })

      return response?.data.data
    },
})

export const watchPOIInfosByIdMapperSelectorFamily = selectorFamily({
  key: generateWatchRecoilKey('watchPOIInfosByIdMapper'),
  get:
    (ids: string[]) =>
    async ({ get }) => {
      const watchList = get(watchPOIInfosByIdSelectorFamily(ids))

      if (!ids.length) return {} as Record<string, boolean>

      const poiWatchMapper: Record<string, boolean> = watchList
        ? watchList.reduce((acc, { poi }) => {
            return {
              ...acc,
              [poi.id]: true,
            }
          }, {})
        : {}

      return poiWatchMapper
    },
})
