import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { ComponentProps, useCallback, useRef, useState } from 'react'

import { Review as ReviewComponent } from '@src/place-utils/review'
import useSpotlightListItem from '@src/react-utils/hooks/useSpotlightListItem'
import { useStepNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'
import { ActivityParams } from '../PoiDetailPage'
import UpvoteButton from '../UpvoteButton'

interface Props extends ComponentProps<typeof ReviewComponent> {}
export const ReviewCard = ({ data, ...props }: Props) => {
  const logEvent = useAnalytics()
  const cardItemRef = useRef<HTMLDivElement>(null)

  const { spotlightPostId, ...params } = useActivityParams<ActivityParams>()
  const { stepReplace } = useStepNavigator('poi_detail')
  const [isHighlighted, setIsHighlighted] = useState(false)

  const handleBeforeSpotlight = useCallback(() => {
    logEvent('show_highlight_review', {
      review_id: data.id,
    })

    stepReplace({
      ...params,
      spotlightPostId: undefined,
    })

    setIsHighlighted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAfterSpotlight = useCallback(() => {
    setIsHighlighted(false)
  }, [])

  useSpotlightListItem({
    itemElement: cardItemRef,
    shouldSpotlight: data.id + '' === spotlightPostId,
    onBeforeSpotlight: handleBeforeSpotlight,
    onAfterSpotlight: handleAfterSpotlight,
  })

  return (
    <Review
      area="review_tab"
      ref={cardItemRef}
      isHighlighted={isHighlighted}
      data={data}
      onClickUserProfileMore={props.onClickUserProfileMore}
      thumbsUp={{
        render: () => <UpvoteButton resourceType={'POI_REVIEW'} resourceId={String(data.id)} type={'UPVOTE'} />,
      }}
    />
  )
}

const Review = styled(ReviewComponent)<{ isHighlighted: boolean }>([
  ({ isHighlighted }) =>
    isHighlighted && {
      background: vars.$semantic.color.paperAccent,
    },
  {
    transition: '500ms background',
    position: 'relative',

    '& + &': {
      ':after': {
        content: '""',
        display: 'block',
        background: vars.$semantic.color.divider1,
        position: 'absolute',
        top: 0,
        left: '1rem',
        width: 'calc(100% - 2rem)',
        height: '1px',
      },
    },
  },
])
