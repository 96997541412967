import { PostV2Dto, ReactionDto } from '@daangn/local-business-network/lib/poi'
import { atomFamily, DefaultValue, selectorFamily } from 'recoil'

import { bizReviewLegacyApi } from '@src/apis'
import { getReactionsV2 } from '@src/apis/__generated__'
import { generateUniqueKey } from '@src/js-utils/key'
import { userInfoAtom } from '@src/place-utils/user/store'

import { userPostsQuerySelectorFamily } from './userPost'

export interface Reaction extends Omit<ReactionDto, 'resourceType'> {
  resourceType: ReactionDto['resourceType'] | 'BIZ_REVIEW'
}
const generateKey = generateUniqueKey(['subprofile', 'reaction'])

const typeFilteredPostsSelectorFamily = selectorFamily({
  key: generateKey('typeFilteredPostsSelectorFamily'),
  get:
    ({ postType, userId }: { postType: Exclude<PostV2Dto['type'], 'BIZ_REVIEW'>; userId: string }) =>
    ({ get }) => {
      const response = get(userPostsQuerySelectorFamily(userId))

      if (!response?.data) return []

      return response.data.filter(({ post }) => post.type === postType)
    },
})

export const typeFilteredPostsReactionQuery = selectorFamily({
  key: generateKey('typeFilteredPostsReactionQuery'),
  get:
    ({ postType, userId }: { postType: Exclude<PostV2Dto['type'], 'BIZ_REVIEW'>; userId: string }) =>
    async ({ get }) => {
      const posts = get(typeFilteredPostsSelectorFamily({ postType, userId }))
      const userInfo = get(userInfoAtom)

      if (!userInfo?.authToken || posts.length <= 0) return []

      const resourceIds = posts.map(({ post }) => post.sourceId)

      const { data } = await getReactionsV2({
        params: {
          xAuthToken: userInfo.authToken,
          resourceType: postType,
          resourceIds,
        },
      })

      return data.data.items
    },
})

const bizReviewPostsSelectorFamily = selectorFamily({
  key: generateKey('bizReviewPostsSelector'),
  get:
    (userId: string) =>
    ({ get }) => {
      const response = get(userPostsQuerySelectorFamily(userId))

      if (!response?.data) return []

      return response.data.filter(({ post }) => post.type === 'BIZ_REVIEW')
    },
})

export const bizReviewPostsReactionQuery = selectorFamily({
  key: generateKey('bizReviewPostsReactionQuery'),
  get:
    (userId: string) =>
    async ({ get }) => {
      const posts = get(bizReviewPostsSelectorFamily(userId))

      if (posts.length <= 0) return []

      const resourceIds = posts.map(({ post }) => post.sourceId)

      const resp = await bizReviewLegacyApi.getLikedByMeByReviewIds(resourceIds)

      return (
        resp.data.data.data
          .filter((res) => {
            return res.liked_by_me === true
          })
          .map(({ review_id }: any) => {
            return {
              resourceType: 'BIZ_REVIEW',
              resourceId: review_id,
              userId: parseInt(userId),
              type: 'UPVOTE',
              reactedAt: '',
            } as Reaction
          }) || []
      )
    },
})

export const myReactionsMapperAtomFamily = atomFamily({
  key: generateKey('myReactions'),
  default: {} as { [key: string]: Reaction },
})

export interface ReactionState extends Omit<Reaction, 'userId' | 'reactedAt'> {
  reactionCount: number
  isReactedByMe: boolean
}

export const reactionStore = atomFamily<ReactionState[], string>({
  key: generateKey('reactionStore'),
  default: [],
})

export const reactionItemSelector = selectorFamily<
  ReactionState | null,
  { resourceType: Reaction['resourceType']; resourceId: Reaction['resourceId']; type: Reaction['type']; userId: string }
>({
  key: generateKey('reactionItemSelector'),
  get:
    ({ resourceType, resourceId, type, userId }) =>
    ({ get }) => {
      const item =
        get(reactionStore(userId)).find(
          (item) => item.resourceId === resourceId && item.resourceType === resourceType && item.type === type
        ) || null
      return item
    },
  set:
    ({ resourceType, resourceId, type, userId }) =>
    ({ set }, newValue) => {
      if (!(newValue instanceof DefaultValue) && newValue) {
        set(reactionStore(userId), (prevState) =>
          prevState.map((item) =>
            item.resourceId === resourceId && item.resourceType === resourceType && item.type === type
              ? { ...item, isReactedByMe: newValue.isReactedByMe, reactionCount: newValue.reactionCount }
              : item
          )
        )
      }
    },
})
