import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

export const Divider = styled.hr`
  height: 1px;
  inline-size: 100%;
  margin: 0 auto;
  border: none;
  background-color: ${vars.$semantic.color.divider1};
`
