import { SimpleBizPostCard } from '@daangn/local-business-kit'
import styled from '@emotion/styled'
import { withErrorBoundary } from 'react-error-boundary'
import { useRecoilValue } from 'recoil'

import { karrotBridge } from '@src/bridge'
import { BIZ_PROFILE_APP_BASE_URL } from '@src/constants/url'
import { bizPostSelectorFamily } from '@src/place-utils/bizPost/store'
import { brandProfileSelectorFamily } from '@src/place-utils/bizProfile/store'
import { SectionHeader as SectionHeaderComponent } from '@src/react-utils/components'
import { Section } from '@src/react-utils/components/Layout'
interface Props {
  brandAccountId: string
}
export const BizPostSection = withErrorBoundary(
  ({ brandAccountId }: Props) => {
    const brandProfile = useRecoilValue(brandProfileSelectorFamily({ brandAccountId }))
    const postId = brandProfile?.brandPostId

    if (!postId) return null

    const post = useRecoilValue(bizPostSelectorFamily({ postId }))

    if (!post?.data) return null

    const { title, counter, coupon, first_image, post_id, actor } = post.data
    const isBrandPost = actor.business_account?.type === 'BRAND'

    const handleClickPost = () => {
      karrotBridge.pushRouter({
        router: {
          remote: `${BIZ_PROFILE_APP_BASE_URL}/posts/detail/${post_id}?referrer=place.poi.detail`,
          navbar: false,
          scrollable: false,
        },
      })
    }

    return (
      <Section>
        <SectionHeader text="소식" type="basic" />
        <SimpleBizPostCard
          postTitle={title ?? ''}
          postImageURL={first_image?.thumbnail_url ?? ''}
          counter={{
            bookmark: counter.bookmark_count,
            comment: counter.comment_count,
          }}
          isBrandProfileType={isBrandPost}
          hasCoupon={!!coupon}
          onClickPost={handleClickPost}
        />
      </Section>
    )
  },
  {
    fallbackRender: () => null,
  }
)

const SectionHeader = styled(SectionHeaderComponent)({
  marginBottom: '1rem',
})
