import { FunctionComponent, Dispatch, ReactNode, useMemo, useState, Context, ComponentType } from 'react'

import { useMap } from '../KakaoMap/useMap'

type MapPropertyOnWindow = 'kakao' | 'maplibregl'

export const createMapProvider =
  <T extends object>(createMapContext: (map: any, setMap: Dispatch<any>) => T, MapContext: Context<T>) =>
  ({ children }: { children: ReactNode }) => {
    // pass map from outer context if available
    const outerContextMap = useMap()

    const [map, setMap] = useState<any>(outerContextMap)

    const value = useMemo(() => createMapContext(map, setMap), [map])

    return <MapContext.Provider value={value}>{children}</MapContext.Provider>
  }

export function createWithMapErrorFallback(requiredWindowPropertyName: MapPropertyOnWindow) {
  return <P extends object>(Component: FunctionComponent<P>, FallbackComponent?: FunctionComponent<P>) => {
    const WithMapErrorFallback = (props: P) => {
      if (!!FallbackComponent && (!window[requiredWindowPropertyName] as any)) return <FallbackComponent {...props} />

      return <Component {...props} />
    }

    return WithMapErrorFallback
  }
}

export function createWithMap(
  MapProvider: ReturnType<typeof createMapProvider>,
  withMapErrorFallback: ReturnType<typeof createWithMapErrorFallback>
) {
  return <P extends object>(Component: ComponentType<P>, FallbackComponent?: ComponentType<P>) => {
    const WithMap: ComponentType<P> = (props: P) => {
      return (
        <MapProvider>
          <Component {...props} />
        </MapProvider>
      )
    }

    return withMapErrorFallback(
      WithMap,
      FallbackComponent
        ? (props: P) => (
            <MapProvider>
              <FallbackComponent {...props} />
            </MapProvider>
          )
        : undefined
    )
  }
}
