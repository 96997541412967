import { NativeReviewDtoVisitCount } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { ComponentProps } from 'react'

import { visitCountToText } from '@src/pages/NativeReview/utils'

import { CrownBadge } from './BadgeCrown'

type CrownBadgeType = {
  type: 'crown'
} & ComponentProps<typeof CrownBadge>

type Badge = CrownBadgeType

type Props =
  | ({
      type: 'years-of-residence'
      yearsOfResidence: number
      regionName: string | any // any type should be removed after fixing the type of regionName
    } & {
      badge?: Badge
    })
  | ({
      type: 'visit-count'
      visitCount: NativeReviewDtoVisitCount
    } & {
      badge?: Badge
    })

export const Tag = (props: Props) => {
  const { type, badge } = props

  const label = type === 'years-of-residence' ? '거주 기간' : '가게 방문'
  const value =
    type === 'years-of-residence'
      ? `${props.regionName} ${props.yearsOfResidence}년`
      : `${visitCountToText(props.visitCount)}`

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Value>{value}</Value>
      {badge?.type === 'crown' && <CrownBadge label={badge.label} />}
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.25rem',
})
const Label = styled.span([
  vars.$semantic.typography.caption1Regular,
  {
    color: vars.$scale.color.gray600,
  },
])
const Value = styled.span([
  vars.$semantic.typography.caption1Bold,
  {
    color: vars.$scale.color.gray700,
  },
])
