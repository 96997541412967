import { ChipRadioGroup, ChipRadio } from '@daangn/sprout-components-chips'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useRecoilValue, useRecoilCallback } from 'recoil'

import {
  EXPERIMENT_enhancedReactionSegments,
  EXPERIMENT_enhancedReactionSelector,
  DO_NOT_USE_enhancedReactionSegmentForceAtom,
} from './enhancedReaction'
import type { EXPERIMENT_enhancedReactionSegmentNameType } from './enhancedReaction'

const EnhancedReaction = () => {
  const segments = EXPERIMENT_enhancedReactionSegments
  const segment = useRecoilValue(EXPERIMENT_enhancedReactionSelector)

  const handleChange = useRecoilCallback(
    ({ set }) =>
      (value: string) => {
        set(DO_NOT_USE_enhancedReactionSegmentForceAtom, value as EXPERIMENT_enhancedReactionSegmentNameType)
      },
    []
  )

  return (
    <Section>
      <SectionTitle>‘여기 맛집이에요’ 리액션 고도화</SectionTitle>
      <ChipRadioGroup onChange={handleChange} defaultValue={segment}>
        {segments.map(({ name, label }) => (
          <ChipRadio value={name} key={name}>
            {label}
          </ChipRadio>
        ))}
      </ChipRadioGroup>
    </Section>
  )
}

const Section = styled.section({
  padding: '1rem 0',
})
const SectionTitle = styled.h2([
  vars.$semantic.typography.subtitle1Bold,
  {
    margin: '0 0 0.5rem 0',
  },
])

export const EnhancedReactionDevTool = EnhancedReaction
