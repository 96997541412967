import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { memo } from 'react'

import { IS_PROD } from '@src/constants/environmentConstants'
import { APP_SCHEME, MY_WATCHES_SCHEME } from '@src/constants/url'
import { josa } from '@src/js-utils/language-korean'
import { useResetPlaceWatch } from '@src/place-utils/watch/hooks'
import { useLogEvent } from '@src/react-utils/components/Analytics'
import { EllipsisText } from '@src/react-utils/components/Map/EllipsisText'
import { useToast } from '@src/react-utils/components/Toast'
import { Link, useNavigator } from '@src/stackflow'
import { safeAreaPadding } from '@src/styles/safeArea'

import { PoiBanner } from './PoiBanner'
import { UserProfile } from './UserProfile'

interface Props {
  id: number
  author: {
    id: number
    nickname: string
    profileImage?: string
  }
  regionName: string
  createdAt: string
  updatedAt?: string
  content?: string
  poi: {
    id: string
    bizAccountId: string | null
    name: string
    thumbnailImage?: string
    category: string
    coordinates: {
      latitude: number
      longitude: number
    }
  }
  isRecommended?: boolean
}
export const Card = memo(({ id, author, regionName, content, poi, createdAt, updatedAt, isRecommended }: Props) => {
  const logEvent = useLogEvent()
  const { showToast } = useToast()
  const { pushScheme, asyncPushScheme } = useNavigator()
  const resetWatch = useResetPlaceWatch()

  const handleClickUserProfile = () => {
    logEvent({ name: 'click_card_user_profile', params: { poi_id: poi.id, comment_id: id.toString() } })

    pushScheme(
      `${APP_SCHEME}community-web/router?remote=https%3A%2F%2Fcommunity-web-profile.${
        IS_PROD ? '' : 'alpha.'
      }karrotwebview.com%2Fprofile%2F${
        author.id
      }%2F%3Fprevious_screen_name%3Dlocal%26area_name%3D%25EC%25B6%2594%25EC%25B2%259C%25EC%259E%25A5%25EC%2586%258C%25EB%25AA%25A8%25EC%2595%2584%25EB%25B3%25B4%25EA%25B8%25B0&navbar=false&scrollable=false`
    )
  }

  const handleClickContent = () => {
    logEvent({ name: 'click_card_contents', params: { poi_id: poi.id, comment_id: id.toString() } })
  }

  const handleAddWatch = () => {
    logEvent({
      name: 'click_watch_button',
      params: {
        watch_type: poi?.bizAccountId ? 'biz_account' : 'poi',
        watch_type_id: poi?.bizAccountId ?? poi?.id,
        comment_id: id.toString(),
        is_already_watched: false,
      },
    })

    if (poi) {
      showToast({
        text: `${josa(poi.name, '을/를')} 관심 가게에 추가했어요.`,
        duration: 'short',
        button: {
          text: '관심 가게 보기',
          onClickButton: async () => {
            await asyncPushScheme(MY_WATCHES_SCHEME)

            resetWatch()
          },
        },
      })
    }
  }

  const handleRemoveWatch = () => {
    logEvent({
      name: 'click_watch_button',
      params: {
        watch_type: poi?.bizAccountId ? 'biz_account' : 'poi',
        watch_type_id: poi?.bizAccountId ?? poi?.id,
        comment_id: id.toString(),
        is_already_watched: true,
      },
    })
  }

  return (
    <Container>
      <ProfileClickArea onClick={handleClickUserProfile}>
        <UserProfile
          nickname={author.nickname}
          profileImage={author.profileImage}
          regionName={regionName}
          createdAt={createdAt}
          updatedAt={updatedAt}
          isRecommended={isRecommended}
        />
        {content && <Contents line={1}>{content}</Contents>}
      </ProfileClickArea>
      <Link
        activityName="poi_detail"
        activityParams={{
          poiId: poi.id,
        }}
        onClick={handleClickContent}>
        <ContentClickArea>
          <Banner
            id={poi.id}
            bizAccountId={poi.bizAccountId}
            name={poi.name}
            thumbnail={poi.thumbnailImage}
            category={poi.category}
            coordinates={poi.coordinates}
            watch={{
              onAdd: handleAddWatch,
              onRemove: handleRemoveWatch,
            }}
          />
        </ContentClickArea>
      </Link>
    </Container>
  )
})

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${vars.$semantic.color.paperSheet};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
`
const ProfileClickArea = styled.div`
  padding: 1.25rem 1rem 0;
`
const ContentClickArea = styled.div`
  padding: 1rem 1rem 1.25rem;
  ${safeAreaPadding('bottom', '1.25rem')};
`
const Contents = styled(EllipsisText)`
  margin: 0.375rem 0 0;

  color: ${vars.$scale.color.gray700};
  ${vars.$semantic.typography.bodyM1Regular};
`
const Banner = styled(PoiBanner)``
