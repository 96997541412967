import { setFetchConfig } from '@daangn/local-business-network/lib/localProfile'
import type { AxiosInterceptorManager } from 'axios'

import { APP_ENV } from '@src/constants/environmentConstants'
import { LOCAL_PROFILE_BASE_URL } from '@src/constants/url'

import { authTokenInjector, requestIdInjector, userAgentInjector } from './utils/interceptors'

const localProfileAxiosInstance = setFetchConfig(APP_ENV as 'production' | 'alpha')

const domainSpecificInjector: Parameters<AxiosInterceptorManager<any>['use']>[0] = (config) => {
  if (!config.headers) {
    config.headers = {}
  }
  config.baseURL = LOCAL_PROFILE_BASE_URL

  return config
}

localProfileAxiosInstance.interceptors.request.use(requestIdInjector)
localProfileAxiosInstance.interceptors.request.use(authTokenInjector)
localProfileAxiosInstance.interceptors.request.use(userAgentInjector)
localProfileAxiosInstance.interceptors.request.use(domainSpecificInjector)
