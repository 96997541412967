import { v4 } from 'uuid'

import { generateUniqueKey } from '@src/js-utils/key'
import { AnalyticsSessionIds } from '@src/react-utils/components/Analytics'

export const generateSearchPoiAnalyticsKey = generateUniqueKey(['searchPoi', 'analytics'])

const initialQuerySessionId = v4()
const initialVisitSessionId = v4()

export const analyticsSessionIds = new AnalyticsSessionIds({
  initialQuerySessionId,
  initialVisitSessionId,
})
