import { useRecoilValue } from 'recoil'

import { useScreenInstance } from '@src/react-utils/hooks/useScreenInstance'

import { subprofileQueryByUserId, subprofileUserIdAtomFamily } from '../state/others'

export const useSubprofile = () => {
  const screenInstance = useScreenInstance()
  const userId = useRecoilValue(subprofileUserIdAtomFamily(screenInstance))
  const subprofile = useRecoilValue(subprofileQueryByUserId(userId))

  return subprofile
}
