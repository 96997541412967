import styled from '@emotion/styled'
import IconSearchRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconSearchRegular'
import { vars } from '@seed-design/design-token'
import * as Sentry from '@sentry/react'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useRef, useState } from 'react'

import { searchAddressesByQuery, SearchAddressListItem } from '@src/js-utils/searchAddress'
import { TextInput } from '@src/react-utils/components/Input'
import { useToast } from '@src/react-utils/components/Toast'
import { Screen } from '@src/stackflow'

import AddressListItem from './AddressListItem'

interface Props {
  onClickAddress: (address: SearchAddressListItem) => void
}

const SearchAddressView = ({ onClickAddress }: Props) => {
  const { showToast } = useToast()

  const [searchText, setSearchText] = useState('')
  const [addressList, setAddressList] = useState([] as SearchAddressListItem[])

  const addressSearchInputEl = useRef<HTMLInputElement>(null)

  const handleSearchAddress = useCallback(
    async (value: string, page: number, perPage: number) => {
      try {
        const res = await searchAddressesByQuery(value, page, perPage)

        if (res) {
          setAddressList(res)
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err.message) {
            return showToast({
              text: err.message.includes('정상아님')
                ? '주소 검색에 오류가 발생했어요. 잠시후 다시 시도해주세요.'
                : '알수 없는 오류가 발생했어요. 계속 실패할 경우 고객센터로 문의해주세요.',
              duration: 'long',
            })
          }
        }

        Sentry.captureException(new Error(`Juso API and Kakao Error: 주소검색을 실패하였습니다.`))

        throw err
      }
    },
    [showToast]
  )

  useEffect(() => {
    if (!addressSearchInputEl.current) return
    const element = addressSearchInputEl.current

    const onKeydown = debounce(async function (this: HTMLInputElement, e: KeyboardEvent) {
      const value = (e?.target as HTMLInputElement | null)?.value
      if (!value) return

      await handleSearchAddress(value, 1, 20)
    }, 500)

    element.addEventListener('keydown', onKeydown)

    setTimeout(() => {
      if (!element) return
      element.focus()
    }, 300)

    return () => {
      element.removeEventListener('keydown', onKeydown)
    }
  }, [handleSearchAddress])

  return (
    <Screen appBar={{ title: '주소 찾기' }}>
      <StyledSearchBar>
        <form action="." noValidate onSubmit={(e) => e.preventDefault()}>
          <TextInput
            type="search"
            ref={addressSearchInputEl}
            value={searchText}
            width="100%"
            placeholder="도로명, 건물명, 번지 검색"
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            onClearInputValue={() => {
              setSearchText('')
            }}
            appendRight={<IconSearchRegular width={22} height={22} />}
          />
        </form>
      </StyledSearchBar>
      <StyledInstructions>
        {addressList.length === 0 ? (
          <>
            <h4>{searchText === '' ? '이렇게 검색해 보세요' : '조금 더 자세하게 검색해 주세요'}</h4>
            <div className="example">
              <div className="example-format">
                도로명 + <span>건물번호</span>
              </div>
              <div className="example-text">예: 테헤란로 4길 14</div>
            </div>
            <div className="example">
              <div className="example-format">
                동/읍/면/리 + <span>번지</span>
              </div>
              <div className="example-text">예:역삼동 825-22</div>
            </div>
            <div className="example">
              <div className="example-format">건물명, 아파트명</div>
              <div className="example-text">예: 미림타워</div>
            </div>
          </>
        ) : (
          <div>
            {addressList.map((address) => (
              <AddressListItem key={address.roadAddress} address={address} onClick={onClickAddress} />
            ))}
          </div>
        )}
      </StyledInstructions>
    </Screen>
  )
}

const StyledSearchBar = styled.div`
  margin: 1rem;
`

const StyledInstructions = styled.div`
  h4 {
    ${vars.$semantic.typography.bodyL1Bold}
    margin: 1rem;
  }
  .example {
    ${vars.$semantic.typography.bodyL2Regular}
    margin: 0 1rem 1.5rem;
    &-format {
      span {
        ${vars.$semantic.typography.bodyL2Bold}
      }
    }
    &-text {
      ${vars.$semantic.typography.bodyL2Regular}
      color: ${vars.$scale.color.gray600};
    }
  }
`

export default SearchAddressView
