import { isDefaultLoadable, useLoadableFamilyPaginate } from '@daangn/recoil-loadable'
import { useMemo } from 'react'
import { useRecoilState } from 'recoil'

import { useSearch } from './useSearch'
import { ResponseType, poisLoadableFamily } from '../state/poi'

export const usePoisLoadableFamilyPaginate = () => {
  const { variables } = useSearch()

  const { value, loadMore, refetch } = useLoadableFamilyPaginate({
    loadable: poisLoadableFamily,
    variables,
    fetchPolicy: 'store-or-network',
  })

  return useMemo(
    () => ({
      value,
      loadMore,
      refetch,
    }),
    [loadMore, refetch, value]
  )
}

export const useStore = () => {
  const { variables } = useSearch()
  const [store, setStore] = useRecoilState(poisLoadableFamily.store(variables))

  const defaultStore: ResponseType = {
    items: [] as ResponseType['items'],
    page: 1,
    perPage: 20,
    hasNextPage: false,
  }

  return {
    store: isDefaultLoadable(store) ? defaultStore : store,
    setStore,
  }
}
