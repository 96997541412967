import styled from '@emotion/styled'
import IconWriteRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconWriteRegular'
import { vars } from '@seed-design/design-token'

import { useLogEvent } from '@src/react-utils/components/Analytics'
import { FloatingButton } from '@src/react-utils/components/Button'
import { useStepNavigator } from '@src/stackflow'

const AddPoiButton = () => {
  const logEvent = useLogEvent()
  const { stepPush } = useStepNavigator('winter_snack')

  const goToWriteReviewPage = async () => {
    logEvent({ name: 'click_suggest_new_poi_button', params: {} })

    stepPush({
      mode: 'edit',
    })
  }

  return <Button color="default" size="size48" onClick={goToWriteReviewPage} icon={<IconWriteRegular />} />
}

const Button = styled(FloatingButton)`
  background-color: ${vars.$semantic.color.paperDefault};

  .icon {
    color: ${vars.$scale.color.gray900};
  }
`

export default AddPoiButton
