import { getRecommendationsV2 } from '@daangn/local-business-network/lib/poi'
import { captureException } from '@sentry/react'
import { selector } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'
import { getQueryParam } from '@src/js-utils/url'
import { checkInRegionInfoAtom } from '@src/place-utils/region/store'
import { userInfoAtom } from '@src/place-utils/user/store'

export const generateKey = generateUniqueKey(['searchPoi', 'recommendations'])

export const recommendationsSelector = selector({
  key: generateKey('recommendationsSelector'),
  get: async ({ get }) => {
    const region = getQueryParam('region')
    const { authToken: xAuthToken } = get(userInfoAtom)
    const { id: checkinRegionId } = get(checkInRegionInfoAtom)

    try {
      const {
        data: { data },
      } = await getRecommendationsV2({
        params: {
          xAuthToken,
          regionId: region ? +region : checkinRegionId,
        },
      })

      return data
    } catch (err) {
      captureException(err)

      return 'error'
    }
  },
})
