import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

import { BRAND_NAME } from '@src/constants/rebranding'

export default function FormFooter() {
  return (
    <Footer>
      <h4>안내 사항</h4>
      <ul>
        <li>
          <div className="dot">&bull;</div>
          <div> 동네 이웃에게 도움이 될 수 있는 위치 정보를 공유해주세요.</div>
        </li>
        <li>
          <div className="dot">&bull;</div>
          <div> 부적절한 위치 정보가 등록될 경우 {BRAND_NAME} 운영정책에 따라 삭제 될 수 있어요.</div>
        </li>
      </ul>
    </Footer>
  )
}

const Footer = styled.footer`
  margin: 2rem 1rem 1rem;
  padding: 1rem 0;
  border-top: solid 1px ${vars.$semantic.color.divider1};
  h4 {
    margin: 0 0 0.375rem;
    ${vars.$semantic.typography.caption2Bold}
    color: ${vars.$scale.color.gray600};
  }
  li {
    display: flex;
    div {
      ${vars.$semantic.typography.caption2Regular}
      color: ${vars.$scale.color.gray600};
    }
    .dot {
      margin: 0 0.25rem 0 0;
    }
  }
`
