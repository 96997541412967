import { useMemo } from 'react'

import { getDateFormatWithPad } from '../utils/date-util'

export const useDateFormat = (date: string | Date | null) => {
  return useMemo(() => {
    if (date) {
      const dateFormat = new Date(date)

      return {
        ...getDateFormatWithPad(dateFormat),
        dateObject: dateFormat,
      }
    }
    return null
  }, [date])
}
