import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

import { skeletonGradient } from '@src/styles/others'

export const Skeleton = styled.div<{ width?: number; height?: string | number; color?: string }>`
  ${skeletonGradient};
  width: ${({ width }) => `${width}px`};
  height: ${({ height = 20 }) => (typeof height === 'number' ? `${height}px` : height)};
  border-radius: 6px;
  background-color: ${({ color }) => color || vars.$scale.color.gray200};
`
