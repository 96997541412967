import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { useRef, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { haversineDistance } from '@src/js-utils/coordinate'
import { useKakaoMap } from '@src/react-utils/components/Map/KakaoMap'
import { useStepNavigator } from '@src/stackflow'

import NoPois from './NoPois'
import PoisCards from './PoisCardSection'
import { DEFAULT_MAP_POI_CARD_HEIGHT, mapPoiCardHeightAtom } from '../constants/style'
import { useSelectedPoi } from '../hooks'
import { filteredLatestSearchResultSelectorFamily } from '../state'
import { WinterSnackParams } from '../WinterSnackPage'

const ViewMode = () => {
  const { filterId, selectedPoiId } = useActivityParams<WinterSnackParams>()
  const { stepReplace } = useStepNavigator('winter_snack')

  const { spotlightPoiOnMap } = useSelectedPoi()
  const { map, getCenter, getBounds } = useKakaoMap()

  const filteredLatestSearchResult = useRecoilValue(
    filteredLatestSearchResultSelectorFamily({ filterId: filterId ?? 'all' })
  )

  const setPoiCardHeight = useSetRecoilState(mapPoiCardHeightAtom)

  const latestPoiAutoSelectRef = useRef('')

  useEffect(() => {
    const mapCenter = getCenter()
    const mapBounds = getBounds()

    if (!map || !mapCenter || !mapBounds) return
    // filteredLastestSearchResult 바뀌면, 유저가 보는 지도의 중심점에서 가장 가까운 poi를 자동으로 선택
    // filteredPoi가 필터변경이 아닌 operationStatus 변경으로 바뀌면, 자동선택을 하지 않음
    if (filterId === latestPoiAutoSelectRef.current) return
    if (filteredLatestSearchResult.length > 0) {
      if (selectedPoiId && filteredLatestSearchResult.find(({ poi }) => poi.id === selectedPoiId)) {
        spotlightPoiOnMap(selectedPoiId)
      } else {
        const userViewingMapCenter = {
          longitude: mapCenter.longitude,
          latitude: mapCenter.latitude + (mapBounds.topRight.latitude - mapCenter.latitude) / 3,
        }

        const nearestPoiToMapCenter = filteredLatestSearchResult
          .slice()
          .sort(
            ({ poi: poiA }, { poi: poiB }) =>
              haversineDistance(poiA.coordinates!, userViewingMapCenter) -
              haversineDistance(poiB.coordinates!, userViewingMapCenter)
          )[0]

        spotlightPoiOnMap(nearestPoiToMapCenter.poi.id!)
      }

      latestPoiAutoSelectRef.current = filterId ?? 'all'
    } else {
      setPoiCardHeight(DEFAULT_MAP_POI_CARD_HEIGHT)
      stepReplace({
        selectedPoiId: undefined,
      })
    }
    // 무한루프 방지!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredLatestSearchResult, map])

  return (
    <CardSection>
      {filteredLatestSearchResult.length === 0 ? (
        <Container>
          <NoPois />
        </Container>
      ) : (
        <PoisCards />
      )}
      <div className="safeAreaCover" />
    </CardSection>
  )
}

const CardSection = styled.div`
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  .safeAreaCover {
    width: 100%;
    height: 0px;
    height: constant(safe-area-inset-bottom);
    height: env(safe-area-inset-bottom);
    background: transparent;
  }
`

const Container = styled.div<{ mapPoiCardHeight?: number }>`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem 1rem;
  margin: 0 1rem 1rem;
  width: calc(100% - 2rem);
  border-radius: 0.375rem;
  background-color: ${vars.$semantic.color.paperDefault};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
`

export default ViewMode
