import { ImpressionRoot } from '@daangn/webview-impression'
import styled from '@emotion/styled'
import { ActivityComponentType } from '@stackflow/react'
import { useEffect } from 'react'

import { IS_LOCAL } from '@src/constants/environmentConstants'
import { Screen } from '@src/stackflow'

import { List } from './List'
import { NativeReviewFormFloatingButton } from './NativeReviewFormFloatingButton'
import { TopArea } from './TopArea'
import { REGION, SERVICE_NAME } from '../constants'
import { withSessionTimer } from '../hoc/withSessionTimer'
import { useAnalytics } from '../hooks'

export interface ActivityParams {
  highlight_review_id?: string
}

const NativeReviewListPre: ActivityComponentType<ActivityParams> = () => {
  const logEvent = useAnalytics()

  useEffect(() => {
    // custom show page view event for session id
    logEvent('show_page_view')
  }, [logEvent])

  return (
    <Screen
      appBar={{
        title: `${REGION} ${SERVICE_NAME} 맛집`,
        border: false,
      }}>
      <ImpressionRoot
        initialized
        logger={({ name, params }) => {
          logEvent(name, params)
        }}
        impressionOptions={{
          threshold: 0.5,
          coolTime: 60000,
          exposureTime: 1000,
          onPreLogging: () => {},
          onPostLogging: () => {},
        }}
        development={IS_LOCAL}>
        <Container>
          <TopArea />
          <List />
          <NativeReviewFormFloatingButton />
        </Container>
      </ImpressionRoot>
    </Screen>
  )
}
export const NativeReviewList = withSessionTimer(NativeReviewListPre)

const Container = styled.div({
  position: 'relative',
  height: '100%',
  overflow: 'scroll',
  padding: '0 1rem',
})
