import styled from '@emotion/styled'
import { useRecoilValue } from 'recoil'

import { useCurrentPosition } from '@src/place-utils/location/hooks'
import { GPSButton as GPSButtonComponent } from '@src/react-utils/components/Map'
import { useKakaoMap } from '@src/react-utils/components/Map/KakaoMap'

import { useAnalytics } from '../hooks'
import { focusedPoiIdAtom } from '../state/map'
import { viewTypeAtom } from '../state/view'

export const GPSButton = () => {
  const logEvent = useAnalytics()
  const { panTo } = useKakaoMap()
  const { access, position } = useCurrentPosition()

  const viewType = useRecoilValue(viewTypeAtom)
  const focusedPoiId = useRecoilValue(focusedPoiIdAtom)
  const bottomSpacing = focusedPoiId ? 86 : 0

  const handleClick = () => {
    logEvent('click_current_position_button')
  }

  const handleOnAccessGranted = () => {
    if (access === 'granted') {
      panTo({ coordinates: position })
    }
  }

  return (
    <Button
      onClick={handleClick}
      onAccessGranted={handleOnAccessGranted}
      bottomSpacing={viewType === 'map' ? bottomSpacing : 0}
    />
  )
}

const Button = styled(GPSButtonComponent)`
  z-index: 2;
  position: absolute;
  bottom: 1rem;
  bottom: calc(1rem + env(safe-area-inset-bottom));
  bottom: calc(1rem + constant(safe-area-inset-bottom));
  right: 1rem;
`
