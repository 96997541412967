import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'
import { ReviewInducement } from '@src/place-utils/review/ReviewInducement'

import { useAnalytics } from '../hooks'

export const EmptyReviewTab = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const logEvent = useAnalytics()

  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))

  const handleClickWriteReview = async () => {
    if (!poi) return

    /** @deprecated */
    logEvent('click_write_review_button', {
      area: 'empty_review_tab',
    })
    logEvent('click_write_profile_review_button', {
      area: 'empty_review_tab',
    })
  }

  return (
    <Container>
      <ReviewInducement poiId={poiId} onClickStarRating={handleClickWriteReview} area="review_tab" />
    </Container>
  )
}

const Container = styled.div`
  padding: 6.25rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${vars.$semantic.color.paperDefault};
`
