import styled from '@emotion/styled'
import { withErrorBoundary } from 'react-error-boundary'
import { useRecoilValue } from 'recoil'

import { brandProfileSelectorFamily } from '@src/place-utils/bizProfile/store'
import CouponCardContainer from '@src/place-utils/coupon/CouponCard/CouponCardContainer'
import { shouldRenderCouponSelectorFamily } from '@src/place-utils/coupon/store'
import { SectionHeader as SectionHeaderComponent } from '@src/react-utils/components'
import { Section } from '@src/react-utils/components/Layout'

interface Props {
  brandAccountId: string
}
export const CouponSection = withErrorBoundary(
  ({ brandAccountId }: Props) => {
    const brandProfile = useRecoilValue(brandProfileSelectorFamily({ brandAccountId }))
    const couponId = brandProfile?.brandCouponId
    const isManager = false

    if (!couponId) {
      return null
    }

    const shouldRenderCoupon = useRecoilValue(shouldRenderCouponSelectorFamily({ couponBoxId: couponId, isManager }))

    if (!shouldRenderCoupon) {
      return null
    }

    return (
      <Section>
        <SectionHeader text="쿠폰" type="basic" />
        <CouponCardContainer couponId={couponId} isManager={isManager} />
      </Section>
    )
  },
  {
    fallbackRender: () => null,
  }
)

const SectionHeader = styled(SectionHeaderComponent)({
  marginBottom: '1rem',
})
