import VConsole from 'vconsole'

let vConsole: VConsole

export const getVConsole = () => {
  if (vConsole) {
    return vConsole
  } else {
    return (vConsole = new VConsole())
  }
}
