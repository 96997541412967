import { css } from '@emotion/react'
import styled from '@emotion/styled'

interface Props {
  line?: number
}

export const EllipsisText = styled.p<Props>`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ line }) => line && line > 1 && multilineEllipsis(line)};
`

const multilineEllipsis = (line: number) => css`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: ${line};
  -webkit-box-orient: vertical;
  white-space: normal;
`
