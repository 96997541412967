import { BoxButton } from '@daangn/sprout-components-button'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { ReactNode, useLayoutEffect, useRef, useState } from 'react'

import { renderMultilineText } from '../utils'

interface Props {
  title?: string | ReactNode
  body: string | ReactNode
  CTAButton?: {
    label: string
    onClick: () => void
  }
}
export const EmptyOrError = ({ title, body, CTAButton }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [offsetHeight, setOffsetHeight] = useState(0)

  const renderTitle = () => {
    if (!title) return null

    if (typeof title === 'string') {
      return (
        <TitleWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
      )
    }

    return <TitleWrapper>{title}</TitleWrapper>
  }

  const renderBody = () => {
    if (!body) return null

    if (typeof body === 'string') {
      return <Body hasTitle={!!title}>{renderMultilineText(body)}</Body>
    }

    return body
  }

  const renderCTAButton = () => {
    if (!CTAButton) return null

    return (
      <CTAButtonWrapper>
        <BoxButton
          size="medium"
          type="button"
          variant="secondary"
          onClick={CTAButton.onClick}
          minWidth={120}
          aria-label={CTAButton.label}>
          {CTAButton.label}
        </BoxButton>
      </CTAButtonWrapper>
    )
  }

  useLayoutEffect(() => {
    setOffsetHeight(ref.current?.offsetHeight ?? 0)
  }, [])

  return (
    <Container ref={ref} offsetHeight={offsetHeight}>
      {renderTitle()}
      {renderBody()}
      {renderCTAButton()}
    </Container>
  )
}

const Container = styled.div<{ offsetHeight: number }>`
  margin: auto 3.375rem;
  position: relative;
  top: ${({ offsetHeight }) => `calc(40% - (${offsetHeight}px) / 2)`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const TitleWrapper = styled.div`
  margin: 0 0 0.25rem;
`
const Title = styled.h3`
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.subtitle1Regular};
`
const Body = styled.p<{ hasTitle?: boolean }>([
  {
    color: vars.$scale.color.gray600,
    textAlign: 'center',
  },
  ({ hasTitle = false }) =>
    hasTitle ? vars.$semantic.typography.subtitle2Regular : vars.$semantic.typography.subtitle1Regular,
])

const CTAButtonWrapper = styled.div`
  margin: 2.375rem 0 0;
`
