import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { Modal } from '@stackflow/plugin-basic-ui'

import { ExperimentDevTool } from '@src/place-utils/__generated__/experiments/ExperimentDevTool'

interface Props {
  onClose: () => void
}
export const NativeReviewDevTools = ({ onClose }: Props) => {
  return (
    <Modal
      onOutsideClick={(e) => {
        e.preventDefault()
        onClose()
      }}>
      <Container>
        <Title>토박이 실험</Title>
        <ExperimentDevTool />
      </Container>
    </Modal>
  )
}

const Container = styled.div({
  padding: '0.5rem',
})
const Title = styled.h1([vars.$semantic.typography.title1Bold, {}])
