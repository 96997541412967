import { Poi } from '@daangn/local-business-network/lib/poi'

import { WatchPlaceIdDto } from '../store'

export const poiToPlaceWatchId = (poi: Poi): WatchPlaceIdDto => {
  if (poi.bizAccountId) {
    return {
      targetId: poi.bizAccountId,
      targetType: 'BA',
    }
  }
  return {
    targetId: poi.id,
    targetType: 'POI',
  }
}
