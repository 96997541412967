import { ToggleTab } from '@daangn/local-business-kit'
import { useLoadableFamilyPaginate } from '@daangn/recoil-loadable'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { CSSProperties, useCallback, useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useRecoilState, useRecoilValue } from 'recoil'

import { myPoiReviewSelectorFamily, reviewsByPoiIdAndUserIdLoadableFamily } from '@src/place-utils/review'
import { ReviewInducement } from '@src/place-utils/review/ReviewInducement'
import { useScreenInstance } from '@src/react-utils/hooks/useScreenInstance'

import { useAnalytics } from '../hooks'
import { ReviewTab } from '../ReviewTab'
import { postsTabAtomFamily, communityPostsStaticMetaAtomFamily, communityPostsSortingOptionAtomFamily } from '../state'
import { StoryTab } from '../StoryTab'

const PostsTab = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const logEvent = useAnalytics()
  const screenInstance = useScreenInstance()

  const [currentTabKey, setCurrentTabKey] = useRecoilState(postsTabAtomFamily(screenInstance))
  const communityPostsSortingOption = useRecoilValue(communityPostsSortingOptionAtomFamily({ poiId, screenInstance }))
  const communityPostsStaticMeta = useRecoilValue(
    communityPostsStaticMetaAtomFamily({ poiId, sortingOption: communityPostsSortingOption, screenInstance })
  )
  const { value: reviewsStore } = useLoadableFamilyPaginate({
    loadable: reviewsByPoiIdAndUserIdLoadableFamily,
    variables: { poiId: parseInt(poiId, 10), sort: 'HIGHEST_SCORE', userId: undefined },
    fetchPolicy: 'store-or-network',
  })

  const reviewCount = Math.max(reviewsStore?.total ?? 0 ?? 0, 0)

  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId }))
  const userReviewId = myReview?.id
  const hasUserReview = !!userReviewId

  const onClickReviewTab = useCallback(() => {
    logEvent('click_review_tab_button', {
      count: reviewCount,
    })
    setCurrentTabKey('review')
  }, [logEvent, reviewCount, setCurrentTabKey])

  const onClickCommunityTab = useCallback(() => {
    logEvent('click_community_tab_button', {
      count: communityPostsStaticMeta.total,
    })
    setCurrentTabKey('story')
  }, [communityPostsStaticMeta.total, logEvent, setCurrentTabKey])

  const tabs = useMemo(
    () =>
      [
        {
          key: `review`,
          label: `후기${reviewCount ? ` ${reviewCount}` : ''}`,
          onClick: onClickReviewTab,
        },
        {
          key: `story`,
          label: `동네생활${
            Math.max(communityPostsStaticMeta.total, 0) ? ` ${Math.max(communityPostsStaticMeta.total, 0)}` : ''
          }`,
          onClick: onClickCommunityTab,
        },
      ] as const,
    [reviewCount, onClickReviewTab, communityPostsStaticMeta.total, onClickCommunityTab]
  )

  const renderTab = () => {
    switch (currentTabKey) {
      case 'review':
      default:
        return <ReviewTab />
      case 'story':
        return <StoryTab />
    }
  }
  return (
    <Container>
      {!hasUserReview && reviewCount > 0 && (
        <ErrorBoundary fallbackRender={() => null}>
          <Wrapper padding={'2rem 1rem'} margin={'0 0 0.5rem'}>
            <ReviewInducement poiId={poiId} area="review_tab" />
          </Wrapper>
        </ErrorBoundary>
      )}

      <Wrapper padding={'1.25rem 1rem 0.75rem'}>
        <ToggleTab tabs={tabs} externalTabKey={currentTabKey} />
      </Wrapper>
      {renderTab()}
    </Container>
  )
}

const Container = styled.div({
  margin: '0.5rem 0 0',
})

const Wrapper = styled('div')<{ padding?: CSSProperties['padding']; margin?: CSSProperties['margin'] }>([
  {
    backgroundColor: vars.$semantic.color.paperDefault,
  },
  ({ padding }) => ({ padding }),
  ({ margin }) => ({ margin }),
])

export default PostsTab
