import { jusoApi, kakaLocalApi } from '@src/apis'
import { DocumentJusoAddress } from '@src/apis/types/juso'
import { KakaoAddressDocument } from '@src/apis/types/kakaoLocal'

export interface SearchAddressListItem {
  roadAddress?: string
  jibunAddress?: string
  zipNumber?: string
}

const convertJusoResponse = ({ roadAddr, jibunAddr, zipNo }: DocumentJusoAddress) => ({
  roadAddress: roadAddr,
  jibunAddress: jibunAddr,
  zipNumber: zipNo,
})

const convertKakaoResponse = (address: KakaoAddressDocument) => ({
  roadAddress: address.road_address?.address_name || '',
  jibunAddress: address.address?.address_name || '',
  zipNumber: address.address?.zip_code,
  coordinates:
    address.y && address.x
      ? {
          latitude: parseFloat(parseFloat(address.y).toFixed(8)),
          longitude: parseFloat(parseFloat(address.x).toFixed(8)),
        }
      : undefined,
})

export const searchAddressesByQuery = async (query: string, page?: number, perPage?: number) => {
  try {
    const res = await jusoApi.searchAddresses({ query, page, perPage })

    if (!res) {
      return []
    }

    return res.juso.map(convertJusoResponse)
  } catch (e) {
    const res = await kakaLocalApi.getQuerySearchAddress({ query, page, size: perPage })

    return res.map(convertKakaoResponse)
  }
}
