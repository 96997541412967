import { ActivityComponentType } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { Screen } from '@src/stackflow'

import { Contents } from './Contents'
import { EmptyState } from './EmptyState'
import { allCommentsAtomFamily } from './state'

export type ActivityParams = {
  storyArticleId: string
  commentId?: string
}

export const StoryArticlePoiCollectionPage: ActivityComponentType<ActivityParams> = ({
  params: { storyArticleId },
}) => {
  const comments = useRecoilValue(allCommentsAtomFamily({ storyArticleId: +storyArticleId }))

  const isEmpty = comments.length === 0

  return (
    <Screen
      appBar={{
        title: '추천 장소 지도로 보기',
        border: false,
      }}>
      {isEmpty ? <EmptyState /> : <Contents />}
    </Screen>
  )
}
