import { BoxButton } from '@daangn/sprout-components-button'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

import { MY_WATCHES_SCHEME } from '@src/constants/url'
import { Screen, useNavigator } from '@src/stackflow'
import { safeAreaPadding } from '@src/styles/safeArea'

import { ReactComponent as WatchedPlaceFadeOut } from './watched-place-fade-out.svg'

export const WatchedPlacesFadeOut = () => {
  const { pushScheme } = useNavigator()

  return (
    <Screen
      appBar={{
        title: '저장한 장소',
        border: false,
      }}>
      <Flex>
        <Container>
          <Title>
            이제 저장한 장소는
            <br />
            관심목록에서 볼 수 있어요!
          </Title>
          <ImageWrapper>
            <WatchedPlaceFadeOut width={'100%'} height={'100%'} />
          </ImageWrapper>
        </Container>
        <ButtonWrapper>
          <BoxButton
            type="button"
            variant="primary"
            size="xlarge"
            width="100%"
            onClick={() => pushScheme(MY_WATCHES_SCHEME)}>
            관심목록 바로가기
          </BoxButton>
        </ButtonWrapper>
      </Flex>
    </Screen>
  )
}
const Flex = styled.div([
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
])
const Container = styled.div({
  flex: 1,
  padding: '0 1rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})
const Title = styled.h1([
  vars.$semantic.typography.title3Bold,
  {
    margin: '0 0 1.5rem',
    color: vars.$scale.color.gray900,
    textAlign: 'center',
  },
])
const ImageWrapper = styled.div({
  width: '100%',
  maxHeight: '500px',
})
const ButtonWrapper = styled.div([
  {
    backgroundColor: vars.$semantic.color.paperDefault,
    position: 'sticky',
    bottom: 0,
    width: '100%',
    padding: '0.75rem 1rem 0.5rem',
  },
  safeAreaPadding('bottom', '0.5rem'),
])
