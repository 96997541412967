import { IS_PROD } from './environmentConstants'

export const winterFoodTags = [
  '계란빵',
  '국화빵',
  '군고구마',
  '떡볶이',
  '붕어빵',
  '어묵',
  '잉어빵',
  '문어빵',
  '호떡',
  '호빵',
  '기타',
]

export const EVENT_ATTRIBUTE_ORDER = {
  environment_day: [9, 10, 11, 12, 13, 5],
} as { [key: string]: any[] }

export const ETC_ATTRIBUTE_ID = 5

export const eventOptions = {
  environment_day: {
    order: [9, 10, 11, 12, 13, ETC_ATTRIBUTE_ID],
    reason: {
      text: '친환경 가게인 이유를 함께 적어주세요.',
      required: true,
    },
  },
  community_restaurant: {
    order: [],
  },
  winter_dessert: {
    order: IS_PROD ? [34, 35, 36, 37, 38, 39, 40, 41, 43, 21] : [133, 134, 135, 136, 137, 138, 139, 140, 141, 142],
  },
} as {
  [key: string]: {
    order: number[]
    reason?: {
      text: string
      required: boolean
    }
  }
}

export const winterSnackEventId = IS_PROD ? 2 : 133
