import { Poi } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import debounce from 'lodash/debounce'
import { Suspense, useEffect, useRef, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { Loading } from '@src/react-utils/components/Loading'
import { Screen, useActivity, useStepNavigator } from '@src/stackflow'

import { ViewTypeToggleButton } from './FloatingLayer/ViewTypeToggleButton'
import { useSearch } from './hooks'
import { List } from './List'
import Map from './Map'
import { NoResult } from './NoResult'
import { SearchField } from './SearchField'
import { boundsAtom, isMapBoundChangedAtom } from './state/map'
import { resultStatusAtom, searchStatusAtom } from './state/poi'
import { queryAtom } from './state/query'
import { ViewType, viewTypeAtom } from './state/view'

interface Props {
  onSelectPoi: (poi: Poi) => void
}
export const SearchPoi = ({ onSelectPoi }: Props) => {
  const { steps } = useActivity()
  const { stepPush, stepReplace } = useStepNavigator('search_poi')
  const { searchByInput, searchByKeyword } = useSearch()

  const resultStatus = useRecoilValue(resultStatusAtom)
  const [defaultInputValue, setDefaultInputValue] = useState<string | undefined>()
  const previousViewTypeRef = useRef<ViewType>('list')

  const handleSearchOnSubmit = useRecoilCallback(
    ({ set }) =>
      (input: string) => {
        if (steps.length > 1) {
          stepReplace({})
        } else {
          stepPush({})
        }

        set(viewTypeAtom, previousViewTypeRef.current)
        searchByInput(input)
      },
    [searchByInput, stepPush, stepReplace, steps.length]
  )

  const handleSearchOnChange = debounce(
    useRecoilCallback(
      ({ reset }) =>
        (input: string) => {
          reset(resultStatusAtom)

          if (input.length === 0) {
            return reset(searchStatusAtom)
          }

          searchByInput(input)
        },
      [searchByInput]
    ),
    500
  )

  const handleSearchByKeyword = (keyword: string) => {
    setDefaultInputValue(keyword)

    searchByKeyword(keyword)
  }

  const handleFocusSearchField = useRecoilCallback(
    ({ set }) =>
      () => {
        set(viewTypeAtom, 'list')
      },
    []
  )

  const handleClearSearch = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(queryAtom)
        reset(searchStatusAtom)
        reset(resultStatusAtom)
        reset(viewTypeAtom)
        reset(boundsAtom)
        reset(isMapBoundChangedAtom)
      },
    []
  )

  const handleClickViewTypeToggleButton = ({ prevViewType }: { prevViewType: ViewType }) => {
    previousViewTypeRef.current = prevViewType
  }

  useEffect(() => {
    const onPopState = () => {
      handleClearSearch()
    }
    window.addEventListener('popstate', onPopState)

    return () => {
      window.removeEventListener('popstate', onPopState)
      handleClearSearch()
    }
  }, [handleClearSearch])

  return (
    <Screen
      appBar={{
        title: '장소 공유',
        border: false,
      }}>
      <Container>
        <SearchField
          defaultValue={defaultInputValue}
          onSearch={handleSearchOnSubmit}
          onChange={handleSearchOnChange}
          onClear={handleClearSearch}
          onFocus={handleFocusSearchField}
        />
        {resultStatus === 'no-result' ? (
          <Contents>
            <NoResult />
          </Contents>
        ) : (
          <>
            <Contents>
              <Suspense fallback={<LoadingSpinner />}>
                <List onSelectPoi={onSelectPoi} onClickKeyword={handleSearchByKeyword} />
                <Map onSelectPoi={onSelectPoi} />
              </Suspense>
            </Contents>
            <ViewTypeToggleButton onClick={handleClickViewTypeToggleButton} />
          </>
        )}
      </Container>
    </Screen>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
`
const Contents = styled.div`
  flex: 1;
  position: relative;
`
const LoadingSpinner = styled(Loading)`
  margin: 2rem 0 0;
  height: auto;
`
