import { FilterPicker } from './FilterPicker'
import { MENU_FILTER_TO_CURATION_CATEGORY_ID_MAPPER } from '../../constants'
import { useAnalytics } from '../../hooks'

export const MenuFilterBottomSheet = () => {
  const logEvent = useAnalytics()

  const handleClickOption = (id: string) => {
    logEvent('click_menu_filter_detail', {
      menu_id: MENU_FILTER_TO_CURATION_CATEGORY_ID_MAPPER[parseInt(id, 10) - 1],
    })
  }
  return (
    <FilterPicker
      id="list-menu-filter"
      title="메뉴"
      options={MENU_FILTER_TO_CURATION_CATEGORY_ID_MAPPER.map(({ name, filterId }) => ({
        name,
        id: filterId.toString(),
      }))}
      onClickOption={handleClickOption}
    />
  )
}
