import { IS_PROD } from '../environmentConstants'

export const APP_SCHEME = IS_PROD ? 'karrot://' : 'karrot.alpha://'

export const GO_SCHEME = IS_PROD ? 'karrot://go/' : 'karrot.alpha://go/'

/**
 * 관심목록 (나의 당근 > 나의 거래)
 */
export const MY_WATCHES_SCHEME = `${APP_SCHEME}my_watches`

/**
 * 동네 인증하기
 */
export const CHECKIN_SCHEME = `${APP_SCHEME}checkin`
