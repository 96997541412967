import { FilterPicker } from './FilterPicker'
import { RESIDENCY_PERIOD_FILTER_TO_RESIDENCY_YEARS } from '../../constants'
import { useAnalytics } from '../../hooks'

export const ResidencePeriodFilterBottomSheet = () => {
  const logEvent = useAnalytics()

  const handleClickOption = (id: string) => {
    logEvent('click_residence_year_filter_detail', {
      residence_year: RESIDENCY_PERIOD_FILTER_TO_RESIDENCY_YEARS[parseInt(id, 10) - 1].key,
    })
  }
  return (
    <FilterPicker
      id="list-residence-period-filter"
      title="거주 기간"
      options={RESIDENCY_PERIOD_FILTER_TO_RESIDENCY_YEARS.map(({ name, filterId }) => ({
        name,
        id: filterId.toString(),
      }))}
      onClickOption={handleClickOption}
    />
  )
}
