export function removeDuplicatesByKey<T>(arr: T[], key: keyof T): T[] {
  const uniqueKeys = new Set<T[keyof T]>()
  const result: T[] = []

  for (const obj of arr) {
    const keyValue = obj[key]

    if (!uniqueKeys.has(keyValue)) {
      uniqueKeys.add(keyValue)
      result.push(obj)
    }
  }

  return result
}
