import { getPoiEventByIdV2 } from '@daangn/local-business-network/lib/poi'
import { selector } from 'recoil'

import { IS_PROD } from '@src/constants/environmentConstants'

import { generateWinterSnackPageRecoilKey } from './keys'

export const winterSnackEventSelector = selector({
  key: generateWinterSnackPageRecoilKey('event'),
  get: async () => {
    const winterSnackEventId = IS_PROD ? 2 : 133

    try {
      const { data } = await getPoiEventByIdV2({ params: { id: winterSnackEventId } })

      return data
    } catch {
      return null
    }
  },
})

export const winterSnackFiltersSelector = selector({
  key: generateWinterSnackPageRecoilKey('filters'),
  get: async ({ get }) => {
    const event = get(winterSnackEventSelector)
    if (!event) return []

    return event.attributes
  },
})
