import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import isEmpty from 'lodash/isEmpty'

import {
  IconEmojiRecommendColored,
  IconEmojiHeartColored,
  IconEmojiSmileColored,
  IconEmojiWowColored,
  IconEmojiSadColored,
  IconEmojiAngryColored,
} from '@src/assets/icons/EmojiColored'

export type ReactionType = 'thumbs_up' | 'heart' | 'smile' | 'surprise' | 'sob' | 'rage'

interface Props {
  reactionCount: number
  reactionTypes: ReactionType[]
}

const ReactionDisplay = ({ reactionCount, reactionTypes }: Props) => {
  const REACTIONS = {
    thumbs_up: {
      type: 'thumbs_up',
      text: '엄지척',
      Icon: IconEmojiRecommendColored,
    },
    heart: {
      type: 'heart',
      text: '하트',
      Icon: IconEmojiHeartColored,
    },
    smile: {
      type: 'smile',
      text: '기뻐요',
      Icon: IconEmojiSmileColored,
    },
    surprise: {
      type: 'surprise',
      text: '놀라워요',
      Icon: IconEmojiWowColored,
    },
    sob: {
      type: 'sob',
      text: '슬퍼요',
      Icon: IconEmojiSadColored,
    },
    rage: {
      type: 'rage',
      text: '화나요',
      Icon: IconEmojiAngryColored,
    },
  }

  const ICON_SIZE = '1rem'

  if (reactionCount > 0 && isEmpty(reactionTypes)) reactionTypes = ['thumbs_up']

  return (
    <Container>
      {reactionTypes.map((type: ReactionType, idx) => {
        const Reaction = REACTIONS[type].Icon

        return <Reaction width={ICON_SIZE} height={ICON_SIZE} key={idx} />
      })}
      {reactionCount}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 400;
  font-size: 0.813rem;
  color: ${vars.$scale.color.gray700};
`

export default ReactionDisplay
