import { APP_SCHEME } from '@src/constants/url'

const articleWriting = ({
  interestId,
  poi,
}: {
  interestId?: number
  poi: {
    id: string
    name?: string
    address?: string
  }
}) => {
  const params = new URLSearchParams()

  params.set('poi_id', poi.id)

  if (interestId) {
    params.set('interest_id', interestId.toString())
  }

  if (poi.name) {
    params.set('poi_name', poi.name)
  }

  if (poi.address) {
    params.set('poi_address', poi.address)
  }

  return `${APP_SCHEME}story_articles/new?${params.toString()}`
}

const articleDetail = ({ articleId }: { articleId: number }) => {
  return `${APP_SCHEME}story_articles/${articleId}`
}

export const getNativeURL = {
  articleWriting,
  articleDetail,
}
