import { css } from '@emotion/react'
import { vars } from '@seed-design/design-token'

type SafeAreaDirection = 'top' | 'bottom' | 'left' | 'right'

export const getSafeAreaValue = (direction: SafeAreaDirection) => {
  const rootElement = document.getElementById('root')
  const safeAreaEnv =
    rootElement && window.getComputedStyle(rootElement).getPropertyValue(`--safe-area-inset-${direction}-env`)
  const safeAreaConstant =
    rootElement && window.getComputedStyle(rootElement).getPropertyValue(`--safe-area-inset-${direction}-constant`)

  return safeAreaEnv || safeAreaConstant || '0px'
}

export const activeEffect = (type: 'highlight' | 'scale' = 'highlight') =>
  type === 'highlight'
    ? css`
        transition: background-color 200ms;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        &:active {
          background-color: ${vars.$semantic.color.grayPressed};
          transition: background-color 0s;
        }
      `
    : css`
        transition: transform 300ms;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        &:active {
          transform: translateY(-0.125rem) scale(0.95);
          transition: 300ms;
        }
      `
