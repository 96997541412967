import { karrotBridge } from '@src/bridge'
import { EmptyOrError } from '@src/react-utils/components'
import { Screen, useActivity, useNavigator } from '@src/stackflow'

export const DaangnMapEndOfService = () => {
  const { pop } = useNavigator()
  const { isRoot } = useActivity()

  const close = () => {
    if (isRoot) {
      karrotBridge.closeRouter({})
    } else {
      pop()
    }
  }

  return (
    <Screen
      appBar={{
        border: false,
      }}>
      <EmptyOrError
        body={`당근지도 서비스가 종료되었어요.\n더 좋은 서비스로 찾아올게요!`}
        CTAButton={{
          label: '뒤로가기',
          onClick: close,
        }}
      />
    </Screen>
  )
}
