import styled from '@emotion/styled'
import IconRemoveCircleFill from '@karrotmarket/karrot-ui-icon/lib/react/IconRemoveCircleFill'
import { vars } from '@seed-design/design-token'
import { forwardRef, MouseEvent, InputHTMLAttributes, ReactNode } from 'react'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  onClearInputValue?: () => void
  appendLeft?: ReactNode
  appendRight?: ReactNode
  invalid?: boolean
  isSeed?: boolean
}

const TextInput = forwardRef<HTMLInputElement, Props & StyleProps>(
  (
    {
      width,
      invalid,
      appendLeft,
      appendRight,
      onClearInputValue,
      value,
      className,
      borderColor,
      backgroundColor,
      clearButtonColor,
      clearButtonSize,
      onFocus,
      onBlur,
      ...inputProps
    },
    ref
  ) => {
    const renderRightElement = () => {
      if (!value || (value && !onClearInputValue)) {
        return appendRight && <RightElement>{appendRight}</RightElement>
      } else if (onClearInputValue) {
        const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          e.preventDefault()
          onClearInputValue()
        }
        return (
          <ClearButton type="button" onClick={handleClick}>
            <IconRemoveCircleFill
              width="1.125rem"
              height="1.125rem"
              color={clearButtonColor || vars.$scale.color.gray600}
            />
          </ClearButton>
        )
      }
    }

    return (
      <Container
        width={width}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        invalid={invalid}
        className={className}>
        {appendLeft && <LeftElement>{appendLeft}</LeftElement>}
        <Input
          type={inputProps.type ?? 'text'}
          ref={ref}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          {...inputProps}
        />
        {renderRightElement()}
      </Container>
    )
  }
)

interface StyleProps {
  width?: string | number
  borderColor?: string
  backgroundColor?: string
  clearButtonColor?: string
  clearButtonSize?: string
  isSeed?: boolean
}

const Container = styled.div<StyleProps & { invalid?: boolean }>`
  height: 3.25rem;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  border: 1px solid
    ${({ invalid, borderColor }) =>
      invalid ? vars.$semantic.color.danger : borderColor ? borderColor : vars.$scale.color.gray400};
  box-sizing: border-box;
  border-radius: 0.375rem;
  background: ${({ invalid, backgroundColor }) =>
    invalid ? vars.$scale.color.redAlpha50 : backgroundColor ? backgroundColor : vars.$scale.color.gray00};

  &:focus {
    border: 1px solid ${vars.$scale.color.gray900};
  }
  &:disabled {
    border: 1px solid ${({ borderColor }) => borderColor};
    background: ${vars.$scale.color.gray400};
    color: ${vars.$scale.color.gray500};
  }
`

const Input = styled.input`
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  ${vars.$semantic.typography.label2Regular};
  color: ${vars.$scale.color.gray900};

  &::placeholder {
    color: ${vars.$scale.color.gray500};
  }

  /** Disable default appearance of input elements in WebKit and iOS */
  &[type='search'] {
    -webkit-appearance: textfield;
  }
  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }
`

const ClearButton = styled.button`
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LeftElement = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.375rem 0 0;
`
const RightElement = styled.div`
  padding: 0 0 0 5px;
  display: flex;
  align-items: center;
`

export default TextInput
