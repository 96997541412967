import { KarrotBridgeSchema } from '@daangn/karrotbridge/lib/__generated__/makeKarrotBridge'

import { Coordinates } from '@src/types/global'

export interface UserInfo {
  id: string
  nickname: string
  authToken: string
  region: {
    id: number
    name: string
    centerCoordinates?: Coordinates
    center_coordinates?: Coordinates
  }
}
export default class KarrotBridgeCache {
  private currentPosition = null as KarrotBridgeSchema['ReqGeolocationCurrentPositionResponse'] | null
  private infoUser = null as KarrotBridgeSchema['ReqInfoUserResponse'] | null

  setCurrentPosition(currentPosition: KarrotBridgeSchema['ReqGeolocationCurrentPositionResponse']) {
    this.currentPosition = currentPosition
  }
  getCurrentPosition() {
    return this.currentPosition
  }

  setInfoUser(infoUser: KarrotBridgeSchema['ReqInfoUserResponse']) {
    this.infoUser = infoUser
  }
  getInfoUser() {
    return this.infoUser
  }
}
