import { send } from '@stackflow/compat-await-push'
import { useCallback } from 'react'
import { useRecoilValue, useRecoilCallback } from 'recoil'

import { karrotBridge } from '@src/bridge'
import { CHECKIN_SCHEME } from '@src/constants/url'
import { checkInRegionInfoAtom } from '@src/place-utils/region/store'
import { userInfoAtom } from '@src/place-utils/user/store'
import { AlertDialog } from '@src/react-utils/components/AlertDialog'
import { useActivity, useNavigator } from '@src/stackflow'

export type CheckinAlertDialogSendData = {
  checkinStatus: 'success' | 'failed'
}
export const CheckinAlertDialog = () => {
  const { id: activityId } = useActivity()
  const { asyncPushScheme, pop } = useNavigator()
  const { name } = useRecoilValue(checkInRegionInfoAtom)

  const onCheckinSuccess = useCallback(() => {
    const data: CheckinAlertDialogSendData = { checkinStatus: 'success' }
    send({ activityId, data })
    pop()
  }, [activityId, pop])

  const onCheckinFailed = useCallback(() => {
    const data: CheckinAlertDialogSendData = { checkinStatus: 'failed' }
    send({ activityId, data })
    pop()
  }, [activityId, pop])

  const handleCheckin = useRecoilCallback(
    ({ set }) =>
      async () => {
        await asyncPushScheme(CHECKIN_SCHEME)

        const newCheckinCount = (await karrotBridge.getUserInfo({})).info.user.displayRegionCheckinsCount ?? 0
        const checkedIn = newCheckinCount > 0

        if (checkedIn) {
          set(userInfoAtom, (prev) => ({ ...prev, displayRegionCheckinsCount: newCheckinCount }))
          onCheckinSuccess()
        } else {
          onCheckinFailed()
        }
      },
    [asyncPushScheme, onCheckinFailed, onCheckinSuccess]
  )

  const handleCancel = () => {
    onCheckinFailed()
  }

  return (
    <AlertDialog
      contents={`맛집에 공감하려면 ${name}의 동네인증이 필요해요.`}
      CTAButton={{
        label: '지금 동네인증 하기',
        onClick: handleCheckin,
      }}
      subButton={{
        label: '취소',
        onClick: handleCancel,
      }}
    />
  )
}
