import { RefObject, useEffect, useState } from 'react'

import { THEME } from '@src/stackflow/styles'

const ANIMATION_DURATION = THEME === 'cupertino' ? 350 : 270

interface Parameters<T> {
  /**
   * element to be spotlighted
   */
  itemElement: RefObject<T>
  /**
   * spotlight condition
   */
  shouldSpotlight: boolean
  /**
   * spotlight duration (in ms)
   */
  duration?: number
  /**
   * function to call before spotlight
   */
  onBeforeSpotlight?: () => void
  /**
   * function to call after spotlight
   */
  onAfterSpotlight?: () => void
}
const useSpotlightListItem = <T extends HTMLElement>({
  itemElement,
  shouldSpotlight,
  duration = 1000,
  onBeforeSpotlight,
  onAfterSpotlight,
}: Parameters<T>) => {
  const [animateHighlight, setAnimateHighlight] = useState(false)

  useEffect(() => {
    if (shouldSpotlight && itemElement.current) {
      onBeforeSpotlight?.()

      const timerId = setTimeout(() => {
        if (itemElement.current) {
          itemElement.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
        setAnimateHighlight(true)
      }, ANIMATION_DURATION)

      return () => clearTimeout(timerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemElement, onBeforeSpotlight])

  useEffect(() => {
    if (!animateHighlight) return

    const timerId = setTimeout(() => {
      setAnimateHighlight(false)
      onAfterSpotlight?.()
    }, duration + ANIMATION_DURATION)

    return () => clearTimeout(timerId)
  }, [animateHighlight, onAfterSpotlight, duration])

  return animateHighlight
}

export default useSpotlightListItem
