import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { MouseEvent } from 'react'

import { useLogEvent } from '@src/react-utils/components/Analytics'
import { useNavigator } from '@src/stackflow'

interface Props {
  poiId: string
  distance?: number
}
export const FailedVisit = ({ poiId, distance }: Props) => {
  const { push } = useNavigator()
  const logEvent = useLogEvent()

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation()

    logEvent({ name: 'click_fail_visit', params: { distance: distance ? Math.round(distance) : null } })
    push('winter_snack_modal', { modalId: 'failedVisitBottomSheet', selectedPoiId: poiId })
  }
  return <Text onClick={handleClick}>방문에 실패했나요?</Text>
}

const Text = styled.a`
  text-decoration-line: underline;
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.label5Regular};
`
