import { useContext } from 'react'

import ToastContext from './context'

function useToast() {
  const { addToast: showToast } = useContext(ToastContext)

  return { showToast }
}

export default useToast
