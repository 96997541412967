import { BoxButton, TextButton } from '@daangn/sprout-components-button'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { Fragment } from 'react'
import Lottie from 'react-lottie'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import roundCheckAnimation from '@src/assets/lottie/round-check.json'
import { karrotBridge } from '@src/bridge'
import { getMatchingValues, removeDuplicates } from '@src/js-utils/array'
import { extractValuesByKey } from '@src/js-utils/object'
import { selectedPoiInfoAtom } from '@src/place-utils/poi/PoiInput/state'
import { checkInRegionInfoAtom } from '@src/place-utils/region/store'
import { useExistingActivities, useNavigator } from '@src/stackflow'
import { safeAreaPadding } from '@src/styles/safeArea'

import { OPEN_REGIONS } from '../../constants'
import { myNativeReviewsSelector } from '../../store'
import { Layout as LayoutComponent } from '../components'
import { useStepForm } from '../hooks/useStepForm'

export const Outro = () => {
  const queryParams = useActivityParams<{ region?: string; entry?: string }>()
  const { variation } = useStepForm()
  const { pop, replace } = useNavigator()
  const activities = useExistingActivities()

  const checkInRegionInfo = useRecoilValue(checkInRegionInfoAtom)
  const checkInRegionParentIds = removeDuplicates(
    extractValuesByKey(checkInRegionInfo, ['id', 'name1Id', 'name2Id', 'name3Id', 'parentId'])
  )

  const title =
    variation === 'gift_card'
      ? `맛집을 추천해주셔서 감사해요!\n상품권에 당첨되면 채팅으로 알려드릴게요.`
      : '맛집을 추천해주셔서 감사해요!'
  const primaryButtonText = variation === 'gift_card' ? '다른 맛집 추천하고 당첨율 높이기' : '다른 맛집도 추천하기'
  const captions =
    variation === 'gift_card'
      ? ['추천한 가게가 맛집으로 등록되면 알림으로 알려드릴게요.', '상품권 미당첨자는 별도의 채팅을 드리지 않아요.']
      : ['추천한 가게가 맛집으로 등록되면 알림으로 알려드릴게요.']

  const isEnteredFromList =
    activities[activities.length - 2]?.name === 'native_review_list' || queryParams?.entry === 'native_review_list'
  const isOpenRegion = getMatchingValues(checkInRegionParentIds, OPEN_REGIONS).hasMatches

  const reset = useRecoilCallback(
    ({ refresh, reset }) =>
      () => {
        refresh(myNativeReviewsSelector)
        reset(selectedPoiInfoAtom)
      },
    []
  )

  const handleNewReview = () => {
    reset()
    replace('native_review_form', { step: '1', region: queryParams.region })
  }

  const handleExit = () => {
    reset()

    if (isEnteredFromList) {
      pop()
    } else {
      replace('native_review_list', {})
    }
  }

  return (
    <>
      <Layout>
        <Lottie
          options={{
            animationData: roundCheckAnimation,
            loop: false,
          }}
          width="6.25rem"
          height="6.25rem"
          style={{
            marginBottom: '1rem',
          }}
        />
        <Title>
          {title.split('\n').map((text, idx) => (
            <Fragment key={idx}>
              {text}
              <br />
            </Fragment>
          ))}
        </Title>
        <Captions length={captions.length}>
          {captions.map((caption, idx) => (
            <li key={idx}>
              {captions.length > 1 && <Dot />}
              <span>{caption}</span>
            </li>
          ))}
        </Captions>
      </Layout>
      <ButtonGroup>
        <BoxButton isDisabled={false} variant="primary" size="xlarge" width="100%" onClick={handleNewReview}>
          {primaryButtonText}
        </BoxButton>
        {isEnteredFromList || isOpenRegion ? (
          <TextButton variant="secondary" size="small" bold onClick={handleExit} style="basic">
            이웃이 추천한 맛집 보러가기
          </TextButton>
        ) : (
          <TextButton variant="secondary" size="small" bold onClick={() => karrotBridge.closeRouter({})} style="basic">
            나가기
          </TextButton>
        )}
      </ButtonGroup>
    </>
  )
}

const Layout = styled(LayoutComponent)({
  alignItems: 'center',
  gap: 0,
})
const Title = styled.h3([
  vars.$semantic.typography.title3Bold,
  {
    color: vars.$scale.color.gray900,
    textAlign: 'center',
  },
])
const Captions = styled.ul<{ length: number }>(({ length }) => [
  vars.$semantic.typography.caption1Regular,
  {
    marginTop: length <= 1 ? '0.5rem' : '0.75rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.125rem',
    color: vars.$scale.color.gray600,

    '& > li': {
      listStyle: 'none',
      display: 'flex',
      alignItems: 'center',
    },
  },
])
const Dot = styled.span({
  marginRight: '0.375rem',
  width: '0.125rem',
  minWidth: '0.125rem',
  height: '0.125rem',
  minHeight: '0.125rem',
  borderRadius: '50%',
  backgroundColor: vars.$scale.color.gray600,
})

const ButtonGroup = styled.div([
  {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    position: 'fixed',
    bottom: 0,
    padding: '1rem',
  },
  safeAreaPadding('bottom', '1rem'),
])
