export function removeDuplicates<T>(array: T[], removalOption?: 'earlier' | 'later'): T[] {
  const uniqueSet = new Set<T>()
  const result: T[] = []

  if (removalOption === 'earlier') {
    for (let i = 0; i < array.length; i++) {
      const value = array[i]
      if (!uniqueSet.has(value)) {
        uniqueSet.add(value)
        result.push(value)
      }
    }
  } else if (removalOption === 'later') {
    for (let i = array.length - 1; i >= 0; i--) {
      const value = array[i]
      if (!uniqueSet.has(value)) {
        uniqueSet.add(value)
        result.unshift(value)
      }
    }
  } else {
    for (const value of array) {
      if (!uniqueSet.has(value)) {
        uniqueSet.add(value)
        result.push(value)
      }
    }
  }

  return result
}
