import styled from '@emotion/styled'
import IconChevronRightRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightRegular'
import { vars } from '@seed-design/design-token'
import { useMemo } from 'react'

import { BizAccountType, CouponEventType, CouponStateReturnValue, CouponType } from '../../types'
import { isCouponManagerViewUiDisabled, isCouponUserViewUiDisabled } from '../../utils/coupon-util'
import { UiDisableProps } from '../CouponCardBase'
import CouponCardType from '../CouponCardType'
import CouponStatus from '../CouponStatus'
import DateAndCountSection from '../DateAndCountSection'

export interface OfflineCouponCardInfoProps {
  isManager: boolean
  couponStatus: CouponStateReturnValue
  name: string
  target: CouponEventType
  issuedCount: number | null
  claimedCount: number
  couponType: CouponType
  expiresAt?: string
  couponBoxId: number
  bizAccountType?: BizAccountType
  onClickCouponContent?: () => void
}

const OfflineCouponCardInfo = ({
  issuedCount,
  claimedCount,
  onClickCouponContent,
  couponStatus,
  isManager,
  name,
  expiresAt,
  target,
  couponType,
  couponBoxId,
  bizAccountType,
}: OfflineCouponCardInfoProps) => {
  const uiDisabled = useMemo(
    () => (isManager ? isCouponManagerViewUiDisabled(couponStatus) : isCouponUserViewUiDisabled(couponStatus)),
    [couponStatus, isManager]
  )

  return (
    <S_Base uiDisabled={uiDisabled} onClick={onClickCouponContent}>
      <S_CouponWrapper>
        <S_CouponContentWrapper>
          <CouponStatus
            isManager={isManager}
            issuedCount={issuedCount}
            claimedCount={claimedCount}
            couponStatus={couponStatus}
            uiDisabled={uiDisabled}
          />
          <S_CouponName uiDisabled={uiDisabled}>
            <span>{name}</span>
            {onClickCouponContent && (
              <S_ChevronWrapper>
                <IconChevronRightRegular
                  width="18"
                  height="18"
                  color={uiDisabled ? vars.$scale.color.gray500 : vars.$scale.color.gray600}
                />
              </S_ChevronWrapper>
            )}
          </S_CouponName>

          <CouponCardType
            isManager={isManager}
            target={target}
            uiDisabled={uiDisabled}
            couponType={couponType}
            couponBoxId={couponBoxId}
            bizAccountType={bizAccountType}
          />

          <DateAndCountSection
            isManager={isManager}
            issuedCount={issuedCount}
            claimedCount={claimedCount}
            expiresAt={expiresAt}
            uiDisabled={uiDisabled}
            couponStatus={couponStatus}
          />
        </S_CouponContentWrapper>
      </S_CouponWrapper>
    </S_Base>
  )
}

const S_Base = styled.div<UiDisableProps>``
const S_CouponWrapper = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
`
const S_CouponContentWrapper = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
`
const S_CouponName = styled.h6<UiDisableProps>`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: ${({ uiDisabled }) => (uiDisabled ? vars.$scale.color.gray500 : vars.$scale.color.gray900)};
  margin: 0 0 0.25rem;
  text-align: start;
`
const S_ChevronWrapper = styled.span`
  margin-left: 0.25rem;
`

export default OfflineCouponCardInfo
