import { getNativeReviewV2 } from '@src/apis/__generated__'
// import { IS_PROD } from '@src/constants/environmentConstants'

export const MENU_FILTER_TO_CURATION_CATEGORY_ID_MAPPER = [
  { name: '한식', key: 166, filterId: 1 },
  { name: '양식', key: 171, filterId: 2 },
  { name: '중식', key: 169, filterId: 3 },
  { name: '분식', key: 168, filterId: 4 },
  { name: '일식', key: 170, filterId: 5 },
  { name: '치킨', key: 173, filterId: 6 },
  { name: '술집', key: 167, filterId: 7 },
]

export const RESIDENCY_PERIOD_FILTER_TO_RESIDENCY_YEARS = [
  { name: '10년 이상 거주', key: 10, filterId: 1 },
  { name: '15년 이상 거주', key: 15, filterId: 2 },
  { name: '20년 이상 거주', key: 20, filterId: 3 },
  { name: '30년 이상 거주', key: 30, filterId: 4 },
] as {
  name: string
  key: Parameters<typeof getNativeReviewV2>[0]['params']['residencyYears']
  filterId: number
}[]

export const VISIT_COUNT_FILTER_TO_VISIT_COUNT_THRESHOLD_MAPPER = [
  { name: '5번 이상', key: 5, filterId: 1 },
  { name: '10번 이상', key: 10, filterId: 2 },
  { name: '30번 이상', key: 30, filterId: 3 },
] as {
  name: string
  key: Parameters<typeof getNativeReviewV2>[0]['params']['visitCountThreshold']
  filterId: number
}[]

export const REGION_LEVEL_TWO_FILTER_TO_REGION_LEVEL_TWO_MAPPER = [
  { name: '인천 전체', key: undefined, filterId: 0 },
  { name: '강화군', key: 968, filterId: 1 },
  { name: '계양구', key: 933, filterId: 2 },
  { name: '남동구', key: 890, filterId: 3 },
  { name: '동구', key: 842, filterId: 4 },
  { name: '미추홀구', key: 854, filterId: 5 },
  { name: '부평구', key: 910, filterId: 6 },
  { name: '서구', key: 946, filterId: 7 },
  { name: '연수구', key: 876, filterId: 8 },
  { name: '옹진군', key: 983, filterId: 9 },
  { name: '중구', key: 826, filterId: 10 },
]

/**
 * 상위 10% 뱃지 기준은 30년 이상 거주
 * https://daangn.slack.com/archives/CAXBSNGJY/p1687236942498989?thread_ts=1687174985.045379&cid=CAXBSNGJY
 */
export const TOP_TIER_BADGE_YEARS_OF_RESIDENCE = 30
/**
 * 토박이 뱃지 기준은 상위 10% 뱃지 기준과 동일
 */
export const NATIVE_BADGE_YEARS_OF_RESIDENCE = TOP_TIER_BADGE_YEARS_OF_RESIDENCE

export const REGION = '인천'
export const SERVICE_NAME = '이웃 추천'

type RegionId = number
type RegionMap = Record<
  RegionId,
  {
    name: string
  }
>

export const REGION_MAP: RegionMap = {
  //강원도 춘천시
  1875: {
    name: '춘천',
  },
  //대구 광역시
  675: {
    name: '대구',
  },
  //제주 특별시
  3811: {
    name: '제주',
  },
  //부산 광역시
  451: {
    name: '부산',
  },
  //전라북도 전주시
  // [IS_PROD ? 4208 : 2489]: {
  //   name: '전주',
  // },
  //전라남도 순천시
  2804: {
    name: '순천',
  },
  //강원도 속초시
  1969: {
    name: '속초',
  },
  //전라남도 여수시
  2773: {
    name: '여수',
  },
  //경상남도 포항시
  // [IS_PROD ? 4209 : 3094]: {
  //   name: '포항',
  // },
  //충청남도 태안군
  2479: {
    name: '태안',
  },
  // 전주시 덕진구
  2509: {
    name: '전주',
  },
  // 전주시 완산구
  2490: {
    name: '전주',
  },
  // 포항시 북구
  3110: {
    name: '포항',
  },
  // 포항시 남구
  3095: {
    name: '포항',
  },
}

export const OPEN_REGIONS = [
  // 인천 (level 1)
  825,
]
