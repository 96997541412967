import {
  WinterSanckMapResponseData,
  WinterSnackMapItem,
  getWinterSnackPoisV2,
} from '@daangn/local-business-network/lib/poi'
import { createLoadableFamily, isDefaultLoadable } from '@daangn/recoil-loadable'
import { atom, DefaultValue, selectorFamily } from 'recoil'

import { CreateSerializableParam } from '@src/types/recoil'

import { generateWinterSnackPageRecoilKey } from './keys'

type WinterSnackRequestParameters = Parameters<typeof getWinterSnackPoisV2>['0']['params']
interface WinterSnackFetchVariables extends Omit<WinterSnackRequestParameters, 'xAuthToken'> {
  authToken: string
}

export const winterSnackPoisLoadable = createLoadableFamily<
  WinterSnackMapItem[] | null,
  CreateSerializableParam<WinterSnackFetchVariables>,
  WinterSanckMapResponseData | null
>({
  getStore({ atomFamily }) {
    return atomFamily({
      key: generateWinterSnackPageRecoilKey('pois'),
    })
  },
  async fetch({ variables }) {
    const { regionId, coordinates, authToken } = variables

    if (!regionId || !authToken) return null

    const { data } = await getWinterSnackPoisV2({
      params: {
        xAuthToken: authToken,
        regionId,
        coordinates,
      },
    })
    return data.data
  },
  onSuccess({ response, prevState, recoilInterface: { set } }) {
    if (!response) return null

    set(latestSearchResultAtom, response.items)

    return [...(prevState ?? []), ...response.items]
  },
})

export const filteredPoisSelectorFamily = selectorFamily({
  key: generateWinterSnackPageRecoilKey('filteredPois'),
  get:
    ({ filterId, ...variables }: { regionId: number; coordinates?: string; authToken: string; filterId: string }) =>
    ({ get }) => {
      const pois = get(winterSnackPoisLoadable.store(variables))

      if (isDefaultLoadable(pois) || !pois) return []

      if (filterId === 'all') return pois

      return pois.filter(({ poi }) => {
        return poi.attributes.find((attribute) => attribute.id.toString() === filterId)
      })
    },
})

export const poiByIdSelectorFamily = selectorFamily<
  WinterSnackMapItem | null,
  { regionId: number; coordinates?: string; authToken: string; poiId: string }
>({
  key: generateWinterSnackPageRecoilKey('poiById'),
  get:
    ({ poiId, ...variables }) =>
    ({ get }) => {
      const pois = get(winterSnackPoisLoadable.store(variables))

      if (isDefaultLoadable(pois) || !pois) return null

      return pois.find(({ poi }) => poi.id === poiId) ?? null
    },
  set:
    ({ poiId, ...variables }) =>
    ({ get, set }, newValue) => {
      const pois = get(winterSnackPoisLoadable.store(variables))

      if (isDefaultLoadable(pois) || !pois || newValue instanceof DefaultValue || !newValue) return

      const poiIndex = pois.findIndex(({ poi }) => poi.id === poiId)
      if (poiIndex === -1) return

      const newPois = pois.slice()
      newPois.splice(poiIndex, 1, newValue)

      set(winterSnackPoisLoadable.store(variables), newPois)
    },
})

export const latestSearchResultAtom = atom<WinterSnackMapItem[]>({
  key: generateWinterSnackPageRecoilKey('lastSearchResult'),
  default: [],
})

export const filteredLatestSearchResultSelectorFamily = selectorFamily({
  key: generateWinterSnackPageRecoilKey('lastFilteredSearchRestult'),
  get:
    ({ filterId }: { filterId: string }) =>
    ({ get }) => {
      const latestSearchResult = get(latestSearchResultAtom)

      if (latestSearchResult.length === 0) {
        return []
      }

      if (filterId === 'all') {
        return latestSearchResult
      }

      const filteredLastSearchResult = latestSearchResult.filter(({ poi }) =>
        poi.attributes.find((attribute) => attribute.id.toString() === filterId)
      )

      return filteredLastSearchResult
    },
})
