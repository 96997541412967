import { ImageUploader, ImageUploadPicture } from '@src/react-utils/components/Image'

import { useReviewEdit } from './contexts/ReviewEditProvider'
import { ImageUploaderWrapper } from '../Form/RestaurantReview'
import { Image } from '../Form/types/restaurant-review-form'

interface Props {
  initialValue: {
    images: Image[]
  }
}
export const Images = ({ initialValue }: Props) => {
  const { updateForm } = useReviewEdit()

  const onUpdateImage = (pictures: ImageUploadPicture[]) => {
    const images = pictures.map((picture) => ({
      id: picture.id,
      url: picture.url,
    }))

    updateForm({
      review: {
        imageIds: images.map((image) => image.id),
      },
    })
  }

  return (
    <ImageUploaderWrapper id="imageIds" label="">
      <ImageUploader
        maxCount={10}
        onUpdateImage={onUpdateImage}
        images={
          initialValue.images.length > 0 ? initialValue.images.map((image) => ({ id: image.id, url: image.url })) : []
        }
      />
    </ImageUploaderWrapper>
  )
}
