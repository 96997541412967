import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

import { UiDisableProps } from './CouponCardBase'
import CouponStatusBadge from './CouponStatusBadge'
import { CouponStateReturnValue } from '../types'

interface Props {
  isManager: boolean
  issuedCount: number | null
  claimedCount: number
  couponStatus: CouponStateReturnValue
  uiDisabled: boolean
}

const CouponStatus = ({ isManager, issuedCount, claimedCount, couponStatus, uiDisabled }: Props) => {
  if (!isManager) {
    return null
  }

  const hasIssuedCount = typeof issuedCount === 'number'
  const leftCounts = (issuedCount || 0) - (claimedCount || 0)

  return (
    <S_CouponStatusWrapper>
      <CouponStatusBadge status={couponStatus} />
      {hasIssuedCount && (
        <S_LeftCouponText uiDisabled={uiDisabled}>남은 쿠폰: {leftCounts.toLocaleString('ko-KR')}</S_LeftCouponText>
      )}
    </S_CouponStatusWrapper>
  )
}

const S_CouponStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  flex-wrap: wrap;
`
const S_LeftCouponText = styled.b<UiDisableProps>`
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: ${({ uiDisabled }) => (uiDisabled ? vars.$scale.color.gray500 : vars.$scale.color.carrot500)};
  white-space: nowrap;
`

export default CouponStatus
