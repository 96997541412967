type YearsMap = Record<number, { value: number; text: string }>
export const getYearsMap = (startYear: number, endYear?: number): YearsMap => {
  const currentYear = new Date().getFullYear()

  const start = startYear
  const end = endYear || currentYear
  const numOfYears = end - (end - start)

  const years = Array.from({ length: end - numOfYears + 1 }, (_, i) => numOfYears + i)

  const yearsMap = years.reduce((map, year, index) => {
    map[index] = {
      value: year,
      text: `${year}년`,
    }
    return map
  }, {} as YearsMap)
  return yearsMap
}
