import { useEffect } from 'react'

import { useReactionByResource } from '@src/place-utils/reaction/hooks/useSyncReactionByResource'
import { ReactionState } from '@src/place-utils/reaction/store'
import type { ResourceQueryParams } from '@src/place-utils/reaction/store/reaction-by-resource'
import { InfiniteScrollObserver } from '@src/react-utils/components/InfiniteScroll'

import { UserProfile } from './UserProfile'

interface Props extends ResourceQueryParams {
  users: NonNullable<ReactionState['reactedUsers']>
  totalCount: number
}
export const Contents = ({ users, totalCount, ...params }: Props) => {
  const { pagination, hasNextPage, setHasNextPage, fetchMore, initialize, useSyncEffect } =
    useReactionByResource(params)

  const onIntersecting = () => {
    setHasNextPage(false)

    if (!pagination) {
      initialize({ page: 2, perPage: 10 })
    } else {
      fetchMore()
    }
  }

  useEffect(() => {
    if (totalCount > 10) {
      setHasNextPage(true)
    }
  }, [setHasNextPage, totalCount])

  useSyncEffect()

  return (
    <>
      {users.map((user) => {
        return <UserProfile {...user} key={user.id} />
      })}
      {hasNextPage && <InfiniteScrollObserver onIntersecting={onIntersecting} />}
    </>
  )
}
