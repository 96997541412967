import styled from '@emotion/styled'
import IconRetryRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconRetryRegular'
import { vars } from '@seed-design/design-token'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { Filter } from './Filter'
import { nativeReviewFilterAtomFamily, FilterId, isNativeReviewFilterOnSelector } from '../../store'

export const Filters = () => {
  const filterIds: FilterId[] = []
  const showResetFilters = !!useRecoilValue(isNativeReviewFilterOnSelector)

  const handleResetFilter = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(nativeReviewFilterAtomFamily('list-menu-filter'))
        reset(nativeReviewFilterAtomFamily('list-residence-period-filter'))
        reset(nativeReviewFilterAtomFamily('list-visit-count-filter'))
        reset(nativeReviewFilterAtomFamily('list-region-level-two-filter'))
      },
    []
  )

  if (!filterIds.length) {
    return null
  }

  return (
    <Container>
      {showResetFilters && (
        <ResetFilter onClick={handleResetFilter}>
          <IconRetryRegular width={18} height={18} />
        </ResetFilter>
      )}
      {filterIds.map((filterId) => (
        <Filter key={filterId} id={filterId} />
      ))}
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  padding: '0.75rem 0',
})
const ResetFilter = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 0.5rem 0 0',
  height: 32,
  width: 32,
  backgroundColor: vars.$scale.color.gray100,
  borderRadius: '50%',
})
