import {
  CSSProperties,
  forwardRef,
  PropsWithChildren,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import type { Coordinates } from './types'
import useEventListenerEffect from './useEventListenerEffect'
import { useMap } from './useMap'

type WatermarkPosition = 'left' | 'top' | 'bottom' | 'right'

interface Props {
  id?: string
  className?: string
  style?: CSSProperties
  /**
   * center coordinates is required
   */
  center: Coordinates
  level?: number
  zoomable?: boolean
  draggable?: boolean
  watermarkPosition?: [WatermarkPosition] | [WatermarkPosition, WatermarkPosition]
  onMapLoaded?: () => void
  onClick?: () => void
  onZoomChanged?: () => void
  onDragEnd?: () => void
}

const Map = forwardRef<any, PropsWithChildren<Props>>(
  (
    {
      style,
      id = 'kakao-map',
      className = 'kakao-map',
      center,
      level,
      zoomable,
      draggable,
      watermarkPosition = ['bottom', 'left'],
      onMapLoaded,
      onClick,
      onZoomChanged,
      onDragEnd,
      children,
    },
    ref
  ) => {
    const { kakao, map, setMap } = useMap()
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const mapRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
      if (isLoaded) return

      const container = mapRef.current

      if (!container || !kakao) return

      const options = {
        center: new kakao.maps.LatLng(center.latitude, center.longitude),
        level: level,
        zoomable,
        draggable,
      }

      const kakaoMap = new kakao.maps.Map(container, options)

      setMap(kakaoMap)
      onMapLoaded?.()

      const kakaoWatermarkContainer = container.childNodes[1] as HTMLDivElement
      kakaoWatermarkContainer.childNodes[0]?.remove()
      if (kakaoWatermarkContainer) {
        const watermark = kakaoWatermarkContainer.childNodes[0] as HTMLDivElement
        if (watermark) {
          if (watermarkPosition.indexOf('left') !== -1) {
            kakaoWatermarkContainer.style.left = '0'
            watermark.style.float = 'left'
          }
          if (watermarkPosition.indexOf('right') !== -1) {
            kakaoWatermarkContainer.style.right = '0'
            watermark.style.float = 'right'
          }
          if (watermarkPosition.indexOf('bottom') !== -1) {
            kakaoWatermarkContainer.style.bottom = '-6px'
          }

          if (watermarkPosition.indexOf('top') !== -1) {
            kakaoWatermarkContainer.style.top = '1px'
          }
        }
      }

      setIsLoaded(true)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded, kakao, map])

    useLayoutEffect(() => {
      if (!map || !level) return

      map.setLevel(level)
    }, [level, map])

    useEventListenerEffect(map, 'click', onClick)
    useEventListenerEffect(map, 'dragend', onDragEnd)
    useEventListenerEffect(map, 'zoom_changed', onZoomChanged)

    useImperativeHandle(ref, () => map, [map])

    return (
      <div id={id} className={className} style={style} ref={mapRef}>
        {children}
      </div>
    )
  }
)

export default Map
