import { useActivityParams } from '@stackflow/react'
import { useCallback } from 'react'

import { useStepNavigator } from '@src/stackflow'

import { WinterSnackParams } from '../WinterSnackPage'

export const defaultFilter = {
  id: 'all',
  name: '전체',
}

export const useFilter = () => {
  const activityParams = useActivityParams<WinterSnackParams>()
  const { stepReplace } = useStepNavigator('winter_snack')

  const toggleFilter = useCallback(
    (id: number) => {
      if (id === -1) {
        stepReplace({
          filterId: defaultFilter.id,
        })

        return false
      }

      const isFilterToggledOn = activityParams.filterId !== id.toString()
      stepReplace({
        filterId: isFilterToggledOn ? id.toString() : defaultFilter.id,
      })

      return isFilterToggledOn
    },
    [activityParams.filterId, stepReplace]
  )

  return {
    selectedFilterId: activityParams.filterId,
    toggleFilter,
  }
}
