import { AlertDialog } from '@src/react-utils/components/AlertDialog'
import { useNavigator } from '@src/stackflow'

interface Props {
  name: string
}
export const BlockedBizReviewAlert = ({ name }: Props) => {
  const { pop } = useNavigator()

  return (
    <AlertDialog
      contents={`작성한 후기가 신고되어 ${name}에는 새로운 후기를 작성할 수 없어요.`}
      CTAButton={{
        label: '확인',
        onClick: pop,
      }}
    />
  )
}
