import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

export const S_Action = styled.button<{ uiDisabled: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  background: ${({ uiDisabled }) => (uiDisabled ? vars.$scale.color.gray100 : vars.$scale.color.carrotAlpha100)};
  min-width: 4.25rem;
`
export const S_CouponCardActionBase = styled.span<{ addOpacity?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${({ addOpacity }) => (addOpacity ? '45%' : 1)};
`
export const S_CouponCardActionName = styled.span<{ color: string }>`
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ color }) => color};
  margin-top: 0.25rem;
`
