import { useActivityParams } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { useLogEvent } from '@src/react-utils/components/Analytics'
import Slide from '@src/react-utils/components/Slides/Slide'
import Slides from '@src/react-utils/components/Slides/Slides'
import { getSafeAreaValue } from '@src/styles/utils'

import { Card } from '../Card'
import { CARD_HEIGHT, CARD_HEIGHT_WITHOUT_CONTENT } from '../constants/style'
import { useSelectedComment } from '../hooks/useSelectedComment'
import { ActivityParams } from '../index'
import { allCommentsAtomFamily } from '../state'

export const CardCarousel = () => {
  const logEvent = useLogEvent()
  const { storyArticleId, commentId } = useActivityParams<ActivityParams>()
  const { spotlightPoiOnMap } = useSelectedComment()

  const comments = useRecoilValue(allCommentsAtomFamily({ storyArticleId: +storyArticleId }))

  const focusedCardIndex = commentId ? comments.findIndex((comment) => comment.id === +commentId) : 0
  const focusedCard = comments[focusedCardIndex]
  const cardHeight = focusedCard.content ? CARD_HEIGHT : CARD_HEIGHT_WITHOUT_CONTENT

  const handleSwipeEnd = (index: number) => {
    logEvent({ name: 'click_swipe_poi_card', params: {} })

    spotlightPoiOnMap({
      commentId: comments[index].id.toString(),
      shouldPanTo: true,
    })
  }
  return (
    <Slides
      slideGap={0}
      slideMargin={0}
      maxLength={comments.length - 1}
      focusedCardIndex={focusedCardIndex}
      onSwipeCardEnd={handleSwipeEnd}
      height={cardHeight + parseInt(getSafeAreaValue('bottom'), 10)}>
      {comments.map((comment, idx) => {
        return (
          <Slide key={comment.id} index={idx}>
            <Card
              id={comment.id}
              author={{
                ...comment.writer,
                profileImage: comment.writer.profileImageUrl ?? undefined,
              }}
              regionName={comment.writer.writeRegion.name}
              createdAt={comment.createdAt}
              updatedAt={comment.updatedAt ?? undefined}
              poi={{
                ...comment.poi,
                name: comment.poi.title,
                category: comment.poi.category?.name ?? '',
                thumbnailImage: comment.poi.thumbnail?.imageUrl ?? undefined,
              }}
              content={comment.content ?? ''}
              isRecommended={comment.recommended ?? undefined}
            />
          </Slide>
        )
      })}
    </Slides>
  )
}
