import { atomFamily, selector } from 'recoil'

import { generateKey } from './key'

export type FilterId =
  | 'list-menu-filter'
  | 'list-residence-period-filter'
  | 'list-visit-count-filter'
  | 'list-region-level-two-filter'
interface Filter {
  isActive: boolean
  value:
    | {
        id: FilterId | string
        name: string
      }
    | undefined
}

export const FILTERS: Record<FilterId, { placeholder: string }> = {
  'list-menu-filter': {
    placeholder: '메뉴',
  },
  'list-residence-period-filter': {
    placeholder: '거주기간',
  },
  'list-visit-count-filter': {
    placeholder: '방문횟수',
  },
  'list-region-level-two-filter': {
    placeholder: '인천 전체',
  },
}

export const nativeReviewFilterAtomFamily = atomFamily<Filter, FilterId>({
  key: generateKey('native-review-filter-atom-family'),
  default: {
    isActive: false,
    value: undefined,
  },
})

export const isNativeReviewFilterOnSelector = selector({
  key: generateKey('is-native-review-filter-on-selector'),
  get: ({ get }) => {
    const menu = get(nativeReviewFilterAtomFamily('list-menu-filter'))
    const residencePeriod = get(nativeReviewFilterAtomFamily('list-residence-period-filter'))
    const visitCount = get(nativeReviewFilterAtomFamily('list-visit-count-filter'))
    const regionLevelTwo = get(nativeReviewFilterAtomFamily('list-region-level-two-filter'))

    return (
      (menu?.value && menu.value.id !== '0') ||
      (residencePeriod?.value && residencePeriod.value.id !== '0') ||
      (visitCount?.value && visitCount.value.id !== '0') ||
      (regionLevelTwo?.value && regionLevelTwo.value.id !== '0')
    )
  },
})
