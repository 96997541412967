import { ImpressionObserver } from '@daangn/webview-impression'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { forwardRef, useMemo, useRef } from 'react'
import { useRecoilValue } from 'recoil'

import { EXPERIMENT_enhancedReactionSelector } from '@src/place-utils/__generated__/experiments/enhancedReaction'
import ContentWithEllipsisMore from '@src/react-utils/components/ContentWithEllipsisMore'
import { ImageBoardNativeReview } from '@src/react-utils/components/Image'

import { AgreedButton, UpvoteButton } from './Buttons'
import Poi from './Poi'
import { Reaction } from './Reaction'
import { UserProfile } from './UserProfile'
import { useAnalytics } from '../../hooks'
import { NativeReviewItem } from '../../store'
import { getYearsOfResidence } from '../../utils'
import { ActivityParams } from '../index'

export const Review = forwardRef<
  HTMLDivElement | null,
  {
    nativeReview: NativeReviewItem
    index: number
  }
>(({ nativeReview, index }, ref) => {
  const { user, review, residenceInfo, displayInfo, poi } = nativeReview
  const yearsOfResidence = getYearsOfResidence(residenceInfo.startYearOfResidence)

  const logEvent = useAnalytics()
  const { highlight_review_id } = useActivityParams<ActivityParams>()

  const enhancedReactionExperimentSegment = useRecoilValue(EXPERIMENT_enhancedReactionSelector)

  const distance = displayInfo?.distance ? displayInfo.distance : undefined

  const contentRef = useRef<HTMLDivElement | null>(null)
  const isHighlighted = useMemo(() => highlight_review_id === review.id.toString(), [highlight_review_id, review.id])

  const handleClickUpvote = ({ isAlreadyClicked }: { isAlreadyClicked: boolean }) => {
    logEvent('click_helpful_button', {
      is_already_clicked: isAlreadyClicked,
      review_id: review.id,
      residence_year: yearsOfResidence,
      category: review.poi.category?.id ?? null,
      poi_id: review.poi.id,
      biz_id: review.poi.bizAccountId,
      visit_count: review.visitCount,
      image_exist: review.images.length > 0,
      image_count: review.images.length,
    })
  }

  const onClickAgreedReactionButton = ({ isAlreadyClicked }: { isAlreadyClicked: boolean }) => {
    logEvent('click_good_restaurant_button', {
      is_already_clicked: isAlreadyClicked,
      review_id: review.id,
      residence_year: yearsOfResidence,
      category: review.poi.category?.id ?? null,
      poi_id: review.poi.id,
      biz_id: review.poi.bizAccountId,
      visit_count: review.visitCount,
      image_exist: review.images.length > 0,
      image_count: review.images.length,
    })
  }

  const handleClickReactionDisplay = () => {
    logEvent('click_reaction_display', {
      review_id: review.id,
      residence_year: yearsOfResidence,
      category: review.poi.category?.id ?? null,
      poi_id: review.poi.id,
      biz_id: review.poi.bizAccountId,
      visit_count: review.visitCount,
      image_exist: review.images.length > 0,
      image_count: review.images.length,
    })
  }

  return (
    <ImpressionObserver
      observeTargetRef={contentRef}
      log={{
        name: 'show_tobaki_impression_card',
        params: {},
      }}>
      <Container
        isHighlighted={isHighlighted}
        ref={(element) => {
          contentRef.current = element
          if (ref) {
            typeof ref === 'object' ? (ref.current = element) : ref(element)
          }
        }}>
        <UserProfile
          index={index}
          user={user}
          regionName={residenceInfo.region.name}
          yearsOfResidence={yearsOfResidence}
          visitCount={review.visitCount}
          reviewId={review.id}
        />
        <Wrapper>
          <Content>
            <ContentWithEllipsisMore fontSizeStyle="1rem" lineHeightStyle="1.35rem" lineCount={3}>
              {review.content}
            </ContentWithEllipsisMore>
            {review.images.length > 0 && (
              <div>
                <ImageBoardNativeReview images={review.images.map((image: any) => image.thumbnail)} />
              </div>
            )}
          </Content>
          <Poi poi={review.poi} distance={distance} index={index} />
          {(() => {
            switch (enhancedReactionExperimentSegment) {
              case 'treatment-A':
                return (
                  <Reaction
                    poiId={poi.id}
                    onClickButton={onClickAgreedReactionButton}
                    onClickReactionDisplay={handleClickReactionDisplay}
                  />
                )
              case 'treatment-B':
                return (
                  <ButtonGroup>
                    <AgreedButton
                      resourceId={poi.id}
                      resourceType="POI"
                      type="MATJIB"
                      onClick={onClickAgreedReactionButton}
                    />
                  </ButtonGroup>
                )
              case 'treatment-C':
                return (
                  <Reaction
                    poiId={poi.id}
                    onClickButton={onClickAgreedReactionButton}
                    onClickReactionDisplay={handleClickReactionDisplay}
                  />
                )
              case 'control':
              default:
                return (
                  <ButtonGroup>
                    <UpvoteButton reviewId={review.id.toString()} onClick={handleClickUpvote} />
                    <AgreedButton
                      resourceId={poi.id}
                      resourceType="POI"
                      type="MATJIB"
                      onClick={onClickAgreedReactionButton}
                    />
                  </ButtonGroup>
                )
            }
          })()}
        </Wrapper>
      </Container>
    </ImpressionObserver>
  )
})

const Container = styled.div<{ isHighlighted: boolean }>([
  {
    margin: '0 -1rem',
    padding: '1.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    transition: '500ms background',
    background: vars.$semantic.color.paperDefault,
    ':first-of-type': {
      padding: '0 1rem 1.5rem',
    },
  },
  ({ isHighlighted }) =>
    isHighlighted && {
      background: vars.$semantic.color.paperAccent,
    },
])

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.25rem',
})

const Content = styled.div([
  vars.$semantic.typography.bodyM1Regular,
  {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
  },
])

const ButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',

  '& > :not(:last-child)': {
    margin: '0 0.5rem 0 0',
  },
})
