import styled from '@emotion/styled'
import IconChevronRightRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightRegular'
import { ActivityComponentType, useActivityParams } from '@stackflow/react'
import { useRef } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { karrotBridge } from '@src/bridge'
import { selectedPoiInfoAtom } from '@src/place-utils/poi/PoiInput/state'
import { userInfoAtom } from '@src/place-utils/user/store'
import { Screen, useActivity, useNavigator } from '@src/stackflow'

import { StepInfo } from './components'
import { StepFormProvider } from './contexts/StepFormProvider'
import { newForm, revisitForm } from './data/native-restaurant-review-form'
import { useStepForm } from './hooks/useStepForm'
import { StepFormData } from './types/form'
import { useRegionInfo } from '../hooks/useRegionInfo'

const BackIcon = styled(IconChevronRightRegular)({
  transform: 'rotate(180deg)',
  width: '1.5rem',
  height: '1.5rem',
  marginLeft: '0.5rem',
})

const BackButton = () => {
  const { isRoot } = useActivity()
  const { pop } = useNavigator()
  const { step, go } = useStepForm()

  const reset = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(selectedPoiInfoAtom)
      },
    []
  )

  const handleBack = () => {
    const isFirstStep = step.current <= 1
    const isLastStep = step.current >= step.total

    if (isFirstStep || isLastStep) {
      reset()

      if (isRoot) {
        karrotBridge.closeRouter({})
      } else {
        pop()
      }
    } else {
      go.prev()
    }
  }

  return <BackIcon onClick={handleBack} />
}
interface NativeReviewFormProps {
  form: StepFormData
}
const NativeReviewForm = ({ form }: NativeReviewFormProps) => {
  const {
    step: { current: currentStep, total: totalStep },
  } = useStepForm()

  return (
    <Screen
      appBar={{
        border: false,
        backButton: {
          render: () => <BackButton />,
        },
        closeButton: {
          render: () => <BackButton />,
        },
        appendRight: () => <StepInfo currentStep={currentStep} totalStep={totalStep} omitLastStep />,
      }}>
      {form[currentStep].Component}
    </Screen>
  )
}

export type NativeReviewFormPageActivityParams = {
  step: string
  referrer?: string
  region?: string
}
export const NativeReviewFormPage: ActivityComponentType<NativeReviewFormPageActivityParams> = () => {
  const { name: activityName } = useActivity()
  const { entry } = useActivityParams<{ entry?: string }>()

  const { displayRegion: _displayRegion } = useRegionInfo('form')
  const displayRegion = useRef(_displayRegion).current

  const user = useRecoilValue(userInfoAtom)

  const isRevisit = entry === activityName || !!displayRegion.me?.hasResidenceInfo
  const form = isRevisit ? revisitForm : newForm

  return (
    <StepFormProvider
      totalStep={Object.keys(form).length}
      initialFormValue={{
        user: {
          id: user.id,
          nickname: user.nickname,
          region: {
            id: displayRegion.region.id,
            name: displayRegion.region.name,
            startYearOfResidence: displayRegion.me?.startYearOfResidence ?? null,
          },
        },
        poi: null,
        review: null,
      }}>
      <NativeReviewForm form={form} />
    </StepFormProvider>
  )
}
