import { useFormik } from 'formik'

import { ImageUploader, ImageUploadPicture } from '@src/react-utils/components/Image'

import { FormItemWrapper } from '../FormItemWrapper'
import type { Field } from '../types'

interface Props {
  field: Field<'pricing' | 'photos'>
  setFieldValue: ReturnType<typeof useFormik>['setFieldValue']
  images?: {
    id: string
    url: string
  }[]
}

export const ImageInput = ({ field, images, setFieldValue }: Props) => {
  const { id, isRequired } = field

  const labelMap: Record<Props['field']['id'], string> = {
    pricing: '가격표',
    photos: '사진',
  }

  const subLabelMap: Record<Props['field']['id'], string> = {
    pricing: '주요 서비스, 메뉴, 판매 물건의 가격표를 등록해주세요.',
    photos: '명함, 간판 등의 사진이 있다면 등록해주세요.',
  }

  return (
    <FormItemWrapper key={id} id={id} label={labelMap[id]} subLabel={subLabelMap[id]} isOptional={!isRequired}>
      <ImageUploader
        maxCount={5}
        images={images}
        onUpdateImage={(pictures: ImageUploadPicture[]) => {
          const pictureIds = pictures.map(({ id }) => id)
          setFieldValue(id, pictureIds)
        }}
      />
    </FormItemWrapper>
  )
}
