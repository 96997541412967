import styled from '@emotion/styled'

import { FlexProps } from './Flex'

type Props = FlexProps & {
  gutter?: number
}

export const Stack = styled.div<Props>`
  ${({ direction = 'row', gutter = 0 }) =>
    direction === 'row'
      ? ` & > * {
            margin-right: ${gutter}px;
          }
          & > :last-child {
            margin-right: 0px;
          }`
      : `
          & > * {
            margin-bottom: ${gutter}px;
          }
          & > :last-child {
            margin-bottom: 0px;
          }
        `}
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
  align-items: ${({ alignItems = 'flex-start' }) => alignItems};
  align-self: ${({ alignSelf }) => alignSelf};
  padding: ${({ padding }) => (padding ? `${padding}px` : null)};
  margin: ${({ margin }) => (margin ? `${margin}px` : null)};
  word-break: break-all;
  word-wrap: break-word;
`
