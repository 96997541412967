import styled from '@emotion/styled'
import IconMoreVertRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconMoreVertRegular'
import { vars } from '@seed-design/design-token'
import isEmpty from 'lodash/isEmpty'

import { UserInfo } from '@src/place-utils/user'
import { Badge as BadgeComponent } from '@src/react-utils/components/Badge'
import { ImageBoard } from '@src/react-utils/components/Image'
import { ReactionDisplay } from '@src/react-utils/components/Reaction'
import type { ReactionType } from '@src/react-utils/components/Reaction/ReactionDisplay'

interface PreviewBase {
  id: string
  author: {
    name: string
    id: number
    profileImage?: string
    regionName?: string
    regionCheckInCount?: number
  }
  content: string
  images?: {
    url: string[]
  }
  createdAt: string
}

interface ArticlePreview extends PreviewBase {
  type: 'article'
  reaction: {
    count?: number
    types?: ReactionType[]
  }
}

interface CommentPreview extends PreviewBase {
  type: 'comment'
  articleContent?: string
  articleId?: number
  reaction: {
    count?: number
    types?: ReactionType[]
  }
}

interface ReviewPreview extends PreviewBase {
  type: 'review'
  tags?: string[]
}

export type PostPreviewType = ArticlePreview | CommentPreview | ReviewPreview

interface Props {
  className?: string
  data: PostPreviewType
  onContentClick?: () => void
  on3DotClick?: () => void
  onClickUserProfile?: () => void
}

const PostPreview = ({ className, data, onContentClick, on3DotClick, ...props }: Props) => {
  const images = data.images?.url

  return (
    <Container className={className}>
      <Top>
        <UserInfo
          name={data.author.name}
          id={data.author.id}
          profileImage={data.author.profileImage}
          regionName={data.author.regionName}
          regionCheckInCount={data.author.regionCheckInCount}
          createdAt={data.createdAt}
          onClickProfile={props.onClickUserProfile}
        />
        {on3DotClick && <IconMoreVertRegular width="1.5rem" height="1.5rem" onClick={on3DotClick} />}
      </Top>
      <Content onClick={onContentClick}>{data.content}</Content>
      {data.type === 'review' && <div>{data.tags?.map((tag) => <Tag key={tag}>{tag}</Tag>)}</div>}
      {!!images && !isEmpty(images) && <ImageBoard images={images} />}

      {data.type === 'comment' && (
        <CommentOrigin onClick={onContentClick} isDeleted={!data.articleContent}>
          <Badge shape="square" size="small" variant="basic" type="outlined">
            원문
          </Badge>
          <p>{data.articleContent ?? '삭제된 게시글이에요.'}</p>
        </CommentOrigin>
      )}
      {data.type !== 'review' && !!data.reaction.count && data.reaction.count > 0 && (
        <ReactionDisplay reactionCount={data.reaction.count} reactionTypes={data.reaction.types ?? []} />
      )}
    </Container>
  )
}

const Container = styled.article`
  width: '100%';
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Content = styled.div`
  font-weight: 400;
  font-size: 1rem;
  color: ${vars.$scale.color.gray900};
  white-space: pre-wrap;
`

const Tag = styled.div`
  display: inline-block;
  margin: 0 4px 4px 0;

  padding: 4px 8px;
  background: ${vars.$scale.color.gray100};
  border-radius: 4px;

  color: ${vars.$scale.color.gray700};
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 16px;
`

const CommentOrigin = styled.div<{ isDeleted: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-weight: 400;
    font-size: 0.813rem;
    line-height: 154%;
    color: ${({ isDeleted }) => (isDeleted ? vars.$scale.color.gray500 : vars.$scale.color.gray600)};
  }
`
const Badge = styled(BadgeComponent)({
  color: vars.$scale.color.gray600,
  border: `1px solid ${vars.$scale.color.gray400}`,
})

export default PostPreview
