/* eslint-disable import/order */
/* eslint-disable no-restricted-imports */
/* tslint:disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact:
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosRequestConfig, AxiosResponse } from 'axios'
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../configs/common'

import { GetProfileV2200Response } from '../../models'
/**
 * ProfilesApi - axios parameter creator
 * @export
 */

/**
 * getProfileV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type GetProfileV2Params = {
  userId: string
}
/**
 *
 */
const getProfileV2AxiosParamCreator = async (
  // @ts-ignore
  params: GetProfileV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/profiles/{userId}`.replace(
    `{${'userId'}}`,
    encodeURIComponent(String(params['userId']))
  )
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}

/**
 *
 */
const getProfileV2Fp = async ({
  params,
  options,
}: {
  params: GetProfileV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<GetProfileV2200Response>> => {
  const localVarAxiosArgs = await getProfileV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}

export const getProfileV2 = ({ params, options }: { params: GetProfileV2Params; options?: AxiosRequestConfig }) => {
  return getProfileV2Fp({ params, options })
}
