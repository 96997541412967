import { ComponentType, createContext, useContext, useMemo } from 'react'
import { v4 } from 'uuid'

interface SessionTimerContextType {
  initialTime: Date
  visitSessionId: string
}

const sessionTimerContext = createContext<SessionTimerContextType>({
  initialTime: new Date(),
  visitSessionId: v4(),
})

const SessionTimerContextProvider = sessionTimerContext.Provider

export const useSessionTimerContext = () => {
  const context = useContext(sessionTimerContext)

  if (!context) throw new Error('useSessionTimerContext must be used within a SessionTimerContextProvider')

  return context
}

export const withSessionTimer = <P extends object>(Component: ComponentType<P>) => {
  return (props: P) => {
    const value = useMemo(() => {
      return { initialTime: new Date(), visitSessionId: v4() }
    }, [])

    return (
      <SessionTimerContextProvider value={value}>
        <Component {...props} />
      </SessionTimerContextProvider>
    )
  }
}
