import { keyframes } from '@emotion/react'

export const fully = keyframes({
  '0%': {
    transform: 'scale(0.05)',
  },
  '10%': {
    transform: 'scale(0.1)',
  },
  '20%': {
    transform: 'scale(0.2)',
  },
  '30%': {
    transform: 'scale(0.3)',
  },
  '40%': {
    transform: 'scale(0.4)',
  },
  '50%': {
    transform: 'scale(1.1)',
  },
  '60%': {
    transform: 'scale(1.2)',
  },
  '70%': {
    transform: 'scale(1.3)',
  },
  '80%': {
    transform: 'scale(1.2)',
  },
  '90%': {
    transform: 'scale(1.1)',
  },
  '100%': {
    transform: 'scale(1)',
  },
})
