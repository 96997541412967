import { receive } from '@stackflow/compat-await-push'
import { useActivityParams } from '@stackflow/react'
import { ComponentProps, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { handleShare } from '@src/js-utils/share'
import { myPoiReviewSelectorFamily } from '@src/place-utils/review/store/poiReview'
import { ActionSheet } from '@src/react-utils/components/ActionSheet'
import { useLogEvent } from '@src/react-utils/components/Analytics'
import { withSuspense } from '@src/react-utils/components/Suspense'
import { useNavigator } from '@src/stackflow'

import { useSelectedPoi } from '../hooks'
import { WinterSnackModalParams } from '../WinterSnackModal'

export interface Props {
  onClose?: () => void
}

type ActionSheetType = ComponentProps<typeof ActionSheet>
type ActionButtonsType = ActionSheetType['actionButtons']

const OptionsBottomSheet = ({ onClose }: Props) => {
  const logEvent = useLogEvent()
  const { pop, replace } = useNavigator()
  const { selectedPoiId, filterId } = useActivityParams<WinterSnackModalParams>()

  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId: selectedPoiId! }))
  const { selectedPoi } = useSelectedPoi()

  const sheets: ActionButtonsType = useMemo(() => {
    return [
      {
        label: '후기 작성',
        onClick: async () => {
          logEvent({ name: 'click_write_review_button', params: {} })
          onClose?.()

          await receive<{ poiId: string; bizAccountId?: string; reviewId: string }>({
            activityId: replace('poi_review', {
              action: myReview?.id ? 'update' : 'create',
              poiId: selectedPoiId,
              reviewId: myReview?.id,
            }).activityId,
          })
        },
      },
      {
        label: '이 가게 공유하기',
        onClick: () => {
          logEvent({ name: 'click_share_button', params: { area: 'options' } })
          onClose?.()
          pop()

          handleShare({
            type: 'WINTER_SNACK',
            data: {
              filterId: filterId ?? 'all',
              selectedPoiId,
              coordinates: selectedPoi?.poi.coordinates ?? undefined,
            },
          })
        },
      },
      {
        label: '정보 수정 제안',
        onClick: () => {
          logEvent({ name: 'click_suggest_edit_info_button', params: {} })
          onClose?.()

          replace('update_poi_suggestion', { poiId: selectedPoiId! })
        },
      },
      {
        type: 'destructive',
        label: '삭제 요청',
        onClick: () => {
          logEvent({ name: 'click_suggest_delete_button', params: {} })
          onClose?.()
          replace('delete_poi_suggestion', { poiId: selectedPoiId! })
        },
      },
    ]
  }, [logEvent, onClose, replace, myReview?.id, selectedPoiId, pop, filterId, selectedPoi?.poi.coordinates])

  return (
    <ActionSheet
      onOutsideClick={() => pop()}
      dismissButton={{
        label: '닫기',
        onClick: () => pop()!,
      }}
      actionButtons={sheets}
    />
  )
}

export default withSuspense(OptionsBottomSheet)
