import styled from '@emotion/styled'
import IconMoreVertRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconMoreVertRegular'
import { MouseEvent } from 'react'

import { useNavigator } from '@src/stackflow'

interface Props {
  reviewId: number
}
export const MoreActions = ({ reviewId }: Props) => {
  const { push } = useNavigator()

  const handleClickMore = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    push('native_review_modal', {
      modalId: 'list-review-more-actions',
      reviewId: reviewId.toString(),
    })
  }

  return (
    <ThreeDot onClick={handleClickMore}>
      <IconMoreVertRegular width={24} height={24} />
    </ThreeDot>
  )
}

const ThreeDot = styled.button({
  position: 'absolute',
  right: 0,
})
