import { send } from '@stackflow/compat-await-push'
import { useRef } from 'react'

import { BottomSheet } from '@src/react-utils/components/BottomSheet'
import { Picker } from '@src/react-utils/components/Picker'
import { useActivity, useNavigator } from '@src/stackflow'

import { getYearsMap } from '../utils'

export const YearPickerBottomSheet = () => {
  const { id: activityId } = useActivity()
  const { pop } = useNavigator()

  const yearsMap = getYearsMap(1900)
  const values = Object.values(yearsMap).map((x) => x.text)
  const initialIndex = values.length - 1

  const selectedRef = useRef(yearsMap[initialIndex].value)

  const handleChange = (index: number) => {
    selectedRef.current = yearsMap[index].value
  }

  const handleConfirm = () => {
    send({ activityId, data: { value: selectedRef.current } })
    pop()
  }

  return (
    <BottomSheet
      contents={<Picker initIndex={initialIndex} values={values} onChange={handleChange} />}
      CTAButton={{
        label: '확인',
        onClick: handleConfirm,
      }}
      options={{
        contentsOverflow: 'hidden',
      }}
    />
  )
}
