import { useStack } from '@stackflow/react'
import { useMemo } from 'react'

export const useExistingActivities = () => {
  const { activities } = useStack()

  const existingActivities = useMemo(
    () => activities.filter((activity) => activity.transitionState === 'enter-done'),
    [activities]
  )

  return existingActivities
}
