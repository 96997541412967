import { useRecoilValue } from 'recoil'

import { userInfoAtom } from '@src/place-utils/user/store'
import { Callout } from '@src/react-utils/components'

import { myReviewCountByStatusSelector } from '../store'

export const ReviewStatusCallout = () => {
  const user = useRecoilValue(userInfoAtom)
  const { pendingCount } = useRecoilValue(myReviewCountByStatusSelector)

  return (
    <>
      {pendingCount > 0 && (
        <Callout
          type="basic"
          state="normal"
          useIcon
          contents={`${user.nickname}님이 작성한 ${pendingCount}개 글을 확인하고 있어요.\n글이 등록되면 알림으로 알려드릴게요.`}
        />
      )}
    </>
  )
}
