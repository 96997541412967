export const prefix = 'poi-web'

/**
 * Do NOT remove keys even if they are unused.
 * Local storage keys are treated as semi-permanent.
 */
export const keys = Object.freeze({
  USE_ANALYTICS: 'USE_ANALYTICS',
  USE_HISTORY: 'USE_HISTORY',

  IS_FIRST_DAANGN_MAP_REVIEW: 'IS_FIRST_DAANGN_MAP_REVIEW',
  LATEST_MAP_THEME: 'latest-map-theme',
  MARKETING_EVENT_BANNER_GOOD_RESTAURANT_IN_TOWN_RELAY: 'MARKETING_EVENT_BANNER_GOOD_RESTAURANT_IN_TOWN_RELAY',

  PURPOSEFUL_CATEGORY_FILTER_TOOLTIP: 'PURPOSEFUL_CATEGORY_FILTER_TOOLTIP',

  WATCH_PAGE_TOOLTIP_story_article_poi_collection: 'WATCH_PAGE_TOOLTIP_story_article_poi_collection',

  WINTER_SNACK_22_23_WRAP_UP_BANNER: 'WINTER_SNACK_22_23_WRAP_UP_BANNER',
  WINTER_SNACK_22_23_WRAP_UP_BOTTOM_SHEET: 'WINTER_SNACK_22_23_WRAP_UP_BOTTOM_SHEET',

  NATIVE_REVIEW_FORM_IMAGE_UPLOADER_HELP_BUBBLE: 'NATIVE_REVIEW_FORM_IMAGE_UPLOADER_HELP_BUBBLE',
})
