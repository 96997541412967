import { SortTab } from '@daangn/local-business-kit'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useMemo } from 'react'
import { useRecoilState } from 'recoil'

import { useScreenInstance } from '@src/react-utils/hooks/useScreenInstance'

import { useAnalytics } from '../hooks'
import { reviewSortingOptionAtomFamily } from '../state'

interface Props {
  poiId: string
}
export const ReviewSortingSelection = ({ poiId }: Props) => {
  const logEvent = useAnalytics()
  const screenInstance = useScreenInstance()

  const [sortingOption, setSortOption] = useRecoilState(reviewSortingOptionAtomFamily({ poiId, screenInstance }))

  const tabs = useMemo(
    () => [
      {
        key: 'HIGHEST_SCORE',
        label: '유용한순',
        onClick: () => {
          /** @deprecated */
          logEvent('click_sort_by_helpful_button', {
            source: 'review',
          })
          logEvent('click_profile_review_tab_order_button', {
            order_filter_from: 'created_at',
            order_filter_to: 'likes_count',
          })
          setSortOption('HIGHEST_SCORE')
        },
      },
      {
        key: 'LATEST_CREATION',
        label: '최신순',
        onClick: () => {
          /** @deprecated */
          logEvent('click_sort_by_latest_button', {
            source: 'review',
          })

          logEvent('click_profile_review_tab_order_button', {
            order_filter_from: 'likes_count',
            order_filter_to: 'created_at',
          })
          setSortOption('LATEST_CREATION')
        },
      },
    ],
    [setSortOption, logEvent]
  )

  return (
    <Container>
      <SortTab tabs={tabs} initialTabKey={sortingOption} />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 1rem 1rem 0;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background: ${vars.$semantic.color.paperDefault};
`
