import CreateSuggestionPage from '@src/pages/CreateSuggestion'
import { DeleteSuggestionPage } from '@src/pages/DeleteSuggestion'
import { DaangnMapEndOfService } from '@src/pages/EndOfService/DaangnMap'
import { NativeReviewEditPage, NativeReviewFormPage, NativeReviewModal } from '@src/pages/NativeReview'
import { NativeReviewList } from '@src/pages/NativeReview/List'
import { PinAddressPage } from '@src/pages/PinAddress'
import { PoiDetailPage, PoiDetailModal } from '@src/pages/PoiDetail'
import SearchAddressPage from '@src/pages/SearchAddress'
import SearchCategoryPage from '@src/pages/SearchCategory'
import { SearchPoiPage } from '@src/pages/SearchPoi'
import { SearchPoiModal } from '@src/pages/SearchPoi/Modal'
import { SearchPoiRedirection } from '@src/pages/SearchPoi/Redirection'
import { StoryArticlePoiCollectionPage } from '@src/pages/StoryArticlePoiCollection'
import { SubprofilePage } from '@src/pages/Subprofile'
import { UpdateSuggestionPage } from '@src/pages/UpdateSuggestion'
import { WatchedPlacesFadeOut } from '@src/pages/WatchedPlaces/WatchedPlacesFadeOut'
import { WinterSnackPage, WinterSnackModal } from '@src/pages/WinterSnack'
import WritePoiReviewPage from '@src/pages/WritePoiReview'
import { FallbackScreen } from '@src/react-utils/components/Error/FallbackScreen'

export const activities = {
  poi_detail: PoiDetailPage,
  poi_detail_modal: PoiDetailModal,
  legacy_create_poi_suggestion: CreateSuggestionPage,
  update_poi_suggestion: UpdateSuggestionPage,
  delete_poi_suggestion: DeleteSuggestionPage,
  pin_address: PinAddressPage,
  search_address: SearchAddressPage,
  search_category: SearchCategoryPage,
  search_poi: SearchPoiPage,
  search_poi_communityTag: SearchPoiRedirection,
  search_poi_modal: SearchPoiModal,
  poi_review: WritePoiReviewPage,

  daangn_map: DaangnMapEndOfService,

  /**
   * @deprecated place_watch page is deprecated.
   * Use `karrot://my_watches` instead.
   */
  place_watch: WatchedPlacesFadeOut,

  subprofile: SubprofilePage,
  subprofile_reviews: SubprofilePage,

  winter_snack: WinterSnackPage,
  winter_snack_modal: WinterSnackModal,
  story_article_poi_collection: StoryArticlePoiCollectionPage,

  // 토박이
  native_review_form: NativeReviewFormPage,
  native_review_modal: NativeReviewModal,
  native_review_list: NativeReviewList,
  native_review_edit: NativeReviewEditPage,

  fallback: FallbackScreen,
}

export type ActivitiesType = typeof activities
