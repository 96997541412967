import { useSetRecoilState } from 'recoil'

import { SearchAddressListItem } from '@src/js-utils/searchAddress'
import { addressPickerAtom } from '@src/react-utils/components/Input/AddressInput/store/addressPicker'
import { useNavigator } from '@src/stackflow'

import { useAnalytics } from './hooks'
import SearchAddressView from './SearchAddressView'

const SearchAddress = () => {
  const logEvent = useAnalytics()
  const { pop } = useNavigator()

  const setSearchAddress = useSetRecoilState(addressPickerAtom)

  const handleClickAddress = (address: SearchAddressListItem) => {
    logEvent('click_select_address')
    setSearchAddress({ ...address })
    pop()
    const addressExtraInput = document.getElementById('addressExtra')
    if (!addressExtraInput) return
    addressExtraInput.focus()
  }

  return <SearchAddressView onClickAddress={handleClickAddress} />
}

export default SearchAddress
