import { object, string } from 'yup'
import 'yup-phone'

export const validationSchema = object({
  name: string().max(64, '64자 이하의 장소 이름을 입력해주세요.').required('장소 이름을 입력해주세요.'),
  phone: string()
    .nullable()
    .test('test-phone', '올바른 전화번호를 입력해주세요.', (value) => {
      if (value) return string().phone('KR', true).isValidSync(value)
      return true
    }),
})
