import styled from '@emotion/styled'
import IconChevronRightThin from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightThin'
import { vars } from '@seed-design/design-token'
import { useSetRecoilState } from 'recoil'

import poiPinSelected from '@src/assets/images/ic_pin_selected.svg'
import { addressPickerAtom } from '@src/react-utils/components/Input/AddressInput/store/addressPicker'
import { useKakaoMap } from '@src/react-utils/components/Map/KakaoMap'
import { useNavigator } from '@src/stackflow'

export const Edit = () => {
  const { getCenter } = useKakaoMap()
  const { push } = useNavigator()

  const setAddressPicker = useSetRecoilState(addressPickerAtom)

  const handleClickPinAddress = async () => {
    const center = getCenter()
    if (!center) return

    setAddressPicker({ coordinates: center })

    push('legacy_create_poi_suggestion', { variation: 'winterSnack' })
  }

  return (
    <PinOverlay>
      <StyledAddressPicker onClick={handleClickPinAddress}>
        <div className="text">
          이 위치 선택하기
          <IconChevronRightThin className="icon" />
        </div>
        <img src={poiPinSelected} />
      </StyledAddressPicker>
    </PinOverlay>
  )
}

const PinOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`

const StyledAddressPicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transform: translate3d(0, -53.5px, 0);
  .text {
    ${vars.$semantic.typography.caption1Bold}
    position: relative;
    display: flex;
    align-items: center;
    padding: 8.5px 10px 8.5px 14px;
    border-radius: 17.5px;
    /** @todo: seed-design */
    box-shadow:
      0px 4px 6px rgba(0, 0, 0, 0.08),
      0px 0px 8px rgba(0, 0, 0, 0.05);
    /** @todo: seed-design */
    background: rgba(0, 0, 0, 0.8);
    color: ${vars.$static.color.staticWhite};
    .icon {
      width: 18px;
      height: 18px;
    }
  }
  .text:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    /** @todo: seed-design */
    border-top-color: rgba(0, 0, 0, 0.8);
    border-bottom: 0;
    transform: translate3d(-50%, 10px, 0);
  }
  img {
    margin: 16px 0 -5px;
    height: 61px;
    width: 40px;
  }
`
