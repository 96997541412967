import styled from '@emotion/styled'
import IconMarketFill from '@karrotmarket/karrot-ui-icon/lib/react/IconMarketFill'
import IconUserGroupFill from '@karrotmarket/karrot-ui-icon/lib/react/IconUserGroupFill'
import { vars } from '@seed-design/design-token'
import { BottomSheet } from '@stackflow/plugin-basic-ui'
import { useRecoilCallback } from 'recoil'

import { storyArticleWritingURLSelectorFamily } from '@src/place-utils/community/store'
import { useLogEvent } from '@src/react-utils/components/Analytics'
import { useToast } from '@src/react-utils/components/Toast'
import { useNavigator } from '@src/stackflow'
import { safeAreaPadding } from '@src/styles/safeArea'

import { ASK_COMMUNITY_QUESTIONS } from '../HomeTab/AskCommunitySection/constants/ask-community-questions'
import { useStory } from '../hooks/useStory'

type QuestionId = keyof typeof ASK_COMMUNITY_QUESTIONS
interface Props {
  poiId: string
  questionId: QuestionId
}
export const AskTargetAudienceBottomSheet = ({ poiId, questionId }: Props) => {
  const reqLogEvent = useLogEvent()
  const { pop, asyncPushScheme } = useNavigator()
  const { showToast } = useToast()
  const { refetch } = useStory({ poiId })

  const handleAskCommunity = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        reqLogEvent({
          name: 'click_bizprofile_poi_home_question_bottom_sheet',
          params: {
            biz_id: null,
            poi_id: poiId,
            chip: questionId,
            target: 'community',
          },
        })

        const { targetUri } = await snapshot.getPromise(
          storyArticleWritingURLSelectorFamily({
            subject: '동네질문',
            poiId,
            writingGuide: ASK_COMMUNITY_QUESTIONS[questionId].writingGuide,
          })
        )

        asyncPushScheme(targetUri).then(() => {
          refetch()
          pop()
        })
      },
    [asyncPushScheme, poiId, pop, questionId, refetch, reqLogEvent]
  )

  const handleAskOwner = () => {
    reqLogEvent({
      name: 'click_bizprofile_poi_home_question_bottom_sheet',
      params: {
        biz_id: null,
        poi_id: poiId,
        chip: questionId,
        target: 'owner',
      },
    })

    showToast({
      text: '이 가게는 사장님이 관리하고 있지 않아요.',
      duration: 'short',
    })
  }

  return (
    <BottomSheet>
      <Container>
        <Header>누구에게 질문할까요?</Header>
        <Button onClick={handleAskCommunity}>
          <Icon backgroundColor={vars.$semantic.color.primaryLow}>
            <IconUserGroupFill color={vars.$semantic.color.primary} width="1.75rem" height="1.75rem" />
          </Icon>
          <Description>
            <DescriptionMain>동네생활에서 이웃에게 질문하기</DescriptionMain>
            <DescriptionSub>평균 3명의 이웃들이 답변해요.</DescriptionSub>
          </Description>
        </Button>
        <Button onClick={handleAskOwner} isDisabled>
          <Icon backgroundColor={vars.$scale.color.blue50}>
            <IconMarketFill color={vars.$scale.color.blue500} width="1.75rem" height="1.75rem" />
          </Icon>
          <Description>
            <DescriptionMain>사장님에게 질문하기</DescriptionMain>
            <DescriptionSub>사장님이 직접 답변해요.</DescriptionSub>
          </Description>
        </Button>
      </Container>
    </BottomSheet>
  )
}

const Container = styled.div([
  {
    padding: '2rem 1rem 1.5rem',
  },
  safeAreaPadding('bottom', '1.5rem'),
])
const Header = styled.header([
  {
    margin: '0 0 1.5rem',
    color: vars.$scale.color.gray900,
  },
  vars.$semantic.typography.title3Bold,
])
const Button = styled.button<{ isDisabled?: boolean }>(({ isDisabled }) => [
  {
    opacity: isDisabled ? 0.4 : 1,
    margin: '0 0 1.5rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    ':last-of-type': {
      margin: 0,
    },
  },
])
const Icon = styled.div<{ backgroundColor: string }>([
  {
    width: '3rem',
    height: '3rem',
    flexShrink: 0,
    borderRadius: '50%',
    display: 'grid',
    placeItems: 'center',
  },
  ({ backgroundColor }) => ({
    backgroundColor,
  }),
])
const Description = styled.div({
  margin: '0 0 0 0.75rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})
const DescriptionMain = styled.span([
  {
    margin: '0 0 0.125rem',
    textAlign: 'start',
    color: vars.$scale.color.gray900,
  },
  vars.$semantic.typography.title3Regular,
])
const DescriptionSub = styled.span([
  {
    color: vars.$scale.color.gray600,
    textAlign: 'start',
  },
  vars.$semantic.typography.caption1Regular,
])
