import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useMemo, MouseEvent, useRef, useState, ReactNode, useEffect } from 'react'

const cacheDecorator = <V, P extends any[]>(callback: (...params: P) => V): ((...params: P) => V) => {
  const cacheMap = new Map()

  return (...params: P) => {
    const key = params.join(',')

    if (cacheMap.has(key)) {
      return cacheMap.get(key)
    }

    const value = callback(...params)
    cacheMap.set(key, value)

    return value
  }
}

export const getOneLineHeight = cacheDecorator((fontSize: string, lineHeight: string) => {
  const element = document.createElement('p')
  element.innerText = '.'
  element.style.cssText = `
    font-size: ${fontSize};
    line-height: ${lineHeight};
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    margin: 0;
  `
  document.body.appendChild(element)
  const height = element.scrollHeight
  document.body.removeChild(element)

  return height
})

interface Props {
  lineCount: number
  fontSizeStyle: string
  lineHeightStyle: string

  onClickContent?: () => void
  children?: ReactNode
}

const ContentWithEllipsisMore: React.FC<Props> = ({
  children,
  lineCount,
  lineHeightStyle,
  fontSizeStyle,

  onClickContent,
}) => {
  const [open, setOpen] = useState(false)

  const contentElementRef = useRef<HTMLParagraphElement>(null)
  const [isOverflowed, setIsOverflowed] = useState(false)

  const handleOpenMore = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setOpen(true)
  }

  useEffect(() => {
    const contentElement = contentElementRef.current

    if (!contentElement) {
      return
    }

    const isOverflowedElement = contentElement.scrollHeight > contentElement.clientHeight
    if (isOverflowedElement) {
      setIsOverflowed(true)
    }
  }, [children])

  const heightLimit = useMemo(() => {
    return lineCount * getOneLineHeight(fontSizeStyle, lineHeightStyle)
  }, [fontSizeStyle, lineCount, lineHeightStyle])

  return (
    <div onClick={handleOpenMore}>
      <S_Content ref={contentElementRef} open={open} maxHeight={heightLimit} onClick={onClickContent}>
        {children}
      </S_Content>
      {isOverflowed && !open && (
        <S_ShowMore>
          ...&nbsp;
          <S_More type="button">더보기</S_More>
        </S_ShowMore>
      )}
    </div>
  )
}

const S_Content = styled.div<{ open: boolean; maxHeight: number }>`
  margin: 0;
  white-space: pre-line;
  word-break: break-all;
  overflow: hidden;

  ${({ open, maxHeight }) => !open && `max-height: ${maxHeight}px`};
`
const S_ShowMore = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
`
export const S_More = styled.button`
  font-size: inherit;
  line-height: inherit;
  color: ${vars.$scale.color.gray600};
  :active {
    opacity: 0.5;
  }
`

export default ContentWithEllipsisMore
