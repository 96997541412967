import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useState, useLayoutEffect, useRef } from 'react'

import { karrotBridge } from '@src/bridge'
import { Button } from '@src/react-utils/components/Button'

const FALLBACK_OFFSET_HEIGHT = 82

export const EmptyState = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [offsetHeight, setOffsetHeight] = useState(FALLBACK_OFFSET_HEIGHT)

  const handleClick = () => {
    karrotBridge.closeRouter({})
  }
  useLayoutEffect(() => {
    setOffsetHeight(ref.current?.offsetHeight ?? FALLBACK_OFFSET_HEIGHT)
  }, [])

  return (
    <Container ref={ref} offsetHeight={offsetHeight}>
      <Text>댓글에 추천된 장소가 없어요.</Text>
      <Button className="button" textSize="16" styleTheme="secondary-outlined" onClick={handleClick}>
        뒤로 가기
      </Button>
    </Container>
  )
}

const Container = styled.div<{ offsetHeight: number }>`
  position: relative;
  top: ${({ offsetHeight }) => `calc(40% - ${offsetHeight}px)`};
  margin: auto 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Text = styled.p`
  margin: 0 0 1rem 0;
  white-space: pre-line;
  text-align: center;
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.bodyL1Regular};
`
