import { Poi } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { memo, useMemo, MouseEvent } from 'react'

import { truncateText } from '@src/js-utils/text'
import { EllipsisText } from '@src/react-utils/components/Map/EllipsisText'
import { KakaoCustomOverlay, KakaoMarker, useKakaoMarkerImage } from '@src/react-utils/components/Map/KakaoMap'

import { useAnalytics } from '../hooks'

interface Props {
  poi: Poi
  focusedPoiId: string | null
  onClick: (poi: Poi) => void
  onSelect: (poi: Poi) => void
}
export const Marker = memo(({ poi, focusedPoiId, onClick, onSelect }: Props) => {
  const { id, bizAccountId, name, coordinates } = poi
  const isSelected = focusedPoiId === id

  const logEvent = useAnalytics()
  const { markerImage } = useKakaoMarkerImage({ color: 'carrot' })

  interface Marker {
    image: any
    zIndex: number
  }
  const marker = useMemo<Marker>(() => {
    return isSelected ? { image: markerImage.selected, zIndex: 3 } : { image: markerImage.default, zIndex: 1 }
  }, [isSelected, markerImage.selected, markerImage.default])

  const handleClickMarker = () => {
    logEvent('click_map_pin', {
      poi_type: bizAccountId ? 'biz_account' : 'poi',
      poi_id: bizAccountId ?? id,
    })

    onClick(poi)
  }

  const handleSelectPoi = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()

    logEvent('click_select_poi_button', {
      poi_type: bizAccountId ? 'biz_account' : 'poi',
      poi_id: bizAccountId ?? id,
      area: 'map_marker',
    })

    onSelect(poi)
  }

  if (!poi || !coordinates) return null

  return (
    <>
      {isSelected && (
        <KakaoCustomOverlay position={coordinates} clickable={true} zIndex={marker.zIndex}>
          <Select onClick={handleSelectPoi}>{truncateText(name, 9, { applyEllipsis: true })} 선택</Select>
        </KakaoCustomOverlay>
      )}
      <KakaoMarker position={coordinates} image={marker.image} onClick={handleClickMarker} zIndex={marker.zIndex} />
      <KakaoCustomOverlay position={coordinates} clickable={false}>
        <Name line={2}>{name}</Name>
      </KakaoCustomOverlay>
    </>
  )
})

const Select = styled.button`
  transform: translate3d(0, calc(-3.875rem - 1.125rem), 0);
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  background-color: ${vars.$static.color.staticBlack};
  color: ${vars.$static.color.staticWhite};
  ${vars.$semantic.typography.caption1Bold};
`
const strokeColor = vars.$static.color.staticWhite
const Name = styled(EllipsisText)`
  transform: translate3d(0, 1.25rem, 0);
  width: 5rem;
  height: 2rem;
  max-width: 5rem;
  max-height: 2rem;
  text-align: center;
  text-shadow:
    ${strokeColor} 2px 0px 0px,
    ${strokeColor} 1.75517px 0.958851px 0px,
    ${strokeColor} 1.0806px 1.68294px 0px,
    ${strokeColor} 0.141474px 1.99499px 0px,
    ${strokeColor} -0.832294px 1.81859px 0px,
    ${strokeColor} -1.60229px 1.19694px 0px,
    ${strokeColor} -1.97998px 0.28224px 0px,
    ${strokeColor} -1.87291px -0.701566px 0px,
    ${strokeColor} -1.30729px -1.5136px 0px,
    ${strokeColor} -0.421592px -1.95506px 0px,
    ${strokeColor} 0.567324px -1.91785px 0px,
    ${strokeColor} 1.41734px -1.41108px 0px,
    ${strokeColor} 1.92034px -0.558831px 0px,
    2px 3px 1px rgba(0, 0, 0, 0.15);
  color: ${vars.$static.color.staticBlack};
  ${vars.$semantic.typography.label4Bold};
`
