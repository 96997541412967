// eslint-disable-next-line import/no-unresolved
import '@daangn/sprout-components-avatar/index.css'

import { Avatar as SproutAvatar, SeedAvatarProps } from '@daangn/sprout-components-avatar'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { memo } from 'react'

import { PROFILE_PLACEHOLDER } from '@src/constants/image'

export type AvatarProps = Omit<SeedAvatarProps, 'src' | 'alt' | 'fallback'> & {
  src: SeedAvatarProps['src'] | null
  alt: SeedAvatarProps['alt'] | null
}
export const Avatar = memo(({ src, alt, ...sproutAvatarProps }: AvatarProps) => {
  const _fallbackSrc = PROFILE_PLACEHOLDER
  const _src = src || _fallbackSrc
  const _alt = alt?.[0] || ''

  return (
    <SproutAvatar
      {...sproutAvatarProps}
      src={_src}
      alt={_alt}
      fallback={<Fallback src={_fallbackSrc} alt={_alt} />}
      UNSAFE_style={{
        backgroundColor: vars.$semantic.color.paperDefault,
      }}
    />
  )
})

const Fallback = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '100%',
})
