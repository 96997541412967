import { atom } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'

import { deleteSuggestionPagePrefix } from './index'

const prefix = `${deleteSuggestionPagePrefix}/poi`
const generateKey = generateUniqueKey(prefix)

export const poiIdAtom = atom({
  key: generateKey('poiIdAtom'),
  default: '',
})
