import { WinterSnackMapItem } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import IconMoreVertRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconMoreVertRegular'
import IconNewtopicFill from '@karrotmarket/karrot-ui-icon/lib/react/IconNewtopicFill'
import { vars } from '@seed-design/design-token'
import { MouseEventHandler, useMemo } from 'react'

import { haversineDistance } from '@src/js-utils/coordinate'
import { MONTH_IN_MS } from '@src/js-utils/date'
import { useCurrentPosition } from '@src/place-utils/location/hooks'
import { useLogEvent } from '@src/react-utils/components/Analytics'
import { useNavigator } from '@src/stackflow'

import { FailedVisit } from './FailedVisit'
import { LatestVisit } from './LatestVisit'
import { Menu } from './Menu'
import { VisitButton } from './VisitButton'

interface Props {
  item: WinterSnackMapItem
}

export const WinterSnackPoiCard = ({ item }: Props) => {
  const { poi } = item

  const { push } = useNavigator()
  const logEvent = useLogEvent()
  const { access, position } = useCurrentPosition()

  const isNew = useMemo(() => {
    return new Date().valueOf() - new Date(poi.createdAt).valueOf() < MONTH_IN_MS
  }, [poi])

  const handleClickCard = () => {
    logEvent({
      name: 'click_wintersnack_card',
      params: {
        poi_id: poi.id,
      },
    })

    push('poi_detail', {
      poiId: poi.id,
    })
  }

  const handleMoreOptionClick: MouseEventHandler = (e) => {
    e.stopPropagation()

    logEvent({
      name: 'click_wintersnack_card_more_option',
      params: {
        poi_id: poi.id,
      },
    })

    push('winter_snack_modal', { selectedPoiId: poi.id, modalId: 'poiOptionsBottomSheet' })
  }

  const poiCoordinates = item.poi.coordinates
  const distance =
    access === 'granted' && !!poiCoordinates?.latitude && !!poiCoordinates.longitude
      ? haversineDistance(position, poiCoordinates)
      : undefined

  return (
    <Card onClick={handleClickCard}>
      <Header>
        <Title>
          <Name>{poi.name}</Name>
          {isNew && (
            <NewBadge>
              <IconNewtopicFill height="100%" width="100%" color={vars.$semantic.color.primary} />
            </NewBadge>
          )}
        </Title>
        <MoreOptionButton onClick={handleMoreOptionClick}>
          <IconMoreVertRegular height="100%" width="100%" />
        </MoreOptionButton>
      </Header>
      <Content>
        <Menu attributes={poi.attributes} />
      </Content>
      <VisitButton item={item} distance={distance} />
      <Footer>
        <LatestVisit poiId={poi.id} />
        <FailedVisit poiId={poi.id} distance={distance} />
      </Footer>
    </Card>
  )
}

const Card = styled.div`
  position: relative;
  padding: 1rem;
  background-color: ${vars.$semantic.color.paperDefault};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
`
const Content = styled.div``
const Title = styled.div`
  display: inline-flex;
  align-items: center;
  min-width: 0; //for ellipsis;
`
const Header = styled.div`
  width: 100%;
  margin: 0 0 0.375rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
`
const Name = styled.h3`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.subtitle1Bold};
`
const NewBadge = styled.div`
  margin: 0 0 0 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
`
const MoreOptionButton = styled.button`
  margin: 0 -0.375rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 1.5rem;
  min-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  color: ${vars.$scale.color.gray900};
`
const Footer = styled.div`
  margin: 1rem 0 0;
  height: 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
`
