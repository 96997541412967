import { Poi } from '@daangn/local-business-network/lib/poi'
import { vars } from '@seed-design/design-token'
import { ActivityComponentType, useActivityParams } from '@stackflow/react'
import isEmpty from 'lodash/isEmpty'
import { useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'

import { bizProfileSDK } from '@src/bizProfileSDK'
import { karrotBridge } from '@src/bridge'
import { APP_SCHEME, BIZ_PROFILE_APP_BASE_URL } from '@src/constants/url'
import { createQueryString } from '@src/js-utils/url'
import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'
import useLoadableLocalProfileByPoiId from '@src/place-utils/review/store/localProfileByPoiIdLoadableFamily'
import { PageLoading } from '@src/react-utils/components/Loading'
import { TransparentNavScreen } from '@src/react-utils/components/Navigation/TransparentNavScreen'
import { useActivity, useNavigator } from '@src/stackflow'

import { IMAGE_CONTAINER_HEIGHT } from './constants'
import Footer from './Footer'
import Header from './Header/Header'
import NavbarButtons from './Navbar/NavbarButtons'
import FetchAndSyncPosts from './PostsTab/FetchAndSyncPosts'
import { imagesByPoiIdSelectorFamily, PostsTabKey, TabKey, tabs } from './state'
import TabPanel from './TabPanel'

export type ActivityParams = {
  poiId: string
  spotlightPostId?: string
  story_article_id?: string
  story_comment_id?: string
  tab?: TabKey | PostsTabKey
}

/**
 * @deprecated
 * 로컬프로필 배포 이후에는 이 페이지는 사용되지 않아요.
 * 대신 business-platform-web 레포의 LocalProfileHomeScreen.tsx 혹은 ViewerPoiHome.tsx를 사용해주세요.
 */

const PoiDetailPage: ActivityComponentType<ActivityParams> = ({ params: { poiId, ...params } }) => {
  const { pop } = useNavigator()
  const { isRoot, name: activityName } = useActivity()
  const { referrer } = useActivityParams<{ referrer: string }>()

  const images = useRecoilValue(imagesByPoiIdSelectorFamily(poiId))
  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))
  const poiRef = useRef<Poi | null>(poi)
  // const addMonitoringWatchPlaceId = useAddMonitoringWatchPlaceIds()

  const localProfileResponse = useLoadableLocalProfileByPoiId(Number(poiId))

  useEffect(() => {
    if (!poiId) throw new Error('poi id IS_LOCAL not defined')

    bizProfileSDK.event.bizProfileCreateByPOI.on(({ poiId }) => {
      if (poiId !== poiRef.current?.id) return

      if (isRoot) {
        document.onvisibilitychange = () => {
          karrotBridge.closeRouter({})
        }
      } else {
        pop()
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!poi) return

    poiRef.current = poi
    const queryString = createQueryString({
      referrer: referrer ?? `place.${activityName}`,
      entry: `place.${activityName}`,
    })

    if (isRoot) {
      karrotBridge.replaceRouter({
        router: {
          remote: `${BIZ_PROFILE_APP_BASE_URL}/local_profiles/${localProfileResponse?.id}/home${queryString}`,
          navbar: false,
          scrollable: false,
        },
      })
    } else {
      pop()
      window.location.href = `${APP_SCHEME}minikarrot/router?remote=${BIZ_PROFILE_APP_BASE_URL}/local_profiles/${localProfileResponse?.id}/home${queryString}`
    }

    // addMonitoringWatchPlaceId([poiToPlaceWatchId(poi)])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poi])

  if (!poi || isEmpty(poi)) return <PageLoading />

  if (poi) return null

  // 로컬프로필 배포 이후 정리 필요
  return (
    <TransparentNavScreen
      appBar={{ title: poi.name ?? '' }}
      breakpoint={images.items.length > 0 ? IMAGE_CONTAINER_HEIGHT : 0}
      appendRight={({ iconColor }) => <NavbarButtons poiData={poi} iconColor={iconColor} />}
      backgroundColor={vars.$semantic.color.paperBackground}>
      <Header tabs={tabs} />
      <TabPanel tabs={tabs} initialTabKey={params.tab} />
      <FetchAndSyncPosts />
      <Footer />
    </TransparentNavScreen>
  )
}

export default PoiDetailPage
