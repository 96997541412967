import { BoxButton } from '@daangn/sprout-components-button'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { Fragment, useReducer, useRef, useState } from 'react'
import { atom, useRecoilState } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'
import { LOCAL_STORAGE_KEYS, localStorage } from '@src/js-utils/localStorage'
import { FormItemWrapper } from '@src/place-utils/widgets/form/FormItemWrapper'
import { ImageUploader, ImageUploadPicture } from '@src/react-utils/components/Image'

import { TextField } from './TextField'
import { Layout, Title } from '../components'
import { useStepForm } from '../hooks/useStepForm'

const generateRecoilKey = generateUniqueKey('native-review-form')

type HelpBubbleVisibility = 'visible' | 'hidden'
const helpBubbleVisibilityAtom = atom<HelpBubbleVisibility>({
  key: generateRecoilKey('visibility'),
  default: (async () => {
    const visibility = (await localStorage.getItem(
      LOCAL_STORAGE_KEYS.NATIVE_REVIEW_FORM_IMAGE_UPLOADER_HELP_BUBBLE
    )) as HelpBubbleVisibility

    if (!visibility) {
      return 'hidden'
    } else {
      return visibility
    }
  })(),
  effects: [
    ({ onSet }) => {
      onSet((newValue) =>
        localStorage.setItem(LOCAL_STORAGE_KEYS.NATIVE_REVIEW_FORM_IMAGE_UPLOADER_HELP_BUBBLE, newValue)
      )
    },
    async ({ setSelf }) => {
      const visibility = (await localStorage.getItem(
        LOCAL_STORAGE_KEYS.NATIVE_REVIEW_FORM_IMAGE_UPLOADER_HELP_BUBBLE
      )) as HelpBubbleVisibility

      if (visibility !== 'hidden') {
        setTimeout(() => {
          setSelf('visible')
          setTimeout(() => {
            setSelf('hidden')
          }, 4000)
        }, 1000)
      }
    },
  ],
})

const USE_LABEL = true
const GIFT_CARD_LABEL_TEXT = `사진을 추가하면 상품권 당첨 확률이 올라가요!`
export const REVIEW_CONTENT_PLACEHOLDER = '맛, 가격, 서비스, 분위기, 편의 시설 등'

export const RestaurantReview = () => {
  const {
    variation,
    formValue: { review },
    go,
    updateForm,
  } = useStepForm()

  const [helpBubbleVisibility, setHelpBubbleVisibility] = useRecoilState(helpBubbleVisibilityAtom)

  const labelText = variation === 'gift_card' ? GIFT_CARD_LABEL_TEXT : undefined
  const contentRef = useRef<string>(review?.content || '')

  const [imageCount, setImageCount] = useState<number>(review?.images?.length || 0)

  type State = { isDisabled: boolean }
  type Action = { type: 'disable' } | { type: 'enable' }
  const initialState: State = { isDisabled: contentRef.current.length === 0 }
  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case 'disable':
        return { isDisabled: true }
      case 'enable':
        return { isDisabled: false }
      default:
        return state
    }
  }
  const [nextButtonState, dispatchNextButtonState] = useReducer(reducer, initialState)

  const onContentChange = (value: string) => {
    contentRef.current = value

    if (value.length <= 0) {
      dispatchNextButtonState({ type: 'disable' })
    } else {
      dispatchNextButtonState({ type: 'enable' })
    }
  }

  const onUpdateImage = (pictures: ImageUploadPicture[]) => {
    const images = pictures.map((picture) => ({
      id: picture.id,
      url: picture.url,
    }))

    updateForm({
      review: {
        images,
      },
    })
    setImageCount(images.length)
  }

  const onClickNext = () => {
    updateForm({
      review: {
        content: contentRef.current,
      },
    })

    go.next()
  }

  return (
    <Layout>
      <Title>이 맛집을 왜 추천하시나요? 🤩</Title>
      {USE_LABEL && typeof labelText === 'string' && (
        <Label>
          {labelText.split('\n').map((text, idx) => (
            <Fragment key={idx}>
              {text}
              <br />
            </Fragment>
          ))}
        </Label>
      )}
      <ImageUploaderWrapper id="imageIds" label="">
        <ImageUploader
          maxCount={10}
          onUpdateImage={onUpdateImage}
          images={review?.images ? review?.images.map((image) => ({ id: image.id, url: image.url })) : []}
          helpBubble={{
            isOpen: helpBubbleVisibility === 'visible',
            onInteractOutside: (e) => {
              e.preventDefault()
            },
          }}
          onClickImageSelectButton={() => setHelpBubbleVisibility('hidden')}
        />
      </ImageUploaderWrapper>
      <TextField
        onChange={onContentChange}
        defaultValue={review?.content ? contentRef.current : undefined}
        imageCount={imageCount}
      />
      <BoxButton
        isDisabled={nextButtonState.isDisabled}
        variant="primary"
        size="xlarge"
        width="100%"
        onClick={onClickNext}>
        다음
      </BoxButton>
    </Layout>
  )
}

export const ImageUploaderWrapper = styled(FormItemWrapper)({
  margin: 0,
})
const Label = styled.p([
  vars.$semantic.typography.label3Regular,
  {
    margin: '-0.625rem 0 0',
    color: vars.$scale.color.gray700,
  },
])

export const multiLineTextFieldStyleOverride = css({
  '& > div[data-part=head] ': {
    margin: 0,
  },
  '& > div[data-part=field]': {
    margin: 0,
    '& > textarea': {
      ...vars.$semantic.typography.bodyM1Regular,
    },
    '& > textarea::placeholder': {
      ...vars.$semantic.typography.bodyM1Regular,
    },
  },
  '& > div[data-part=foot]': {
    margin: 0,
  },
})
