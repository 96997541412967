import { selector } from 'recoil'

import { IS_LOCAL } from '@src/constants/environmentConstants'
import { IS_ANDROID, IS_IOS } from '@src/constants/environmentConstantsWithBridge'

import { appInfoAtom } from '../device/store'
import { satisfyVersion } from '../version/satisfy-version'

export const showShortVideoSelector = selector({
  key: 'showShortVideo',
  get: ({ get }) => {
    if (IS_LOCAL) return true
    /**
     *
     * 안드로이드 23.44.0
     * ios 23.48.0
     *
     */
    const app = get(appInfoAtom)

    const isValidVersion = satisfyVersion({
      os: IS_ANDROID ? 'ANDROID' : IS_IOS ? 'IOS' : 'UNKNOWN',
      version: app.appVersion,
      androidVersionCheck: '>=23.44.0',
      iosVersionCheck: '>=23.48.0',
    })

    return isValidVersion
  },
})
