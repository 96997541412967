import { installKarrotBridgeDriver, makeKarrotBridge } from '@daangn/karrotbridge'
import { CategoryPlugin, LocalBusinessHubSdk } from '@daangn/local-business-webview-hub-sdk'
import { Bridge as NativeBridge } from '@daangn/webview-bridge-modern'
import { PluginAnalytics } from '@daangn/webview-bridge-modern/lib/plugins/Analytics'
import { PluginGeolocation } from '@daangn/webview-bridge-modern/lib/plugins/Geolocation'
import { PluginInfo } from '@daangn/webview-bridge-modern/lib/plugins/Info'
import { PluginRouter } from '@daangn/webview-bridge-modern/lib/plugins/Router'
import { PluginShare } from '@daangn/webview-bridge-modern/lib/plugins/Share'
import { PluginStorage } from '@daangn/webview-bridge-modern/lib/plugins/Storage'
import { PluginToast } from '@daangn/webview-bridge-modern/lib/plugins/Toast'

import { IS_PROD } from '@src/constants/environmentConstants'

import KarrotBridgeCache from './apis/BridgeCache'

const { driver } = installKarrotBridgeDriver()

export const bridge = new NativeBridge({ dangerouslyInjectDriver: driver })
  .addPlugin(PluginAnalytics)
  .addPlugin(PluginInfo)
  .addPlugin(PluginRouter)
  .addPlugin(PluginShare)
  .addPlugin(PluginToast)
  .addPlugin(PluginStorage)
  .addPlugin(PluginGeolocation)

export const karrotBridge = makeKarrotBridge({ driver })

export const karrotBridgeCache = new KarrotBridgeCache()

export const localBusinessHubSdk = new LocalBusinessHubSdk({ bridge: karrotBridge, isProduction: IS_PROD }).add(
  CategoryPlugin
)
