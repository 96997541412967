import semver from 'semver'

export function satisfyVersion({
  os,
  version,
  androidVersionCheck,
  iosVersionCheck,
}: {
  os: 'ANDROID' | 'IOS' | 'UNKNOWN'
  version: string | null
  androidVersionCheck?: string
  iosVersionCheck?: string
}) {
  if (!version) {
    return false
  }

  if (os === 'ANDROID' && androidVersionCheck) {
    return semver.satisfies(version, androidVersionCheck)
  }

  if (os === 'IOS' && iosVersionCheck) {
    return semver.satisfies(version, iosVersionCheck)
  }

  return false
}
