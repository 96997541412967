import { atomFamily, selectorFamily } from 'recoil'

import { ReactionDtoResourceType, ReactionDtoType } from '@src/apis/__generated__/poi'

import { generateKey } from './generateKey'
import { nativeReviewAgreedSelectorFamily } from './native-review-agreed'
import { nativeReviewUpvoteSelectorFamily } from './native-review-upvote'
import { poiMatjibReactionSelectorFamily } from './poi-matjib'

type ReactionResourceType = ReactionDtoResourceType
type ReactionType = ReactionDtoType
export type ReactionKey = {
  resourceId: string
  resourceType: ReactionResourceType
  type: ReactionType
}
export type ReactedUser = {
  id: number
  nickname: string
  profileImageURL?: string
  // 가장 최근 해당 리액션을 했을때의 스냅샷
  residenceInfo: {
    region: {
      id: number
      name: string
    }
    startYearOfResidence: number
  }
}
type ReactionBase = ReactionKey & {
  isReactedByMe: boolean
  totalCount: number
}
// type SortBy = 'residenceYear'
export type ReactionState = ReactionBase & { reactedUsers?: ReactedUser[] }

const reactionSelectorFamily = selectorFamily<ReactionState & { timestamp: number }, ReactionKey>({
  key: generateKey('reaction-selector-family'),
  get:
    (reactionKey) =>
    ({ get }) => {
      let reaction: ReactionState | undefined

      const { resourceType, type } = reactionKey

      if (resourceType === 'NATIVE_REVIEW') {
        if (type === 'AGREED') {
          reaction = get(nativeReviewAgreedSelectorFamily(reactionKey))
        }
        if (type === 'UPVOTE') {
          reaction = get(nativeReviewUpvoteSelectorFamily(reactionKey))
        }
      }

      if (resourceType === 'POI') {
        if (type === 'MATJIB') {
          reaction = get(poiMatjibReactionSelectorFamily(reactionKey))
        }
      }

      const fallback: ReactionState = {
        ...reactionKey,
        isReactedByMe: false,
        totalCount: 0,
      }

      return {
        ...(reaction ?? fallback),
        timestamp: Date.now(),
      }
    },
})

export const reactionAtomFamily = atomFamily<ReactionState, ReactionKey>({
  key: generateKey('reaction-atom-family'),
  default: reactionSelectorFamily,
})
