import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

import { CouponStateReturnValue } from '../types'

interface Props {
  status: CouponStateReturnValue
}

const getCouponBadgeMapper = (status: CouponStateReturnValue): { text: string; color: string } => {
  const { isInactiveStatus, isDeleted, isExpired, isLeak } = status

  if (isInactiveStatus) {
    return { text: '발급중단', color: vars.$scale.color.gray500 }
  }

  if (isDeleted || isExpired) {
    return { text: '기한만료', color: vars.$scale.color.gray500 }
  }

  if (isLeak) {
    return { text: '수량소진', color: vars.$scale.color.gray500 }
  }

  return { text: '발급중', color: vars.$scale.color.carrot500 }
}

const CouponStatusBadge = ({ status }: Props) => {
  const { color, text } = getCouponBadgeMapper(status)

  return <S_Badge color={color}>{text}</S_Badge>
}

const S_Badge = styled.span<{ color: string }>`
  padding: 0.1563rem 0.375rem;
  background: ${({ color }) => color};
  border-radius: 0.625rem;
  font-weight: bold;
  font-size: 0.6875rem;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${vars.$static.color.staticWhite};
  margin-right: 0.375rem;
  white-space: nowrap;
`

export default CouponStatusBadge
