/* eslint-disable import/order */
/* eslint-disable no-restricted-imports */
/* tslint:disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact:
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosRequestConfig, AxiosResponse } from 'axios'
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../configs/common'

import {
  CreateReactionBody,
  CreateReactionDto,
  DeleteReactionBody,
  DeleteReactionDto,
  GetReactionsDto,
  GetReactionsResponseDto,
} from '../../models'
/**
 * ReactionsApi - axios parameter creator
 * @export
 */

/**
 * createReactionV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type CreateReactionV2Params = {
  xAuthToken: string
  createReactionBody: CreateReactionBody
}
/**
 *
 */
const createReactionV2AxiosParamCreator = async (
  // @ts-ignore
  params: CreateReactionV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/me/reactions`
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any
  if (params['xAuthToken'] !== undefined && params['xAuthToken'] !== null) {
    localVarHeaderParameter['x-auth-token'] = String(params['xAuthToken'])
  }

  localVarHeaderParameter['Content-Type'] = 'application/json'

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }
  localVarRequestOptions.data = params['createReactionBody'] || ''

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}
/**
 * deleteReactionV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type DeleteReactionV2Params = {
  xAuthToken: string
  deleteReactionBody: DeleteReactionBody
}
/**
 *
 */
const deleteReactionV2AxiosParamCreator = async (
  // @ts-ignore
  params: DeleteReactionV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/me/reactions`
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'DELETE', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any
  if (params['xAuthToken'] !== undefined && params['xAuthToken'] !== null) {
    localVarHeaderParameter['x-auth-token'] = String(params['xAuthToken'])
  }

  localVarHeaderParameter['Content-Type'] = 'application/json'

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }
  localVarRequestOptions.data = params['deleteReactionBody'] || ''

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}
/**
 * getReactionsV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type GetReactionsV2Params = {
  xAuthToken: string
  resourceType: 'STORY_ARTICLE' | 'STORY_COMMENT' | 'POI_REVIEW' | 'NATIVE_REVIEW' | 'POI'
  resourceIds: Array<string>
}
/**
 *
 */
const getReactionsV2AxiosParamCreator = async (
  // @ts-ignore
  params: GetReactionsV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/me/reactions`
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any
  if (params['resourceType'] !== undefined) {
    localVarQueryParameter['resourceType'] = params['resourceType']
  }

  if (params['resourceIds']) {
    localVarQueryParameter['resourceIds'] = params['resourceIds']
  }

  if (params['xAuthToken'] !== undefined && params['xAuthToken'] !== null) {
    localVarHeaderParameter['x-auth-token'] = String(params['xAuthToken'])
  }

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}
/**
 * paginateReactionsV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type PaginateReactionsV2Params = {
  xAuthToken: string
  resourceType: 'STORY_ARTICLE' | 'STORY_COMMENT' | 'POI_REVIEW' | 'NATIVE_REVIEW' | 'POI'
  resourceId: string
  type: 'UPVOTE' | 'DOWNVOTE' | 'AGREED' | 'MATJIB'
  page: number
  perPage: number
  sortBy: 'residenceYear'
}
/**
 *
 */
const paginateReactionsV2AxiosParamCreator = async (
  // @ts-ignore
  params: PaginateReactionsV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/reactions`
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any
  if (params['resourceType'] !== undefined) {
    localVarQueryParameter['resourceType'] = params['resourceType']
  }

  if (params['resourceId'] !== undefined) {
    localVarQueryParameter['resourceId'] = params['resourceId']
  }

  if (params['type'] !== undefined) {
    localVarQueryParameter['type'] = params['type']
  }

  if (params['page'] !== undefined) {
    localVarQueryParameter['page'] = params['page']
  }

  if (params['perPage'] !== undefined) {
    localVarQueryParameter['perPage'] = params['perPage']
  }

  if (params['sortBy'] !== undefined) {
    localVarQueryParameter['sortBy'] = params['sortBy']
  }

  if (params['xAuthToken'] !== undefined && params['xAuthToken'] !== null) {
    localVarHeaderParameter['x-auth-token'] = String(params['xAuthToken'])
  }

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}

/**
 *
 */
const createReactionV2Fp = async ({
  params,
  options,
}: {
  params: CreateReactionV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<CreateReactionDto>> => {
  const localVarAxiosArgs = await createReactionV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}
/**
 *
 */
const deleteReactionV2Fp = async ({
  params,
  options,
}: {
  params: DeleteReactionV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<DeleteReactionDto>> => {
  const localVarAxiosArgs = await deleteReactionV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}
/**
 *
 */
const getReactionsV2Fp = async ({
  params,
  options,
}: {
  params: GetReactionsV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<GetReactionsDto>> => {
  const localVarAxiosArgs = await getReactionsV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}
/**
 *
 */
const paginateReactionsV2Fp = async ({
  params,
  options,
}: {
  params: PaginateReactionsV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<GetReactionsResponseDto>> => {
  const localVarAxiosArgs = await paginateReactionsV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}

export const createReactionV2 = ({
  params,
  options,
}: {
  params: CreateReactionV2Params
  options?: AxiosRequestConfig
}) => {
  return createReactionV2Fp({ params, options })
}
export const deleteReactionV2 = ({
  params,
  options,
}: {
  params: DeleteReactionV2Params
  options?: AxiosRequestConfig
}) => {
  return deleteReactionV2Fp({ params, options })
}
export const getReactionsV2 = ({ params, options }: { params: GetReactionsV2Params; options?: AxiosRequestConfig }) => {
  return getReactionsV2Fp({ params, options })
}
export const paginateReactionsV2 = ({
  params,
  options,
}: {
  params: PaginateReactionsV2Params
  options?: AxiosRequestConfig
}) => {
  return paginateReactionsV2Fp({ params, options })
}
