import { hasBatchim } from './hasBatchim'

type Josa = '을/를' | '이/가' | '은/는' | '와/과'

export const josa = (word: string, josa: Josa) => {
  if (word.length === 0) return word

  const appropriateJosa = josa.split('/')[hasBatchim(word) ? 0 : 1]

  return `${word}${appropriateJosa}`
}
