import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

import { useImageSliderState } from '@src/react-utils/hooks/useImageSliderState'

import ImageCenterCover from './ImageCenterCover'
import ImageDetailSlider from './ImageDetailSlider'

interface Props {
  images: string[]
}

const ImageBoardNativeReview = ({ images }: Props) => {
  const { handleCloseDetailImage, handleSelectDetailImage, selectedImageIdx } = useImageSliderState()

  const handleClickMorePhotos = (index: number) => {
    handleSelectDetailImage(index)
  }

  return images.length === 0 ? null : (
    <>
      <StyledImageBoard imageLength={images.length}>
        {images.length >= 4 ? (
          <div className="leftside-images">
            {images.slice(0, 2).map((image, idx) => {
              return (
                <div key={idx} className="image-container" onClick={handleClickMorePhotos.bind(null, idx)}>
                  <ImageCenterCover src={image} />
                </div>
              )
            })}
          </div>
        ) : (
          <div className="image-container" onClick={handleClickMorePhotos.bind(null, 0)}>
            <ImageCenterCover src={images[0]} />
          </div>
        )}
        {images.length >= 2 && (
          <div className="rightside-images">
            {(images.length < 4 ? images.slice(1, images.length) : images.slice(2, 4)).map((image, idx) => {
              const showMorePhotoOverlay = idx === 1 && images.length > 4
              const clickedIndex = images.length < 4 ? idx + 1 : idx + 2
              return (
                <div key={idx} className="image-container" onClick={handleClickMorePhotos.bind(null, clickedIndex)}>
                  <ImageCenterCover src={image} />
                  {showMorePhotoOverlay && <div className="more-photo-overlay">+{images.length - 4}</div>}
                </div>
              )
            })}
          </div>
        )}
      </StyledImageBoard>
      <ImageDetailSlider onClose={handleCloseDetailImage} initialIndex={selectedImageIdx} images={images} />
    </>
  )
}

const StyledImageBoard = styled.div<{ imageLength: number }>`
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  width: 100%;
  .image-container {
    position: relative;
    display: block;
    width: ${({ imageLength }) => (imageLength > 1 ? '50%' : '100%')};
    height: 257px;
  }
  .leftside-images,
  .rightside-images {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 2px;
    height: 257px;
    width: ${({ imageLength }) => (imageLength > 1 ? '50%' : '100%')};
    .image-container {
      height: ${({ imageLength }) => (imageLength > 2 ? '50%' : '100%')};
      width: 100%;
    }
    .image-container:first-of-type {
      margin: 0 0 2px;
    }
    .more-photo-overlay {
      ${vars.$semantic.typography.label1Regular}
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: rgba(0, 0, 0, 0.4);
      color: ${vars.$static.color.staticWhite};
    }
  }
  .leftside-images {
    margin: 0;
  }
`

export default ImageBoardNativeReview
