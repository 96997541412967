import { memo, useMemo } from 'react'

import { useWatch } from '@src/place-utils/watch/hooks'
import { KakaoMarker, useKakaoMarkerImage } from '@src/react-utils/components/Map/KakaoMap'
import { withSuspense } from '@src/react-utils/components/Suspense'

import { useSelectedComment } from '../hooks/useSelectedComment'

interface Props {
  commentId: string
  poi: {
    id: string
    bizAccountId: string | null
    coordinates: {
      latitude: number
      longitude: number
    }
  }
  onClick: ({ commentId }: { commentId: string }) => void
}
export const Marker = withSuspense(
  memo(({ commentId, poi, onClick }: Props) => {
    const { selectedPoi } = useSelectedComment()
    const selectedPoiId = selectedPoi?.id

    const { markerImage } = useKakaoMarkerImage({ color: 'carrot' })
    const watchId = poi.bizAccountId
      ? { targetType: 'BA' as const, targetId: poi.bizAccountId }
      : { targetType: 'POI' as const, targetId: poi.id }

    const { watched } = useWatch({
      placeWatchId: watchId,
    })

    const coordinates = poi.coordinates
    interface Marker {
      image: any
      zIndex: number
    }

    const marker = useMemo<Marker>(() => {
      const isSelected = selectedPoiId === poi.id

      if (watched)
        return isSelected
          ? { image: markerImage.selectedWatched, zIndex: 3 }
          : { image: markerImage.watched, zIndex: 2 }

      return isSelected ? { image: markerImage.selected, zIndex: 3 } : { image: markerImage.default, zIndex: 1 }
    }, [
      selectedPoiId,
      poi.id,
      watched,
      markerImage.selectedWatched,
      markerImage.watched,
      markerImage.selected,
      markerImage.default,
    ])

    if (!poi || !coordinates) return null

    return (
      <KakaoMarker
        position={{
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        }}
        image={marker.image}
        onClick={() => onClick({ commentId })}
        zIndex={marker.zIndex}
      />
    )
  })
)
