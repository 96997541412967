import { Category } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import IconSearchRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconSearchRegular'
import debounce from 'lodash/debounce'
import { useState, useRef, useEffect, ChangeEvent } from 'react'
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil'

import { bridge } from '@src/bridge'
import { TextInput } from '@src/react-utils/components/Input'
import { TRANSITION_DURATION } from '@src/stackflow/styles'

import { useAnalytics } from './hooks'
import LoadMoreCategories from './LoadMoreCategories'
import SearchResults from './SearchResults'
import { paginationAtomFamily, SearchCategoriesClientName, searchTextAtomFamily } from './state'

export interface ResultComponentBaseProps {
  isActive: boolean
  onClick: (argument: Category) => void
}

interface Props {
  onChangeSearchText?: (searchText: string) => void
  onClickSearchResult: (category: Category, searchText: string) => void
  clientName: SearchCategoriesClientName
}

function SearchCategory({ onChangeSearchText, onClickSearchResult, clientName }: Props) {
  const logEvent = useAnalytics()

  const [searchText, setSearchText] = useRecoilState(searchTextAtomFamily(clientName))
  const pagination = useRecoilValue(
    paginationAtomFamily({
      clientName,
      searchText,
    })
  )

  const asyncSetPagination = useRecoilCallback(
    ({ snapshot, set }) =>
      async (page: number) => {
        const searchText = await snapshot.getPromise(searchTextAtomFamily(clientName))

        set(paginationAtomFamily({ clientName, searchText }), (prev) => ({
          ...prev,
          page,
        }))
      },
    [clientName]
  )

  const [inputText, setInputText] = useState('')

  const inputRef = useRef<HTMLInputElement>(null)

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChangeSearchText) {
      onChangeSearchText(e.target.value)
    }
    setInputText(e.target.value)
  }

  const handleClearInput = () => {
    setSearchText('')
    setInputText('')
    asyncSetPagination(0)
  }

  const handleSelectCategory = (category: Category) => {
    if (category.id) {
      onClickSearchResult(category, searchText)
    }
  }

  useEffect(() => {
    if (!inputRef.current) return
    const element = inputRef.current

    const onKeydown = debounce(async function (this: HTMLInputElement, e: KeyboardEvent) {
      const value = (e?.target as HTMLInputElement | null)?.value
      if (!value) return
      bridge.analytics.log({ name: 'search_all_category', params: { from: 'poi_webview', keyword: searchText } })
      logEvent('handle_search_category_input', {
        query: value,
      })
      setSearchText(value)
      asyncSetPagination(1)
    }, 500)

    element.addEventListener('keydown', onKeydown)

    return () => {
      element.removeEventListener('keydown', onKeydown)
    }
  }, [asyncSetPagination, logEvent, pagination, searchText, setSearchText])

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus?.(), TRANSITION_DURATION)
  }, [])

  return (
    <>
      <StyledSearchBar>
        <form action="." noValidate onSubmit={(e) => e.preventDefault()}>
          <TextInput
            type="search"
            ref={inputRef}
            value={inputText}
            placeholder="예) 카페, 반찬가게, 용달 등"
            onChange={handleChangeInput}
            appendRight={<IconSearchRegular className="icon" />}
            onClearInputValue={handleClearInput}
          />
        </form>
      </StyledSearchBar>
      <SearchResults clientName={clientName} onClickSearchResult={handleSelectCategory} />
      <LoadMoreCategories clientName={clientName} />
    </>
  )
}

const StyledSearchBar = styled.div`
  padding: 24px 16px 0;
`

export default SearchCategory
