import { Poi } from '@daangn/local-business-network/lib/poi'

import { winterSnackEventId } from '@src/constants/poiEvent'
import { WEEK_ID_VALUE_MAPPER } from '@src/react-utils/components/Input/BusinessHours/BusinessWeekdaysInput'

import type { FormValues, SuggestionRequestBody } from '../types'

export const parseFromPoiToValues = (poi: Poi): FormValues => {
  const winterSnackEvent = poi?.events.find(({ id }) => id === winterSnackEventId)
  const winterSnackMenus = winterSnackEvent
    ? poi.attributes
        ?.filter(({ id }) => winterSnackEvent.attributes?.find(({ id: searchingId }) => id === searchingId))
        .map(({ id }) => id.toString())
    : undefined

  return {
    name: poi.name,
    address: {
      roadAddress: poi.address ?? undefined,
      addressExtra: poi.addressExtra ?? undefined,
      jibunAddress: poi.jibunAddress ?? undefined,
      coordinates: poi.coordinates ?? undefined,
    },
    categoryId: poi.category?.id,
    phone: poi.phone ?? undefined,
    operatingInformation: {
      weekdayIds: [],
      businessHours: [null, null],
    },
    url: poi.url ?? undefined,
    winterSnackMenu: winterSnackMenus,
  }
}

const parseOperatingInformation = (
  operatingInformation: FormValues['operatingInformation']
): SuggestionRequestBody['openingHours'] => {
  if (!operatingInformation) {
    return undefined
  }

  const { weekdayIds, businessHours } = operatingInformation
  const openingTime = businessHours[0] ?? undefined
  const closingTime = businessHours[1] ?? undefined

  if (!weekdayIds || weekdayIds.length === 0) {
    if (openingTime || closingTime) {
      return [{ weekOfDay: 'unknown', openingTime, closingTime }]
    } else {
      return undefined
    }
  }

  return weekdayIds.map((weekdayId) => ({
    weekOfDay: WEEK_ID_VALUE_MAPPER[weekdayId] ?? 'unknown',
    openingTime,
    closingTime,
  }))
}

export const parseFromValuesToRequestBody = (values: FormValues): SuggestionRequestBody => {
  const {
    name,
    address: addressValues,
    categoryId,
    phone,
    operatingInformation,
    url,
    pricing,
    photos,
    createSuggestionDescription,
    winterSnackMenu,
    categorySuggestionId,
  } = values

  const { roadAddress, addressExtra, jibunAddress, coordinates } = addressValues
  const openingHours = parseOperatingInformation(operatingInformation)

  return {
    name,
    categoryId,
    categorySuggestionId,
    phone,
    address: roadAddress,
    addressExtra,
    jibunAddress,
    coordinates,
    openingHours,
    url,
    pricingImageIds: pricing,
    imageIds: photos,
    userNote: createSuggestionDescription,
    eventId: winterSnackMenu && winterSnackMenu.length > 0 ? winterSnackEventId : undefined,
    attributeIds: winterSnackMenu?.map((id) => parseInt(id, 10)),
  }
}
