import { StarRating } from '@daangn/business-review-ui'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useRecoilValue } from 'recoil'

import { delay } from '@src/js-utils/timeout'
import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'
import { userInfoAtom } from '@src/place-utils/user/store'
import { TRANSITION_DURATION } from '@src/stackflow/styles'

import { useBusinessReviewRoutingManager } from './hooks/useBusinessReviewRoutingManager'
import { myPoiReviewSelectorFamily } from './store'

interface ReviewInducementProps {
  poiId: string
  className?: string
  onClickStarRating?: (starRating: number) => void

  area: string
}
export const ReviewInducement = ({ poiId, onClickStarRating, ...props }: ReviewInducementProps) => {
  const { openCreateReviewPage } = useBusinessReviewRoutingManager()
  const { nickname } = useRecoilValue(userInfoAtom)
  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))
  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId }))
  const userReviewId = myReview?.id
  const hasUserReview = !!userReviewId

  if (hasUserReview) {
    return null
  }
  return (
    <Base className={props.className}>
      <Box>
        <Title>
          {nickname}님, {poi.name} 이용하셨나요?
        </Title>
        <Subtitle>이웃에게 이용 경험을 알려보세요.</Subtitle>
      </Box>
      <StarRating
        size="large"
        onClick={async (_e, { value }) => {
          onClickStarRating?.(value)

          await delay(TRANSITION_DURATION)

          openCreateReviewPage({ poiId, starRating: value })
        }}
      />
    </Base>
  )
}

const Base = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  gap: '1rem',
  alignItems: 'center',
})
const Box = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  gap: '0.25rem',
})
const Title = styled('span')([
  vars.$semantic.typography.title3Bold,
  {
    textAlign: 'center',
    wordBreak: 'keep-all',
    color: vars.$scale.color.gray900,
  },
])
const Subtitle = styled('span')([
  vars.$semantic.typography.subtitle2Regular,
  {
    textAlign: 'center',
    color: vars.$scale.color.gray600,
  },
])
