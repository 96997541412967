import {
  suggestUpdateV1,
  SuggestPoiUpdateRequest,
  getRegionV2,
} from '@daangn/local-business-network/lib/businessAccount'
import { AlertDialog, DialogContainer } from '@daangn/sprout-components-dialog'
import * as Sentry from '@sentry/react'
import { ActivityComponentType, useActivityParams } from '@stackflow/react'
import { FormikHelpers } from 'formik'
import flatten from 'lodash/flatten'
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { bridge } from '@src/bridge'
import { eventOptions, winterSnackEventId } from '@src/constants/poiEvent'
import { CategoryInputProvider } from '@src/place-utils/category/CategoryInput'
import { selectedCategoryAtom } from '@src/place-utils/category/CategoryInput/state'
import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'
import { checkInRegionInfoAtom } from '@src/place-utils/region/store'
import { userInfoAtom } from '@src/place-utils/user/store'
import { FormPage } from '@src/place-utils/widgets/form/FormPage'
import type { Field, UpdateSuggestionRequestBody } from '@src/place-utils/widgets/form/types'
import { addressPickerAtom } from '@src/react-utils/components/Input/AddressInput/store/addressPicker'
import { useToast } from '@src/react-utils/components/Toast'
import { useNavigator, useStepNavigator } from '@src/stackflow'

import { useAnalytics } from './hooks'
import RemoveSuggestion from './RemoveSuggestion'
import { errorMessage } from '../../react-utils/components/Error/constants'

type DialogType = 'completeSubmit'

type ActivityParams = {
  poiId: string
  alert?: DialogType
}
const UpdateSuggestion: ActivityComponentType<ActivityParams> = ({ params: { poiId, alert } }) => {
  const logEvent = useAnalytics()
  const { pop } = useNavigator()
  const { stepPush } = useStepNavigator('update_poi_suggestion')
  const { showToast } = useToast()

  const setAddressPicker = useSetRecoilState(addressPickerAtom)
  const setSelectedCategory = useSetRecoilState(selectedCategoryAtom)
  const userInfo = useRecoilValue(userInfoAtom)
  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))
  const userRegionInfo = useRecoilValue(checkInRegionInfoAtom)
  const { referrer } = useActivityParams<{ referrer: string }>()

  const [isInitialValuesSet, setIsInitialValuesSet] = useState(false)

  const filteredEventsByEventId =
    poi?.events &&
    poi.events.filter(({ id, name }) => {
      return id === winterSnackEventId && eventOptions[name]
    })

  const selectedAttributeIds = useMemo(() => {
    const selectedAttributeIds = poi?.attributes ?? []
    return flatten(
      filteredEventsByEventId?.map(({ attributes: eventAttributes }) => {
        return selectedAttributeIds
          .map(({ id }) => id)
          .filter(
            (selectedAttributeId) =>
              // https://karrot.atlassian.net/browse/PLC-3447?atlOrigin=eyJpIjoiNGFkNDJmOTI4N2JjNGUzYmI5YmY3NGE2MjhiZTE0ZjgiLCJwIjoiaiJ9
              eventAttributes?.some((eventAttribute: any) => eventAttribute?.id === selectedAttributeId)
          )
      })
    )
  }, [poi, filteredEventsByEventId])

  const fetchGetRegion = useCallback(async (address: string) => {
    try {
      const data = await getRegionV2({
        params: {
          address,
        },
      }).then((res) => res.data.data!)

      if (!data.id) throw new Error('지역 정보를 가져오는데 실패했어요.')

      return data.id
    } catch (e) {
      throw e
    }
  }, [])
  const handleSubmit = async (requestBody: Partial<UpdateSuggestionRequestBody>, { setErrors }: FormikHelpers<any>) => {
    const isWinterSnack = !!winterSnackEvent
    const attributeIds = requestBody?.attributeIds || selectedAttributeIds
    const hasAttributeIds = attributeIds && attributeIds.length > 0
    if (isWinterSnack && !hasAttributeIds) {
      setErrors({
        attributeIds: '판매하는 메뉴를 선택해주세요.',
      })
      return
    }

    const AUTH_TOKEN_ERROR = 'AUTH_TOKEN_ERROR'

    try {
      if (!userInfo?.authToken) throw new Error(AUTH_TOKEN_ERROR)
      if (!(requestBody.coordinates?.longitude && requestBody.coordinates?.latitude))
        throw new Error('coordinate is required')
      if (!requestBody.name) throw new Error('name is required')
      if (!requestBody.address && !requestBody.jibunAddress) throw new Error('address is required')

      const regionId = await fetchGetRegion(requestBody.address || requestBody.jibunAddress || '')

      const { coordinates, address, ...params } = requestBody

      await suggestUpdateV1({
        params: {
          suggestPoiUpdateRequest: {
            ...params,

            roadAddress: address,
            referrer,
            userRegionId: Number(userRegionInfo.id),
            regionId: Number(regionId),
            name: requestBody.name,
            coordinate: {
              x: requestBody.coordinates.longitude,
              y: requestBody.coordinates.latitude,
            },

            poiId: Number(poiId),
            openingHours: requestBody.openingHours as SuggestPoiUpdateRequest['openingHours'],
          },
        },
      })

      logEvent('click_submit_form_button')

      stepPush({ poiId, alert: 'completeSubmit' })
    } catch (err) {
      Sentry.captureException(err)

      if (err instanceof Error) {
        if (err.message === AUTH_TOKEN_ERROR) {
          return showToast({
            text: errorMessage.AUTHENTICATION_MESSAGE,
            duration: 'long',
          })
        }
      }

      bridge.toast.open({ body: errorMessage.PLEASE_RETRY_MESSAGE })
    }
  }

  const winterSnackEvent = useMemo(() => poi?.events.find(({ id }) => id === winterSnackEventId), [poi])

  const fields = useMemo(() => {
    const baseFields: Field<
      'name' | 'address' | 'categoryId' | 'phone' | 'operatingInformation' | 'url' | 'pricing' | 'photos'
    >[] = [
      {
        id: 'name',
        isRequired: true,
      },
      {
        id: 'address',
        isRequired: true,
      },
      {
        id: 'categoryId',
      },
      {
        id: 'phone',
      },
      {
        id: 'operatingInformation',
      },
      {
        id: 'url',
      },
      {
        id: 'pricing',
      },
      {
        id: 'photos',
      },
    ]

    if (winterSnackEvent) {
      const winterSnackFields: Field<
        | 'name'
        | 'winterSnackMenu'
        | 'address'
        | 'categoryId'
        | 'phone'
        | 'operatingInformation'
        | 'url'
        | 'pricing'
        | 'photos'
      >[] = [
        {
          id: 'name',
          isRequired: true,
        },
        {
          id: 'winterSnackMenu',
          isRequired: true,
        },
        {
          id: 'address',
          isRequired: true,
        },
        {
          id: 'categoryId',
        },
        {
          id: 'phone',
        },
        {
          id: 'operatingInformation',
        },
        {
          id: 'url',
        },
        {
          id: 'pricing',
        },
        {
          id: 'photos',
        },
      ]

      return winterSnackFields
    }

    return baseFields
  }, [winterSnackEvent])

  // Set picker values to poi, if is update suggestion
  useEffect(() => {
    if (!poi) return

    if (poi.address || poi.jibunAddress || poi.coordinates) {
      setAddressPicker({
        roadAddress: poi.address ?? undefined,
        jibunAddress: poi.jibunAddress ?? undefined,
        coordinates: poi.coordinates ?? undefined,
      })
    }

    if (poi.category) setSelectedCategory(poi.category)

    setIsInitialValuesSet(true)
  }, [poi, setAddressPicker, setSelectedCategory, winterSnackEvent])

  return poi && isInitialValuesSet ? (
    <CategoryInputProvider entry="updateSuggestion">
      <FormPage
        fields={fields}
        title="정보 수정 제안"
        poi={poi}
        onClickAddressSearch={() => {
          bridge.analytics.log({
            name: 'click_tagging_address_register',
            params: { from: 'search' },
          })
          logEvent('click_search_address_input')
        }}
        onClickPinAddress={
          window.kakao
            ? (e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                bridge.analytics.log({
                  name: 'click_tagging_address_register',
                  params: { from: 'map' },
                })
                logEvent('click_pin_address_button')
              }
            : undefined
        }
        appendBottom={() => {
          return <RemoveSuggestion poiId={poiId} />
        }}
        onSubmit={handleSubmit}
      />

      {alert === 'completeSubmit' && (
        <DialogContainer>
          <AlertDialog
            description="소중한 제안 감사해요. 정보가 반영되면 알려드릴게요."
            primaryActionLabel="확인"
            onPrimaryAction={pop}
          />
        </DialogContainer>
      )}
    </CategoryInputProvider>
  ) : null
}

export default UpdateSuggestion
