import { BoxButton } from '@daangn/sprout-components-button'
import styled from '@emotion/styled'
import IconCloseRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCloseRegular'
import { vars } from '@seed-design/design-token'
import {
  BottomSheet as StackflowBottomSheet,
  BottomSheetProps as StackflowBottomSheetProps,
} from '@stackflow/plugin-basic-ui'
import { CSSProperties, ReactNode } from 'react'

import { renderMultilineText } from '@src/react-utils/utils'
import { APP_BAR_HEIGHT } from '@src/stackflow/styles'
import { safeAreaPadding } from '@src/styles/safeArea'
import { getSafeAreaValue } from '@src/styles/utils'

import { Divider } from '../Divider'

export interface BottomSheetProps extends Pick<StackflowBottomSheetProps, 'onOutsideClick'> {
  contents: ReactNode
  title?:
    | string
    | {
        render?: () => ReactNode
      }
  description?:
    | string
    | {
        render?: () => ReactNode
      }
  closeButton?: {
    onClick: () => void
  }
  CTAButton?: {
    label: string
    onClick: () => void
  }

  options?: {
    headerAlign?: 'left' | 'center'
    useDividerBetweenHeaderAndContent?: boolean
    contentsOverflow?: CSSProperties['overflow']
    useContentsSafeAreaPadding?: boolean
    UNSTABLE_overrideMaxHeight?: CSSProperties['maxHeight']
  }
}
export const BottomSheet = ({
  contents,
  title,
  description,
  closeButton,
  CTAButton,
  options,
  ...stackflowBottomSheetProps
}: BottomSheetProps) => {
  const useHeader = !!title || !!description || !!closeButton
  const useDivider = (useHeader && options?.useDividerBetweenHeaderAndContent) ?? false

  return (
    <StackflowBottomSheet {...stackflowBottomSheetProps}>
      <Container maxHeight={options?.UNSTABLE_overrideMaxHeight}>
        {useHeader && (
          <Header align={options?.headerAlign}>
            {(!!title || !!description) && (
              <div>
                {title &&
                  (typeof title === 'string' ? (
                    <Title>{renderMultilineText(title)}</Title>
                  ) : 'render' in title && title.render ? (
                    <>{title.render()}</>
                  ) : null)}
                {description &&
                  (typeof description === 'string' ? (
                    <Description>{renderMultilineText(description)}</Description>
                  ) : 'render' in description && description.render ? (
                    <>{description.render()}</>
                  ) : null)}
              </div>
            )}
            {closeButton && (
              <CloseButton onClick={closeButton.onClick}>
                <IconCloseRegular />
              </CloseButton>
            )}
          </Header>
        )}
        {useDivider && <Divider style={{ margin: '0 1rem', width: 'auto' }} />}
        <Contents overflow={options?.contentsOverflow} useSafeAreaPadding={options?.useContentsSafeAreaPadding}>
          {contents}
        </Contents>
        {CTAButton && (
          <ButtonWrapper>
            <BoxButton variant="primary" size="xlarge" width="100%" onClick={CTAButton.onClick}>
              {CTAButton.label}
            </BoxButton>
          </ButtonWrapper>
        )}
      </Container>
    </StackflowBottomSheet>
  )
}

const Container = styled.div<{ maxHeight: NonNullable<BottomSheetProps['options']>['UNSTABLE_overrideMaxHeight'] }>([
  {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    maxHeight: '23.438rem',
  },
  ({ maxHeight }) =>
    maxHeight && {
      maxHeight: `calc(100vh - ${APP_BAR_HEIGHT}px - ${getSafeAreaValue('top')})` /* Set initial max-height to 100vh */,

      [`@media (min-height: ${maxHeight})`]: {
        maxHeight: maxHeight, // Override max-height
      },
    },
])
const CloseButton = styled.button([
  {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    top: '1.25rem',
    right: '1rem',
    color: vars.$scale.color.gray900,
  },
])
const Header = styled.div<{ align: NonNullable<BottomSheetProps['options']>['headerAlign'] }>([
  {
    padding: '1.25rem 1rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.375rem',
  },
  ({ align = 'left' }) => ({
    alignItems: align === 'center' ? 'center' : 'flex-start',
  }),
])
const Title = styled.h1([
  vars.$semantic.typography.title2Bold,
  {
    color: vars.$scale.color.gray900,
  },
])
const Description = styled.p([
  vars.$semantic.typography.bodyM1Regular,
  {
    color: vars.$scale.color.gray700,
  },
])
const Contents = styled.div<{
  overflow?: NonNullable<BottomSheetProps['options']>['contentsOverflow']
  useSafeAreaPadding?: boolean
}>([
  {
    flex: 1,
  },
  ({ overflow = 'auto' }) => ({ overflow }),
  ({ useSafeAreaPadding = false }) => useSafeAreaPadding && safeAreaPadding('bottom'),
])
const ButtonWrapper = styled.div([
  {
    padding: '0.75rem 1rem 1rem',
  },
  safeAreaPadding('bottom', '1rem'),
])
