import IconWatchRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconHeartRegular'
import { vars } from '@seed-design/design-token'

interface Props {
  className?: string
}

function WatchButtonPlaceholder({ className }: Props) {
  return <IconWatchRegular className={className} color={vars.$scale.color.gray400} height="1.5rem" width="1.5rem" />
}

export default WatchButtonPlaceholder
