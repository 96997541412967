import { RefObject, useEffect } from 'react'

export interface IUseInfiniteScroll {
  ref: RefObject<HTMLElement>
  onIntersecting: () => void
  options?: {
    threshold: number
  }
}

function useInfiniteScroll({
  ref,
  onIntersecting,
  options = {
    threshold: 0.5,
  },
}: IUseInfiniteScroll) {
  useEffect(() => {
    const observingElement = ref.current

    if (!observingElement) return

    const observer = new IntersectionObserver(async ([entry]) => {
      if (entry.isIntersecting) {
        onIntersecting()
      }
    }, options)
    observer.observe(observingElement)

    return () => {
      if (!observingElement || !observer) return
      observer.disconnect()
    }
  }, [onIntersecting, options, ref])
}

export default useInfiniteScroll
