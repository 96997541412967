import { ComponentType, useCallback, useReducer } from 'react'
import { createPortal } from 'react-dom'

import ToastContext from './context'
import type { IToast } from './context'
import ToastItems from './ToastItems'

type ToastState = { [id: string]: IToast }
type ToastStateActionType =
  | {
      type: 'add'
      payload: ToastState
    }
  | {
      type: 'remove'
      payload: string
    }

function withToast<P extends object>(Component: ComponentType<P>) {
  const WithToast = (props: P) => {
    const [toasts, dispatchToasts] = useReducer((state: ToastState, action: ToastStateActionType) => {
      switch (action.type) {
        case 'remove': {
          const newState = { ...state }
          delete newState[action.payload]

          return newState
        }
        case 'add': {
          return {
            ...state,
            ...action.payload,
          }
        }
      }
    }, {})

    const removeToast = useCallback((toastId: string) => {
      dispatchToasts({ type: 'remove', payload: toastId })
    }, [])

    const addToast = useCallback(
      (toast: IToast) => {
        const id = Math.floor(Date.now() / 1000).toString()

        dispatchToasts({
          type: 'add',
          payload: {
            [id]: toast,
          },
        })

        const durationInMs =
          typeof toast.duration === 'number' ? toast.duration : toast.duration === 'long' ? 5000 : 3000

        setTimeout(() => {
          removeToast(id)
          toast.onRemove?.()
        }, durationInMs)
      },
      [removeToast]
    )

    return (
      <ToastContext.Provider
        value={{
          toasts,
          addToast,
          removeToast,
        }}>
        <Component {...props} />
        {createPortal(<ToastItems />, document.body)}
      </ToastContext.Provider>
    )
  }

  return WithToast
}

export default withToast
