import { deleteNativeReviewV2 } from '@daangn/local-business-network/lib/poi'
import { useActivityParams } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { userInfoAtom } from '@src/place-utils/user/store'
import { AlertDialog } from '@src/react-utils/components/AlertDialog'
import { useToast } from '@src/react-utils/components/Toast'
import { useNavigator } from '@src/stackflow'

import { NativeReviewModalParams } from '.'
import { useNativeReviews } from '../hooks'

export const DeleteConfirmAlertDialog = () => {
  const { pop } = useNavigator()
  const { reviewId } = useActivityParams<NativeReviewModalParams>()
  const { showToast } = useToast()
  const { reset: resetReviews } = useNativeReviews()
  const userInfo = useRecoilValue(userInfoAtom)

  const handleClickDeleteConfirm = async () => {
    if (!reviewId || !userInfo) {
      return
    }

    await deleteNativeReviewV2({
      params: {
        id: parseInt(reviewId, 10),
        xAuthToken: userInfo.authToken,
      },
    })
    pop(2)
    resetReviews()
    showToast({
      text: '추천 글을 삭제했어요.',
      duration: 'short',
    })
  }

  const handleClickCancel = () => {
    pop()
  }

  return (
    <AlertDialog
      contents="추천 글을 삭제할까요?"
      CTAButton={{
        label: '삭제',
        onClick: handleClickDeleteConfirm,
      }}
      subButton={{
        label: '취소',
        onClick: handleClickCancel,
      }}
      buttonAlign="row"
    />
  )
}
