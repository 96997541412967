import { css } from '@emotion/react'
import styled from '@emotion/styled'
import IconCloseRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCloseRegular'
import { vars } from '@seed-design/design-token'
import { MouseEvent, MouseEventHandler } from 'react'
import { atom, useRecoilState } from 'recoil'

import { ReactComponent as SpeakerIconSVG } from '@src/assets/icons/icon_ct_speaker.svg'
import { GO_SCHEME } from '@src/constants/url'
import { isDateWithinAPeriod } from '@src/js-utils/date'
import { generateUniqueKey } from '@src/js-utils/key'
import { localStorage, LOCAL_STORAGE_KEYS } from '@src/js-utils/localStorage'
import { useLogEvent } from '@src/react-utils/components/Analytics'
// import { IS_PROD } from '@src/constants/environmentConstants'

import { WINTER_SNACK_SERVICE_END_DATE } from './constants/date'

const generateRecoilKey = generateUniqueKey('event_banner')

type BannerVisibility = 'visible' | 'hidden'
const bannerVisibilityAtom = atom<BannerVisibility>({
  key: generateRecoilKey('visibility'),
  default: (async () => {
    const visibility = await localStorage.getItem(LOCAL_STORAGE_KEYS.WINTER_SNACK_22_23_WRAP_UP_BANNER)
    if (!visibility) {
      return 'visible'
    } else {
      return visibility as BannerVisibility
    }
  })(),
  effects: [
    ({ onSet }) => {
      onSet((newValue) => localStorage.setItem(LOCAL_STORAGE_KEYS.WINTER_SNACK_22_23_WRAP_UP_BANNER, newValue))
    },
  ],
})

interface Props {
  viewType: 'map' | 'list'
  onClickBanner?: MouseEventHandler<HTMLDivElement>
  onClickClose?: MouseEventHandler<HTMLButtonElement>
}
export const EventBanner = ({ viewType, onClickBanner, onClickClose }: Props) => {
  const logEvent = useLogEvent()

  const [bannerVisibility, setBannerVisibility] = useRecoilState(bannerVisibilityAtom)

  const handleClickBanner = (e: MouseEvent<HTMLDivElement>) => {
    logEvent({ name: 'click_event_banner', params: {} })

    onClickBanner?.(e)

    window.location.href = `${GO_SCHEME}place/winter-snack/22-23-wrap-up?referrer=winter_snack_map.banner`
  }
  const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onClickClose?.(e)
    setBannerVisibility('hidden')
  }

  const START_DATE = '2023-03-21 08:00'
  const isEventPeriod = isDateWithinAPeriod({
    startDate: START_DATE,
    endDate: WINTER_SNACK_SERVICE_END_DATE,
  })

  // 배너를 숨기는 조건
  // if (IS_PROD) return null
  if (bannerVisibility === 'hidden' || !isEventPeriod) return null

  const fadeOutData = new Date(WINTER_SNACK_SERVICE_END_DATE)
  return (
    <Container viewType={viewType} onClick={handleClickBanner}>
      <Icon>
        <SpeakerIconSVG />
      </Icon>
      <Description>
        겨울 간식 지도는{' '}
        <span>
          {fadeOutData.getMonth() + 1}월 {fadeOutData.getDate()}일까지
        </span>{' '}
        볼 수 있어요.
        <br />
        이번 겨울 총결산을 확인해보세요!
      </Description>
      <CloseButton onClick={handleClose}>
        <IconCloseRegular width="100%" height="100%" />
      </CloseButton>
    </Container>
  )
}

const Container = styled.div<Pick<Props, 'viewType'>>`
  display: flex;
  width: calc(100vw - 2rem);
  padding: 0.75rem;
  border-radius: 0.375rem;
  ${({ viewType }) => (viewType === 'list' ? list : map)}
`
const map = css`
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  background-color: ${vars.$semantic.color.paperDefault};
`
const list = css`
  margin: 0.25rem 1rem 0.75rem;
  background-color: ${vars.$semantic.color.paperContents};
`
const Icon = styled.div`
  margin: 0 0.25rem 0 0;
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
`
const Description = styled.p`
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.caption1Regular};

  span {
    ${vars.$semantic.typography.caption1Bold};
  }
`
const CloseButton = styled.button`
  margin: 0 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  color: ${vars.$scale.color.gray900};
`
