import { useActivityParams } from '@stackflow/react'
import { useFormik } from 'formik'
import { ChangeEvent } from 'react'

import { TextInput as TextInputBase } from '@src/react-utils/components/Input'

import { FormItemWrapper } from '../FormItemWrapper'
import type { Field } from '../types'

interface Props {
  field: Field<'name' | 'phone' | 'url' | 'createSuggestionDescription'>
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  setFieldTouched: ReturnType<typeof useFormik>['setFieldTouched']
  value: string
  errorMessage?: string
}

export const TextInput = ({ field, handleChange, setFieldTouched, value, errorMessage }: Props) => {
  // eslint-disable-next-line no-warning-comments
  // @todo remove this when we have a better way to handle this
  const activityParams = useActivityParams<{ variation?: 'winterSnack' }>()

  const labelMap: Record<Props['field']['id'], string> = {
    name: activityParams.variation === 'winterSnack' ? '가게 위치' : '장소 이름',
    phone: '전화번호',
    url: '웹사이트',
    createSuggestionDescription: '설명',
  }

  const defaultPlaceholderMap: Record<Props['field']['id'], string> = {
    name: activityParams.variation === 'winterSnack' ? '가게 위치를 설명해주세요.' : '장소 이름을 입력해주세요.',
    phone: '전화번호를 입력해주세요.',
    url: '예) https://www.daangn.com',
    createSuggestionDescription: '장소에 대해 설명해주세요.',
  }

  const { id, placeholder, isRequired = false } = field

  return (
    <>
      <FormItemWrapper key={id} id={id} label={labelMap[id]} isOptional={!isRequired} errorMessage={errorMessage}>
        <TextInputBase
          id={id}
          type="text"
          placeholder={placeholder ?? defaultPlaceholderMap[id]}
          value={value}
          maxLength={id === 'name' ? 65 : undefined}
          onChange={handleChange}
          onFocus={() => {
            setFieldTouched(id, false)
          }}
          onBlur={() => {
            setFieldTouched(id, true)
          }}
          width="100%"
        />
      </FormItemWrapper>
    </>
  )
}
