import { ActivityComponentType } from '@stackflow/react'

import CreatePoiReviewWithFixedPoiPage from './CreatePoiReviewWithFixedPoiPage'
import UpdatePoiReviewPage from './UpdatePoiReviewPage'
import { WritePoiReviewPage as DefaultWritePoiReviewPage } from './WritePoiReviewPage'

export type ActivityParams = {
  action: 'write' | 'create' | 'update'
  poiId?: string
  poiName?: string
  bizAccountId?: string
  reviewId?: string
  from?:
    | 'home_tab_no_review'
    | 'home_tab_quick_menu'
    | 'review_tab_empty'
    | 'review_tab_options'
    | 'map_floating_button'
    | 'map_list_empty_state'
    | 'map_map_no_pois'
    | 'winter_snack_map_toast'
}
const WritePoiReviewPage: ActivityComponentType<ActivityParams> = ({ params: { action, poiId, reviewId } }) => {
  switch (action) {
    case 'create':
      if (!poiId) return <DefaultWritePoiReviewPage />

      return <CreatePoiReviewWithFixedPoiPage />
    case 'update':
      if (!reviewId || !poiId) return <DefaultWritePoiReviewPage />

      return <UpdatePoiReviewPage />
    case 'write':
      return <DefaultWritePoiReviewPage />
    default:
      return <DefaultWritePoiReviewPage />
  }
}

export default WritePoiReviewPage
