import { BoxButton } from '@daangn/sprout-components-button'

import { useNavigator } from '@src/stackflow'

import { useReviewEdit } from './contexts/ReviewEditProvider'
import { useNativeReviews } from '../hooks'

export const SubmitButton = () => {
  const { pop } = useNavigator()
  const { isSubmitDisabled, submitForm } = useReviewEdit()
  const { reset } = useNativeReviews()

  const handleSubmit = async () => {
    await submitForm()
    pop()
    reset()
  }
  return (
    <BoxButton isDisabled={isSubmitDisabled} variant="primary" size="xlarge" width="100%" onClick={handleSubmit}>
      수정 완료
    </BoxButton>
  )
}
