import { useActivityParams } from '@stackflow/react'
import { useSetRecoilState } from 'recoil'

import { useNavigator } from '@src/stackflow'

import { ReviewCard } from './ReviewCard'
import { useAnalytics } from '../hooks'
import { interestedSingleReviewAtom, ReviewResponseType } from '../state'

interface Props {
  reviews: ReviewResponseType['items']
}
export const ReviewPreviews = ({ reviews }: Props) => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const { push } = useNavigator()
  const logEvent = useAnalytics()

  const setInterestedSingleReview = useSetRecoilState(interestedSingleReviewAtom)

  const handleClickMyReviewMoreOptions = ({ reviewId, reviewUserId }: { reviewId: string; reviewUserId: number }) => {
    logEvent('click_review_card_more_options_button')
    setInterestedSingleReview({
      reviewId,
      reviewUserId,
    })
    push('poi_detail_modal', { poiId, modalId: 'singleReviewOptionsBottomSheet' })
  }

  return (
    <>
      {reviews.map((review, idx) => (
        <ReviewCard
          key={idx}
          data={review}
          onClickUserProfileMore={({ reviewId, reviewerId }) =>
            handleClickMyReviewMoreOptions({ reviewId, reviewUserId: reviewerId })
          }
        />
      ))}
    </>
  )
}
