import { useFormik } from 'formik'
import { useCallback } from 'react'

import { CategoryInput as CategoryInputBase } from '@src/place-utils/category'
import { CategoryInputState } from '@src/place-utils/category/CategoryInput/state'

import { FormItemWrapper } from '../FormItemWrapper'
import type { Field } from '../types'

interface Props {
  field: Field<'categoryId'>
  setFieldValue: ReturnType<typeof useFormik>['setFieldValue']
}

export const CategoryInput = ({ field, setFieldValue }: Props) => {
  const { id, isRequired = false } = field

  const handleChangeCategory = useCallback(
    (category: CategoryInputState | null) => {
      setFieldValue('categoryId', category?.id ?? undefined, true)

      category?.categorySuggestionId && setFieldValue('categorySuggestionId', category.categorySuggestionId, true)
    },
    [setFieldValue]
  )

  return (
    <FormItemWrapper key={id} id={id} label="업종" isOptional={!isRequired}>
      <CategoryInputBase key={id} onChangeCategory={handleChangeCategory} />
    </FormItemWrapper>
  )
}
