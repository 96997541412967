import type { AxiosInterceptorManager } from 'axios'
import { v4 } from 'uuid'

import { tokenStorage } from '@src/js-utils/tokenStorage'

import { bridge } from '../Bridge'

export const requestIdInjector: Parameters<AxiosInterceptorManager<any>['use']>[0] = (config) => {
  if (!config.headers) {
    config.headers = {}
  }
  config.headers['x-request-id'] = v4()
  return config
}

export const authTokenInjector: Parameters<AxiosInterceptorManager<any>['use']>[0] = (config) => {
  if (!config.headers) {
    config.headers = {}
  }
  config.headers['x-auth-token'] = tokenStorage.getAuthToken()
  return config
}

const appInfoPromise = bridge.getAppInfo()
export const userAgentInjector: Parameters<AxiosInterceptorManager<any>['use']>[0] = async (config) => {
  if (!config.headers) {
    config.headers = {}
  }

  const appInfo = await appInfoPromise
  config.headers['x-user-agent'] = appInfo.info.app.userAgent
  return config
}
