import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { MouseEvent } from 'react'

import { fully } from './animation'
import { HeartIcon, type HeartIconProps } from './HeartIcon'

export interface HeartButtonProps extends HeartIconProps {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  filled?: boolean
  className?: string
  count?: number | null
  isOnImage?: boolean
}

export const HeartButton = ({
  className,
  onClick,
  filled = false,
  count,
  isOnImage,
  size,
  fillColor = vars.$semantic.color.primary,
  strokeColor,
}: HeartButtonProps) => {
  const borderColor = isOnImage ? vars.$static.color.staticWhite : fillColor
  const unfilledColor = isOnImage ? vars.$static.color.staticBlackAlpha200 : 'transparent'

  return (
    <Button onClick={onClick} className={className}>
      <IconWrapper isOnImage={isOnImage} filled={filled}>
        <HeartIcon
          size={size}
          fillColor={filled ? fillColor : unfilledColor}
          strokeColor={filled ? borderColor : strokeColor}
        />
      </IconWrapper>
      {typeof count === 'number' && <Count>{count}</Count>}
    </Button>
  )
}

const Button = styled.button({
  padding: '1rem',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
})
const IconWrapper = styled.div<{ filled?: boolean; isOnImage?: boolean }>([
  ({ filled }) =>
    filled && {
      animation: `${fully} 200ms ease-in`,
    },
  ({ isOnImage }) =>
    isOnImage && {
      filter: `drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.08))`,
    },
])
const Count = styled.p({
  ...vars.$semantic.typography.caption2Regular,
  margin: 0,
  color: vars.$scale.color.gray600,
})
