import { Poi } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { useRecoilState, useRecoilValue } from 'recoil'

import { useKakaoMap, withKakaoMap } from '@src/react-utils/components/Map/KakaoMap'

import { BoundarySearchButton } from './BoundarySearchButton'
import { CardCarousel } from './CardCarousel'
import { GPSButton } from './GPSButton'
import { Map } from './Map'
import { useStore } from '../hooks'
import { focusedPoiIdAtom, isMapBoundChangedAtom } from '../state/map'
import { searchStatusAtom } from '../state/poi'
import { recommendationsSelector } from '../state/recommendation'
import { ViewType, viewTypeAtom } from '../state/view'

interface Props {
  onSelectPoi: (poi: Poi) => void
}
export default withKakaoMap(({ onSelectPoi }: Props) => {
  const { panTo } = useKakaoMap()
  const {
    store: { items: searchResults },
  } = useStore()

  const viewType = useRecoilValue(viewTypeAtom)
  const searchStatus = useRecoilValue(searchStatusAtom)
  const isMapBoundChanged = useRecoilValue(isMapBoundChangedAtom)
  const recommendations = useRecoilValue(recommendationsSelector)
  const pois = searchStatus === 'initial' ? (recommendations === 'error' ? [] : recommendations) : searchResults

  const [focusedPoiId, setFocusedPoiId] = useRecoilState(focusedPoiIdAtom)

  const handleFocusPoi = (poi: Poi) => {
    panTo({ coordinates: poi.coordinates! })
    setFocusedPoiId(poi.id)
  }
  const handleUnfocusPoi = () => {
    setFocusedPoiId(null)
  }

  if (pois.length === 0) return null
  return (
    <Container viewType={viewType}>
      <TopArea>{searchStatus !== 'initial' && isMapBoundChanged && <BoundarySearchButton />}</TopArea>
      <GPSButton />
      <Map
        pois={pois}
        focusedPoiId={focusedPoiId}
        onSelectPoi={onSelectPoi}
        onClickMarker={handleFocusPoi}
        onClickMap={handleUnfocusPoi}
      />
      {!!focusedPoiId && (
        <CardSection>
          <CardCarousel pois={pois} focusedPoiId={focusedPoiId} onSelectPoi={onSelectPoi} onSwipeEnd={handleFocusPoi} />
        </CardSection>
      )}
    </Container>
  )
})

const Container = styled.div<{ viewType: ViewType }>`
  position: absolute;
  z-index: ${({ viewType }) => (viewType === 'map' ? 2 : 1)};
  width: 100%;
  height: 100%;
`
const TopArea = styled.div`
  z-index: 2;
  width: 0;
  height: fit-content;
  position: absolute;
  transform: translate3d(50vw, 0, 0);
  top: 1rem;
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  align-items: center;
`

const CardSection = styled.div`
  z-index: 2;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 1rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
