import { vars } from '@seed-design/design-token'
import { CSSProperties } from 'react'

import type { BadgeProps } from './index'

type Size = BadgeProps['size']
type Type = BadgeProps['type']

export const typography: Record<Size, Record<Type, CSSProperties>> = {
  small: {
    normal: vars.$semantic.typography.label6Regular,
    outlined: vars.$semantic.typography.label6Bold,
    bold: vars.$semantic.typography.label6Bold,
  },
  medium: {
    normal: vars.$semantic.typography.label5Regular,
    outlined: vars.$semantic.typography.label5Bold,
    bold: vars.$semantic.typography.label5Bold,
  },
  large: {
    normal: vars.$semantic.typography.label4Regular,
    outlined: vars.$semantic.typography.label4Bold,
    bold: vars.$semantic.typography.label4Bold,
  },
}
