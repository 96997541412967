import styled from '@emotion/styled'
import { useRecoilValue } from 'recoil'

import { reactionAtomFamily, ReactionKey } from '@src/place-utils/reaction/store/reactions'

import { Button } from './Button'
import { Display } from './Display'

interface Props {
  poiId: string
  onClickButton: ({ isAlreadyClicked }: { isAlreadyClicked: boolean }) => void
  onClickReactionDisplay: () => void
}
export const Reaction = ({ poiId, onClickButton, onClickReactionDisplay }: Props) => {
  const reactionKey: ReactionKey = {
    resourceId: poiId,
    resourceType: 'POI',
    type: 'MATJIB',
  }
  const { totalCount, reactedUsers } = useRecoilValue(reactionAtomFamily(reactionKey))

  return (
    <Wrapper>
      <Button {...reactionKey} onClick={onClickButton} />
      {totalCount > 0 && (
        <Display
          poiId={poiId}
          reactionCount={totalCount}
          reactedUsers={
            reactedUsers?.map(({ nickname, profileImageURL, residenceInfo }) => ({
              nickname,
              profileImageURL,
              residenceInfo,
            })) ?? []
          }
          onClick={onClickReactionDisplay}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0.75rem',
})
