import { getPostsByUserIdV2, GetPostsByUserIdV2200Response } from '@daangn/local-business-network/lib/poi'
import { atomFamily, selectorFamily } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'
import { userInfoAtom } from '@src/place-utils/user/store'

const subprofileKey = generateUniqueKey(['subprofile', 'userPosts'])

export const userPostsPaginationAtom = atomFamily({
  key: subprofileKey('pagination'),
  default: {
    page: 1,
    perPage: 10,
  },
})

type PostsSortBy = 'createdAt' | 'upvoteCount'
export const userPostsSortByAtom = atomFamily<PostsSortBy, string>({
  key: subprofileKey('sortBy'),
  default: 'createdAt',
})

export const userPostsQuerySelectorFamily = selectorFamily({
  key: subprofileKey('query'),
  get:
    (userId: string) =>
    async ({ get }) => {
      const userInfo = get(userInfoAtom)
      if (!userId) return null

      const sortBy = get(userPostsSortByAtom(userId))
      const { page, perPage } = get(userPostsPaginationAtom({ sortBy, userId }))
      const { data: profile } = await getPostsByUserIdV2({
        params: {
          xAuthToken: userInfo.authToken,
          userId,
          page,
          perPage,
          sortBy,
        },
      })

      return profile
    },
})

export const userPostsAtom = atomFamily({
  key: subprofileKey('userPostsStore'),
  default: [] as GetPostsByUserIdV2200Response['data'],
})

export const userPostsMetaAtom = atomFamily({
  key: subprofileKey('meta'),
  default: {} as GetPostsByUserIdV2200Response['meta'],
})

export const syncedUserPostPageAtom = atomFamily({
  key: subprofileKey('syncedPage'),
  default: 0,
})
