import { Poi } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { memo, ReactNode } from 'react'
import { useRecoilValue } from 'recoil'

import { haversineDistance, formatDistanceInMeterToString } from '@src/js-utils/coordinate'
import { currentPositionSelector } from '@src/place-utils/location/store'
import { EllipsisText } from '@src/react-utils/components/Map/EllipsisText'
import { activeEffect } from '@src/styles/utils'

interface Props {
  poi: Poi
  onClick?: (poi: Poi) => void
  useActiveEffect?: boolean
  className?: string
  appendRight?: () => ReactNode
}
export const PoiCard = memo(({ poi, onClick, appendRight, useActiveEffect = true, ...props }: Props) => {
  const { name, address: roadAddress, jibunAddress, coordinates } = poi
  const address = roadAddress || jibunAddress

  const currentPosition = useRecoilValue(currentPositionSelector)

  const category = poi.category?.name
  const distance =
    currentPosition.access === 'granted' && coordinates?.latitude
      ? formatDistanceInMeterToString(haversineDistance(currentPosition.position, coordinates))
      : undefined
  const tags = [category, distance].filter(Boolean)

  const handleClick = () => {
    onClick?.(poi)
  }

  const renderAppendRight = () => {
    if (typeof appendRight !== 'undefined') {
      return <AppendRight>{appendRight()}</AppendRight>
    } else {
      return null
    }
  }

  return (
    <Container onClick={handleClick} useActiveEffect={useActiveEffect} className={props.className}>
      <Info>
        <Title>
          <Name>{name}</Name>
          {tags && tags.length > 0 && (
            <Tags>
              {tags.map((tag, idx) => (
                <Tag key={idx}>{tag}</Tag>
              ))}
            </Tags>
          )}
        </Title>
        {address && <Address>{address}</Address>}
      </Info>
      {renderAppendRight()}
    </Container>
  )
})

const Container = styled.div<Pick<Props, 'useActiveEffect'>>`
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: row;
  background-color: ${vars.$semantic.color.paperDefault};
  ${({ useActiveEffect }) => useActiveEffect && activeEffect()};
`
const Info = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`
const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Name = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.subtitle1Regular};
`
const Tags = styled.span`
  min-width: fit-content;
  margin: 0 0 0 0.25rem;
  display: inline-flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
const Tag = styled.span`
  min-width: fit-content;
  display: flex;
  align-items: center;
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.caption2Regular};

  &:not(:first-of-type) {
    &::before {
      content: '⸱';
      width: 0.188rem;
      height: 0.875rem;
      margin: 0 0.219rem;
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 700;
      font-size: 0.625rem;
      line-height: 140%;
      color: ${vars.$scale.color.gray600};
    }
  }
`
const Address = styled(EllipsisText)`
  margin: 0.25rem 0 0;
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.caption1Regular};
`
const AppendRight = styled.div`
  margin: 0 0 0 0.5rem;
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`
