import { NativeReviewDtoVisitCount } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useRecoilValue } from 'recoil'

import { User } from '@src/apis/__generated__/poi'
import { APP_SCHEME, COMMUNITY_WEB_PROFILE_BASE_URL } from '@src/constants/url'
import { TOP_TIER_BADGE_YEARS_OF_RESIDENCE } from '@src/pages/NativeReview/constants'
import { useAnalytics } from '@src/pages/NativeReview/hooks'
import { userInfoAtom } from '@src/place-utils/user/store'
import { Avatar } from '@src/react-utils/components/Avatar'

import { MoreActions } from './MoreActions'
import { Nickname } from './Nickname'
import { Tag } from './Tag'

interface Props {
  user: Pick<User, 'id' | 'nickname' | 'profileImageUrl'>
  regionName: string
  yearsOfResidence: number
  visitCount: NativeReviewDtoVisitCount
  reviewId: number
  index: number
}

export const UserProfile = ({ user: author, yearsOfResidence, regionName, visitCount, reviewId, index }: Props) => {
  const userInfo = useRecoilValue(userInfoAtom)
  const isMyReview = userInfo?.id === author.id

  const logEvent = useAnalytics()

  const handleClickProfile = () => {
    logEvent('click_profile_detail', {
      user_id: author.id,
      review_id: reviewId,
      index: index,
    })
    const path = `${APP_SCHEME}community-web/router?remote=${encodeURIComponent(
      COMMUNITY_WEB_PROFILE_BASE_URL
    )}%2Fprofile%2F${author.id}%2F&navbar=false&scrollable=false`

    window.location.href = path
  }

  return (
    <Container onClick={handleClickProfile}>
      <Avatar src={author.profileImageUrl || null} alt={author.nickname} size="medium" margin={'0 0.75rem 0 0'} />
      <ProfileData>
        <UserInfo>
          <Nickname>{author.nickname}</Nickname>
        </UserInfo>
        <AdditionalData>
          <Tag
            type="years-of-residence"
            regionName={regionName}
            yearsOfResidence={yearsOfResidence}
            badge={
              yearsOfResidence >= TOP_TIER_BADGE_YEARS_OF_RESIDENCE ? { type: 'crown', label: '상위 10%' } : undefined
            }
          />
          <Tag type="visit-count" visitCount={visitCount} />
        </AdditionalData>
      </ProfileData>
      {isMyReview && <MoreActions reviewId={reviewId} />}
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  position: 'relative',
})

const ProfileData = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

const UserInfo = styled.div([
  vars.$semantic.typography.label2Bold,
  {
    display: 'flex',
    alignItems: 'center',
    color: vars.$scale.color.gray900,

    '& > :not(:first-of-type)': {
      margin: '0 0 0 0.25rem',
    },
  },
])

const AdditionalData = styled.div([
  vars.$semantic.typography.caption1Regular,
  {
    color: vars.$scale.color.gray600,
  },
])
