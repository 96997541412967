import { useActivityParams } from '@stackflow/react'
import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'

import { usePoiInputSelectionState } from '@src/place-utils/poi/PoiInput/state'
import { myPoiReviewSelectorFamily } from '@src/place-utils/review/store/poiReview'
import { useLogEvent } from '@src/react-utils/components/Analytics'

import { ActivityParams } from '..'

const useAnalytics = () => {
  const logEvent = useLogEvent()
  const { poiId, reviewId, from } = useActivityParams<ActivityParams>()

  const [selectedPoiInfo] = usePoiInputSelectionState()
  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId: selectedPoiInfo.id }))

  return useCallback(
    (name: string, params?: { [key: string]: any }) => {
      const _params = {
        is_new: !(!!reviewId || !!myReview?.id),
        poi_id: poiId ?? null,
        review_id: (reviewId || myReview?.id) ?? null,
        from: from ?? null,
        ...params,
      }

      logEvent({ name, params: _params })
    },
    [logEvent, poiId, reviewId, myReview, from]
  )
}

export default useAnalytics
