import { atom } from 'recoil'

export const DEFAULT_DIRECTION = 'topLeft'

export type ArrowDirection = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
export interface NudgeType {
  text: string
  arrowDirection: ArrowDirection
  top?: number
  bottom?: number
  left?: number
  right?: number
  className?: string
  /**
   * duration in ms
   */
  duration?: number
  /**
   * custom z-index (default: 98)
   */
  zIndex?: number
  onRemove?: () => void
  isShowCloseButton?: boolean
}

export const nudgeItemsAtom = atom<Record<string, NudgeType>>({
  key: 'nudgeItems',
  default: {},
})
