import { addBreadcrumb, captureException } from '@sentry/react'
import { ReactNode } from 'react'
import { ErrorBoundary as _ErrorBoundary, ErrorBoundaryPropsWithComponent } from 'react-error-boundary'

import ErrorUI from './ErrorUI'

interface Props extends Omit<ErrorBoundaryPropsWithComponent, 'FallbackComponent'> {
  rejectedFallback?: ErrorBoundaryPropsWithComponent['FallbackComponent']
  children?: ReactNode
}

const ErrorBoundary = ({ rejectedFallback, onError, ...props }: Props) => {
  return (
    <_ErrorBoundary
      FallbackComponent={rejectedFallback ? rejectedFallback : ErrorUI}
      onError={(error, errorInfo) => {
        onError?.(error, errorInfo)

        addBreadcrumb({
          message: 'React ErrorBoundary!',
          data: {
            errorInfo,
          },
        })
        captureException(error)
      }}
      {...props}
    />
  )
}

export default ErrorBoundary
