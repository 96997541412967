import { AlertDialog } from '@src/react-utils/components/AlertDialog'
import { useNavigator } from '@src/stackflow'

export const BizManagerSelfReviewAlert = () => {
  const { pop } = useNavigator()

  return (
    <AlertDialog
      contents="사장님은 본인 가게에 추천 글을 쓸 수 없어요."
      CTAButton={{
        label: '확인',
        onClick: pop,
      }}
    />
  )
}
