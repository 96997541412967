import { ActivityComponentType, useActivityParams } from '@stackflow/react'
import { useCallback } from 'react'

import { ActivitiesType } from '../activities'
import { useStepFlow } from '../stackflow'

export const useStepNavigator = <K extends Extract<keyof ActivitiesType, string>>(activityName: K) => {
  const activityParams = useActivityParams()

  const action = useStepFlow(activityName)

  type StepPushOptions = Parameters<(typeof action)['stepPush']>['1']
  const stepPush = useCallback(
    (
      params: ActivitiesType[K] extends
        | ActivityComponentType<infer U>
        | {
            component: ActivityComponentType<infer U>
          }
        ? U
        : Record<string, unknown>,
      options?: StepPushOptions
    ) => {
      return action.stepPush({ ...activityParams, ...params }, options)
    },
    [action, activityParams]
  )

  type StepPopOptions = Parameters<(typeof action)['stepPop']>['0']
  const stepPop = useCallback(
    (options?: StepPopOptions) => {
      return action.stepPop(options)
    },
    [action]
  )

  type StepReplaceOptions = Parameters<(typeof action)['stepReplace']>['1']
  const stepReplace = useCallback(
    (
      params: ActivitiesType[K] extends
        | ActivityComponentType<infer U>
        | {
            component: ActivityComponentType<infer U>
          }
        ? U
        : Record<string, unknown>,
      options?: StepReplaceOptions
    ) => {
      return action.stepReplace({ ...activityParams, ...params }, options)
    },
    [action, activityParams]
  )

  return {
    stepPush,
    stepPop,
    stepReplace,
    pending: action.pending,
  }
}
