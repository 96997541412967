import { listIsWatchingV2 } from '@daangn/local-business-network/lib/businessAccount'
import { selectorFamily } from 'recoil'

import { userInfoAtom } from '@src/place-utils/user/store'

import { generateWatchRecoilKey } from './recoilKey'

export const watchBAInfosByIdSelectorFamily = selectorFamily({
  key: generateWatchRecoilKey('watchBAInfosById'),
  get:
    (ids: string[]) =>
    async ({ get }) => {
      const userInfo = get(userInfoAtom)
      if (!userInfo) return null

      if (!ids.length) return []

      const response = await listIsWatchingV2({
        params: { businessAccountIds: ids.map((id) => parseInt(id, 10)) },
        options: {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      })

      return response?.data?.data?.watches ?? []
    },
})

export const watchBAInfosByIdMapperSelectorFamily = selectorFamily({
  key: generateWatchRecoilKey('watchBAInfosByIdMapper'),
  get:
    (ids: string[]) =>
    async ({ get }) => {
      const watchList = await get(watchBAInfosByIdSelectorFamily(ids))

      if (!ids.length) return {} as Record<string, boolean>

      const baWatchMapper: Record<string, boolean> = watchList
        ? watchList.reduce((acc, { businessAccountId, isWatching }) => {
            return {
              ...acc,
              [businessAccountId]: isWatching,
            }
          }, {})
        : {}

      return baWatchMapper
    },
})
