import styled from '@emotion/styled'
import IconFilter02Thin from '@karrotmarket/karrot-ui-icon/lib/react/IconFilter02Thin'
import { vars } from '@seed-design/design-token'
import { useRecoilState } from 'recoil'

import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../../hooks'
import { FILTERS, FilterId, nativeReviewFilterAtomFamily } from '../../store'

interface Props {
  id: FilterId
}

const MODAL_ID_TO_LOG_NAME_MAPPER: Record<FilterId, string> = {
  'list-menu-filter': 'click_menu_filter',
  'list-residence-period-filter': 'click_residence_year_filter',
  'list-visit-count-filter': 'click_visit_count_filter',
  'list-region-level-two-filter': 'click_region_level_two_filter',
}

export const Filter = ({ id }: Props) => {
  const { push } = useNavigator()
  const logEvent = useAnalytics()

  const [{ value }, setFilter] = useRecoilState(nativeReviewFilterAtomFamily(id))
  const { placeholder } = FILTERS[id]

  const handleClick = async () => {
    logEvent(MODAL_ID_TO_LOG_NAME_MAPPER[id], {})
    setFilter((prev) => ({ ...prev, isActive: true }))

    push('native_review_modal', {
      modalId: id,
    })
  }

  const unselected = value?.id === '0'
  const isSelected = !!value && !unselected
  const label = value?.name ?? placeholder

  return (
    <Container isSelected={isSelected} onClick={handleClick}>
      <IconFilter02Thin width="1.125rem" height="1.125rem" style={{ margin: '0 0.25rem 0 0' }} />
      {unselected ? placeholder : label}
    </Container>
  )
}

const Container = styled.div<{ isSelected: boolean }>([
  vars.$semantic.typography.caption1Regular,
  {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0.5rem 0 0',
    padding: '0.375rem 0.75rem',
    borderRadius: '1.125rem',
    border: 'solid 1px',
    boxSizing: 'border-box',
    borderColor: vars.$semantic.color.divider2,
    backgroundColor: vars.$semantic.color.paperDefault,
    color: vars.$scale.color.gray900,
  },
  ({ isSelected }) =>
    isSelected && {
      borderColor: 'transparent',
      backgroundColor: vars.$semantic.color.grayActive,
      color: vars.$static.color.staticWhite,
    },
])
