import styled from '@emotion/styled'

import { Callout as CalloutComponent } from '@src/react-utils/components'

interface Props {
  onClick?: () => void
}
export const Callout = ({ onClick }: Props) => {
  return (
    <_Callout type="action_link" state="tip" contents="찾고 있는 장소가 없다면 직접 등록해보세요." onClick={onClick} />
  )
}
const _Callout = styled(CalloutComponent)({
  margin: '0.5rem 1rem',
  width: 'calc(100% - 2rem)',
})
