import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { MouseEvent, ReactNode } from 'react'

type ColorType = 'default' | 'primary' | 'secondary'
type SizeType = 'size48'

interface Props {
  color?: ColorType
  size?: SizeType
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  icon?: ReactNode
  children?: ReactNode
  className?: string
}

const FloatingButton = ({ color = 'default', size = 'size48', onClick, icon, children, className }: Props) => {
  return (
    <Button className={className} color={color} size={size} onClick={onClick}>
      {icon && <span className="icon">{icon}</span>}
      {children}
    </Button>
  )
}

const Button = styled.button<{
  color: ColorType
  size: SizeType
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.08)) drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.05));

  ${({ color }) => styleColor(color)}
  ${({ size }) => styleSize(size)}
`

const styleColor = (color: ColorType) => {
  switch (color) {
    case 'default':
      return colorDefault
    case 'primary':
      return colorPrimary
    case 'secondary':
      return colorSecondary
    default:
      return colorDefault
  }
}

const colorDefault = css`
  background-color: ${vars.$semantic.color.paperSheet};
  color: ${vars.$scale.color.gray900};

  .icon {
    color: ${vars.$scale.color.gray900};
  }
`

const colorPrimary = css`
  background-color: ${vars.$semantic.color.primary};
  color: ${vars.$scale.color.gray00};

  .icon {
    color: ${vars.$scale.color.gray00};
  }
`

const colorSecondary = css`
  background-color: ${vars.$semantic.color.secondary};

  .icon {
    color: ${vars.$scale.color.gray900};
  }
`

const styleSize = (size: SizeType) => {
  switch (size) {
    case 'size48':
      return size48
    default:
      return size48
  }
}

const size48 = css`
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
`
export default FloatingButton
