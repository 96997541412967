import 'core-js/stable'
import './style'
import { Global } from '@emotion/react'
import * as Sentry from '@sentry/react'
import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil'

import App from './App'
import { initClarity } from './clarity'
import { IS_LOCAL } from './constants/environmentConstants'
import ErrorPage from './react-utils/components/Error/Base'
import { PageLoading } from './react-utils/components/Loading'
import { initSentry } from './sentry'
import globalCss from './styles/global'
// eslint-disable-next-line import/no-unresolved
import '@stackflow/plugin-basic-ui/index.css'
// eslint-disable-next-line import/no-unresolved
import '@daangn/sprout-components-button/index.css'
// eslint-disable-next-line import/no-unresolved
import '@daangn/sprout-components-text-field/index.css'
// eslint-disable-next-line import/no-unresolved
import '@daangn/sprout-components-help-bubble/index.css'
// eslint-disable-next-line import/no-unresolved
import '@daangn/sprout-components-chips/index.css'
// eslint-disable-next-line import/no-unresolved
import '@daangn/sprout-components-callout/index.css'
// eslint-disable-next-line import/no-unresolved
import '@daangn/sprout-components-fab/index.css'
// eslint-disable-next-line import/no-unresolved
import '@daangn/sprout-components-dialog/index.css'

import '@daangn/business-review-ui/index.css'

import './bridge'
import './communityBridge'

if (!IS_LOCAL) {
  initSentry()
  initClarity()
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <StrictMode>
    <RecoilRoot>
      <Suspense fallback={<PageLoading />}>
        <Sentry.ErrorBoundary
          fallback={<ErrorPage messages={['알수 없는 오류가 발생했어요.', '화면을 닫고 다시 시도해 주세요.']} />}>
          <Global styles={globalCss} />
          <App />
        </Sentry.ErrorBoundary>
      </Suspense>
    </RecoilRoot>
  </StrictMode>
)

// :)
