import { saveNativeReviewV2 } from '@daangn/local-business-network/lib/poi'
import { captureException } from '@sentry/react'
import { useActivityParams } from '@stackflow/react'
import mergeWith from 'lodash/mergeWith'
import { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { removeDuplicatesByKey } from '@src/js-utils/array'
import { userInfoAtom } from '@src/place-utils/user/store'
import { useStepNavigator } from '@src/stackflow'
import { DeepPartial } from '@src/types/utility-types'

import { myResidenceInfoAtom } from '../../store'
import { NativeReviewFormPageActivityParams } from '../index'
import type { RestaurantReviewForm } from '../types/restaurant-review-form'

export function mergeNestedObjects<T>(obj1: T, obj2: DeepPartial<T>, options?: { override?: string[] }): T {
  const merged = mergeWith(obj1, obj2, (a: any, b: any, key: keyof T) => {
    if (options) {
      const { override } = options
      // If the key is included in override option, then we don't merge the value.
      // Instead, we just replace the value with the new one.
      if (override?.includes(key as string)) {
        return b
      }
    }
  })

  return merged
}

type IntermediateFormValue = DeepPartial<RestaurantReviewForm>
type FormValue = RestaurantReviewForm

interface StepForm {
  variation?: 'default' | 'gift_card'
  step: {
    current: number
    total: number
  }
  formValue: FormValue
  go: {
    next: () => void
    prev: () => void
  }
  updateForm: (newValue: IntermediateFormValue) => void
  submitForm: () => Promise<void>
}

const createStepFormContext = (context: StepForm) => context

export const StepFormContext = createContext<ReturnType<typeof createStepFormContext>>(null as any)

interface Props {
  totalStep: number
  initialFormValue: FormValue
}
export const StepFormProvider = ({ totalStep, initialFormValue, children }: PropsWithChildren<Props>) => {
  const { step: currentStep = '1', referrer } = useActivityParams<NativeReviewFormPageActivityParams>()
  const { stepPush, stepPop } = useStepNavigator('native_review_form')

  const { authToken } = useRecoilValue(userInfoAtom)

  const variation: StepForm['variation'] = useMemo(() => {
    if (!referrer) return 'default'

    if (/gift_card/.test(referrer)) return 'gift_card'

    return 'default'
  }, [referrer])

  const [formValue, setFormValue] = useState<FormValue>(initialFormValue)

  const updateForm = useCallback((newValue: IntermediateFormValue) => {
    setFormValue((prev) => {
      const mergedValue = mergeNestedObjects(prev, newValue, { override: ['images'] })

      return mergedValue
    })
  }, [])

  const submitForm = useRecoilCallback(
    ({ set }) =>
      async () => {
        const { poi, user, review } = formValue

        const imageIds = review?.images?.map((image) => image.id) ?? []

        try {
          const poiId = poi!.id

          const {
            data: { data },
          } = await saveNativeReviewV2({
            params: {
              createNativeReviewBody: {
                poi: {
                  id: poiId,
                  visitCount: poi!.visitCount!,
                },
                user: {
                  region: {
                    id: user.region.id,
                    startYearOfResidence: user.region.startYearOfResidence!,
                  },
                },
                review: {
                  content: review?.content || '',
                  image: imageIds,
                },
              },
              xAuthToken: authToken,
            },
          })

          set(myResidenceInfoAtom, (prev) => removeDuplicatesByKey([...prev, data.residenceInfo], 'regionId'))
        } catch (err) {
          captureException(err)

          throw err
        }
      },
    [authToken, formValue]
  )

  const next = useCallback(() => {
    const nextStep = +currentStep + 1
    const isLastStep = nextStep > totalStep

    if (isLastStep) {
      return
    } else {
      stepPush({
        step: nextStep.toString(),
      })
    }
  }, [currentStep, stepPush, totalStep])

  const back = useCallback(() => {
    stepPop()
  }, [stepPop])

  const value = useMemo(
    () =>
      createStepFormContext({
        variation,
        formValue,
        updateForm,
        submitForm,
        step: {
          current: +currentStep,
          total: totalStep,
        },
        go: { next, prev: back },
      }),
    [variation, formValue, updateForm, submitForm, currentStep, totalStep, next, back]
  )

  return <StepFormContext.Provider value={value}>{children}</StepFormContext.Provider>
}
