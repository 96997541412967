import styled from '@emotion/styled'
import IconCheckRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCheckRegular'
import { vars } from '@seed-design/design-token'
import { useState } from 'react'
import { useRecoilState } from 'recoil'

import { BottomSheet } from '@src/react-utils/components/BottomSheet'
import { useNavigator } from '@src/stackflow'

import { FilterId, nativeReviewFilterAtomFamily } from '../../store'

interface Option {
  id: string
  name: string
}

interface Props {
  id: FilterId
  title: string
  options: Option[]
  onClickOption?: (id: string) => void
}

export const FilterPicker = ({ id, title, options, onClickOption }: Props) => {
  const { pop } = useNavigator()
  const [filter, setFilter] = useRecoilState(nativeReviewFilterAtomFamily(id))
  const [selectedOption, setSelectedOption] = useState<Option>(filter.value ?? options[0])

  const handleOutsideClick = () => {
    setFilter((prev) => ({ ...prev, isActive: false }))
  }

  const handleConfirm = () => {
    setFilter((prev) => ({ ...prev, value: selectedOption, isActive: false }))
    pop()
  }
  return (
    <BottomSheet
      onOutsideClick={handleOutsideClick}
      title={title}
      contents={
        <Options>
          {options.map(({ id, name }) => (
            <Option
              key={id}
              selected={selectedOption.id === id}
              onClick={() => {
                setSelectedOption({ id, name })
                onClickOption?.(id)
              }}>
              {name}
              {selectedOption.id === id && (
                <IconCheckRegular height={18} width={18} color={vars.$semantic.color.primary} />
              )}
            </Option>
          ))}
        </Options>
      }
      CTAButton={{
        label: '확인',
        onClick: handleConfirm,
      }}
      options={{
        useDividerBetweenHeaderAndContent: true,
        useContentsSafeAreaPadding: true,
      }}
    />
  )
}

const Options = styled.div({
  padding: '0.625rem 0',
})
const Option = styled.div<{ selected: boolean }>([
  {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.75rem 1rem',
  },
  ({ selected }) => (selected ? vars.$semantic.typography.bodyM1Bold : vars.$semantic.typography.bodyM1Regular),
  ({ selected }) => ({
    color: selected ? vars.$scale.color.carrot600 : vars.$scale.color.gray900,
  }),
])
