import { FormikErrors } from 'formik'

import { Field, FormValues } from '../types'

export const validateWinterSnackMenuErrors = (
  value: FormValues['winterSnackMenu'],
  field?: Field<'winterSnackMenu'>
) => {
  const errors = {} as FormikErrors<FormValues>

  if (field) {
    if (field.isRequired && (!value || value.length === 0)) {
      errors['winterSnackMenu'] = '판매하는 메뉴를 선택해주세요.'
    }
  }

  return errors
}
