import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useEffect, useRef, useState, useCallback } from 'react'

import { useDebounce } from '@src/react-utils/hooks'

import { Item } from './Item'

export const Picker = ({
  values,
  itemHeight = 46,
  visibleItemsCount = 5,
  initIndex,
  onChange,
}: {
  values: string[] | number[]
  itemHeight?: number
  visibleItemsCount?: number
  initIndex?: number
  onChange: (index: number) => void
}) => {
  const containerRef = useRef<any>()
  const [pickedIndex, setPickedIndex] = useState<number>(0)

  const ITEM_HEIGHT = itemHeight
  const VISIBLE_ITEMS_COUNT = visibleItemsCount
  const CONTAINER_HEIGHT = ITEM_HEIGHT * VISIBLE_ITEMS_COUNT

  // 올바른 위치로 스크롤 (picked 아이템이 정확히 중앙에)
  const alignScroll = useCallback(
    ({ newPickedIndex, smooth = true }: { newPickedIndex: number; smooth?: boolean }) => {
      containerRef?.current?.scrollTo({
        top: newPickedIndex * ITEM_HEIGHT,
        behavior: smooth ? 'smooth' : 'auto',
      })
    },
    [ITEM_HEIGHT]
  )

  // 스크롤을 멈출때, pick된 아이템이 중앙으로 오게 스크롤
  const debouncedAlignScroll = useDebounce((newPickedIndex) => {
    alignScroll({ newPickedIndex })
  }, 700)

  // unpicked 아이템 클릭했을때 picked로 변경
  const onUnpickedItemClick = useCallback(
    (newPickedIndex: number) => {
      alignScroll({ newPickedIndex })
    },
    [alignScroll]
  )

  // 시작 스크롤 위치
  useEffect(() => {
    if (initIndex !== undefined && initIndex > -1) {
      setPickedIndex(initIndex)
      alignScroll({ newPickedIndex: initIndex, smooth: false })
      onChange(initIndex)
    } else {
      setPickedIndex(values.length - 1)
      alignScroll({ newPickedIndex: values.length - 1, smooth: false })
    }
  }, [alignScroll, initIndex, onChange, values.length])

  useEffect(() => {
    const containerRefCurrent = containerRef.current

    const wheelHandler = () => {
      const nowScroll = containerRefCurrent.scrollTop
      const nowHalfHeight = nowScroll + CONTAINER_HEIGHT / 2
      const newPickedIndex = Math.floor(nowHalfHeight / ITEM_HEIGHT) - Math.floor(VISIBLE_ITEMS_COUNT / 2)

      if (pickedIndex !== newPickedIndex) {
        setPickedIndex(newPickedIndex)
        onChange(newPickedIndex)
      }

      debouncedAlignScroll(newPickedIndex)
    }

    containerRefCurrent.addEventListener('scroll', wheelHandler)
    return () => {
      containerRefCurrent.removeEventListener('scroll', wheelHandler)
    }
  }, [CONTAINER_HEIGHT, ITEM_HEIGHT, VISIBLE_ITEMS_COUNT, debouncedAlignScroll, onChange, pickedIndex])

  return (
    <Wrap>
      <Blur position={'top'} />
      <ItemsWrap CONTAINER_HEIGHT={CONTAINER_HEIGHT} ref={containerRef}>
        <EmptySpace VISIBLE_ITEMS_COUNT={VISIBLE_ITEMS_COUNT} ITEM_HEIGHT={ITEM_HEIGHT} />
        {values.map((value, i) => {
          return (
            <Item
              key={i}
              index={i}
              height={ITEM_HEIGHT}
              value={value}
              isPicked={pickedIndex === i}
              onUnpickedItemClick={onUnpickedItemClick}
            />
          )
        })}
        <EmptySpace VISIBLE_ITEMS_COUNT={VISIBLE_ITEMS_COUNT} ITEM_HEIGHT={ITEM_HEIGHT} />
      </ItemsWrap>
      <Blur position={'bottom'} />
    </Wrap>
  )
}

const EmptySpace = ({ VISIBLE_ITEMS_COUNT, ITEM_HEIGHT }: { VISIBLE_ITEMS_COUNT: number; ITEM_HEIGHT: number }) => {
  return (
    <>
      {new Array(Math.floor(VISIBLE_ITEMS_COUNT / 2)).fill(null).map((e, i) => (
        <EmptyItem height={ITEM_HEIGHT} key={e + i} />
      ))}
    </>
  )
}

const Wrap = styled.div`
  position: relative;
`

const ItemsWrap = styled.div<{ CONTAINER_HEIGHT: number }>`
  position: relative;
  height: ${(props) => props.CONTAINER_HEIGHT}px;
  width: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Blur = styled.div<{ position: 'top' | 'bottom' }>`
  position: absolute;
  width: 100%;
  height: 40px;
  ${(props) => (props.position === 'top' ? 'top: 0px;' : 'bottom: 0px;')}
  z-index: 1;
  background: linear-gradient(
    to ${(props) => (props.position === 'top' ? 'bottom' : 'top')},
    ${vars.$semantic.color.paperDefault},
    ${vars.$semantic.color.paperDefault} 15%,
    transparent
  );
  // 클릭방지
  pointer-events: none;
`

const EmptyItem = styled.div<{ height: number }>`
  width: 100%;
  height: ${(props) => props.height}px;
`
