import { ShortVideoEntry, useShortVideoEntry, ShortVideoPlacement } from '@daangn/short-video-entry'
import styled from '@emotion/styled'
import { IconStoryRegular } from '@seed-design/icon'
import { useActivityParams } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { IS_PROD } from '@src/constants/environmentConstants'
import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'
import { userInfoAtom } from '@src/place-utils/user/store'
import { SectionHeader as SectionHeaderComponent } from '@src/react-utils/components'
import { Button } from '@src/react-utils/components/Button'
import { Section } from '@src/react-utils/components/Layout'
import { useNavigator } from '@src/stackflow'

const ShortVideoSection = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))

  const userInfo = useRecoilValue(userInfoAtom)

  const { pushScheme } = useNavigator()
  const { loadMore, appSchemesGetters, ...shortVideoRest } = useShortVideoEntry({
    placement: ShortVideoPlacement.POI_HOME,
    poiId: parseInt(poiId, 10),
    xAuthToken: userInfo.authToken,
    ENV: IS_PROD ? 'production' : 'alpha',
  })

  const handleClickCreateShortVideo = () => {
    pushScheme(
      appSchemesGetters.new({
        itemType: 'POI',
        itemId: poiId,
        itemTitle: poi?.name,
      })
    )
  }

  const handleClickMoreShortVideo = () => {
    pushScheme(
      appSchemesGetters.collection({
        placement: ShortVideoPlacement.POI_HOME,
        poiId,
      })
    )
  }

  if (shortVideoRest.shortVideos.length === 0) return null

  return (
    <Section>
      <SectionHeader
        type="action"
        text="스토리"
        action={
          <HeaderButton styleTheme="text" textSize="14" onClick={handleClickCreateShortVideo}>
            <IconStoryRegular width={16} height={16} /> 스토리 추가
          </HeaderButton>
        }
      />
      <SlideContainer>
        <ShortVideoEntry {...shortVideoRest} />
      </SlideContainer>
      <StyledButton styleTheme="secondary-low" fullwidth textSize="14" onClick={handleClickMoreShortVideo}>
        스토리 전체 보기
      </StyledButton>
    </Section>
  )
}

const SectionHeader = styled(SectionHeaderComponent)({
  marginBottom: '0.75rem',
})

const SlideContainer = styled.div({
  margin: '0 -1rem',
})

const HeaderButton = styled(Button)({
  marginRight: '-0.75rem',
})

const StyledButton = styled(Button)({
  marginTop: '1rem',
})

export default ShortVideoSection
