import Request from './Request'
import { DocumentJusoAddress } from './types/juso'

export default class JusoAPI extends Request {
  async searchAddresses({ query, page = 1, perPage = 20 }: { query: string; page?: number; perPage?: number }) {
    const resp = await this.req.get<{
      results: {
        common: {
          errorMessage: string
          countPerPage: string
          totalCount: string
          errorCode: string
          currentPage: string
        }
        juso: DocumentJusoAddress[] | null
      }
    }>('/addrlink/addrLinkApi.do', {
      params: {
        confmKey: import.meta.env.JUSO_API_KEY,
        resultType: 'json',
        keyword: query,
        currentPage: page,
        countPerPage: perPage,
      },
    })
    if (!resp?.data) {
      return null
    }
    if (resp.data.results.common.errorMessage !== '정상') {
      if (resp.data.results.common.errorMessage.includes('주소를 상세히')) {
        return null
      } else if (resp.data.results.common.errorMessage) {
        return null
      }
      throw new Error(`Juso service 정상아님: ${resp.data.results.common.errorMessage}`)
    }

    if (!resp?.data.results.juso) {
      return null
    }

    return {
      common: {
        ...resp.data.results.common,
        totalCount: Number(resp.data.results.common.totalCount),
        countPerPage: Number(resp.data.results.common.countPerPage),
        currentPage: Number(resp.data.results.common.currentPage),
      },
      juso: resp.data.results.juso.map((j) => ({
        ...j,
        regionAddress: j.jibunAddr,
        roadAddress: j.roadAddr,
      })),
    }
  }
}
