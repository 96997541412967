import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { Fragment } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { PostV2Dto } from '@src/apis/__generated__'
import { PostPreviewSimplified } from '@src/place-utils/community/Preview'
import { storyArticleDetailURLSelectorFamily } from '@src/place-utils/community/store'
import { SectionHeader } from '@src/react-utils/components'
import { Button } from '@src/react-utils/components/Button'
import { Section } from '@src/react-utils/components/Layout'
import type { ReactionType } from '@src/react-utils/components/Reaction/ReactionDisplay'
import { useToast } from '@src/react-utils/components/Toast'
import { useScreenInstance } from '@src/react-utils/hooks/useScreenInstance'
import { useNavigator } from '@src/stackflow'

import { useAnalytics, useTab } from '../../hooks'
import {
  communityPostsAtomFamily,
  communityPostsStaticMetaAtomFamily,
  communityPostsSortingOptionAtomFamily,
} from '../../state/story'

const StorySection = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const { pushScheme } = useNavigator()
  const { showToast } = useToast()

  const logEvent = useAnalytics()
  const { setTab } = useTab({ poiId })
  const screenInstance = useScreenInstance()

  const sortingOption = useRecoilValue(communityPostsSortingOptionAtomFamily({ screenInstance, poiId }))
  const communityPosts = useRecoilValue(communityPostsAtomFamily({ screenInstance, poiId, sortingOption }))
  const communityPostsStaticMeta = useRecoilValue(
    communityPostsStaticMetaAtomFamily({ screenInstance, poiId, sortingOption })
  )
  const communityPostsInitialized = communityPostsStaticMeta.total !== -1

  const goToStoryArticleTab = () => {
    logEvent('click_view_more_community_button')
    setTab('posts', 'story')
  }

  const goToStoryArticleDetail = useRecoilCallback(
    ({ snapshot }) =>
      async ({ post, index }: { post: PostV2Dto; index: number }) => {
        logEvent('click_community_card', {
          area: 'home_tab_community_section',
          position: index + 1,
          source_type: post.type === 'STORY_ARTICLE' ? 'post' : post.type === 'STORY_COMMENT' ? 'comment' : undefined,
          source_id: post.sourceId,
        })

        const postId = post.type === 'STORY_COMMENT' ? post.article?.id : post.sourceId

        // 본문이 삭제된 댓글의 경우, article이 null 로 내려오기 때문에 이동 불가해요.
        if (!postId) {
          showToast({
            text: '동네생활 게시글이 삭제되었어요.',
            duration: 'long',
          })
          return
        }

        const { targetURI } = await snapshot.getPromise(storyArticleDetailURLSelectorFamily({ articleId: +postId }))
        pushScheme(targetURI)
      },
    [logEvent, pushScheme, showToast]
  )

  if (!communityPostsInitialized || communityPostsStaticMeta.total === 0) {
    return null
  }

  return (
    <Section>
      <SectionHeader type="basic" text="동네생활" />
      <div>
        {communityPosts
          .filter((communityPost) => communityPost.content)
          .slice(0, 3)
          .map(({ type, ...post }, idx) => {
            return (
              <Fragment key={idx}>
                <Card
                  data={{
                    type: type === 'STORY_COMMENT' ? 'comment' : 'article',
                    id: post.sourceId,
                    author: {
                      id: post.user?.id ?? -1,
                      name: post.user?.nickname ?? '',
                      profileImage: post.user?.profileImageUrl ?? undefined,
                      regionName: post.user?.displayRegionName?.toString() ?? '',
                      regionCheckInCount: post.user?.displayRegionCheckinsCount,
                    },
                    content: post.content ?? '',
                    images: {
                      url: post.images?.map((image) => image.url) ?? [],
                    },
                    createdAt: post.createdAt ?? '',
                    reaction: {
                      count: post.emotionsCount,
                      types: post.topEmotions?.map((emotion) => emotion as ReactionType),
                    },
                    ...(type === 'STORY_COMMENT' &&
                      'article' in post && {
                        articleContent: post.article?.content,
                        articleId: post.article?.id,
                      }),
                  }}
                  onContentClick={goToStoryArticleDetail.bind(null, { post: { type, ...post }, index: idx })}
                />
                <Divider />
              </Fragment>
            )
          })}
      </div>
      {communityPostsStaticMeta.total > 3 && (
        <Button styleTheme="secondary-low" textSize="14" fullwidth onClick={goToStoryArticleTab}>
          동네생활 글 더보기
        </Button>
      )}
    </Section>
  )
}

const Card = styled(PostPreviewSimplified)`
  padding: 1rem 0;
`
const Divider = styled.hr`
  height: 0.063rem;
  inline-size: 100%;
  margin: 0 auto;
  border: none;
  background-color: ${vars.$semantic.color.divider1};
  &:last-of-type {
    display: none;
  }
`
export default StorySection
