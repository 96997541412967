import { ResidenceInfoDto } from '@daangn/local-business-network/lib/poi'
import { atom, selector } from 'recoil'

import { userInfoAtom } from '@src/place-utils/user/store'

import { generateKey } from './key'
import { nativeInfoSelectorFamily } from './native-info'
import { userNativeReviewsSelectorFamily } from './review'

const myNativeInfoSelector = selector({
  key: generateKey('my-native-info-selector'),
  get: ({ get }) => {
    const { id: userId } = get(userInfoAtom)
    const myNativeInfo = get(nativeInfoSelectorFamily(userId))

    return myNativeInfo
  },
})

export const myResidenceInfoSelector = selector({
  key: generateKey('my-residence-info-selector'),
  get: ({ get }) => {
    const myNativeInfo = get(myNativeInfoSelector)

    return myNativeInfo?.residenceInfos || []
  },
})

export const myResidenceInfoAtom = atom<ResidenceInfoDto[]>({
  key: generateKey('my-residence-info-atom'),
  default: myResidenceInfoSelector,
})

export const myReviewCountByStatusSelector = selector({
  key: generateKey('my-review-count-by-status-selector'),
  get: ({ get }) => {
    const myNativeInfo = get(myNativeInfoSelector)
    const countByStatus = myNativeInfo?.reviewCountByStatus

    return {
      pendingCount: countByStatus?.PENDING || 0,
      approvedCount: countByStatus?.APPROVED || 0,
      rejectedCount: countByStatus?.REJECTED || 0,
    }
  },
})

export const myNativeReviewsSelector = selector({
  key: generateKey('my-native-reviews-selector'),
  get: ({ get }) => {
    const { id: userId } = get(userInfoAtom)
    const reviews = get(userNativeReviewsSelectorFamily(userId))

    return reviews
  },
})
