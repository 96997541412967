import { LegacyPoiReviewResponse } from '@daangn/local-business-network/lib/legacyReview'
import { Image } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { useActivityParams } from '@stackflow/react'
import { ChangeEvent, useCallback, useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import type { Review as BizReviewType } from '@src/apis/types/BizReview'
import PoiInput from '@src/place-utils/poi/PoiInput/PoiInput'
import { usePoiInputSelectionState } from '@src/place-utils/poi/PoiInput/state'
import { myPoiReviewSelectorFamily } from '@src/place-utils/review/store/poiReview'
import { FormItemWrapper } from '@src/place-utils/widgets/form/FormItemWrapper'
import { ImageUploader, ImageUploadPicture } from '@src/react-utils/components/Image'
import { Ref } from '@src/react-utils/components/Image/ImageUploader/ImageUploader'
import { MultiLineTextInput } from '@src/react-utils/components/Input'
import { Loading } from '@src/react-utils/components/Loading'
import { withSuspense } from '@src/react-utils/components/Suspense'

import { ActivityParams } from '.'
import { useAnalytics } from './hook'
import { contentAtom, imageIdsAtom } from './state'
import { myBizReviewSelectorFamily } from './state/bizReview'

interface Props {
  poiId?: string
  initialValues?: {
    content: string
    images: Image[]
  }
}

const WritePoiReview = ({ initialValues, ...props }: Props) => {
  const params = useActivityParams<ActivityParams>()
  const logEvent = useAnalytics()
  const [selectedPoiInfo, setPoiInputSelection] = usePoiInputSelectionState()
  const { id, bizAccountId } = selectedPoiInfo

  const poiId = props.poiId || id || params.poiId || ''

  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId }))

  const { status: myBizReviewStatus, review: myBizReview } = useRecoilValue(
    myBizReviewSelectorFamily(bizAccountId ?? '')
  )

  const setImageIds = useSetRecoilState(imageIdsAtom)
  const [content, setContent] = useRecoilState(contentAtom)

  const imageUploaderRef = useRef<Ref>({})

  const reviewInputId = 'review'

  const handleClickPoiInput = () => {
    logEvent('click_search_poi_input')
  }

  const handleFocusReviewTextarea = () => {
    logEvent('click_review_textarea')
  }

  const handleChangeReviewTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value)
  }

  const handleImageUploaded = (pictures: ImageUploadPicture[]) => {
    const pictureIds = pictures.map(({ id }) => id)
    setImageIds(pictureIds)
  }

  const handleFetchPoiReview = useCallback(
    (review: LegacyPoiReviewResponse) => {
      setContent(review.content)

      imageUploaderRef.current?.handleAddPictures?.(
        review.images.map((image) => ({
          id: image.id,
          url: image.thumbnail,
        }))
      )
    },
    [setContent]
  )

  const handleFetchBizReview = useCallback(
    (review: BizReviewType) => {
      if (myBizReviewStatus !== 'DELETED') {
        setContent(review.content)
        imageUploaderRef.current?.handleAddPictures?.(
          review.media_objects.map((media) => ({
            id: media.id,
            url: media.url,
          }))
        )
      }
    },
    [myBizReviewStatus, setContent]
  )

  useEffect(() => {
    if (myBizReview) return handleFetchBizReview(myBizReview)

    if (myReview) return handleFetchPoiReview(myReview)

    setContent('')
    imageUploaderRef.current?.handleResetPictures?.()
  }, [handleFetchBizReview, handleFetchPoiReview, myBizReview, myReview, setContent])

  useEffect(() => {
    const { poiId: id, poiName: name, bizAccountId } = params

    if (!id || !name) return

    setPoiInputSelection({
      id,
      name,
      bizAccountId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // fill initial Values
  useEffect(() => {
    if (!initialValues) return

    const { content, images } = initialValues
    if (content) setContent(content)
    if (images) setImageIds(images.map(({ id }) => id))
  }, [initialValues, setContent, setImageIds])

  return (
    <Container>
      {!params.poiId && <PoiInput onClick={handleClickPoiInput} />}
      <FormItemWrapper id={reviewInputId} label="후기">
        <MultiLineTextInput
          id={reviewInputId}
          value={content}
          onFocus={handleFocusReviewTextarea}
          onChange={handleChangeReviewTextarea}
          placeholder="이웃을 위해 자세한 추천 글을 써주세요"
          maxHeight={246}
          maxTextLength={3000}
        />
      </FormItemWrapper>
      <FormItemWrapper id="imageIds" label="사진">
        <ImageUploader
          maxCount={5}
          ref={imageUploaderRef}
          images={
            initialValues?.images
              ? initialValues.images.map(({ id, thumbnail }: { id: string; thumbnail: string }) => {
                  return { id, url: thumbnail }
                })
              : []
          }
          onUpdateImage={handleImageUploaded}
        />
      </FormItemWrapper>
    </Container>
  )
}

const Container = styled.div`
  padding: 16px;
`
export default withSuspense(WritePoiReview, Loading)
