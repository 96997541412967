import IconExpandMoreRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconExpandMoreRegular'
import { receive } from '@stackflow/compat-await-push'
import { useState } from 'react'

import { useNavigator } from '@src/stackflow'

import { useReviewEdit } from './contexts/ReviewEditProvider'
import { Dropdown } from '../Form/components'
import { VisitCount } from '../Form/types/restaurant-review-form'
import { VisitCountMap } from '../Modal/FrequencyPickerBottomSheet'
import { convertVisitCountToText } from '../utils'

interface Props {
  initialValue: {
    visitCount: VisitCount
  }
}
export const VisitCountDropdown = ({ initialValue }: Props) => {
  const { push } = useNavigator()
  const { updateForm } = useReviewEdit()

  const [selectedVisitCount, setSelectedVisitCount] = useState<{
    value?: VisitCountMap[0]['value']
    text: VisitCountMap[0]['text']
  }>({
    value: initialValue.visitCount,
    text: convertVisitCountToText(initialValue.visitCount),
  })

  const handleClick = async () => {
    const { value } = await receive<{ value: VisitCount }>({
      activityId: push('native_review_modal', { modalId: 'form-frequency-picker' }).activityId,
    })

    setSelectedVisitCount({
      value,
      text: convertVisitCountToText(value),
    })
    updateForm({
      poi: {
        visitCount: value,
      },
    })
  }

  return (
    <Dropdown
      placeholder={convertVisitCountToText(initialValue.visitCount)}
      suffix={<IconExpandMoreRegular width={24} height={24} onClick={handleClick} />}
      onClick={handleClick}
      value={selectedVisitCount.text}
    />
  )
}
