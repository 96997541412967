import { IS_PROD } from '../environmentConstants'

export const BIZ_PROFILE_APP_BASE_URL = IS_PROD
  ? 'https://bizprofile.karrotwebview.com'
  : 'https://bizprofile.alpha.karrotwebview.com'

export const PERMALINK_BASE_URL = IS_PROD ? 'https://www.daangn.com' : 'https://alpha.daangn.com'

export const WEBVIEW_BASE_URL = IS_PROD
  ? 'https://webview.prod.kr.karrotmarket.com/'
  : 'https://webview.alpha.kr.karrotmarket.com/'

export const PLACE_EVENT_WEB_BASE_URL = IS_PROD ? 'https://place.daangn.com' : 'https://place.alpha.daangn.com'

export const COMMUNITY_WEB_PROFILE_BASE_URL = IS_PROD
  ? 'https://community-web-profile.karrotwebview.com'
  : 'https://community-web-profile.alpha.karrotwebview.com'

/**
 * 동네생활 오픈커뮤니티 (아고라)
 */
export const COMMUNITY_AGORA_WEB_BASE_URL = IS_PROD
  ? 'https://community-web-agora.kr.karrotmarket.com'
  : 'https://community-web-agora.alpha.kr.karrotmarket.com'

/**
 * 신고하기
 */
export const REPORT_WEBVIEW_BASE_URL = IS_PROD
  ? 'https://report.karrotwebview.com'
  : 'https://report.alpha.karrotwebview.com'
