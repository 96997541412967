import { useActivityParams } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { removeDuplicates, ensureArray, getMatchingValues } from '@src/js-utils/array'
import { extractValuesByKey } from '@src/js-utils/object'
import { checkInRegionInfoAtom } from '@src/place-utils/region/store'

import { REGION_MAP } from '../constants'
import { myResidenceInfoAtom } from '../store'
import { getYearsOfResidence } from '../utils'

function getResidenceInfo(
  myResidenceInfo: { regionId: number; startYearOfResidence: number }[],
  targetRegionId: number
): {
  hasResidenceInfo: boolean
  startYearOfResidence?: number
  yearsOfResidence?: number
} {
  const startYearOfResidence = myResidenceInfo.find((info) => info.regionId === targetRegionId)?.startYearOfResidence
  const hasResidenceInfo = !!startYearOfResidence
  const yearsOfResidence = hasResidenceInfo ? getYearsOfResidence(startYearOfResidence) : undefined

  return {
    hasResidenceInfo,
    startYearOfResidence,
    yearsOfResidence,
  }
}

type UsedFor = 'list' | 'form'
type RegionInfo<T extends UsedFor> = {
  region: {
    id: number
    name: string
  }
  me: T extends 'form'
    ? {
        hasResidenceInfo: boolean
        startYearOfResidence?: number
        yearsOfResidence?: number
      }
    : undefined
}

interface UseRegionInfo<T extends UsedFor> {
  displayRegion: RegionInfo<T>
  checkInRegion: RegionInfo<T>
}
export const useRegionInfo = (type: 'list' | 'form'): UseRegionInfo<'form' | 'list'> => {
  const myResidenceInfo = useRecoilValue(myResidenceInfoAtom)

  // 유저의 현재 체크인 지역 정보
  const checkInRegionInfo = useRecoilValue(checkInRegionInfoAtom)
  const allCheckInRegionIds = removeDuplicates(
    extractValuesByKey(checkInRegionInfo, ['id', 'name1Id', 'name2Id', 'name3Id', 'parentId'])
  )
  // 현재 체크인 지역 정보에 대한 유저의 거주정보
  const checkInRegionResidenceInfo = getResidenceInfo(myResidenceInfo, checkInRegionInfo.id)
  const checkInRegion: UseRegionInfo<'form' | 'list'>['checkInRegion'] = {
    region: {
      id: checkInRegionInfo.id,
      name: checkInRegionInfo.name,
    },
    me: checkInRegionResidenceInfo,
  }

  // 쿼리 파라미터에 ?region=123이 존재하는 경우, 해당 지역을 타겟 지역으로 사용
  const queryParams = useActivityParams<{ region?: string }>()
  // comma separated로 여러 region id 값들을 받을 수 있음
  const queryParamRegionIds = decodeURIComponent(queryParams.region ?? '')
    .split(',')
    .filter(Boolean)
  const availableRegionIds = Object.keys(REGION_MAP)
  const { values: matchingKeys, hasMatches: isAvailableRegions } = getMatchingValues(
    ensureArray(queryParamRegionIds),
    availableRegionIds
  )

  const targetRegionIds = isAvailableRegions ? matchingKeys.map((id) => +id) : undefined
  const { hasMatches: isCheckedInTargetRegion, values: matches } = getMatchingValues(
    allCheckInRegionIds,
    targetRegionIds ?? []
  )

  // 타겟 지역 또는 그 하위 지역에 체크인이 되어있는 경우, 해당 지역을 타겟 지역으로 사용.
  // 만약 타겟 지역 또는 그 하위 지역에 체크인이 되어있지 않을 경우, 첫번째 지역을 타겟 지역으로 사용. (추후 엣지케이스 대응방식 논의 필요)
  const targetRegionId = isCheckedInTargetRegion ? matches[0] : targetRegionIds ? targetRegionIds[0] : undefined
  const targetRegion = targetRegionId
    ? {
        id: targetRegionId,
        name: REGION_MAP[targetRegionId].name,
      }
    : undefined

  // 타겟 지역에 대한 유저의 거주정보
  const targetRegionResidenceInfo = targetRegionId ? getResidenceInfo(myResidenceInfo, targetRegionId) : undefined

  // 생성 폼에 사용될 정보
  const displayRegionForForm = ((): UseRegionInfo<'form'>['displayRegion'] => {
    // 타겟 지역을 지정한 페이지가 아닌경우 유저의 체크인 지역 정보를 사용.
    // 쿼리 파라미터에 ?region=123이 존재하지 않는 경우가 이에 해당됩니다.
    if (!targetRegion?.id) {
      return checkInRegion
    }

    // 타겟 지역에 대한 거주정보가 벌써 존재하는 경우, 해당 거주정보를 사용.
    if (targetRegionResidenceInfo?.hasResidenceInfo) {
      return {
        region: targetRegion,
        me: targetRegionResidenceInfo,
      }
    }

    // 타겟 지역이 체크인 지역의 상위(parent) 지역인 경우, display용 name을 제외한 모든 정보를 체크인 지역 정보를 사용.
    if (isCheckedInTargetRegion) {
      return {
        region: {
          id: checkInRegionInfo.id,
          name: targetRegion.name,
        },
        me: checkInRegionResidenceInfo,
      }
    }

    return {
      region: targetRegion,
      me: {
        hasResidenceInfo: false,
        startYearOfResidence: undefined,
        yearsOfResidence: undefined,
      },
    }
  })()

  /**
   * UNSTABLE
   */
  const displayRegionForList = ((): UseRegionInfo<'list'>['displayRegion'] => {
    // 타겟 지역을 지정한 페이지가 아닌경우 유저의 체크인 지역 정보를 사용.
    // 쿼리 파라미터에 ?region=123이 존재하지 않는 경우가 이에 해당됩니다.
    if (!targetRegion?.id) {
      return checkInRegion
    }

    return {
      region: targetRegion,
      me: undefined,
    }
  })()

  return {
    checkInRegion,
    displayRegion: type === 'form' ? displayRegionForForm : displayRegionForList,
  }
}
