import styled from '@emotion/styled'
import { memo } from 'react'

import { Spinner } from '../Spinner'

export const PageLoading = memo(({ className }: { className?: string }) => {
  return (
    <FullHeightProgressWrapper className={className}>
      <Spinner variant="gray" size="medium" />
    </FullHeightProgressWrapper>
  )
})

const FullHeightProgressWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    box-sizing: border-box;
  }
`
