import { getPoiByIdV2 } from '@daangn/local-business-network/lib/poi'
import * as Sentry from '@sentry/react'
import { selectorFamily } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'

const generatePoisApiRecoilKey = generateUniqueKey('poisApi')

export const poiByIdSelectorFamily = selectorFamily({
  key: generatePoisApiRecoilKey('poiById'),
  get:
    ({ poiId }: { poiId: string }) =>
    async () => {
      try {
        if (poiId.length === 0) return null

        const resp = await getPoiByIdV2({ params: { id: poiId } })

        return resp.data
      } catch (error) {
        Sentry.captureException(error)

        throw error
      }
    },
})
