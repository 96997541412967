import { useActivityParams } from '@stackflow/react'
import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'

import { useLogEvent } from '@src/react-utils/components/Analytics'

import { useKeywords } from './useKeywords'
import { analyticsSessionIds } from '../state/analytics'
import { queryAtom } from '../state/query'

export const useAnalytics = () => {
  const logEvent = useLogEvent()

  const { story_article_id } = useActivityParams<{ story_article_id?: string }>()
  const query = useRecoilValue(queryAtom)
  const { hasKeyword } = useKeywords()

  return useCallback(
    (name: string, params?: { [key: string]: any }) => {
      logEvent({
        name,
        params: {
          query: typeof query === 'string' && query.length > 0 ? query : null,
          is_predicted_keyword: hasKeyword(query),
          query_session_id: analyticsSessionIds.getQuerySessionId() ?? null,
          visit_session_id: analyticsSessionIds.getVisitSessionId() ?? null,
          story_article_id: story_article_id ?? null,
          ...params,
        },
      })
    },
    [hasKeyword, logEvent, query, story_article_id]
  )
}
