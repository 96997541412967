import { selectorFamily } from 'recoil'

import { nativeReviewsAtomFamily, nativeReviewsQueryFamilyKey } from '@src/pages/NativeReview/store'

import { generateKey } from './generateKey'
import { type ReactionKey, type ReactionState } from './reactions'

export const nativeReviewUpvoteSelectorFamily = selectorFamily<ReactionState | undefined, ReactionKey>({
  key: generateKey('native-review-upvote-selector-family'),
  get:
    ({ resourceId, resourceType, type }) =>
    ({ get }) => {
      const familyKey = get(nativeReviewsQueryFamilyKey)
      const nativeReviews = get(nativeReviewsAtomFamily(familyKey))
      const reactions = nativeReviews.items.find(({ review }) => String(review.id) === resourceId)?.reactions
      const upvoteReaction = reactions?.find(
        ({ type, targetType }) => targetType === 'NATIVE_REVIEW' && type === 'UPVOTE'
      )

      if (!upvoteReaction) {
        return undefined
      }

      return {
        resourceId,
        resourceType,
        type,
        totalCount: upvoteReaction.totalCount,
        isReactedByMe: false,
      }
    },
})
