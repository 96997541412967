import { numberWithCommas } from '@daangn/local-business-kit'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

import { UiDisableProps } from './CouponCardBase'
import { useDateFormat } from '../hooks'
import { CouponStateReturnValue } from '../types'

export interface CouponCardProps {
  isManager: boolean
  issuedCount: number | null
  claimedCount: number
  expiresAt?: string
  uiDisabled: boolean
  couponStatus: CouponStateReturnValue
}

const DateAndCountSection = ({
  isManager,
  issuedCount,
  claimedCount,
  expiresAt,
  uiDisabled,
  couponStatus,
}: CouponCardProps) => {
  const dateFormat = useDateFormat(expiresAt || '')
  const hasIssuedCount = typeof issuedCount === 'number'
  const leftCounts = (issuedCount || 0) - (claimedCount || 0)
  const showLeftCounts = hasIssuedCount && leftCounts <= 30
  const claimableCoupon = couponStatus.isActiveStatus

  return (
    <S_Base uiDisabled={uiDisabled}>
      {dateFormat && (
        <S_ExpiresAt>
          {dateFormat.year}년 {dateFormat.month}월 {dateFormat.date}일까지
        </S_ExpiresAt>
      )}
      {showLeftCounts && !isManager && claimableCoupon && (
        <S_CountWrapper>
          {dateFormat && <S_DotDivider>&nbsp;∙&nbsp;</S_DotDivider>}
          <S_LeftCouponText uiDisabled={uiDisabled}>{numberWithCommas(leftCounts)}장 남음</S_LeftCouponText>
        </S_CountWrapper>
      )}
    </S_Base>
  )
}

const S_Base = styled.div<UiDisableProps>`
  display: flex;
  color: ${({ uiDisabled }) => (uiDisabled ? vars.$scale.color.gray500 : vars.$scale.color.gray600)};
`
const DescriptionText = () => css`
  display: block;
  font-size: 0.75rem;
  line-height: 133%;
  letter-spacing: -0.02em;
`
const S_ExpiresAt = styled.span`
  ${DescriptionText}
`
const S_CountWrapper = styled.div`
  display: flex;
`
const S_DotDivider = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.625rem;
  font-weight: 500;
`
const S_LeftCouponText = styled.b<UiDisableProps>`
  font-weight: 400;
  line-height: 133%;
  color: ${({ uiDisabled }) => (uiDisabled ? vars.$scale.color.gray500 : vars.$scale.color.carrot500)};
  ${DescriptionText}
`

export default DateAndCountSection
