import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

const S_FilledBadge = styled.span<{ uiDisabled: boolean; margin?: string }>`
  padding: 0.0938rem 0.25rem 0.0313rem;
  border-radius: 0.125rem;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ uiDisabled }) => (uiDisabled ? vars.$scale.color.gray500 : vars.$scale.color.gray700)};
  background: ${vars.$scale.color.gray100};
  border: 1px solid ${vars.$scale.color.gray100};
  margin: ${({ margin }) => margin ?? '0'};
  white-space: nowrap;
`

export default S_FilledBadge
