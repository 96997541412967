import { BoxButton } from '@daangn/sprout-components-button'
import IconExpandMoreRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconExpandMoreRegular'
import { receive } from '@stackflow/compat-await-push'
import { useState } from 'react'

import { josa } from '@src/js-utils/language-korean'
import { useNavigator } from '@src/stackflow'

import { Layout, Title, Dropdown } from '../components'
import { useStepForm } from '../hooks/useStepForm'

const SELECT_YEAR_PLACEHOLDER = '거주 시작 연도를 선택해주세요.'

const convertYearToText = (year: number) => `${year}년부터`

export const StartYearOfResidence = () => {
  const { push } = useNavigator()
  const {
    go,
    formValue: {
      user: {
        region: { startYearOfResidence, ...region },
      },
    },
    updateForm,
  } = useStepForm()

  const initialYearText = startYearOfResidence ? convertYearToText(startYearOfResidence) : SELECT_YEAR_PLACEHOLDER
  const [selectedYear, setSelectedYear] = useState<{ value: number | null; text: string }>({
    value: startYearOfResidence,
    text: initialYearText,
  })

  const openYearPicker = async () => {
    const { value } = await receive<{ value: number }>({
      activityId: push('native_review_modal', { modalId: 'form-year-picker' }).activityId,
    })

    setSelectedYear({
      value,
      text: convertYearToText(value),
    })

    updateForm({
      user: {
        region: {
          startYearOfResidence: value,
        },
      },
    })
  }

  const handleClickNext = () => {
    go.next()
  }

  return (
    <Layout>
      <Title>
        지금 계신 {josa(region.name, '은/는')}
        <br /> 언제부터 거주하셨어요? 🧐
      </Title>
      <Dropdown
        placeholder={SELECT_YEAR_PLACEHOLDER}
        suffix={<IconExpandMoreRegular width={24} height={24} onClick={openYearPicker} />}
        onClick={openYearPicker}
        value={selectedYear.text}
      />

      <BoxButton
        isDisabled={!selectedYear.value}
        variant="primary"
        size="xlarge"
        width="100%"
        onClick={handleClickNext}>
        다음
      </BoxButton>
    </Layout>
  )
}
