import { updateNativeReviewV2, UpdateNativeReviewBody } from '@daangn/local-business-network/lib/poi'
import { captureException } from '@sentry/react'
import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useReducer, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { userInfoAtom } from '@src/place-utils/user/store'
import { DeepPartial } from '@src/types/utility-types'

import { mergeNestedObjects } from '../../Form/contexts/StepFormProvider'
import { nativeReviewSelectorFamily } from '../../store'

type FormValue = UpdateNativeReviewBody
type IntermediateFormValue = DeepPartial<FormValue>

interface ReviewEditForm {
  formValue: FormValue
  isSubmitDisabled: boolean
  updateForm: (newValue: IntermediateFormValue) => void
  submitForm: () => Promise<void>
}
const createReviewEditFormContext = (context: ReviewEditForm) => context

export const ReviewEditFormContext = createContext<ReturnType<typeof createReviewEditFormContext>>(null as any)

interface Props {
  initialFormValue: FormValue
  reviewId: number
}
export const ReviewEditProvider = ({ initialFormValue, reviewId, children }: PropsWithChildren<Props>) => {
  const { authToken } = useRecoilValue(userInfoAtom)

  const [formValue, setFormValue] = useState<FormValue>(initialFormValue)

  const initialSubmitState: boolean = useMemo(() => {
    const isContentEmpty = formValue.review.content.length <= 0

    return isContentEmpty
  }, [formValue.review.content.length])
  const [isSubmitDisabled, dispatchSubmitState] = useReducer(
    (state: boolean, action: { type: 'disable' } | { type: 'enable' }): boolean => {
      switch (action.type) {
        case 'disable':
          return true
        case 'enable':
          return false
        default:
          return state
      }
    },
    initialSubmitState
  )
  const validate = ({ content }: { content: string }) => {
    if (content.length <= 0) {
      dispatchSubmitState({ type: 'disable' })
    } else {
      dispatchSubmitState({ type: 'enable' })
    }
  }

  const updateForm = useCallback((newValue: IntermediateFormValue) => {
    setFormValue((prev) => {
      const mergedValue = mergeNestedObjects(prev, newValue, { override: ['imageIds'] })

      validate({ content: mergedValue.review.content })

      return mergedValue
    })
  }, [])

  const submitForm = useRecoilCallback(
    ({ refresh }) =>
      async () => {
        try {
          await updateNativeReviewV2({
            params: {
              xAuthToken: authToken,
              id: reviewId,
              updateNativeReviewBody: formValue,
            },
          })

          refresh(nativeReviewSelectorFamily(reviewId))
        } catch (error) {
          captureException(error)

          throw error
        }
      },
    [authToken, formValue, reviewId]
  )

  const value = useMemo(() => {
    return {
      formValue,
      isSubmitDisabled,
      updateForm,
      submitForm,
    }
  }, [formValue, isSubmitDisabled, submitForm, updateForm])

  return <ReviewEditFormContext.Provider value={value}>{children}</ReviewEditFormContext.Provider>
}

export const useReviewEdit = () => useContext(ReviewEditFormContext)
