type AddPosition = 'first' | 'last'

export function addItemToArray<T>(array: T[], item: T, position: number | AddPosition): T[] {
  if (position === 'first') {
    return [item, ...array]
  } else if (position === 'last') {
    return [...array, item]
  } else {
    return [...array.slice(0, position), item, ...array.slice(position)]
  }
}
