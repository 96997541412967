import { MultilineTextField } from '@daangn/sprout-components-text-field'
import { useRef } from 'react'

import { useReviewEdit } from './contexts/ReviewEditProvider'
import { multiLineTextFieldStyleOverride, REVIEW_CONTENT_PLACEHOLDER } from '../Form/RestaurantReview'

interface Props {
  initialValue: {
    content: string
  }
}

export const Content = ({ initialValue }: Props) => {
  const { updateForm } = useReviewEdit()
  const contentRef = useRef<string>(initialValue.content)

  const onContentChange = (value: string) => {
    updateForm({
      review: {
        content: value,
      },
    })
  }

  return (
    <MultilineTextField
      placeholder={REVIEW_CONTENT_PLACEHOLDER}
      width="100%"
      height="12.5rem"
      marginBottom="1rem"
      UNSAFE_className={multiLineTextFieldStyleOverride}
      onChange={onContentChange}
      defaultValue={contentRef.current}
    />
  )
}
