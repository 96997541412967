import { atom } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'

const generateKey = generateUniqueKey(['searchPoi', 'view'])

export type ViewType = 'list' | 'map'
export const viewTypeAtom = atom<ViewType>({
  key: generateKey('viewType'),
  default: 'list',
})
