import { selectorFamily } from 'recoil'

import { removeDuplicatesByKey } from '@src/js-utils/array'
import { nativeReviewsAtomFamily, nativeReviewsQueryFamilyKey } from '@src/pages/NativeReview/store'

import { generateKey } from './generateKey'
import { reactionByResourceAtomFamily } from './reaction-by-resource'
import { type ReactionKey, type ReactionState } from './reactions'

export const poiMatjibReactionSelectorFamily = selectorFamily<ReactionState | undefined, ReactionKey>({
  key: generateKey('poi-matjib-reaction-selector-family'),
  get:
    ({ resourceId, resourceType, type }) =>
    ({ get }) => {
      // list에서 받아온 10개 리액션
      const familyKey = get(nativeReviewsQueryFamilyKey)
      const nativeReviews = get(nativeReviewsAtomFamily(familyKey))
      const reactions = nativeReviews.items.find(({ poi }) => resourceId === poi.id)?.reactions
      const matjibReaction = reactions?.find(({ type, targetType }) => targetType === 'POI' && type === 'MATJIB')

      const reaction1: ReactionState['reactedUsers'] =
        matjibReaction?.nativeUsers.map(({ userId, nickname, profileImageUrl, residenceInfo }) => ({
          id: userId,
          nickname,
          profileImageURL: profileImageUrl,
          residenceInfo: {
            region: {
              id: residenceInfo.regionId,
              name: residenceInfo.regionName,
            },
            startYearOfResidence: residenceInfo.startYearOfResidence,
          },
        })) ?? []

      const reactionByResource = get(
        reactionByResourceAtomFamily({ resourceId, resourceType, type, sortBy: 'residenceYear' })
      )
      const reaction2: ReactionState['reactedUsers'] =
        reactionByResource?.nativeUsers.map(({ userId, nickname, profileImageUrl, residenceInfo }) => ({
          id: userId,
          nickname,
          profileImageURL: profileImageUrl,
          residenceInfo: {
            region: {
              id: residenceInfo.regionId,
              name: residenceInfo.regionName,
            },
            startYearOfResidence: residenceInfo.startYearOfResidence,
          },
        })) ?? []

      const totalCount = matjibReaction?.totalCount || reactionByResource?.totalCount || 0
      const aggregatedReactedUsers = removeDuplicatesByKey([...reaction1, ...(reaction2 ?? [])], 'id')

      return {
        resourceId,
        resourceType,
        type,
        totalCount,
        isReactedByMe: false,
        reactedUsers: aggregatedReactedUsers,
      }
    },
})
