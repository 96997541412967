// eslint-disable-next-line import/no-unresolved
import '@daangn/sprout-components-avatar/index.css'

import { AvatarGroup as SproutAvatarGroup, SeedAvatarGroupProps } from '@daangn/sprout-components-avatar'

import { Avatar, AvatarProps } from '../Avatar'

export type AvatarGroupProps = SeedAvatarGroupProps & {
  avatars: Pick<AvatarProps, 'src' | 'alt'>[]
}
export const AvatarGroup = ({ avatars, size, limit, ...sproutAvatarGroupProps }: AvatarGroupProps) => {
  return (
    <SproutAvatarGroup {...sproutAvatarGroupProps} size={size} limit={limit}>
      {avatars.map(({ alt, src }, idx) => (
        <Avatar key={idx} src={src} alt={alt} size={size} />
      ))}
    </SproutAvatarGroup>
  )
}
