import styled from '@emotion/styled'
import IconRetryRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconRetryRegular'
import { vars } from '@seed-design/design-token'
import { MouseEvent } from 'react'

import { ActionChip } from '@src/react-utils/components/Chip'

import { Spinner } from '../Spinner'

const ICON_SIZE = '0.875rem'
interface Props {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  isLoading?: boolean
  className?: string
}
export const BoundarySearchButton = ({ onClick, isLoading, ...props }: Props) => {
  return (
    <Button
      className={props.className}
      onClick={onClick}
      text="이 지역 재검색"
      type="normal"
      appendLeft={
        isLoading ? (
          <Spinner variant="gray" height={ICON_SIZE} width={ICON_SIZE} />
        ) : (
          <IconRetryRegular width={ICON_SIZE} height={ICON_SIZE} color={vars.$scale.color.gray900} />
        )
      }
    />
  )
}

const Button = styled(ActionChip)({
  boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
})
