import { IS_ANDROID } from '@src/constants/environmentConstantsWithBridge'

import Request from './Request'
import { ExperimentKeysRequest, ExperimentVariable } from './types/ExperimentationPlatform'
export const DEFAULT_EXPERIMENT_VARIABLE: ExperimentVariable = {
  key: 'default_key',
  value: 'control',
  variable_type: 'string',
  metadata: {
    experiment_key: 'default_experiment_key',
    assigned_group_name: 'control',
    is_allowlisted: false,
  },
}

export default class ExperimentationPlatform extends Request {
  reqGetExperiment = (experimentKey: ExperimentKeysRequest) =>
    this.req.post<{ variables: ExperimentVariable[] }>(`/api/v1/variables/trigger`, experimentKey, {
      headers: {
        ...this.getUserAuthHeader(),
        contentType: 'application/json',
      },
    })

  reqGetNativeReviewTitle = (userId: string) =>
    this.reqGetExperiment({
      subject_type: 'SUBJECT_TYPE_USER',
      subject_id: userId,
      experiment_keys: ['kr_place_native_review_title_web_v1'],
      subject_metadata: {
        platform: IS_ANDROID ? 'PLATFORM_ANDROID' : 'PLATFORM_IOS',
        country_code: 'COUNTRY_CODE_KR',
      },
    })

  reqGetNativeReviewReliability = (userId: string) => {
    return this.reqGetExperiment({
      subject_type: 'SUBJECT_TYPE_USER',
      subject_id: userId,
      experiment_keys: ['kr_place_native_review_reliability_web_v1'],
      subject_metadata: {
        platform: IS_ANDROID ? 'PLATFORM_ANDROID' : 'PLATFORM_IOS',
        country_code: 'COUNTRY_CODE_KR',
      },
    })
  }
}
