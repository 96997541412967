import { MultilineTextField } from '@daangn/sprout-components-text-field'
import { css } from '@emotion/css'
import { vars } from '@seed-design/design-token'
import { useRef, useEffect, useCallback, useReducer } from 'react'

const TEXT_FIELD_PLACEHOLDER = '맛, 가격, 서비스, 분위기, 편의 시설 등을 알려주세요.'
const TEXT_FIELD_DESCRIPTION = {
  NOT_ENOUGH: '자세한 후기는 맛집을 찾는 이웃에게 큰 도움이 돼요.',
  NO_IMAGE: '음식이나 식당 사진을 추가해보세요.',
  SHORT_TEXT: '조금 더 자세히 써볼까요?',
}

const TEXT_LENGTH_THRESHOLD = 20
const IMAGE_COUNT_THRESHOLD = 1

type State = { status: keyof typeof TEXT_FIELD_DESCRIPTION; text: string } | { status: 'ENOUGH'; text: undefined }
type Action = { payload: { textLength?: number; imageCount?: number } }

interface Props {
  onChange: (value: string) => void
  defaultValue?: string
  imageCount?: number
}
export const TextField = ({ onChange, defaultValue, imageCount = 0 }: Props) => {
  const textFieldRef = useRef<HTMLTextAreaElement>(null)

  const initialState: State = { status: 'NOT_ENOUGH', text: TEXT_FIELD_DESCRIPTION['NOT_ENOUGH'] }
  const reducer = (state: State, action: Action): State => {
    const textLength = action.payload.textLength || textFieldRef.current?.value.length || 0
    const imageCount = action.payload.imageCount || 0

    if (textLength < TEXT_LENGTH_THRESHOLD) {
      // 글자 수 20자 미만, 사진 있음
      if (imageCount >= IMAGE_COUNT_THRESHOLD) {
        return {
          status: 'SHORT_TEXT',
          text: TEXT_FIELD_DESCRIPTION['SHORT_TEXT'],
        }
      }
      // 글자 수 20자 미만, 사진 없음
      else {
        return {
          status: 'NOT_ENOUGH',
          text: TEXT_FIELD_DESCRIPTION['NOT_ENOUGH'],
        }
      }
    } else if (textLength >= TEXT_LENGTH_THRESHOLD) {
      // 글자 수 20자 이상, 사진 있음
      if (imageCount >= IMAGE_COUNT_THRESHOLD) {
        return {
          status: 'ENOUGH',
          text: undefined,
        }
      }
      // 글자 수 20자 이상, 사진 없음
      else {
        return {
          status: 'NO_IMAGE',
          text: TEXT_FIELD_DESCRIPTION['NO_IMAGE'],
        }
      }
    }

    return state
  }
  const [description, dispatchDescription] = useReducer(reducer, initialState)

  const handleChange = useCallback(
    (value: string) => {
      onChange(value)
      dispatchDescription({ payload: { textLength: value.length, imageCount } })
    },
    [imageCount, onChange]
  )

  useEffect(() => {
    dispatchDescription({ payload: { imageCount } })
  }, [imageCount])

  return (
    <MultilineTextField
      ref={textFieldRef}
      defaultValue={defaultValue}
      placeholder={TEXT_FIELD_PLACEHOLDER}
      description={description.text}
      onChange={handleChange}
      width="100%"
      height="13.75rem"
      UNSAFE_className={multiLineTextFieldStyleOverride}
    />
  )
}

const multiLineTextFieldStyleOverride = css({
  '& > div[data-part=head] ': {
    margin: 0,
  },
  '& > div[data-part=field]': {
    margin: 0,
    '& > textarea': {
      ...vars.$semantic.typography.bodyM1Regular,
    },
    '& > textarea::placeholder': {
      ...vars.$semantic.typography.bodyM1Regular,
    },
  },
})
