import { ExtendedFab } from '@daangn/sprout-components-fab'
import { HelpBubbleAnchor } from '@daangn/sprout-components-help-bubble'
import { css } from '@emotion/css'
import IconAddRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconAddRegular'
import { vars } from '@seed-design/design-token'
import { memo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { checkInRegionInfoAtom } from '@src/place-utils/region/store'
import { useNavigator } from '@src/stackflow'

import { useAnalytics, useMyCheckinRegionResidenceInfo } from '../hooks'
import { isWriteReviewButtonNudgeOpenAtom } from '../store/nudge'

export const NativeReviewFormFloatingButton = memo(() => {
  const { push } = useNavigator()
  const logEvent = useAnalytics()
  const { yearsOfResidence } = useMyCheckinRegionResidenceInfo()

  const [isOpen, setIsOpen] = useRecoilState(isWriteReviewButtonNudgeOpenAtom)

  const checkinRegion = useRecoilValue(checkInRegionInfoAtom)

  const handleClickWriteReview = () => {
    logEvent('click_write_review', {})
    push('native_review_form', { step: '1' })
  }

  const handleClick = () => {
    handleClickWriteReview()
  }

  const handleCloseHelpBubble = () => {
    setIsOpen(false)
  }

  return (
    <HelpBubbleAnchor
      isOpen={isOpen}
      onClose={handleCloseHelpBubble}
      description={`${checkinRegion.name} ${yearsOfResidence}년차의 맛집이 궁금해요!`}
      positioning={{
        placement: 'top-end',
      }}>
      <ExtendedFab
        onClick={handleClick}
        icon={<IconAddRegular width={16} height={16} />}
        size="medium"
        position="fixed"
        bottom={16}
        right={16}
        UNSAFE_style={{ backgroundColor: vars.$semantic.color.primary, color: vars.$scale.color.gray00 }}
        UNSAFE_className={extendedFabStyleOverride}>
        내 맛집 추천
      </ExtendedFab>
    </HelpBubbleAnchor>
  )
})

const extendedFabStyleOverride = css({
  marginBottom: ['constant(safe-area-inset-bottom)', 'env(safe-area-inset-bottom)'],

  '> *': {
    backgroundColor: vars.$semantic.color.primary,
    color: vars.$scale.color.gray00,
  },
})
