import styled from '@emotion/styled'
import IconChevronRightThin from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightThin'
import { vars } from '@seed-design/design-token'
import { send } from '@stackflow/compat-await-push'
import { ActivityComponentType } from '@stackflow/react'
import { useMemo, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import poiPinSelected from '@src/assets/images/ic_pin_selected.svg'
import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'
import { checkInRegionInfoAtom } from '@src/place-utils/region/store'
import { addressPickerAtom } from '@src/react-utils/components/Input/AddressInput/store/addressPicker'
import { withKakaoMapErrorFallback } from '@src/react-utils/components/Map/KakaoMap'
import Map from '@src/react-utils/components/Map/Map'
import { useNavigator, Screen, useActivity } from '@src/stackflow'
import { Coordinates, GPS } from '@src/types/global'

import { useAnalytics } from './hooks'
import RedirectToAddressSearch from './RedirectToAddressSearch'

type ActivityParams = {
  poi_id?: string
}
const PinAddress: ActivityComponentType<ActivityParams> = ({ params: { poi_id } }) => {
  const logEvent = useAnalytics()
  const { id: activityId } = useActivity()
  const { pop } = useNavigator()

  const checkInRegionInfo = useRecoilValue(checkInRegionInfoAtom)
  const [addressPickerValue, setAddressPicker] = useRecoilState(addressPickerAtom)
  const userRegionCoordinates = checkInRegionInfo.centerCoordinates
  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId: poi_id ?? '' }))

  const center = useMemo(() => {
    const availableCoordinates = addressPickerValue?.coordinates ?? poi?.coordinates ?? userRegionCoordinates
    return availableCoordinates && ([availableCoordinates.latitude, availableCoordinates.longitude] as GPS)
  }, [addressPickerValue, poi, userRegionCoordinates])

  const kakaoMapRef = useRef<any>(null)
  const kakaoRef = useRef<any>(null)

  const handleMapLoaded = (kakao: any, map: any) => {
    kakaoRef.current = kakao
    kakaoMapRef.current = map
  }

  const handleSetPinAsAddress = () => {
    logEvent('click_select_current_location_button')

    const selectedCoordinates = kakaoMapRef.current.getCenter()
    const coordinates: Coordinates = { latitude: selectedCoordinates.getLat(), longitude: selectedCoordinates.getLng() }

    setAddressPicker({ coordinates })

    send({ activityId, data: { coordinates } })
    pop()
  }

  return (
    <Screen
      appBar={{
        title: '주소 찾기',
      }}>
      <StyledPinAddress>
        <div className="map-container">
          <Map center={center} onMapLoad={handleMapLoaded} />
          <div className="pin-picker-overlay">
            <StyledAddressPicker onClick={handleSetPinAsAddress}>
              <div className="text">
                이 위치 선택하기
                <IconChevronRightThin className="icon" />
              </div>
              <img src={poiPinSelected} />
            </StyledAddressPicker>
          </div>
        </div>
      </StyledPinAddress>
    </Screen>
  )
}

const StyledPinAddress = styled.div`
  height: 100%;
  .map-container {
    height: 100%;
    position: relative;
    .pin-picker-overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const StyledAddressPicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transform: translate3d(0, -53.5px, 0);
  .text {
    ${vars.$semantic.typography.caption1Bold}
    position: relative;
    display: flex;
    align-items: center;
    padding: 8.5px 10px 8.5px 14px;
    border-radius: 17.5px;
    /** @todo: seed-design */
    box-shadow:
      0px 4px 6px rgba(0, 0, 0, 0.08),
      0px 0px 8px rgba(0, 0, 0, 0.05);
    /** @todo: seed-design */
    background: rgba(0, 0, 0, 0.8);
    color: ${vars.$static.color.staticWhite};
    .icon {
      width: 18px;
      height: 18px;
    }
  }
  .text:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    /** @todo: seed-design */
    border-top-color: rgba(0, 0, 0, 0.8);
    border-bottom: 0;
    transform: translate3d(-50%, 10px, 0);
  }
  img {
    margin: 16px 0 -5px;
    height: 62px;
    width: 40px;
  }
`

export default withKakaoMapErrorFallback(PinAddress, RedirectToAddressSearch)
