import type { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'
import globalAxios from 'axios'

let axiosInstance: AxiosInstance = globalAxios
/**
 * @param {AxiosRequestConfig} args - axios.create 의 파라미터
 * @returns {AxiosInstance} - 생성된 axios 객체
 */
export function setFetchConfig(args: AxiosRequestConfig) {
  axiosInstance = globalAxios.create(args)
  return axiosInstance
}

export const MOCK_URL = 'http://localhost:3000'

/**
 *
 * @export
 */
export function createRequest<T = unknown, R = AxiosResponse<T>>({ axiosArgs }: { axiosArgs: RequestArgs }) {
  const axiosRequestArgs = { ...axiosArgs.options, url: axiosArgs.url }

  return axiosInstance.request<T, R>(axiosRequestArgs)
}

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string
  options: AxiosRequestConfig
}
