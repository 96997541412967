import { useRecoilCallback } from 'recoil'

import {
  placeIdsMonitoringWatchStatusAtomFamily,
  watchBAInfosByIdSelectorFamily,
  watchPOIInfosByIdSelectorFamily,
  watchedPlacesByUserMutationAtomFamily,
} from '../store'

export const useResetPlaceWatch = () => {
  return useRecoilCallback(({ snapshot, reset, refresh }) => async () => {
    const baIds = await snapshot.getPromise(placeIdsMonitoringWatchStatusAtomFamily('BA'))
    const poiIds = await snapshot.getPromise(placeIdsMonitoringWatchStatusAtomFamily('POI'))
    refresh(watchPOIInfosByIdSelectorFamily(poiIds))
    refresh(watchBAInfosByIdSelectorFamily(baIds))

    baIds.forEach((baId) => {
      reset(watchedPlacesByUserMutationAtomFamily({ targetType: 'BA', targetId: baId }))
    })
    poiIds.forEach((poiId) => {
      reset(watchedPlacesByUserMutationAtomFamily({ targetType: 'POI', targetId: poiId }))
    })
  })
}
