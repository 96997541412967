import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'

import { useNavigator } from '@src/stackflow'

import IconButton from './IconButton'
import { useAnalytics } from '../hooks'

interface Props {
  iconColor?: string
}

const NavbarMoreButton = ({ iconColor }: Props) => {
  const logEvent = useAnalytics()
  const { poiId } = useActivityParams<{ poiId: string }>()
  const { push } = useNavigator()

  return (
    <IconButton
      type="more"
      activatedStatusColor={iconColor || `${vars.$scale.color.gray900}`}
      onClick={() => {
        logEvent('click_navbar_more_options_button')
        push('poi_detail_modal', { poiId, modalId: 'poiOptionsBottomSheet' })
      }}
    />
  )
}

export default NavbarMoreButton
