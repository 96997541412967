import { MutableRefObject, useEffect, useState } from 'react'

export function useOnScreen<T extends Element>(ref: MutableRefObject<T>, threshold = 0.5, unique = false): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIntersecting(unique ? true : entry.isIntersecting)
        }
      },
      { threshold }
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isIntersecting
}
