import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { receive } from '@stackflow/compat-await-push'
import { useCallback, memo } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { delay } from '@src/js-utils/timeout'
import { useMyCheckinRegionResidenceInfo } from '@src/pages/NativeReview/hooks'
import { CheckinAlertDialogSendData } from '@src/pages/NativeReview/Modal/CheckinAlertDialog'
import type { YearPickerBottomSheetForListData } from '@src/pages/NativeReview/Modal/YearPickerBottomSheetForList/YearPickerBottomSheet'
import { isWriteReviewButtonNudgeOpenAtom } from '@src/pages/NativeReview/store/nudge'
import { getYearsOfResidence } from '@src/pages/NativeReview/utils'
import { ReactionButton, ReactionButtonProps } from '@src/place-utils/reaction/ReactionButton'
import { ReactionKey } from '@src/place-utils/reaction/store'
import { userInfoAtom } from '@src/place-utils/user/store'
import { useNavigator } from '@src/stackflow'
import { TRANSITION_DURATION } from '@src/stackflow/styles'

const BUTTON_TOGGLE_DELAY = 300
const NUDGE_DELAY = 1000
const NUDGE_DURATION = 4000

interface Props extends ReactionKey {
  label?: Parameters<typeof ReactionButton>[number]['label']
  onClick?: ({ isAlreadyClicked }: { isAlreadyClicked: boolean }) => void
}
export const AgreedButton = memo(({ resourceId: reviewId, resourceType, type, label, onClick }: Props) => {
  const { push } = useNavigator()

  const { displayRegionCheckinsCount = 0 } = useRecoilValue(userInfoAtom)
  const isCheckedIn = displayRegionCheckinsCount > 0

  const { hasResidenceInfo } = useMyCheckinRegionResidenceInfo()

  const showWriteReviewButtonNudge = useRecoilCallback(
    ({ set }) =>
      async () => {
        await delay(NUDGE_DELAY + TRANSITION_DURATION)
        set(isWriteReviewButtonNudgeOpenAtom, true)

        await delay(NUDGE_DURATION)
        set(isWriteReviewButtonNudgeOpenAtom, false)
      },
    []
  )

  const handlePickResidenceInfo = useCallback(
    async (isAlreadyClicked: boolean): Promise<{ success: boolean }> => {
      const { selectedYear } = await receive<YearPickerBottomSheetForListData>({
        activityId: push('native_review_modal', { modalId: 'list-year-picker', reviewId }).activityId,
      })

      if (selectedYear) {
        await delay(BUTTON_TOGGLE_DELAY + TRANSITION_DURATION)

        onClick?.({ isAlreadyClicked })

        const yearsOfResidence = selectedYear ? getYearsOfResidence(selectedYear) : undefined
        const livedLongEnough = !!yearsOfResidence && yearsOfResidence >= 5

        if (livedLongEnough) {
          showWriteReviewButtonNudge()
        }

        return { success: true }
      } else {
        return { success: false }
      }
    },
    [onClick, push, reviewId, showWriteReviewButtonNudge]
  )

  const handleCheckin = useCallback(
    async (isAlreadyClicked: boolean): Promise<{ success: boolean }> => {
      const { checkinStatus } = await receive<CheckinAlertDialogSendData>({
        activityId: push('native_review_modal', { modalId: 'list-checkin' }).activityId,
      })

      if (checkinStatus === 'success') {
        await delay(TRANSITION_DURATION)

        if (hasResidenceInfo) {
          await delay(BUTTON_TOGGLE_DELAY)

          onClick?.({ isAlreadyClicked })

          return { success: true }
        } else {
          return handlePickResidenceInfo(isAlreadyClicked)
        }
      } else {
        return { success: false }
      }
    },
    [handlePickResidenceInfo, hasResidenceInfo, onClick, push]
  )

  const handleClick: NonNullable<ReactionButtonProps['onClick']> = useCallback(
    async ({ isAlreadyClicked }) => {
      if (isCheckedIn && hasResidenceInfo) {
        onClick?.({ isAlreadyClicked })

        return { activateReaction: true }
      } else if (isCheckedIn && !hasResidenceInfo) {
        const { success } = await handlePickResidenceInfo(isAlreadyClicked)

        return { activateReaction: success }
      } else {
        const { success } = await handleCheckin(isAlreadyClicked)

        return { activateReaction: success }
      }
    },
    [handleCheckin, handlePickResidenceInfo, hasResidenceInfo, isCheckedIn, onClick]
  )

  return (
    <ReactionButton
      resourceId={reviewId}
      resourceType={resourceType}
      type={type}
      label={
        label ?? {
          render: ({ totalCount }) => (
            <Label>
              👍 여기 맛집이에요
              {totalCount > 0 && <span className="highlight"> {totalCount}</span>}
            </Label>
          ),
        }
      }
      onClick={handleClick}
    />
  )
})

const Label = styled.span({
  '.highlight': {
    color: vars.$semantic.color.primary,
  },
})
