import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

import { SearchAddressListItem } from '@src/js-utils/searchAddress'

interface Props {
  address: SearchAddressListItem
  onClick: (address: SearchAddressListItem) => void
}

const AddressListItem = ({ address, onClick }: Props) => {
  return (
    <StyledAddressListItem
      onClick={() => {
        onClick(address)
      }}>
      {address.roadAddress && (
        <>
          <div className="zone_no">{address.zipNumber}</div>
          <div className="address">
            <div>도로명</div>
            {address.roadAddress}
          </div>
        </>
      )}
      {address.jibunAddress && (
        <div className="address">
          <div>지 번</div>
          {address.jibunAddress}
        </div>
      )}
    </StyledAddressListItem>
  )
}

const StyledAddressListItem = styled.div`
  border-bottom: solid 1px ${vars.$semantic.color.divider1};
  padding: 0.5rem 1rem 0.75rem;
  :first-of-type {
    margin: -0.5rem 0 0;
  }

  .zone_no {
    ${vars.$semantic.typography.bodyL2Regular}
    color: ${vars.$scale.color.blue800};
  }
  .address {
    display: flex;
    margin: 0.5rem 0 0;
    align-items: center;
    ${vars.$semantic.typography.bodyL2Regular}
    div {
      ${vars.$semantic.typography.label6Bold}
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.5rem 0 0;
      border-radius: 2px;
      width: 35px;
      height: 1rem;
      color: ${vars.$scale.color.gray700};
      background-color: ${vars.$scale.color.gray100};
    }
  }
`

export default AddressListItem
