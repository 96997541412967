/**
 * 특정 iOS 기기 앱버전 6.6.8에서 canvas.toBlob(callback)의 callback이 실행되지 않는 이슈가 있음.
 * 그래서 blueimp-canvas-to-blob의 toBlob 폴리필을 모듈화해서 iOS 앱버전 6.6.8에서는 이걸 사용하도록 처리함
 * blueimp-canvas-to-blob
 *   - npm: https://www.npmjs.com/package/blueimp-canvas-to-blob
 *   - github: https://github.com/blueimp/JavaScript-Canvas-to-Blob/blob/master/js/canvas-to-blob.js
 *
 * 관련 슬랙
 * https://daangn.slack.com/archives/C014DJPSGC8/p1647851611831649
 * https://daangn.slack.com/archives/C014DJPSGC8/p1647409809459189
 */
const createCanvasToBlob = function () {
  const CanvasPrototype = window.HTMLCanvasElement && window.HTMLCanvasElement.prototype
  const hasBlobConstructor =
    window.Blob &&
    (function () {
      try {
        return Boolean(new Blob())
      } catch (e) {
        return false
      }
    })()
  const hasArrayBufferViewSupport =
    hasBlobConstructor &&
    window.Uint8Array &&
    (function () {
      try {
        return new Blob([new Uint8Array(100)]).size === 100
      } catch (e) {
        return false
      }
    })()
  const BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder
  const dataURIPattern = /^data:((.*?)(;charset=.*?)?)(;base64)?,/
  const dataURLtoBlob =
    (hasBlobConstructor || BlobBuilder) &&
    window.atob &&
    window.ArrayBuffer &&
    window.Uint8Array &&
    function (dataURI) {
      let matches, mediaType, isBase64, dataString, byteString, arrayBuffer, intArray, i, bb
      // Parse the dataURI components as per RFC 2397
      matches = dataURI.match(dataURIPattern)
      if (!matches) {
        throw new Error('invalid data URI')
      }
      // Default to text/plain;charset=US-ASCII
      mediaType = matches[2] ? matches[1] : 'text/plain' + (matches[3] || ';charset=US-ASCII')
      isBase64 = !!matches[4]
      dataString = dataURI.slice(matches[0].length)
      if (isBase64) {
        // Convert base64 to raw binary data held in a string:
        byteString = atob(dataString)
      } else {
        // Convert base64/URLEncoded data component to raw binary:
        byteString = decodeURIComponent(dataString)
      }
      // Write the bytes of the string to an ArrayBuffer:
      arrayBuffer = new ArrayBuffer(byteString.length)
      intArray = new Uint8Array(arrayBuffer)
      for (i = 0; i < byteString.length; i += 1) {
        intArray[i] = byteString.charCodeAt(i)
      }
      // Write the ArrayBuffer (or ArrayBufferView) to a blob:
      if (hasBlobConstructor) {
        return new Blob([hasArrayBufferViewSupport ? intArray : arrayBuffer], {
          type: mediaType,
        })
      }
      bb = new BlobBuilder()
      bb.append(arrayBuffer)
      return bb.getBlob(mediaType)
    }

  if (CanvasPrototype.mozGetAsFile) {
    return function (canvas, callback, type, quality) {
      setTimeout(function () {
        if (quality && CanvasPrototype.toDataURL && dataURLtoBlob) {
          callback(dataURLtoBlob(canvas.toDataURL(type, quality)))
        } else {
          callback(canvas.mozGetAsFile('blob', type))
        }
      })
    }
  } else if (CanvasPrototype.toDataURL && dataURLtoBlob) {
    if (CanvasPrototype.msToBlob) {
      return function (canvas, callback, type, quality) {
        setTimeout(function () {
          if (((type && type !== 'image/png') || quality) && CanvasPrototype.toDataURL && dataURLtoBlob) {
            callback(dataURLtoBlob(canvas.toDataURL(type, quality)))
          } else {
            callback(canvas.msToBlob(type))
          }
        })
      }
    } else {
      return function (canvas, callback, type, quality) {
        setTimeout(function () {
          callback(dataURLtoBlob(canvas.toDataURL(type, quality)))
        })
      }
    }
  }
}

export const canvasToBlob = createCanvasToBlob()
