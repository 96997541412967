import { Poi } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { FormikHelpers, useFormik } from 'formik'
import { MouseEvent, ReactNode, useCallback, useEffect, useMemo } from 'react'

import { Button } from '@src/react-utils/components/Button'
import { PageLoading } from '@src/react-utils/components/Loading'
import { Screen } from '@src/stackflow'

import { Form } from './Form'
import FormFooter from './FormFooter'
import { validationSchema } from './TextInput'
import type { Field, FieldType, FormValues, SuggestionRequestBody } from './types'
import { parseFromPoiToValues, parseFromValuesToRequestBody } from './utils/parse'
import { validate } from './utils/validate'

interface FormRendererProps {
  fields: Field<FieldType>[]
  poi?: Poi
  title?: string
  onSubmit: (values: SuggestionRequestBody, formikHelpers: FormikHelpers<FormValues>) => Promise<void>
  appendBottom?: () => ReactNode
  onClickAddressSearch?: () => void
  onClickPinAddress?: (e: MouseEvent<HTMLButtonElement>) => void
  initialValues?: Partial<FormValues>
}

export const FormPage = ({
  poi,
  fields,
  title,
  onSubmit,
  appendBottom,
  onClickAddressSearch,
  onClickPinAddress,
  initialValues: initialValuesProps = {} as FormValues,
}: FormRendererProps) => {
  const initialValuesFromPoi = useMemo(() => (poi ? parseFromPoiToValues(poi) : ({} as FormValues)), [poi])
  const initialValues = useMemo(
    () => ({ ...initialValuesFromPoi, ...initialValuesProps }),
    [initialValuesFromPoi, initialValuesProps]
  )

  const formik = useFormik<FormValues>({
    initialValues,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema,
    validate: validate.bind(null, fields),
    onSubmit: async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      // remove unchanged values
      const requestBody = parseFromValuesToRequestBody(values)

      await onSubmit(requestBody, formikHelpers)
    },
  })
  const {
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    submitCount,
    setFieldValue,
    setFieldTouched,
    setTouched,
    validateField,
    validateForm,
    handleChange,
    handleSubmit,
  } = formik
  const setAllFieldsTouched = useCallback(() => {
    const touched = fields.reduce((touched, { id }) => {
      return {
        ...touched,
        [id]: true,
      }
    }, {})
    setTouched(touched)
  }, [fields, setTouched])

  useEffect(() => {
    if (isSubmitting) {
      setAllFieldsTouched()
    }
  }, [isSubmitting, setAllFieldsTouched])

  return (
    <Screen
      appBar={{
        title: title,
        appendRight: () => (
          <SubmitButton
            type="submit"
            styleTheme="text"
            disabled={isSubmitting || !isValid || submitCount !== 0}
            onClick={() => {
              setAllFieldsTouched()
              handleSubmit()
            }}>
            완료
          </SubmitButton>
        ),
      }}>
      <Container isSubmitting={isSubmitting}>
        <Form
          fields={fields}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          validateField={validateField}
          validateForm={validateForm}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          onClickAddressSearch={onClickAddressSearch}
          onClickPinAddress={onClickPinAddress}
        />
        {appendBottom?.()}
        <FormFooter />
      </Container>
      {isSubmitting && <SubmittingOverlay />}
    </Screen>
  )
}

const Container = styled.div<{ isSubmitting: boolean }>`
  z-index: 1;
  position: relative;
  height: 100%;
  overflow-y: ${({ isSubmitting }) => (isSubmitting ? 'hidden' : 'scroll')};
`

const SubmitButton = styled(Button)<{ disabled: boolean }>`
  padding: 0.5rem;
  color: ${({ disabled }) => (disabled ? vars.$scale.color.gray400 : vars.$semantic.color.primary)};
  ${vars.$semantic.typography.bodyM1Regular};
`

const SubmittingOverlay = styled(PageLoading)`
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: ${vars.$semantic.color.overlayLow};
`
