import { Poi } from '@daangn/local-business-network/lib/poi'

import { usePoiInputSelectionState } from '@src/place-utils/poi/PoiInput/state'
import { useToast } from '@src/react-utils/components/Toast'
import { useNavigator } from '@src/stackflow'

import CreateSuggestion from './CreateSuggestion'

const CreateSuggestionForWriteReviewPage = () => {
  const [, setSelectedPoiInfo] = usePoiInputSelectionState()
  const { pop } = useNavigator()
  const { showToast } = useToast()

  const handleSubmitComplete = (poi: Poi | null) => {
    if (poi) {
      setSelectedPoiInfo({
        id: poi.id,
        name: poi.name,
      })
    } else {
      showToast({
        text: '일시적으로 생성하신 장소의 후기를 작성할수 없습니다.',
        duration: 'short',
      })
    }

    pop(2)
  }

  return <CreateSuggestion onSubmit={handleSubmitComplete} />
}

export default CreateSuggestionForWriteReviewPage
