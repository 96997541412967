import { captureException } from '@sentry/react'
import { StackflowReactPlugin } from '@stackflow/react'

import ErrorBoundary from '@src/react-utils/components/Error/ErrorBoundary'

export const errorBoundaryPlugin = (): StackflowReactPlugin => {
  return () => ({
    key: 'error-boundary-plugin',
    wrapActivity: ({ activity }) => {
      return <ErrorBoundary onError={(error) => captureException(error)}>{activity.render()}</ErrorBoundary>
    },
  })
}
