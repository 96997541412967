import { KarrotBridgeSchema } from '@daangn/karrotbridge/lib/__generated__/makeKarrotBridge'
import { atom } from 'recoil'

import { karrotBridge } from '@src/bridge'
import { IS_LOCAL } from '@src/constants/environmentConstants'
import { PROFILE_PLACEHOLDER } from '@src/constants/image'
import { tokenStorage } from '@src/js-utils/tokenStorage'

import { generateKey } from './generate-key'

export const userInfoAtom = atom<KarrotBridgeSchema['ReqInfoUserResponse']['info']['user']>({
  key: generateKey('user-info-atom'),
  default: (async () => {
    if (IS_LOCAL) {
      if (!import.meta.env.LOCAL_AUTH_TOKEN) throw new Error('LOCAL_AUTH_TOKEN does not exist in the environment')
      if (!import.meta.env.LOCAL_USER_ID) throw new Error('LOCAL_USER_ID does not exist in the environment')

      tokenStorage.setAuthToken(import.meta.env.LOCAL_AUTH_TOKEN)

      return {
        hashId: '',
        id: +import.meta.env.LOCAL_USER_ID,
        nickname: '로컬제이슨',
        phone: '01012345678',
        profileImage: PROFILE_PLACEHOLDER,
        authToken: import.meta.env.LOCAL_AUTH_TOKEN,
        displayRegionCheckinsCount: 25,
      }
    }

    const bridgeResponse = await karrotBridge.getUserInfo({})
    const userInfo = bridgeResponse.info.user

    tokenStorage.setAuthToken(userInfo.authToken)

    return userInfo
  })(),
})
