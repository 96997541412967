import { NativeReviewDtoVisitCount } from '@daangn/local-business-network/lib/poi'

export const visitCountToText = (count: NativeReviewDtoVisitCount) => {
  switch (count) {
    default:
    case 'VISIT_1':
      return '1번'
    case 'VISIT_2_TO_4':
      return '2번 이상'
    case 'VISIT_5_TO_9':
      return '5번 이상'
    case 'VISIT_10_TO_29':
      return '10번 이상'
    case 'VISIT_30_OR_MORE':
      return '30번 이상'
  }
}
