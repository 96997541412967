import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { CSSProperties } from 'react'

import { HeartButton as HeartButtonComponent, HeartButtonProps } from '@src/react-utils/components/Button'
import { withSuspense } from '@src/react-utils/components/Suspense'

import WatchButtonPlaceholder from './Placeholder'
import { useWatch } from '../hooks/useWatch'
import { WatchPlaceIdDto } from '../store'

interface WatchButtonProps {
  placeWatchId: WatchPlaceIdDto
  labels?: {
    default: string
    watched: string
  }
  onClick?: (isAddWatch: boolean) => void
  onAddWatch?: () => void
  onRemoveWatch?: () => void
  className?: string
  UNSAFE_heartButtonStyle?: CSSProperties
  UNSAFE_iconStyle?: Pick<HeartButtonProps, 'fillColor' | 'strokeColor' | 'size'>
}

export const WatchButton = withSuspense(
  ({
    placeWatchId,
    labels,
    onClick,
    onAddWatch,
    onRemoveWatch,
    UNSAFE_heartButtonStyle,
    UNSAFE_iconStyle,
    ...props
  }: WatchButtonProps) => {
    const { watched, handleClickWatch } = useWatch({
      placeWatchId,
      onClick,
      onAddWatch,
      onRemoveWatch,
    })

    return (
      <Wrapper className={props.className}>
        <HeartButton
          watched={watched}
          filled={watched}
          onClick={handleClickWatch}
          UNSAFE_heartButtonStyle={UNSAFE_heartButtonStyle}
          {...UNSAFE_iconStyle}
        />
        {labels && (watched ? labels.watched : labels.default)}
      </Wrapper>
    )
  },
  () => <WatchButtonPlaceholder />
)

const Wrapper = styled.div`
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${vars.$semantic.typography.label4Regular};
`
const HeartButton = styled(HeartButtonComponent)<{ watched: boolean; UNSAFE_heartButtonStyle?: CSSProperties }>([
  {
    margin: '0 0 0.125rem',
    padding: 0,
    width: '1.5rem',
    height: '1.5rem',
  },
  ({ UNSAFE_heartButtonStyle }) => UNSAFE_heartButtonStyle && { ...UNSAFE_heartButtonStyle },
])
