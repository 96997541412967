import { ActivityComponentType } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'

import { BizManagerSelfReviewAlert } from './BizManagerSelfReviewAlert'
import { BlockedBizReviewAlert } from './BlockedBizReviewAlert'
import { BlockedBizReviewUpdateDialog } from './BlockedBizReviewUpdateDialog'

type ModalType =
  | 'BizManagerSelfReviewAlert' // 비즈프로필 사장님은 본인 가게에 후기 작성 불가
  | 'BlockedBizReviewUpdateDialog' // 신고된 후기는 수정 해도 미노출됨
  | 'BlockedBizReviewAlert' // 해당 비즈프로필에 신고된 후기가 있는 경우 새로운 후기 작성 불가
interface SearchPoiModalActivityParams {
  modalId: ModalType
  poiId: string
}

export const SearchPoiModal: ActivityComponentType<SearchPoiModalActivityParams> = ({ params: { modalId, poiId } }) => {
  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))

  if (!poi) return null
  return (
    <>
      {(() => {
        switch (modalId) {
          default:
            return null
          case 'BizManagerSelfReviewAlert':
            return <BizManagerSelfReviewAlert />
          case 'BlockedBizReviewAlert':
            return <BlockedBizReviewAlert name={poi.name} />
          case 'BlockedBizReviewUpdateDialog':
            return (
              <BlockedBizReviewUpdateDialog id={poi.id} name={poi.name} bizAccountId={poi.bizAccountId ?? undefined} />
            )
        }
      })()}
    </>
  )
}
