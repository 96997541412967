import { Review } from '@daangn/business-review-ui'
import { PoiV2Dto, PostV2Dto } from '@daangn/local-business-network/lib/poi'
import { useActivityParams } from '@stackflow/react'
import { useEffect, useRef } from 'react'

import { karrotBridge } from '@src/bridge'
import { BIZ_PROFILE_APP_BASE_URL } from '@src/constants/url'
import { createQueryString } from '@src/js-utils/url'
import { ImageDetailSlider } from '@src/react-utils/components/Image'
import { useToast } from '@src/react-utils/components/Toast'
import { useImageSliderState } from '@src/react-utils/hooks/useImageSliderState'
import { useOnScreen } from '@src/react-utils/hooks/useOnScreen'
import { useActivity, useNavigator } from '@src/stackflow'

import { ActivityParams } from '.'
import { useAnalytics } from './hooks'
import { UpvoteButton } from './UpvoteButton'

interface Props {
  isMySubprofile: boolean
  poi: PoiV2Dto
  post: PostV2Dto
  index: number
}

export const SubprofileCard = ({ isMySubprofile, poi, post, index }: Props) => {
  const { handleCloseDetailImage, handleSelectDetailImage, selectedImageIdx } = useImageSliderState()
  const { push } = useNavigator()
  const logEvent = useAnalytics()
  const ref = useRef<HTMLDivElement>(document.createElement('div'))
  const onScreen = useOnScreen<HTMLDivElement>(ref, 1, true)
  const { showToast } = useToast()
  const { referrer } = useActivityParams<ActivityParams>()
  const { name: activityName } = useActivity()

  const handleClickMorePhotos = (index: number) => {
    handleSelectDetailImage(index)
  }

  const handleClickCard = (post: PostV2Dto, poi: PoiV2Dto, tab: 'post' | 'information') => {
    logEvent(tab === 'post' ? 'click_poi_content' : 'click_poi_title', {
      poi_id: poi.bizAccountId ?? poi.id,
      content_type: post.type?.toLocaleLowerCase(),
      type: poi.bizAccountId ? 'biz_account' : 'poi',
    })

    if (poi.destroyed) {
      showToast({
        text: '장소가 삭제되어 더 이상 확인할 수 없어요.',
        duration: 'long',
      })
      return
    }

    const bizAccountId = poi.bizAccountId
    if (bizAccountId) {
      const isStory = post.type === 'STORY_ARTICLE' || post.type === 'STORY_COMMENT'
      const isBizReview = post.type === 'BIZ_REVIEW'
      const queryString = createQueryString({
        referrer: referrer ?? `place.${activityName}`,
        entry: `place.${activityName}`,
        tab: isStory ? 'STORY' : 'REVIEW',
        highlightReviewId: isBizReview ? post.sourceId : null,
        highlightStoryId: isStory ? post.sourceId : null,
      })

      karrotBridge.pushRouter({
        router: {
          remote: `${BIZ_PROFILE_APP_BASE_URL}/biz_accounts/${bizAccountId}/viewer/home${queryString}`,
          navbar: false,
          scrollable: false,
        },
      })
    } else {
      const tapParam = tab === 'information' ? 'home' : post.type === 'POI_REVIEW' ? 'review' : 'story'

      push('poi_detail', { poiId: poi.id, tab: tapParam })
    }
  }

  useEffect(() => {
    if (onScreen) {
      logEvent('show_poi_card', {
        poi_type: poi.bizAccountId ? 'biz_account' : 'poi',
        poi_id: poi.bizAccountId ?? poi.id,
        position: index + 1,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen])

  return (
    <>
      <Review
        ref={ref}
        bridge={karrotBridge}
        status={{ type: 'default' }}
        viewer={isMySubprofile ? 'author' : 'others'}
        profile={{
          type: 'business',
          name: poi.name,
          tags: [poi.category?.name ?? '', poi.region?.name ?? ''].filter(Boolean),
          onClick: () => {
            handleClickCard(post, poi, 'information')
          },
        }}
        content={{
          createdAt: new Date(post.createdAt),
          text: post.content,
          images: post.images.map((img, idx) => ({ src: img.url, alt: `후기 사진 ${idx}` })),
          tags: post.tags.map((tag) => ({ label: tag, type: 'basic' })),
          onClickImage: (_, { index }) => {
            handleClickMorePhotos(index)
          },
        }}
        interactions={[
          {
            type: 'thumbs-up',
            render: () => (
              <UpvoteButton
                resourceType={post.type}
                resourceId={post.sourceId}
                type={'UPVOTE'}
                bizId={poi.bizAccountId}
              />
            ),
          },
        ]}
      />

      <ImageDetailSlider
        onClose={handleCloseDetailImage}
        initialIndex={selectedImageIdx}
        images={post.images.map(({ url }) => url)}
      />
    </>
  )
}
