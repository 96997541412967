import { Poi } from '@daangn/local-business-network/lib/poi'
import { isDefaultLoadable } from '@daangn/recoil-loadable'
import { AlertDialog, DialogContainer } from '@daangn/sprout-components-dialog'
import { useActivityParams } from '@stackflow/react'
import { useState } from 'react'
import { useSetRecoilState } from 'recoil'

import { useNavigator, useStepNavigator } from '@src/stackflow'

import CreateSuggestion from './CreateSuggestion'
import { DialogType } from './index'
import { usePoisStore } from '../WinterSnack/hooks'
import { latestSearchResultAtom } from '../WinterSnack/state'

const CreateSuggestionForWinterSnack = () => {
  const params = useActivityParams<{ alert?: DialogType }>()

  const { pop } = useNavigator()
  const { stepPush } = useStepNavigator('legacy_create_poi_suggestion')
  const { stepReplace } = useStepNavigator('winter_snack')

  const setLatestSearchResult = useSetRecoilState(latestSearchResultAtom)
  const [pois, setPois] = usePoisStore()

  const [submittedPoi, setSubmittedPoi] = useState<Poi>()

  const handleSubmitComplete = (poi: Poi | null) => {
    stepPush({ alert: 'winterSnackCompleteSubmit' })

    if (isDefaultLoadable(pois) || !pois || !poi) return

    setSubmittedPoi(poi)
    setPois([
      ...pois,
      {
        poi,
        distance: 0,
      },
    ])
    setLatestSearchResult([
      {
        poi,
        distance: 0,
      },
    ])
  }

  const handleClickConfirm = () => {
    pop()

    if (submittedPoi) {
      stepReplace({
        selectedPoiId: submittedPoi.id,
        filterId: 'all',
        mode: 'view',
      })
    }
  }

  // eslint-disable-next-line no-warning-comments
  // @todo change Poi type generated from local-business-network
  return (
    <>
      <CreateSuggestion onSubmit={(poi) => handleSubmitComplete(poi as Poi)} />
      {params.alert === 'winterSnackCompleteSubmit' && (
        <DialogContainer>
          <AlertDialog
            description="소중한 제보 감사해요. 제보된 장소는 검토 후 겨울 간식 지도에 보여져요."
            primaryActionLabel="확인"
            onPrimaryAction={handleClickConfirm}
          />
        </DialogContainer>
      )}
    </>
  )
}

export default CreateSuggestionForWinterSnack
