import { atomFamily, selectorFamily } from 'recoil'

import { paginateReactionsV2 } from '@src/apis/__generated__'
import { userInfoAtom } from '@src/place-utils/user/store'

import { generateKey } from './generateKey'
import { ReactionKey } from './reactions'

export type ResourceQueryParams = ReactionKey & Pick<Parameters<typeof paginateReactionsV2>['0']['params'], 'sortBy'>
type ResourceQueryResponse = Awaited<ReturnType<typeof paginateReactionsV2>>['data']

export const reactionByResourceQuery = selectorFamily<ResourceQueryResponse, ResourceQueryParams>({
  key: generateKey('reaction-selector-family'),
  get:
    (params) =>
    async ({ get }) => {
      const pagination = get(reactionByResourcePaginationAtomFamily(params))

      if (!pagination) {
        return {
          hasNextPage: false,
          nativeUsers: [],
          totalCount: 0,
        }
      }

      const xAuthToken = get(userInfoAtom).authToken
      const { data } = await paginateReactionsV2({ params: { xAuthToken, ...pagination, ...params } })

      return data
    },
})

export const reactionByResourceHasNextPageAtomFamily = atomFamily<boolean, ResourceQueryParams>({
  key: generateKey('reaction-by-resource-has-next-page-atom-family'),
  default: false,
})
interface Pagination {
  page: number
  perPage: number
}
export const reactionByResourcePaginationAtomFamily = atomFamily<Pagination | null, ResourceQueryParams>({
  key: generateKey('reaction-by-resource-pagination-atom-family'),
  default: null,
})
export const reactionByResourceAtomFamily = atomFamily<ResourceQueryResponse | undefined, ResourceQueryParams>({
  key: generateKey('reaction-by-resource-atom-family'),
  default: undefined,
})
