import { useCallback } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { storyArticleDetailURLSelectorFamily } from '@src/place-utils/community/store'
import { ActionSheet } from '@src/react-utils/components/ActionSheet'
import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'
import { interestedSingleStoryAtom } from '../state'

export const SingleStoryActionSheet = () => {
  const logEvent = useAnalytics()
  const { pop, pushScheme } = useNavigator()

  const { postId, resourceType, resourceId, index } = useRecoilValue(interestedSingleStoryAtom)

  const handleClose = useCallback(() => {
    pop()
  }, [pop])

  const handleClickViewStoryArticle = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        logEvent('click_view_in_community_button', {
          area: 'community_card_more_options',
          position: index + 1,
          source_type: resourceType === 'article' ? 'post' : resourceType === 'comment' ? 'comment' : undefined,
          source_id: resourceId,
        })

        const { targetURI } = await snapshot.getPromise(storyArticleDetailURLSelectorFamily({ articleId: +postId! }))

        pushScheme(targetURI)
      },
    [index, logEvent, postId, pushScheme, resourceId, resourceType]
  )
  const selections = [
    {
      label: '동네생활에서 보기',
      onClick: () => {
        handleClose()
        handleClickViewStoryArticle()
      },
    },
  ]

  return (
    <ActionSheet
      onOutsideClick={handleClose}
      dismissButton={{
        label: '닫기',
        onClick: handleClose,
      }}
      actionButtons={selections}
    />
  )
}
