import styled from '@emotion/styled'

interface Props {
  size: number
}

export const Spacing = styled.div<Props>`
  height: ${({ size }) => size}px;
`

export const RowSpacing = styled.div<Props>`
  width: ${({ size }) => `${size}px`};
  height: 100%;
`
