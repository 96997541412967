import { useCallback, useEffect } from 'react'
import { useRecoilValueLoadable, useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { removeDuplicatesByKey, removeItemByProperty } from '@src/js-utils/array'
import { userInfoAtom } from '@src/place-utils/user/store'

import { reactionAtomFamily, ReactionState } from '../store'
import {
  reactionByResourceAtomFamily,
  reactionByResourceHasNextPageAtomFamily,
  reactionByResourcePaginationAtomFamily,
  reactionByResourceQuery,
  ResourceQueryParams,
} from '../store/reaction-by-resource'

export const useReactionByResource = (params: ResourceQueryParams) => {
  const { id: userId } = useRecoilValue(userInfoAtom)
  const loadable = useRecoilValueLoadable(reactionByResourceQuery(params))
  const [, setReactionStore] = useRecoilState(reactionByResourceAtomFamily(params))
  const [pagination, setPagination] = useRecoilState(reactionByResourcePaginationAtomFamily(params))
  const [hasNextPage, setHasNextPage] = useRecoilState(reactionByResourceHasNextPageAtomFamily(params))
  const setReaction = useSetRecoilState(
    reactionAtomFamily({ resourceId: params.resourceId, resourceType: params.resourceType, type: params.type })
  )
  const initialize = useCallback(
    ({ page, perPage }: { page?: number; perPage?: number }) => {
      setPagination({ page: page ?? 1, perPage: perPage ?? 10 })
    },
    [setPagination]
  )

  const fetchMore = useCallback(() => {
    setPagination((prev) => {
      if (!prev) {
        return null
      }

      if (!hasNextPage) {
        return prev
      }

      return {
        ...prev,
        page: prev.page + 1,
      }
    })
  }, [setPagination, hasNextPage])

  const useSyncEffect = () => {
    return useEffect(() => {
      if (loadable.state !== 'hasValue' || loadable.contents.totalCount === 0) return

      const { hasNextPage, nativeUsers } = loadable.contents

      setHasNextPage(hasNextPage)
      setReactionStore((prev) => {
        if (!prev) {
          return loadable.contents
        } else {
          return {
            ...prev,
            nativeUsers: removeDuplicatesByKey([...prev.nativeUsers, ...(nativeUsers ?? [])], 'userId'),
          }
        }
      })
      setReaction((prev) => {
        const newReactedUsers: ReactionState['reactedUsers'] = nativeUsers?.map(
          ({ userId, nickname, profileImageUrl, residenceInfo }) => ({
            id: userId,
            nickname,
            profileImageURL: profileImageUrl,
            residenceInfo: {
              region: {
                id: residenceInfo.regionId,
                name: residenceInfo.regionName,
              },
              startYearOfResidence: residenceInfo.startYearOfResidence,
            },
          })
        )

        const unique = removeDuplicatesByKey([...(prev.reactedUsers ?? []), ...(newReactedUsers ?? [])], 'id')
        const reactedUsers = !prev.isReactedByMe ? removeItemByProperty(unique, 'id', userId) : unique

        return {
          ...prev,
          reactedUsers,
        }
      })

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadable.state])
  }

  return {
    pagination,
    hasNextPage,
    setHasNextPage,
    initialize,
    fetchMore,
    useSyncEffect,
  }
}
