import { Poi } from '@daangn/local-business-network/lib/poi'
import { useScrollToTopEffect } from '@daangn/stackflow-plugin-scroll-to-top'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useRef } from 'react'
import { useRecoilValue } from 'recoil'

import { safeAreaPadding } from '@src/styles/safeArea'

import { PredictedKeywords } from './PredictedKeywords'
import { Recommendations } from './Recommendations'
import { Result } from './Result'
import { useKeywords } from '../hooks'
import { searchStatusAtom } from '../state/poi'

interface Props {
  onClickKeyword: (keyword: string) => void
  onSelectPoi: (poi: Poi) => void
}

export const List = ({ onClickKeyword, onSelectPoi }: Props) => {
  const { keywords } = useKeywords()
  const hasKeywords = keywords.length > 0
  const searchStatus = useRecoilValue(searchStatusAtom)

  const scrollRef = useRef<HTMLDivElement>(null)
  useScrollToTopEffect(() => {
    scrollRef?.current?.scroll({ top: 0, behavior: 'smooth' })
  }, [scrollRef])

  return (
    <Container ref={scrollRef}>
      {(() => {
        switch (searchStatus) {
          default:
          case 'initial':
            return (
              <>
                {hasKeywords && <PredictedKeywords keywords={keywords} onClickKeyword={onClickKeyword} />}
                <Recommendations onSelectPoi={onSelectPoi} />
              </>
            )
          case 'searching':
            return <Result onSelectPoi={onSelectPoi} />
          case 'searched':
            return <Result onSelectPoi={onSelectPoi} />
        }
      })()}
    </Container>
  )
}

const Container = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: scroll;
  background-color: ${vars.$semantic.color.paperDefault};
  ${safeAreaPadding('bottom', '4rem')};

  ::-webkit-scrollbar {
    display: none;
  }
`
