import { Outro } from '../Outro'
import { RestaurantRecommendation } from '../RestaurantRecommendation'
import { RestaurantReview } from '../RestaurantReview'
import { StartYearOfResidence } from '../StartYearOfResidence'
import { StepFormData } from '../types/form'
import { VisitCount } from '../VisitCount'

export const newForm: StepFormData = {
  '1': {
    Component: <StartYearOfResidence />,
  },
  '2': {
    Component: <RestaurantRecommendation />,
  },
  '3': {
    Component: <RestaurantReview />,
  },
  '4': {
    Component: <VisitCount />,
  },
  '5': {
    Component: <Outro />,
  },
}

export const revisitForm: StepFormData = {
  '1': {
    Component: <RestaurantRecommendation />,
  },
  '2': {
    Component: <RestaurantReview />,
  },
  '3': {
    Component: <VisitCount />,
  },
  '4': {
    Component: <Outro />,
  },
}
