import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'

export const FILTER_ITEMS_HEIGHT = 56

interface StyleProps {
  isSelected: boolean
}

export const FilterButton = styled.button<StyleProps>`
  border: none;
  background-color: ${({ isSelected }) => (isSelected ? vars.$scale.color.gray700 : vars.$semantic.color.paperDefault)};

  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  height: 32px;
  border-radius: 18px;
  border: 1px solid ${({ isSelected }) => (isSelected ? vars.$scale.color.gray700 : vars.$semantic.color.divider2)};
  padding: 6px 12px;

  ${({ isSelected }) =>
    isSelected ? vars.$semantic.typography.caption1Bold : vars.$semantic.typography.caption1Regular}
  color: ${({ isSelected }) => (isSelected ? vars.$scale.color.gray00 : vars.$scale.color.gray900)}
`
