import { useCallback, useEffect, useMemo, useState } from 'react'

import { useActivity, useStepNavigator } from '@src/stackflow'
import { ActivitiesType } from '@src/stackflow/activities'

export const useImageSliderState = () => {
  const { name, params } = useActivity()
  const { stepPush, stepPop } = useStepNavigator(name as keyof ActivitiesType)

  const [selectedImageIdx, setSelectedImageIdx] = useState<number | null>(null)

  const handleSelectDetailImage = useCallback(
    (idx: number) => {
      stepPush({ ...params })
      setSelectedImageIdx(idx)
    },
    [params, stepPush]
  )

  const handleCloseDetailImage = useCallback(() => {
    stepPop()
    setSelectedImageIdx(null)
  }, [stepPop])

  useEffect(() => {
    if (selectedImageIdx === null) {
      return
    }
    const onPopState = () => {
      selectedImageIdx !== null && handleCloseDetailImage()
    }
    window.addEventListener('popstate', onPopState)
    return () => window.removeEventListener('popstate', onPopState)
  }, [handleCloseDetailImage, selectedImageIdx])

  return useMemo(
    () => ({ selectedImageIdx, handleSelectDetailImage, handleCloseDetailImage }),
    [handleCloseDetailImage, handleSelectDetailImage, selectedImageIdx]
  )
}
