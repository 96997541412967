import { UserInfoHeader } from '@daangn/local-business-kit'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { ActivityComponentType } from '@stackflow/react'
import { useEffect, useMemo } from 'react'
import { SwipeEventData, useSwipeable } from 'react-swipeable'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { PROFILE_PLACEHOLDER } from '@src/constants/image'
import { APP_SCHEME } from '@src/constants/url'
import { userInfoAtom } from '@src/place-utils/user/store'
import { useRegisterScreenCommonPayloadEffect } from '@src/react-utils/components/Analytics'
import { InfiniteScrollObserver } from '@src/react-utils/components/InfiniteScroll'
import { useScreenInstance } from '@src/react-utils/hooks/useScreenInstance'
import { Screen, useStepNavigator } from '@src/stackflow'

import { SubprofileCard } from './Card'
import { EmptyPage } from './EmptyPage'
import { useAnalytics, useCleanupReactions, useSyncMyReactionMapper, useSyncReactions } from './hooks'
import { useSubprofile } from './hooks/useSubprofile'
import { useSyncUserPosts } from './hooks/useSyncUserPosts'
import { useUserPosts } from './hooks/useUserPosts'
import { subprofileUserIdAtomFamily } from './state/others'

export type ActivityParams = {
  userId: string
  referrer?: string
}

const _SubprofilePage: ActivityComponentType<ActivityParams> = ({ params: { userId } }) => {
  const subprofile = useSubprofile()
  const { posts, meta, fetchMore } = useUserPosts()
  const logEvent = useAnalytics()
  const { ref } = useSwipeable({
    onSwiped: (eventData: SwipeEventData) => {
      logEvent('handle_scroll_list', {
        direction: eventData.dir === 'Down' ? 'up' : 'down',
        scrolled_pixels: eventData.deltaY,
      })
    },
  })
  const screenInstance = useScreenInstance()

  const setSubprofileUserId = useSetRecoilState(subprofileUserIdAtomFamily(screenInstance))
  const userInfo = useRecoilValue(userInfoAtom)
  const isMySubprofile = userInfo?.id?.toString() === userId

  const bizReviewCount = subprofile?.bizReviewTotalCount ?? 0
  const poiReviewCount = subprofile?.poiReviewTotalCount ?? 0
  const reviewCount = bizReviewCount + poiReviewCount

  useSyncUserPosts()
  useSyncMyReactionMapper()
  useSyncReactions()
  useCleanupReactions()

  const handleClickUserProfile = () => {
    if (!subprofile) return

    logEvent('click_user_profile', {
      viewer_id: userInfo?.id,
      subprofile_user_id: subprofile.user.id,
    })

    if (isMySubprofile) {
      window.location.href = `${APP_SCHEME}my_profile`
    } else {
      window.location.href = `${APP_SCHEME}users/${subprofile.user.id}`
    }
  }

  useEffect(() => {
    if (!userId) return
    setSubprofileUserId(userId)
  }, [userId, setSubprofileUserId])

  useRegisterScreenCommonPayloadEffect(
    {
      viewer_id: userInfo?.id ?? null,
      subprofile_user_id: subprofile?.user.id ?? null,
    },
    []
  )

  return subprofile ? (
    <Screen appBar={{ title: '동네 가게 후기' }}>
      {reviewCount > 0 ? (
        <>
          {!isMySubprofile && (
            <UserContainer onClick={handleClickUserProfile}>
              <UserInfoHeader
                name={subprofile.user.nickname}
                info={[
                  subprofile.user.region
                    ? `${subprofile.user.region.name} 인증 ${subprofile.user.displayRegionCheckinsCount ?? 0}회`
                    : '',
                ]}
                temperature={Math.round(subprofile.user.temperature! * 10) / 10}
                profileImage={subprofile.user.profileImageUrl ?? PROFILE_PLACEHOLDER}
              />
            </UserContainer>
          )}
          <Summary>
            {isMySubprofile ? '내가 ' : ''}남긴 후기{reviewCount > 0 && <Count> {reviewCount}</Count>}
          </Summary>
          <div ref={ref}>
            {posts
              .filter(({ post: { type } }) => type === 'BIZ_REVIEW' || type === 'POI_REVIEW')
              .map(({ pois, post }, index) => {
                return (
                  <Item key={post.id}>
                    <SubprofileCard poi={pois[0]} post={post} index={index} isMySubprofile={isMySubprofile} />
                  </Item>
                )
              })}
            {meta?.hasNextPage ? <InfiniteScrollObserver onIntersecting={fetchMore} /> : <BottomPlaceholder />}
          </div>
        </>
      ) : (
        <EmptyPage />
      )}
    </Screen>
  ) : null
}

export const SubprofilePage: ActivityComponentType<ActivityParams> = ({ params }) => {
  const userInfo = useRecoilValue(userInfoAtom)
  const userId = useMemo(
    () => (params.userId === 'me' ? userInfo.id + '' : params.userId),
    [params.userId, userInfo.id]
  )
  const { stepReplace } = useStepNavigator('subprofile_reviews')

  useEffect(() => {
    stepReplace({ userId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return <_SubprofilePage params={{ ...params, userId }} />
}

const UserContainer = styled.div`
  padding: 1rem 1rem 0;
`
const Summary = styled.div([
  vars.$semantic.typography.title3Bold,
  {
    padding: '1.5rem 1rem 0',
    color: vars.$scale.color.gray900,
  },
])
const Count = styled.span([
  vars.$semantic.typography.title3Bold,
  {
    color: vars.$semantic.color.primary,
  },
])

const BottomPlaceholder = styled.div`
  height: 4rem;
`

const Item = styled.div({
  position: 'relative',

  '& + &': {
    ':after': {
      content: '""',
      display: 'block',
      background: vars.$semantic.color.divider1,
      position: 'absolute',
      top: 0,
      left: '1rem',
      width: 'calc(100% - 2rem)',
      height: '1px',
    },
  },
})
