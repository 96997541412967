import { atom } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'

export const generateKey = generateUniqueKey(['searchPoi', 'query'])

export const queryAtom = atom({
  key: generateKey('query'),
  default: '',
})
