import { useActivityParams } from '@stackflow/react'

import { Loading } from '@src/react-utils/components/Loading'
import { withSuspense } from '@src/react-utils/components/Suspense'

import { useSyncStoryEffect } from '../hooks/useStory'
import { useSyncReactions } from '../state/reaction'

const FetchAndSyncPosts = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  useSyncReactions({ poiId })
  useSyncStoryEffect({ poiId })

  return null
}

export default withSuspense(FetchAndSyncPosts, Loading)
