import { addBreadcrumb, captureException } from '@sentry/react'
import exif, { ExifData } from 'blueimp-load-image'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { v4 } from 'uuid'

import { bigPictureApi } from '@src/apis'
import BigPictureAPI from '@src/apis/BigPicture'
import { BigPictureUploadResponse } from '@src/apis/types/bigPicture'
import { bridge } from '@src/bridge'
import { IS_IOS } from '@src/constants/environmentConstantsWithBridge'
import { uniqByArrayObject } from '@src/js-utils/array'
import { userInfoAtom } from '@src/place-utils/user/store'

import { FILE_RESIZE_OPTIONS, toBlobPromise, validateFileFormat } from './utils/image'

export interface ImageUploadPicture {
  url: string
  id: string
  imageId?: number
  loading: boolean
}

export type Image = Omit<ImageUploadPicture, 'loading'>
interface ImageUploadOptions {
  formOptions?: Parameters<BigPictureAPI['imageUpload']>[0]['formOptions']
  fileResizeOptions?: Parameters<exif>[1]
}

export const useImageUpload = (
  maxCount = 1,
  images?: Image[],
  onImageUploaded?: (pictures: BigPictureUploadResponse[]) => void,
  options: ImageUploadOptions = {}
) => {
  const userInfo = useRecoilValue(userInfoAtom)

  const [imageUploading, setImageUploading] = useState(false)
  const [pictures, setPictures] = useState<ImageUploadPicture[]>([])

  useEffect(() => {
    if (images && images.length) {
      setPictures((prevPictures) =>
        uniqByArrayObject(
          [
            ...images.map((image) => ({
              url: image.url,
              id: image.id,
              loading: false,
              imageId: image.imageId,
            })),
            ...prevPictures,
          ],
          'id'
        ).slice(0, maxCount)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeFileInput = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target || !e.target.files || !userInfo) {
        return
      }

      try {
        const { id: userId, authToken } = userInfo

        // validation condition variables
        const isMaxOnly = maxCount === 1
        const fileLength = e.target.files.length
        const availableFileLength = isMaxOnly ? 1 : maxCount - pictures.length

        // Validation
        if (maxCount > 1 && fileLength > availableFileLength) {
          bridge.toast.open({ body: `사진은 최대 ${maxCount}장까지만 올릴 수 있어요.` })
        }
        const allowFormatList = Array.from(e.target.files).filter(validateFileFormat)
        if (fileLength !== allowFormatList.length) {
          bridge.toast.open({ body: '지원되지 않는 포맷이에요.' })
        }
        if (allowFormatList.length) {
          setImageUploading(true)
        }

        // Section: exif load image
        // parse single file input
        const parseFileInput = async (file: File) => {
          const id = v4()

          try {
            const resizeExifData = await exif(file, options.fileResizeOptions || FILE_RESIZE_OPTIONS)
            const { image } = resizeExifData
            setPictures((prevPictures) =>
              isMaxOnly
                ? [{ url: image.toDataURL(), loading: true, id }]
                : [...prevPictures, { url: image.toDataURL(), loading: true, id }]
            )

            return { resizeExifData, file, id }
          } catch (e) {
            addBreadcrumb({ message: 'Exif failure' })

            captureException(e)

            return null
          }
        }
        const exifPromiseArray = allowFormatList.slice(0, availableFileLength).map(parseFileInput)
        const exifDataArray = await Promise.all(exifPromiseArray)

        // Section: image upload
        // upload single image
        const uploadImage = async (exifData: { resizeExifData: ExifData; file: File; id: string } | null) => {
          if (!exifData) return null

          const { resizeExifData, file, id } = exifData

          try {
            const blob = await toBlobPromise(resizeExifData.image, file.type, IS_IOS)

            if (!blob) return null

            const response = await bigPictureApi.imageUpload({
              authToken,
              userId,
              blob,
              file,
              image: resizeExifData.image,
              formOptions: options.formOptions,
            })

            const {
              data: { image },
            } = response

            const imageWithOldImageId = image as BigPictureUploadResponse & { old_image_id: number }

            setPictures((prevPictures) =>
              prevPictures.map((currentImage) =>
                currentImage.id === id
                  ? {
                      ...currentImage,
                      loading: false,
                      id: imageWithOldImageId.id,
                      imageId: imageWithOldImageId.old_image_id,
                      url: imageWithOldImageId.medium,
                    }
                  : currentImage
              )
            )

            return image
          } catch {
            bridge.toast.open({ body: '이미지 업로드에 실패했어요.' })
            setPictures((prevPictures) => prevPictures.filter((currentImage) => currentImage.id !== id))

            return null
          }
        }
        const imagePromiseArray = exifDataArray.map(uploadImage)
        const images = await Promise.all(imagePromiseArray)

        setImageUploading(false)
        e.target.value = ''

        onImageUploaded?.(images.filter((image) => image !== null) as BigPictureUploadResponse[])
      } catch (err) {
        addBreadcrumb({ message: 'Image upload failure' })

        captureException(err)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxCount, pictures, userInfo]
  )

  const handleDeletePicture = useCallback((id: string) => {
    setPictures((prevPictures) => prevPictures.filter((picture) => picture.id !== id))
  }, [])

  const handleAddPictures = useCallback(
    (images: Image[]) => {
      setPictures((prevPictures) =>
        uniqByArrayObject(
          [
            ...images.map((image) => ({
              url: image.url,
              id: image.id,
              loading: false,
              imageId: image.imageId,
            })),
            ...prevPictures,
          ],
          'id'
        ).slice(0, maxCount)
      )
    },
    [maxCount]
  )
  const reset = useCallback(() => setPictures([]), [])

  return useMemo(
    () => ({
      handleDeletePicture,
      handleAddPictures,
      pictures,
      imageUploading,
      onChangeFileInput,
      reset,
    }),
    [handleDeletePicture, handleAddPictures, pictures, imageUploading, onChangeFileInput, reset]
  )
}
