export const deleteSuggestionReasons = [
  {
    id: 'closed',
    text: '폐업',
  },
  {
    id: 'moved',
    text: '다른곳으로 이전',
  },
  {
    id: 'illegal',
    text: '불법/유해업체',
  },
  {
    id: 'duplicate',
    text: '중복된 장소',
  },
  {
    id: 'non-exist',
    text: '없는 장소',
  },
  {
    id: 'custom',
    text: '기타',
  },
]
