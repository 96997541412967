import {
  getPoiReviewsByPoiId,
  LegacyPoiReviewPaginationResponseLegacyPoiReviewResponse,
} from '@daangn/local-business-network/lib/legacyReview'
import { createLoadableFamily } from '@daangn/recoil-loadable'

import { generateUniqueKey } from '@src/js-utils/key'
import { CreateSerializableParam } from '@src/types/recoil'

const generateKey = generateUniqueKey('reviews')

type ReviewRequestType = Parameters<typeof getPoiReviewsByPoiId>[0]['params']
type ReviewResponseType = LegacyPoiReviewPaginationResponseLegacyPoiReviewResponse
interface ReviewPagination {
  page: number
  perPage: number
}

interface ReviewFetchVariables {
  poiId: ReviewRequestType['poiId']
  userId: ReviewRequestType['userId']
  sort: ReviewRequestType['sort']
}

export const reviewsByPoiIdAndUserIdLoadableFamily = createLoadableFamily<
  (ReviewPagination & ReviewResponseType) | null,
  CreateSerializableParam<ReviewFetchVariables>,
  ReviewResponseType | null
>({
  getStore: ({ atomFamily }) => atomFamily({ key: generateKey('reviews-by-poi-id-and-user-id-loadable-family') }),
  fetch: async ({ behavior, prevState, variables }) => {
    const page = behavior === 'initial' ? 1 : 'loadMore' && prevState?.hasNextPage ? prevState.page + 1 : 1
    const perPage = prevState?.perPage ?? 10

    if (!variables.poiId) return null

    const { data } = await getPoiReviewsByPoiId({
      params: {
        ...variables,
        page,
        perPage,
      },
    })

    return data
  },
  onSuccess: ({ response, prevState, behavior }) => {
    if (!response) return prevState

    return {
      items:
        behavior === 'loadMore' && prevState?.items
          ? [...new Set([...(prevState?.items ?? []), ...response.items])]
          : response.items,
      hasNextPage: response.hasNextPage,
      page: behavior === 'loadMore' && prevState?.hasNextPage ? prevState.page + 1 : prevState?.page ?? 1,
      perPage: prevState?.perPage ?? 10,
      total: response.total ?? 0,
    }
  },
})
