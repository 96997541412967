export const isDateWithinAPeriod = ({
  startDate,
  endDate,
  targetDate,
}: {
  startDate: string
  endDate: string
  targetDate?: string
}) => {
  const target = targetDate ? new Date(targetDate).getTime() : new Date().getTime()

  const start = new Date(startDate).getTime()
  const end = new Date(endDate).getTime()

  return target >= start && target <= end
}
