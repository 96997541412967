import { atomFamily, selectorFamily } from 'recoil'

import { getProfileV2 } from '@src/apis/__generated__/poi'
import { generateUniqueKey } from '@src/js-utils/key'

const subprofileKey = generateUniqueKey('subprofile')

export const subprofileQueryByUserId = selectorFamily({
  key: subprofileKey('queryByUserId'),
  get: (userId: string) => async () => {
    if (!userId) return null

    const {
      data: { data: profile },
    } = await getProfileV2({
      params: {
        userId,
      },
    })

    return profile
  },
})

export const subprofileUserIdAtomFamily = atomFamily({
  key: subprofileKey('userId'),
  default: '',
})
