import { ThumbsUp } from '@daangn/business-review-ui'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { bizReviewLegacyApi } from '@src/apis'
import { createReactionV2, deleteReactionV2 } from '@src/apis/__generated__'
import { convertResourceTypeToSourceType } from '@src/place-utils/reaction/utils/convertResourceTypeToSourceType'
import { userInfoAtom } from '@src/place-utils/user/store'

import { useAnalytics } from './hooks'
import { Reaction, reactionItemSelector } from './state/reaction'

interface Props {
  resourceType: Reaction['resourceType']
  resourceId: Reaction['resourceId']
  type: Reaction['type']
  bizId?: string | null
}

export const UpvoteButton = ({ resourceType, resourceId, type, bizId }: Props) => {
  const logEvent = useAnalytics()

  const userInfo = useRecoilValue(userInfoAtom)
  const [reaction, setReaction] = useRecoilState(
    reactionItemSelector({ resourceType, resourceId, type, userId: userInfo.id.toString() })
  )

  const isUpvotedByMe = reaction?.isReactedByMe ?? false
  const upvoteCount = reaction?.reactionCount ?? 0

  const isUpvotedRef = useRef<boolean>(isUpvotedByMe)

  const [upvoteStatus, setUpvoteStatus] = useState<{
    count: number
    isUpvoted: boolean
  }>({
    count: upvoteCount <= 0 ? 0 : upvoteCount,
    isUpvoted: isUpvotedByMe,
  })

  const handleUpvoteFetching = useCallback(
    async (isUpvoted: boolean) => {
      if (isUpvoted !== isUpvotedRef.current) return

      const upvote = async () => {
        isUpvotedRef.current = true

        if (resourceType === 'BIZ_REVIEW') {
          if (bizId) await bizReviewLegacyApi.like(bizId, resourceId)
        } else {
          await createReactionV2({
            params: {
              xAuthToken: userInfo!.authToken,
              createReactionBody: {
                resourceId,
                resourceType,
                type,
              },
            },
          })
        }

        setReaction((prev) => ({
          ...prev,
          resourceId,
          type,
          resourceType,
          isReactedByMe: !prev?.isReactedByMe ?? true,
          reactionCount: prev?.reactionCount ? prev?.reactionCount + 1 : 1,
        }))

        logEvent('click_upvote_button', {
          source_type: convertResourceTypeToSourceType(resourceType),
          source_id: resourceId,
        })
      }

      const cancelUpvote = async () => {
        isUpvotedRef.current = false

        if (resourceType === 'BIZ_REVIEW') {
          if (bizId) await bizReviewLegacyApi.unlike(bizId, resourceId)
        } else {
          await deleteReactionV2({
            params: {
              xAuthToken: userInfo!.authToken,
              deleteReactionBody: {
                resourceId,
                resourceType,
                type,
              },
            },
          })
        }

        setReaction((prev) => ({
          ...prev,
          resourceId,
          type,
          resourceType,
          isReactedByMe: !prev?.isReactedByMe ?? false,
          reactionCount: prev?.reactionCount ? prev?.reactionCount - 1 : 0,
        }))

        logEvent('click_cancel_upvote_button', {
          source_type: convertResourceTypeToSourceType(resourceType),
          source_id: resourceId,
        })
      }

      isUpvoted ? cancelUpvote() : upvote()
    },
    [bizId, logEvent, resourceId, resourceType, setReaction, type, userInfo]
  )

  const debouncedHandleUpvoteFetching = useMemo(() => debounce(handleUpvoteFetching, 300), [handleUpvoteFetching])

  const handleUpvote = useCallback(
    async (isUpvoted: boolean) => {
      const upvote = () => {
        setUpvoteStatus((prevState) => ({
          count: prevState.count + 1,
          isUpvoted: true,
        }))
      }

      const cancelUpvote = () => {
        setUpvoteStatus((prevState) => ({
          count: prevState.count - 1,
          isUpvoted: false,
        }))
      }

      isUpvoted ? cancelUpvote() : upvote()

      debouncedHandleUpvoteFetching(isUpvoted)
    },
    [debouncedHandleUpvoteFetching]
  )

  useEffect(() => {
    isUpvotedRef.current = isUpvotedByMe

    setUpvoteStatus({
      count: upvoteCount <= 0 ? 0 : upvoteCount,
      isUpvoted: isUpvotedByMe,
    })
  }, [isUpvotedByMe, upvoteCount])

  return (
    <ThumbsUp
      isSelected={upvoteStatus.isUpvoted}
      onClick={() => handleUpvote(upvoteStatus.isUpvoted)}
      count={upvoteStatus.count}
    />
  )
}
