import { createPoiReview } from '@daangn/local-business-network/lib/legacyReview'
import { useRecoilValue } from 'recoil'

import { userInfoAtom } from '@src/place-utils/user/store'
import { useToast } from '@src/react-utils/components/Toast'
import { Screen, useNavigator } from '@src/stackflow'

import { SubmitButton, SubmitParams } from './Submit'
import WritePoiReview from './WritePoiReview'

const CreatePoiReviewWithFixedPoiPage = () => {
  const { showToast } = useToast()
  const { pop } = useNavigator()

  const userInfo = useRecoilValue(userInfoAtom)

  const handleSubmit = async (params: SubmitParams) => {
    try {
      await createPoiReview({
        params: {
          legacyCreatePoiReviewRequest: {
            ...params,
            tagIds: [],
          },
        },
      })

      showToast({
        text: `소중한 후기 감사해요. ${userInfo.nickname}님 덕분에 동네 정보가 풍부해졌어요.`,
        duration: 'long',
      })

      pop()
    } catch (error) {
      showToast({
        text: '후기 등록에 실패했어요. 다시 한번 시도해주세요.',
        duration: 'long',
      })

      throw error
    }
  }

  return (
    <Screen
      appBar={{
        title: '후기 쓰기',
        appendRight: () => {
          return <SubmitButton onSubmit={handleSubmit}>완료</SubmitButton>
        },
      }}>
      <WritePoiReview />
    </Screen>
  )
}

export default CreatePoiReviewWithFixedPoiPage
