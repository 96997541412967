import { useCallback } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { userInfoAtom } from '@src/place-utils/user/store'
import { errorMessage } from '@src/react-utils/components/Error/constants'
import { useToast } from '@src/react-utils/components/Toast'

import { watchedPlacesByUserMutationAtomFamily, WatchPlaceIdDto } from '../store'
import { addWatchApi, removeWatchApi } from '../utils/watchApis'

export type WatchStatus = 'loading' | 'error' | 'exists' | 'no-watches'

export function useInternalWatchMutation(watchPlaceId: WatchPlaceIdDto) {
  const { showToast } = useToast()
  const userInfo = useRecoilValue(userInfoAtom)

  const AUTH_TOKEN_ERROR = 'AUTH_TOKEN_ERROR'

  const handleWatchError = useCallback(
    async (successCB: () => Promise<void>, failureCB: () => void) => {
      try {
        if (!userInfo?.authToken) throw new Error(AUTH_TOKEN_ERROR)

        await successCB()
      } catch (err) {
        failureCB()
        if (err instanceof Error) {
          if (err.message === AUTH_TOKEN_ERROR) {
            return showToast({
              text: errorMessage.AUTHENTICATION_MESSAGE,
              duration: 'long',
            })
          }
        }

        showToast({
          text: errorMessage.TEMPORARY_NOT_WORKING_MESSAGE,
          duration: 'long',
        })
      }
    },
    [showToast, userInfo?.authToken]
  )

  const addWatch = useRecoilCallback(
    ({ set }) =>
      async () => {
        const handleAddWatch = async () => {
          await addWatchApi(watchPlaceId, userInfo.authToken)
          set(watchedPlacesByUserMutationAtomFamily(watchPlaceId), { isWatched: true })
        }

        const handleAddWatchFailure = () => {
          set(watchedPlacesByUserMutationAtomFamily(watchPlaceId), { isWatched: false })
        }

        await handleWatchError(handleAddWatch, handleAddWatchFailure)
      },
    [handleWatchError, userInfo.authToken, watchPlaceId]
  )

  const removeWatch = useRecoilCallback(
    ({ set }) =>
      async () => {
        const handleRemoveWatch = async () => {
          await removeWatchApi(watchPlaceId, userInfo.authToken)
          set(watchedPlacesByUserMutationAtomFamily(watchPlaceId), { isWatched: false })
        }

        const handleRemoveWatchFailure = () => {
          set(watchedPlacesByUserMutationAtomFamily(watchPlaceId), { isWatched: true })
        }

        await handleWatchError(handleRemoveWatch, handleRemoveWatchFailure)
      },
    [handleWatchError, userInfo.authToken, watchPlaceId]
  )

  return {
    addWatch,
    removeWatch,
  }
}
