import { CommunityPostCard, XCommunityPostCard } from '@daangn/local-business-kit'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { useCallback, useRef, useState } from 'react'
import { useRecoilCallback } from 'recoil'

import { storyArticleDetailURLSelectorFamily } from '@src/place-utils/community/store'
import { ImageDetailSlider } from '@src/react-utils/components/Image'
import { useToast } from '@src/react-utils/components/Toast'
import { useImageSliderState } from '@src/react-utils/hooks/useImageSliderState'
import useSpotlightListItem from '@src/react-utils/hooks/useSpotlightListItem'
import { useNavigator, useStepNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'
import { ActivityParams } from '../PoiDetailPage'
import UpvoteButton from '../UpvoteButton'

interface Props {
  data: XCommunityPostCard['data'] & { articleId?: number }
  index: number
  onClick3Dot: () => void
  onClickUserProfile: () => void
}
const StoryCard = ({ data, index, onClick3Dot, onClickUserProfile }: Props) => {
  const { id, type } = data
  const { pushScheme } = useNavigator()
  const logEvent = useAnalytics()
  const { showToast } = useToast()

  const cardItemRef = useRef<HTMLDivElement>(null)
  const [isHighlighted, setIsHighlighted] = useState(false)

  const { spotlightPostId, ...params } = useActivityParams<ActivityParams>()
  const { stepReplace } = useStepNavigator('poi_detail')

  const handleBeforeSpotlight = useCallback(() => {
    logEvent('show_highlight_story', {
      source_type: type,
      source_id: id,
    })

    stepReplace({
      ...params,
      spotlightPostId: undefined,
    })

    setIsHighlighted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAfterSpotlight = useCallback(() => {
    setIsHighlighted(false)
  }, [])

  useSpotlightListItem({
    itemElement: cardItemRef,
    shouldSpotlight: id === spotlightPostId,
    onBeforeSpotlight: handleBeforeSpotlight,
    onAfterSpotlight: handleAfterSpotlight,
  })

  const handleClickContent = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        logEvent('click_community_card', {
          area: 'community_tab',
          position: index + 1,
          source_type: type === 'article' ? 'post' : type === 'comment' ? 'comment' : undefined,
          source_id: id,
        })

        const postId = type === 'comment' ? data?.articleId : id

        // 본문이 삭제된 댓글의 경우, article이 null 로 내려오기 때문에 이동 불가해요.
        if (!postId) {
          showToast({
            text: '동네생활 게시글이 삭제되었어요.',
            duration: 'long',
          })
          return
        }

        const { targetURI } = await snapshot.getPromise(storyArticleDetailURLSelectorFamily({ articleId: +postId! }))

        pushScheme(targetURI)
      },
    [data?.articleId, id, index, logEvent, pushScheme, showToast, type]
  )

  const { handleCloseDetailImage, handleSelectDetailImage, selectedImageIdx } = useImageSliderState()
  const handleClickImage = (idx: number) => {
    handleSelectDetailImage(idx)
  }

  const resourceMap = {
    ['article']: 'STORY_ARTICLE',
    ['comment']: 'STORY_COMMENT',
  } as const

  return (
    <>
      <StoryArticleCard ref={cardItemRef} isHighlighted={isHighlighted}>
        <CommunityPostCard
          data={data}
          onClickContent={handleClickContent}
          onClickMoreOptions={onClick3Dot}
          onClickImage={(idx) => handleClickImage(idx)}
          onClickUserProfile={onClickUserProfile}
        />
        <div>
          <UpvoteButton resourceType={resourceMap[type]} resourceId={id} type={'UPVOTE'} />
        </div>
      </StoryArticleCard>

      <ImageDetailSlider
        onClose={handleCloseDetailImage}
        initialIndex={selectedImageIdx}
        images={data.images?.map((image) => image.url) ?? []}
      />
    </>
  )
}

const StoryArticleCard = styled.div<{ isHighlighted: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  background: ${({ isHighlighted }) =>
    isHighlighted ? vars.$semantic.color.paperAccent : vars.$semantic.color.paperDefault};
  transition: 300ms background;
`

export default StoryCard
