import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { ReactNode } from 'react'

interface BasicType {
  type: 'basic'
  text: string
}

interface ActionType {
  type: 'action'
  text: string
  action: ReactNode
}

type SectionHeaderProps = BasicType | ActionType

const SectionHeader = (props: SectionHeaderProps & { className?: string }) => {
  return (
    <Wrapper className={props.className}>
      <HeaderText>
        {props.text}
        {props.type === 'action' && <AppendRight>{props.action}</AppendRight>}
      </HeaderText>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const HeaderText = styled.h2`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 1.125rem;
  color: ${vars.$scale.color.gray900};
`

const AppendRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default SectionHeader
