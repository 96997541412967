import styled from '@emotion/styled'
import { useActivityParams } from '@stackflow/react'
import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { useAddMonitoringWatchPlaceIds } from '@src/place-utils/watch/hooks/useAddMonitoringWatchPlaceIds'
import { WatchPlaceIdDto } from '@src/place-utils/watch/store'
import { withKakaoMap } from '@src/react-utils/components/Map/KakaoMap'

import { ActivityParams } from '.'
import { CardCarousel } from './CardCarousel'
import Map from './Map'
import { allCommentsAtomFamily } from './state'

export const Contents = withKakaoMap(() => {
  const { storyArticleId } = useActivityParams<ActivityParams>()
  const addMonitoringWatchPlaceIds = useAddMonitoringWatchPlaceIds()
  const comments = useRecoilValue(allCommentsAtomFamily({ storyArticleId: +storyArticleId }))

  useEffect(() => {
    const placeWatchIds: WatchPlaceIdDto[] = comments.map(({ poi: { bizAccountId, id } }) => {
      if (bizAccountId) {
        return {
          targetId: bizAccountId,
          targetType: 'BA',
        }
      }
      return {
        targetId: id,
        targetType: 'POI',
      }
    })

    addMonitoringWatchPlaceIds(placeWatchIds)
  }, [addMonitoringWatchPlaceIds, comments])

  return (
    <Container>
      <ContentWrapper zIndex={1}>
        <Map />
      </ContentWrapper>
      <CardSection>
        <CardCarousel />
      </CardSection>
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const ContentWrapper = styled.div<{ zIndex: number }>`
  position: absolute;
  z-index: ${({ zIndex }) => zIndex};
  width: 100%;
  height: 100%;
`

const CardSection = styled.div`
  z-index: 2;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 16px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
