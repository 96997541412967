import { ImpressionRoot } from '@daangn/webview-impression'
import { useActivityParams } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { IS_LOCAL } from '@src/constants/environmentConstants'
import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'
import { showShortVideoSelector } from '@src/place-utils/shortVideo/showShortVideo'
import { useLogEvent } from '@src/react-utils/components/Analytics'

import { BizPostSection } from './BizPostSection'
import { CouponSection } from './CouponSection'
import { InfoSection } from './InfoSection'
import PriceSection from './PriceSection/PriceSection'
import { ReviewSection } from './ReviewSection/ReviewSection'
import ShortVideoSection from './ShortVideoSection'
import StorySection from './StorySection/StorySection'

const HomeTab = () => {
  const reqLogEvent = useLogEvent()

  const { poiId } = useActivityParams<{ poiId: string }>()
  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))
  const brandAccountId = poi?.brandBaId

  const showShortVideo = useRecoilValue(showShortVideoSelector)

  return (
    <ImpressionRoot
      initialized
      logger={({ name, params }) => {
        reqLogEvent({ name, params })
      }}
      impressionOptions={{
        threshold: 0.8,
        coolTime: 60000,
        exposureTime: 1000,
      }}
      development={IS_LOCAL}>
      {/* 정보 */}
      <InfoSection />
      {/* 브랜드 쿠폰 */}
      {!!brandAccountId && <CouponSection brandAccountId={brandAccountId} />}
      {/* 가격표 */}
      {poi?.pricingImages && poi.pricingImages.length > 0 && <PriceSection pricingImages={poi.pricingImages} />}
      {/* 후기 */}
      <ReviewSection />
      {/* 동네생활 */}
      <StorySection />
      {/* 숏폼 */}
      {showShortVideo && <ShortVideoSection />}
      {/* 브랜드 소식 */}
      {!!brandAccountId && <BizPostSection brandAccountId={brandAccountId} />}
    </ImpressionRoot>
  )
}

export default HomeTab
