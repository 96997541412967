import { ActionSheet } from '@src/react-utils/components/ActionSheet'
import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'

interface Props {
  reviewId?: number
}
export const NativeReviewActionSheet = ({ reviewId }: Props) => {
  const { pop, push, replace } = useNavigator()
  const logEvent = useAnalytics()

  const handleClickEdit = () => {
    if (!reviewId) return
    logEvent('click_edit', {})
    replace('native_review_edit', { reviewId: reviewId.toString() })
  }

  const handleClickDelete = () => {
    if (!reviewId) return
    logEvent('click_delete', {})
    push('native_review_modal', {
      modalId: 'list-confirm-delete-review',
      reviewId: reviewId?.toString(),
    })
  }

  return (
    <ActionSheet
      onOutsideClick={() => {
        pop()
      }}
      actionButtons={[
        {
          label: '수정',
          onClick: handleClickEdit,
        },
        {
          label: '삭제',
          onClick: handleClickDelete,
          type: 'destructive',
        },
      ]}
      dismissButton={{
        label: '닫기',
        onClick: () => {
          pop()
        },
      }}
    />
  )
}
