import Request from './Request'

export default class NearbyApi extends Request {
  getDebugUser = async ({ userId, regionId }: { userId: number; regionId: number }) => {
    const res = await this.req.post(
      '/graphql',
      {
        query: `
          query test($regionId: Int!) {
            viewer {
              isDebugUiVisible(regionId: $regionId)
              isDeveloper
            }
          }`,
        variables: {
          regionId,
        },
      },
      {
        headers: {
          ...this.getUserAuthHeader(),
          'X-User-Id': userId,
        },
      }
    )

    return res
  }
}
