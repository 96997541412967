import { formatDistanceInMeterToString } from './formatDistanceInMeterToString'
import { haversineDistance } from './haversineDistance'

export const getDistanceBetweenCoordinates = (lat1: number, lng1: number, lat2: number, lng2: number) => {
  const meterDistance = haversineDistance({ latitude: lat1, longitude: lng1 }, { latitude: lat2, longitude: lng2 })

  return {
    meterDistance,
    text: formatDistanceInMeterToString(meterDistance),
  }
}
