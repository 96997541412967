import { logEvent, getReferrer } from '@daangn/local-business-analytics'
import { karrotAnalyticsPlugin } from '@daangn/stackflow-plugin-karrot-analytics'
import { scrollToTopPlugin } from '@daangn/stackflow-plugin-scroll-to-top'
import { vars } from '@seed-design/design-token'
import { basicUIPlugin } from '@stackflow/plugin-basic-ui'
import { preloadPlugin } from '@stackflow/plugin-preload'
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic'
import { stackDepthChangePlugin } from '@stackflow/plugin-stack-depth-change'
import { stackflow } from '@stackflow/react'

import { bridge, karrotBridge } from '@src/bridge'
import { IS_PROD } from '@src/constants/environmentConstants'
import { localBusinessAnalyticsStackflowPlugin } from '@src/react-utils/components/Analytics'
import { referrerMapper } from '@src/react-utils/components/Analytics/utils'

import { activities } from './activities'
import { devToolsPlugin, errorBoundaryPlugin } from './plugins'
import { historySyncWithModalPlugin } from './plugins/historySyncWithModalPlugin'
import { TRANSITION_DURATION } from './styles'
import { THEME } from './styles/theme'

export const { useStepFlow, Stack } = stackflow({
  transitionDuration: TRANSITION_DURATION,
  activities: activities,
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      theme: THEME,
      backgroundColor: vars.$semantic.color.paperDefault,
      appBar: {
        closeButton: {
          onClick: () => bridge.router.close(),
        },
      },
    }),
    historySyncWithModalPlugin({
      useHash: true,
      routes: {
        poi_detail: '/home/:poiId',
        legacy_create_poi_suggestion: '/createSuggestion/form(/:variation)',
        update_poi_suggestion: '/updateSuggestion/:poiId',
        delete_poi_suggestion: '/deleteSuggestion/:poiId',
        pin_address: '/pinAddress',
        search_address: '/searchAddress(/:variation)',
        search_category: '/searchCategory(/:variation)',
        search_poi: ['/searchPoi(/:variation)', '/searchPOI(/:variation)'],
        search_poi_communityTag: '/tagPoi',
        poi_review: '/writeReview/:action(/:poiId)(/:reviewId)',

        subprofile: '/subprofile/:userId',
        subprofile_reviews: '/subprofile/:userId/reviews',

        winter_snack: '/winterSnack',
        story_article_poi_collection: '/storyArticlePoiCollection/:storyArticleId',

        // 토박이
        native_review_form: '/native-review/form',
        native_review_modal: '/native-review/modal/:modalId',
        native_review_list: '/native-review/list',
        native_review_edit: '/native-review/:reviewId/edit',

        /**
         * @deprecated place_watch page is deprecated.
         * Use `karrot://my_watches` instead.
         */
        place_watch: ['/watch', '/bookmark'],

        /**
         * @deprecated 당근지도 (End of service)
         */
        daangn_map: '/map',

        fallback: '/fallback',
      },
      fallbackActivity: () => 'fallback',
    }),
    preloadPlugin({
      loaders: {
        place_watch: () => {},
      },
    }),
    stackDepthChangePlugin({
      onInit: () => {
        karrotBridge.styleCurrentRouter({
          router: {
            navbar: false,
            scrollable: true,
            backSwipable: true,
          },
        })
      },
      onDepthChanged: ({ depth }) => {
        karrotBridge.styleCurrentRouter({
          router: {
            navbar: false,
            scrollable: true,
            backSwipable: depth === 0,
          },
        })
      },
    }),
    scrollToTopPlugin(),
    karrotAnalyticsPlugin({
      bridge: karrotBridge,
      serviceName: 'place',
    }),
    localBusinessAnalyticsStackflowPlugin({
      debug: !IS_PROD,
      logEvent: ({ name, params }) => {
        logEvent({ bridge: { type: 'KARROT_BRIDGE', bridge: karrotBridge } })({
          name,
          params: {
            ...params,
            referrer: referrerMapper(params['referrer'] as string),
            where: `place.${params['where']}`,
          },
        })
      },
      commonPayloads: {
        referrer: getReferrer(),
        environment: bridge.environment === 'Web' ? 'web' : 'inapp',
      },
    }),
    errorBoundaryPlugin(),
    devToolsPlugin(),
  ],
})
