import { ReactNode, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { useRegisterScreenCommonPayloadEffect } from '@src/react-utils/components/Analytics'

import { nativeReviewSelectorFamily } from '../../store'
import { getYearsOfResidence } from '../../utils'

export const EvenLogWrapper = ({ reviewId, children }: { reviewId?: number; children: ReactNode }) => {
  const { review, residenceInfo } = useRecoilValue(nativeReviewSelectorFamily(reviewId!))

  const logEventParams: {
    review_id: number | null
    residence_year: number | null
    poi_id: string | null
  } = useMemo(
    () => ({
      review_id: reviewId || null,
      residence_year: getYearsOfResidence(residenceInfo.startYearOfResidence) || null,
      poi_id: review.poi.id || null,
    }),
    [residenceInfo.startYearOfResidence, review.poi.id, reviewId]
  )

  useRegisterScreenCommonPayloadEffect(logEventParams, [reviewId])

  return <>{children}</>
}
