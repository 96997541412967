import styled from '@emotion/styled'
import IconNewtopicFill from '@karrotmarket/karrot-ui-icon/lib/react/IconNewtopicFill'
import { vars } from '@seed-design/design-token'

import defaultPoiImage from '@src/assets/images/poi_img_map.png'
import { formatDistanceInMeterToString, haversineDistance } from '@src/js-utils/coordinate'
import { WEEK_IN_MS } from '@src/js-utils/date'
import { useCurrentPosition } from '@src/place-utils/location/hooks'
import { WatchButton } from '@src/place-utils/watch/WatchButton'
import { EllipsisText } from '@src/react-utils/components/Map/EllipsisText'
import { Coordinates } from '@src/types/global'

interface Props {
  id: string
  name: string
  bizAccountId: string | null
  thumbnail?: string
  category?: string
  coordinates?: Coordinates
  createdAt?: string
  className?: string
  watch: {
    onAdd?: () => void
    onRemove?: () => void
  }
}

export const PoiBanner = ({
  id,
  bizAccountId,
  name,
  thumbnail,
  category,
  coordinates,
  createdAt,
  watch,
  ...props
}: Props) => {
  const { position, access } = useCurrentPosition()
  const distance = access === 'granted' && coordinates ? haversineDistance(position, coordinates) : undefined

  const showNewBadge = createdAt && new Date().valueOf() - new Date(createdAt).valueOf() <= WEEK_IN_MS

  return (
    <Card className={props.className}>
      <Image>
        <img className="thumbnail-image" alt="thumbnail-image" src={thumbnail || defaultPoiImage} />
      </Image>
      <Content>
        <Text>
          <Title className="title">
            {name}
            {showNewBadge && (
              <IconNewtopicFill
                className="newBadge"
                width="16px"
                height="16px"
                color={vars.$semantic.color.primary}
                fill={vars.$semantic.color.primary}
              />
            )}
          </Title>
          <Information className="info">
            {category}
            {category && !!distance && <span className="bull">&bull;</span>}
            {!!distance && formatDistanceInMeterToString(distance)}
          </Information>
        </Text>
        <StyledWatchButton
          placeWatchId={{
            targetId: bizAccountId ?? id,
            targetType: bizAccountId ? 'BA' : 'POI',
          }}
          onAddWatch={watch.onAdd}
          onRemoveWatch={watch.onRemove}
        />
      </Content>
    </Card>
  )
}

const Card = styled.div`
  position: relative;
  display: flex;
  align-items: left;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid ${vars.$scale.color.gray200};
`

const Image = styled.div`
  height: 62px;
  flex-basis: 62px;

  .thumbnail-image {
    object-fit: cover;
    width: 62px;
    height: 62px;
    min-width: 62px;
    min-height: 62px;
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 62px);
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: calc(100% - 54px);
  padding: 10px 0 10px 14px;

  .bull {
    font-weight: 100;
    color: ${vars.$scale.color.gray600};
    margin: 0 2px 2px;
  }
  .info {
    font-weight: 400;
    color: ${vars.$scale.color.gray600};
    font-size: 0.813rem;
    line-height: 20px;
    height: 20px;
  }
  .newBadge {
    margin: 0 0 0 2px;
  }
`

const Title = styled(EllipsisText)`
  ${vars.$semantic.typography.bodyM2Regular}
  margin: 0 4px 2px 0;
  width: 100%;
  height: 20px;
  svg {
    transform: translateY(4px);
  }
`

const Information = styled(EllipsisText)`
  min-width: 30px;
`

const StyledWatchButton = styled(WatchButton)`
  padding: 0 14px 0 0;
  color: ${vars.$scale.color.gray600};
  width: 54px;
  font-size: 0.75rem;
`
