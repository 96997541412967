import { IS_PROD } from './environmentConstants'

export const MAP_META_DATA = {
  [100]: {
    name: '맛집',
    theme: 'restaurant',
    id: 100,
  },
  [IS_PROD ? 2 : 133]: {
    name: '겨울간식',
    theme: 'winter_desert',
    id: IS_PROD ? 2 : 133,
  },
} as const

export const MAP_ID = {
  COMMUNITY_RESTAURANT: 100,
  WINTER_DESSERT: IS_PROD ? 2 : 133,
}

export const mapThemeToEventIdMap: Record<string, string> = {
  RESTAURANT: '100',
  WINTER_DESSERT: IS_PROD ? '2' : '133',
}
export const eventIdToMapThemeMap: Record<string, string> = {
  [`${mapThemeToEventIdMap.RESTAURANT}`]: 'RESTAURANT',
  [`${mapThemeToEventIdMap.WINTER_DESSERT}`]: 'WINTER_DESSERT',
}
export const eventIdToMapThemeKorMap: Record<string, string> = {
  [`${mapThemeToEventIdMap.RESTAURANT}`]: '맛집',
  [`${mapThemeToEventIdMap.WINTER_DESSERT}`]: '겨울간식',
}

export const 얼음땡id = IS_PROD ? 67 : 2
