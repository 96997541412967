import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { MouseEvent, ReactNode } from 'react'

interface Base {
  className?: string
  text: string
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  appendLeft?: ReactNode
}
interface NormalType extends Base {
  type: 'normal'
}

type ActionChipProps = NormalType

const ActionChip = ({ className, onClick, text, appendLeft }: ActionChipProps) => {
  return (
    <Chip className={className} onClick={onClick}>
      {appendLeft}
      {text}
    </Chip>
  )
}

const Chip = styled.button`
  min-width: fit-content;
  padding: 0.375rem 0.75rem;
  background-color: ${vars.$semantic.color.paperDefault};
  border: 1px solid ${vars.$semantic.color.divider2};
  border-radius: 1.125rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.caption1Regular};
`

export default ActionChip
