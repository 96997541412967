import { useFormik } from 'formik'
import { ChangeEvent, MouseEvent, useCallback } from 'react'

import { AddressInput as AddressInputBase } from '@src/react-utils/components/Input'
import { AddressPicker } from '@src/react-utils/components/Input/AddressInput/store/addressPicker'

import { Map } from './Map'
import { FormItemWrapper } from '../FormItemWrapper'
import type { Field } from '../types'

interface Props {
  field: Field<'address'>
  addressExtra: string
  setFieldValue: ReturnType<typeof useFormik>['setFieldValue']
  setFieldTouched: ReturnType<typeof useFormik>['setFieldTouched']
  onClickAddressSearch?: () => void
  onClickPinAddress?: (e: MouseEvent<HTMLButtonElement>) => void
  errorMessage?: string
}

export const AddressInput = ({
  field: { id },
  addressExtra,
  setFieldValue,
  setFieldTouched,
  onClickAddressSearch,
  onClickPinAddress,
  errorMessage,
}: Props) => {
  const handleChangeAddress = useCallback(
    (address: AddressPicker & { addressExtra?: string }) => {
      setFieldValue('address', address, true)
    },
    [setFieldValue]
  )

  const handleClickAddressSearch = useCallback(() => {
    onClickAddressSearch?.()

    setTimeout(() => {
      setFieldTouched('address')
    }, 300)
  }, [onClickAddressSearch, setFieldTouched])

  const handleClickPinAddress = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onClickPinAddress?.(e)

      setTimeout(() => {
        setFieldTouched('address')
      }, 300)
    },
    [onClickPinAddress, setFieldTouched]
  )

  const handleChangeAddressExtra = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue('address.addressExtra', e.target.value)
    },
    [setFieldValue]
  )

  return (
    <FormItemWrapper key={id} id={id} label="주소" errorMessage={errorMessage}>
      <Map />
      <AddressInputBase
        key={id}
        addressExtra={addressExtra ?? ''}
        onChangeAddress={handleChangeAddress}
        onClickAddressSearch={handleClickAddressSearch}
        onClickPinAddress={handleClickPinAddress}
        onChangeAddressExtra={handleChangeAddressExtra}
      />
    </FormItemWrapper>
  )
}
