import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { Loading } from '@src/react-utils/components/Loading'
import { withSuspense } from '@src/react-utils/components/Suspense'

import {
  categoriesBySearchTextPaginatedSelectorFamily,
  paginationAtomFamily,
  SearchCategoriesClientName,
  searchTextAtomFamily,
} from './state'

interface Props {
  clientName: SearchCategoriesClientName
}
const LoadMoreCategories = ({ clientName }: Props) => {
  const searchText = useRecoilValue(searchTextAtomFamily(clientName))

  const [pagination, setPagination] = useRecoilState(paginationAtomFamily({ clientName, searchText }))
  const { hasNextPage } = useRecoilValue(categoriesBySearchTextPaginatedSelectorFamily(clientName))

  const endOfPageRef = useRef<HTMLDivElement>(null)

  const handleLoadMoreCategories = () => {
    if (!hasNextPage) return

    setPagination({
      ...pagination,
      page: pagination.page + 1,
    })
  }

  useEffect(() => {
    // Fetch more reviews on scroll to bottom
    let observer: IntersectionObserver

    if (endOfPageRef.current) {
      observer = new IntersectionObserver(
        ([{ isIntersecting }]) => {
          isIntersecting && handleLoadMoreCategories()
        },
        { threshold: 0.3 }
      )
      observer.observe(endOfPageRef.current!)

      if (!hasNextPage) {
        observer && observer.disconnect()
      }
    }

    return () => observer && observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, pagination])

  return <ObservingDiv ref={endOfPageRef} />
}

const ObservingDiv = styled.div`
  position: relative;
  height: 30px;
  width: 100%;
`

export default withSuspense(LoadMoreCategories, Loading)
