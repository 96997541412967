import { ActivityComponentType } from '@stackflow/react'

import { Screen } from '@src/stackflow'

import ErrorPage from './Base'

export const FallbackScreen: ActivityComponentType = () => {
  return (
    <Screen>
      <ErrorPage />
    </Screen>
  )
}
