import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { BottomSheet, BottomSheetProps } from '@stackflow/plugin-basic-ui'
import { MouseEvent } from 'react'

import { safeAreaPadding } from '@src/styles/safeArea'

type ActionType = 'default' | 'destructive'
type ActionButtonType = {
  label: string
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  type?: ActionType
}
interface Props extends Omit<BottomSheetProps, 'children'> {
  dismissButton?: {
    label: string
    onClick: (e: MouseEvent<HTMLButtonElement>) => void
  }
  actionButtons?: ActionButtonType[]
}
export const ActionSheet = ({ dismissButton, actionButtons, onOutsideClick }: Props) => {
  return (
    <BottomSheet
      onOutsideClick={(e) => {
        e.preventDefault()
        onOutsideClick?.(e)
      }}>
      <Container>
        {actionButtons?.map((actionButton, idx) => (
          <ActionButton key={idx} actionType={actionButton.type ?? 'default'} onClick={actionButton.onClick}>
            {actionButton.label}
          </ActionButton>
        ))}
        {dismissButton && <DismissButton onClick={dismissButton.onClick}>{dismissButton.label}</DismissButton>}
      </Container>
    </BottomSheet>
  )
}

const Container = styled.div`
  padding: 0.625rem 0 0;
  ${safeAreaPadding('bottom')};
`
const ActionButton = styled.button<{ actionType: ActionType }>`
  padding: 1.125rem 0 0.625rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ actionType }) =>
    actionType === 'destructive' ? vars.$semantic.color.danger : vars.$scale.color.gray900};
  ${vars.$semantic.typography.label2Regular};
`
const DismissButton = styled.button`
  margin: 0.75rem 1rem 1rem;
  padding: 1rem 0;
  width: calc(100% - 2rem);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: ${vars.$semantic.color.secondaryLow};
  color: ${vars.$semantic.color.inkText};
  ${vars.$semantic.typography.label3Bold};
`
