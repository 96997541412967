import { getPoiReviewsByPoiId, getPoiReviewsByIds } from '@daangn/local-business-network/lib/legacyReview'
import { atom, atomFamily } from 'recoil'

import { generateUniqueKey } from '@src/js-utils/key'

import { detailPagePrefix } from '../constants/recoilKey'

type ReviewRequestType = Parameters<typeof getPoiReviewsByPoiId>[0]['params']
type ReviewResponseType = ReturnType<typeof getPoiReviewsByIds>
type ReviewRequestSortType = ReviewRequestType['sort']

type PageSerializeParam = { poiId: string; screenInstance: string; sortingOption: ReviewRequestSortType }

const generateKey = generateUniqueKey([detailPagePrefix, 'review'])

/**
 * Page scope
 */
const reviewSortingOptionAtomFamily = atomFamily<ReviewRequestSortType, Omit<PageSerializeParam, 'sortingOption'>>({
  key: generateKey('sorting-option'),
  default: 'HIGHEST_SCORE',
})

export type { ReviewResponseType, PageSerializeParam as ReviewPageSerializeParam }
export { reviewSortingOptionAtomFamily }

export const interestedSingleReviewAtom = atom<{
  reviewId: string
  reviewUserId: number
}>({
  key: generateKey('interestedSingleReview'),
  default: undefined,
})
