import { useScrollToTopEffect } from '@daangn/stackflow-plugin-scroll-to-top'
import styled from '@emotion/styled'
import { forwardRef, ReactNode, useImperativeHandle, useRef } from 'react'

interface Props {
  children: ReactNode
  onScroll: () => void
  height: string
  marginTop: string
}

export const ScrollContainer = forwardRef<HTMLDivElement, Props>(({ children, ...props }, forwardedRef) => {
  const ref = useRef<HTMLDivElement>(null)
  useImperativeHandle(forwardedRef, () => ref.current as HTMLDivElement)

  useScrollToTopEffect(() => {
    ref.current?.scroll({ top: 0, behavior: 'smooth' })
  }, [ref])

  return (
    <Container ref={ref} onScroll={props.onScroll} height={props.height} marginTop={props.marginTop}>
      {children}
    </Container>
  )
})

const Container = styled.div<{ height: string; marginTop: string }>`
  margin-top: ${({ marginTop }) => marginTop};
  width: 100%;
  height: ${({ height }) => height};

  overflow-y: auto;
  overflow-x: hidden;

  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  flex-direction: column;
  flex: 1;
`
