import { selector } from 'recoil'

import { nearbyApi } from '@src/apis'
import { IS_LOCAL } from '@src/constants/environmentConstants'
import { checkInRegionInfoAtom } from '@src/place-utils/region/store'
import { userInfoAtom } from '@src/place-utils/user/store'

type DebugUser = {
  isDeveloper: boolean
  isDebugUiVisible: boolean
}
export const debugUserAtom = selector<DebugUser>({
  key: 'debug-user',
  get: async ({ get }) => {
    if (IS_LOCAL)
      return {
        isDeveloper: true,
        isDebugUiVisible: true,
      }

    try {
      const userId = get(userInfoAtom).id
      const regionId = get(checkInRegionInfoAtom).id

      const { data } = await nearbyApi.getDebugUser({ userId, regionId })
      const { isDeveloper, isDebugUiVisible } = data.data.viewer

      return { isDeveloper, isDebugUiVisible }
    } catch {
      return {
        isDeveloper: false,
        isDebugUiVisible: false,
      }
    }
  },
})
