import { Poi } from '@daangn/local-business-network/lib/poi'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'

import { EmptyOrError } from '@src/react-utils/components'

import { PoiCard } from './PoiCard'
import { useAnalytics } from '../hooks'
import { recommendationsSelector } from '../state/recommendation'

interface Props {
  onSelectPoi: (poi: Poi) => void
}
export const Recommendations = ({ onSelectPoi }: Props) => {
  const logEvent = useAnalytics()

  const recommendations = useRecoilValue(recommendationsSelector)
  const refetch = useRecoilRefresher_UNSTABLE(recommendationsSelector)

  const handleClickPoiCard = (index: number) => (poi: Poi) => {
    logEvent('click_select_poi_button', {
      poi_id: poi.id,
      position: index + 1,
      page: Math.ceil((index + 1) / 20),
      area: 'recommendations',
    })
    onSelectPoi(poi)
  }

  const hasError = recommendations === 'error'
  if (hasError) {
    return (
      <EmptyOrError
        body="추천 장소를 불러오는데 실패했어요."
        CTAButton={{
          label: '다시 시도하기',
          onClick: refetch,
        }}
      />
    )
  }

  const isEmpty = !recommendations || recommendations.length === 0
  if (isEmpty) {
    return <EmptyOrError body="아직 우리 동네 추천 장소가 없어요." />
  }

  return (
    <>
      {recommendations.map((poi, index) => (
        <PoiCard key={poi.id} poi={poi} onClick={handleClickPoiCard(index)} />
      ))}
    </>
  )
}
