import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { useRecoilValue } from 'recoil'

import { InfiniteScrollObserver } from '@src/react-utils/components/InfiniteScroll'
import { useScreenInstance } from '@src/react-utils/hooks/useScreenInstance'

import EmptyStoryArticleTab from './EmptyStoryTab'
import Options from './Options'
import StoryPreviews from './StoryPreviews'
import { useStory } from '../hooks/useStory'
import {
  communityPostsDynamicMetaAtomFamily,
  communityPostsStaticMetaAtomFamily,
  communityPostsSortingOptionAtomFamily,
} from '../state/story'

const StoryTab = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const { fetchMore } = useStory({ poiId })
  const screenInstance = useScreenInstance()

  const sortingOption = useRecoilValue(communityPostsSortingOptionAtomFamily({ poiId, screenInstance }))
  const staticMeta = useRecoilValue(communityPostsStaticMetaAtomFamily({ poiId, screenInstance, sortingOption }))
  const dynamicMeta = useRecoilValue(communityPostsDynamicMetaAtomFamily({ poiId, screenInstance, sortingOption }))

  if (staticMeta.total <= 0) {
    return <EmptyStoryArticleTab />
  }

  return (
    <Container>
      <Options poiId={poiId} />
      <StoryPreviews />
      {dynamicMeta.hasNextPage && <InfiniteScrollObserver onIntersecting={fetchMore} />}
    </Container>
  )
}

const Container = styled.div({
  background: vars.$semantic.color.paperDefault,
})

export default StoryTab
