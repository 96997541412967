import { updatePoiReview } from '@daangn/local-business-network/lib/legacyReview'
import { useActivityParams } from '@stackflow/react'

import { useToast } from '@src/react-utils/components/Toast'
import { Screen, useNavigator } from '@src/stackflow'

import { ActivityParams } from '.'
import { SubmitButton, SubmitParams } from './Submit'
import WritePoiReview from './WritePoiReview'

const UpdatePoiReviewPage = () => {
  const { showToast } = useToast()
  const { pop } = useNavigator()
  const { reviewId } = useActivityParams<ActivityParams>()

  const handleSubmit = async (params: SubmitParams) => {
    if (!reviewId) return

    try {
      await updatePoiReview({
        params: {
          reviewId: parseInt(reviewId, 10),
          legacyUpdatePoiReviewRequest: {
            ...params,
            tagIds: [],
          },
        },
      })

      showToast({
        text: '후기를 수정했어요.',
        duration: 'short',
      })

      pop()
    } catch (error) {
      showToast({
        text: '후기 수정에 실패했어요. 다시 한번 시도해주세요.',
        duration: 'long',
      })

      throw error
    }
  }

  return (
    <Screen
      appBar={{
        title: '후기 수정',
        appendRight: () => {
          return <SubmitButton onSubmit={handleSubmit}>완료</SubmitButton>
        },
      }}>
      <WritePoiReview />
    </Screen>
  )
}

export default UpdatePoiReviewPage
