import { AnalyticsEvent, localBusinessAnalyticsPlugin } from '@daangn/local-business-analytics'

import { EXPERIMENT_BizProfileLogEventTypes } from './types/askCommunityExperimentTypes'

export const {
  useLogEvent,
  useUnregisterScreenCommonPayload,
  useRegisterScreenCommonPayloadEffect,
  useRegisterScreenCommonPayload,
  plugin: localBusinessAnalyticsStackflowPlugin, // stackflow plugin
} = localBusinessAnalyticsPlugin<
  | AnalyticsEvent<'click_submit_review_button'> // 리뷰 작성 완료 버튼 클릭 (POI리뷰, 토박이)
  | AnalyticsEvent<string, any>
  | EXPERIMENT_BizProfileLogEventTypes
>()
