import { karrotBridge } from '@src/bridge'
import { Coordinates } from '@src/types/global'

export const openKarrotMap = ({
  coordinates,
  name,
  address,
}: {
  coordinates: Coordinates
  name: string
  address: string
}) => {
  const params = new URLSearchParams({
    lat: coordinates.latitude.toString(),
    lng: coordinates.longitude.toString(),
    name,
    address,
  }).toString()

  const url = `https://map.kr.karrotmarket.com/marker?${params}`

  karrotBridge.pushRouter({
    router: {
      remote: url,
      navbar: true,
    },
  })
}
