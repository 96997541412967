import styled from '@emotion/styled'
import IconCallThin from '@karrotmarket/karrot-ui-icon/lib/react/IconCallThin'
import IconChevronRightFill from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightFill'
import IconClockThin from '@karrotmarket/karrot-ui-icon/lib/react/IconClockThin'
import IconGlobalThin from '@karrotmarket/karrot-ui-icon/lib/react/IconGlobalThin'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { displayBusinessOpenSchedule } from '@src/js-utils/date'
import { formatPhoneNumber } from '@src/js-utils/phone'
import { poiByIdSelectorFamily } from '@src/place-utils/poi/store'
import { SectionHeader as SectionHeaderComponent } from '@src/react-utils/components'
import { Section as SectionComponent } from '@src/react-utils/components/Layout'
import { Skeleton } from '@src/react-utils/components/Loading'
import { StaticMap as StaticMapComponent } from '@src/react-utils/components/Map/StaticMap'
import { useNavigator } from '@src/stackflow'
import { Spacing } from '@src/styles/Spacing'

import { Address } from './Address'
import { BrandInfo } from './BrandInfo'
import { UpdateSuggestion } from './UpdateSuggestion'
import { useAnalytics } from '../../hooks'

export const InfoSection = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const logEvent = useAnalytics()
  const { push } = useNavigator()

  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))
  const brandAccountId = poi?.brandBaId

  const businessHoursText = useMemo(() => {
    if (!poi) return ''

    if (poi.openingHours && poi.openingHours.length > 0) {
      return displayBusinessOpenSchedule(poi.openingHours)
    }

    if (poi.businessHoursFrom && poi.businessHoursTo) {
      return (
        (poi.businessHoursFrom && poi.businessHoursTo ? `${poi.businessHoursFrom} ~ ${poi.businessHoursTo} ` : '') +
        (poi.businessHoursExtra || '')
      )
    }

    return ''
  }, [poi])

  const handleClaimOwnership = () => {
    logEvent('click_claim_ownership_button')
    push('poi_detail_modal', { poiId, modalId: 'claimOwnershipDialog' })
  }

  const handleMapClick = () => {
    logEvent('click_profile_map')
  }

  return (
    <Section>
      <Wrapper>
        <SectionHeader
          type="action"
          text="정보"
          action={
            !brandAccountId && (
              <ClaimOwnershipButton onClick={handleClaimOwnership}>
                사장님이신가요? <IconChevronRightFill width={8} height={12} color={vars.$scale.color.gray900} />
              </ClaimOwnershipButton>
            )
          }
        />
        {poi?.coordinates && (
          <StaticMap
            target={{
              name: poi.name,
              address: poi.address || poi.jibunAddress || '',
              coordinates: poi.coordinates,
            }}
            onClickMap={handleMapClick}
          />
        )}
        {(poi?.address || poi?.jibunAddress) && (
          <Address
            roadAddress={poi?.address ?? undefined}
            jibunAddress={poi?.jibunAddress ?? undefined}
            addressExtra={poi?.addressExtra ?? undefined}
          />
        )}
        {poi?.phone && (
          <SubSection>
            <IconCallThin />
            <p>{formatPhoneNumber(poi.phone)}</p>
          </SubSection>
        )}
        {businessHoursText && (
          <SubSection>
            <IconClockThin />
            <p>{businessHoursText}</p>
          </SubSection>
        )}
        {poi?.url && (
          <SubSection>
            <IconGlobalThin />
            <a
              href={poi.url}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                /** @deprecated */
                logEvent('click_url_link_button')
                logEvent('click_profile_url', { original_url: poi.url })
              }}>
              {poi.url}
            </a>
          </SubSection>
        )}
      </Wrapper>
      <Divider fullBleed={!!brandAccountId} />
      {brandAccountId ? <BrandInfo brandAccountId={brandAccountId} /> : <UpdateSuggestion />}
    </Section>
  )
}

const InformationSectionLoading = () => {
  return (
    <Section>
      <Skeleton height={120} />
      <Spacing size={10} />
      <Skeleton width={200} />
      <Spacing size={10} />
      <Skeleton width={200} />
      <Spacing size={10} />
      <Skeleton width={200} />
    </Section>
  )
}

const Section = styled(SectionComponent)({
  padding: 0,
})
const Wrapper = styled.div`
  padding: 1.25rem 1rem 0.75rem;
`
const SectionHeader = styled(SectionHeaderComponent)({
  margin: '0 0 1rem',
})
const Divider = styled.hr<{ fullBleed?: boolean }>`
  height: 1px;
  margin: ${({ fullBleed }) => (fullBleed ? '0 auto' : '0 1rem')};
  border: none;
  background-color: ${vars.$semantic.color.divider1};
`

const StaticMap = styled(StaticMapComponent)({
  margin: '0 0 1rem',
})

const ClaimOwnershipButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.label3Regular};
`

export const SubSection = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 0 1rem;

  svg {
    position: relative;
    top: 0.25rem;
    min-width: 18px;
    min-height: 18px;
    width: 18px;
    height: 18px;
  }

  p,
  a {
    ${vars.$semantic.typography.bodyL1Regular}
    display: inline-flex;
    align-items: center;
    flex: 1;
    margin: 0 0 0 0.5rem;
    color: ${vars.$scale.color.gray900};
    overflow: inherit;
    text-overflow: inherit;
    white-space: inherit;
  }
  a {
    text-decoration: underline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export { InformationSectionLoading }
