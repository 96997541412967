import { useRecoilValue } from 'recoil'

import { checkInRegionInfoAtom } from '@src/place-utils/region/store'

import { myResidenceInfoAtom } from '../store'
import { getYearsOfResidence } from '../utils'

interface UseMyCheckinRegionResidenceInfo {
  region: {
    id: number
    name: string
  }
  hasResidenceInfo: boolean
  startYearOfResidence?: number
  yearsOfResidence?: number
}
export const useMyCheckinRegionResidenceInfo = (): UseMyCheckinRegionResidenceInfo => {
  const checkinRegion = useRecoilValue(checkInRegionInfoAtom)
  const myResidenceInfo = useRecoilValue(myResidenceInfoAtom)

  const startYearOfResidence = myResidenceInfo.find((info) => info.regionId === checkinRegion.id)?.startYearOfResidence

  const hasResidenceInfo = !!startYearOfResidence

  const yearsOfResidence = hasResidenceInfo ? getYearsOfResidence(startYearOfResidence) : undefined

  return {
    region: {
      id: checkinRegion.id,
      name: checkinRegion.name,
    },
    hasResidenceInfo,
    startYearOfResidence,
    yearsOfResidence,
  }
}
