/* eslint-disable import/order */
/* eslint-disable no-restricted-imports */
/* tslint:disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact:
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosRequestConfig, AxiosResponse } from 'axios'
// Some imports not used depending on template conditions
import { createRequest, MOCK_URL, RequestArgs } from '../../common'
// @ts-ignore
import { setSearchParams, toPathString } from '../../../configs/common'

import {
  AggregatedNativeReviewResponse,
  CreateNativeReviewBody,
  CreateNativeUserInfoBody,
  GetNativeReviewByUserResponse,
  GetNativeReviewV2200Response,
  NativeResidenceInfoResponse,
  NativeUserInfoResponse,
  UpdateNativeReviewBody,
} from '../../models'
/**
 * NativeApi - axios parameter creator
 * @export
 */

/**
 * createNativeUserInfoV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type CreateNativeUserInfoV2Params = {
  xAuthToken: string
  createNativeUserInfoBody: CreateNativeUserInfoBody
}
/**
 *
 * @summary 유저정보중 거주정보만 생성
 */
const createNativeUserInfoV2AxiosParamCreator = async (
  // @ts-ignore
  params: CreateNativeUserInfoV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/native/users`
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any
  if (params['xAuthToken'] !== undefined && params['xAuthToken'] !== null) {
    localVarHeaderParameter['x-auth-token'] = String(params['xAuthToken'])
  }

  localVarHeaderParameter['Content-Type'] = 'application/json'

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }
  localVarRequestOptions.data = params['createNativeUserInfoBody'] || ''

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}
/**
 * deleteNativeReviewV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type DeleteNativeReviewV2Params = {
  id: number
  xAuthToken: string
}
/**
 *
 * @summary 유저가 본인이 작성한 토박이 리뷰 삭제
 */
const deleteNativeReviewV2AxiosParamCreator = async (
  // @ts-ignore
  params: DeleteNativeReviewV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/native/reviews/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(params['id'])))
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'DELETE', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any
  if (params['xAuthToken'] !== undefined && params['xAuthToken'] !== null) {
    localVarHeaderParameter['x-auth-token'] = String(params['xAuthToken'])
  }

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}
/**
 * getNativeResidenceInfoV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type GetNativeResidenceInfoV2Params = {
  userId: number
}
/**
 *
 * @summary 유저의 거주이력 조회
 */
const getNativeResidenceInfoV2AxiosParamCreator = async (
  // @ts-ignore
  params: GetNativeResidenceInfoV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/native/users/{userId}/residence-info`.replace(
    `{${'userId'}}`,
    encodeURIComponent(String(params['userId']))
  )
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}
/**
 * getNativeReviewByIdV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type GetNativeReviewByIdV2Params = {
  id: number
}
/**
 *
 * @summary 토박이 리뷰 단건 조회
 */
const getNativeReviewByIdV2AxiosParamCreator = async (
  // @ts-ignore
  params: GetNativeReviewByIdV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/native/reviews/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(params['id'])))
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}
/**
 * getNativeReviewByUserV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type GetNativeReviewByUserV2Params = {
  userId: number
}
/**
 *
 * @summary 유저가 작성한 토박이 리뷰 조회
 */
const getNativeReviewByUserV2AxiosParamCreator = async (
  // @ts-ignore
  params: GetNativeReviewByUserV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/native/users/{userId}/reviews`.replace(
    `{${'userId'}}`,
    encodeURIComponent(String(params['userId']))
  )
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}
/**
 * getNativeReviewV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type GetNativeReviewV2Params = {
  xAuthToken: string
  residencyYears?: 10 | 15 | 20 | 30
  visitCountThreshold?: 5 | 10 | 30
  curationCategoryId?: number
  userCoordinates?: string
  regionId?: number
  page?: number
  perPage?: number
  orderBy?: 'DISTANCE' | 'REVIEW_SCORE_AND_DISTANCE_WEIGHT' | 'REVIEW_SCORE'
}
/**
 *
 * @summary 토박이 리뷰 조회
 */
const getNativeReviewV2AxiosParamCreator = async (
  // @ts-ignore
  params: GetNativeReviewV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/native/reviews`
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any
  if (params['residencyYears'] !== undefined) {
    localVarQueryParameter['residencyYears'] = params['residencyYears']
  }

  if (params['visitCountThreshold'] !== undefined) {
    localVarQueryParameter['visitCountThreshold'] = params['visitCountThreshold']
  }

  if (params['curationCategoryId'] !== undefined) {
    localVarQueryParameter['curationCategoryId'] = params['curationCategoryId']
  }

  if (params['userCoordinates'] !== undefined) {
    localVarQueryParameter['userCoordinates'] = params['userCoordinates']
  }

  if (params['regionId'] !== undefined) {
    localVarQueryParameter['regionId'] = params['regionId']
  }

  if (params['page'] !== undefined) {
    localVarQueryParameter['page'] = params['page']
  }

  if (params['perPage'] !== undefined) {
    localVarQueryParameter['perPage'] = params['perPage']
  }

  if (params['orderBy'] !== undefined) {
    localVarQueryParameter['orderBy'] = params['orderBy']
  }

  if (params['xAuthToken'] !== undefined && params['xAuthToken'] !== null) {
    localVarHeaderParameter['x-auth-token'] = String(params['xAuthToken'])
  }

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}
/**
 * getNativeUserInfoV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type GetNativeUserInfoV2Params = {
  userId: number
}
/**
 *
 * @summary 유저의 토박이 정보 조회
 */
const getNativeUserInfoV2AxiosParamCreator = async (
  // @ts-ignore
  params: GetNativeUserInfoV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/native/users/{userId}`.replace(
    `{${'userId'}}`,
    encodeURIComponent(String(params['userId']))
  )
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}
/**
 * saveNativeReviewV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type SaveNativeReviewV2Params = {
  xAuthToken: string
  createNativeReviewBody: CreateNativeReviewBody
}
/**
 *
 * @summary 토박이 리뷰 생성
 */
const saveNativeReviewV2AxiosParamCreator = async (
  // @ts-ignore
  params: SaveNativeReviewV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/native/reviews`
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any
  if (params['xAuthToken'] !== undefined && params['xAuthToken'] !== null) {
    localVarHeaderParameter['x-auth-token'] = String(params['xAuthToken'])
  }

  localVarHeaderParameter['Content-Type'] = 'application/json'

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }
  localVarRequestOptions.data = params['createNativeReviewBody'] || ''

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}
/**
 * updateNativeReviewV2 parameters
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type UpdateNativeReviewV2Params = {
  id: number
  xAuthToken: string
  updateNativeReviewBody: UpdateNativeReviewBody
}
/**
 *
 * @summary 유저가 본인이 작성한 토박이 리뷰 수정
 */
const updateNativeReviewV2AxiosParamCreator = async (
  // @ts-ignore
  params: UpdateNativeReviewV2Params,
  options: AxiosRequestConfig = {}
): Promise<RequestArgs> => {
  const localVarPath = `/api/v2/native/reviews/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(params['id'])))
  // use dummy base URL string because the URL constructor only accepts absolute URLs.
  const localVarUrlObj = new URL(localVarPath, MOCK_URL)

  const localVarRequestOptions: AxiosRequestConfig = { method: 'PATCH', ...options }
  const localVarHeaderParameter = {} as any
  const localVarQueryParameter = {} as any
  if (params['xAuthToken'] !== undefined && params['xAuthToken'] !== null) {
    localVarHeaderParameter['x-auth-token'] = String(params['xAuthToken'])
  }

  localVarHeaderParameter['Content-Type'] = 'application/json'

  setSearchParams(localVarUrlObj, localVarQueryParameter)
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers }
  localVarRequestOptions.data = params['updateNativeReviewBody'] || ''

  return {
    url: toPathString(localVarUrlObj),
    options: localVarRequestOptions,
  }
}

/**
 *
 * @summary 유저정보중 거주정보만 생성
 */
const createNativeUserInfoV2Fp = async ({
  params,
  options,
}: {
  params: CreateNativeUserInfoV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<NativeResidenceInfoResponse>> => {
  const localVarAxiosArgs = await createNativeUserInfoV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}
/**
 *
 * @summary 유저가 본인이 작성한 토박이 리뷰 삭제
 */
const deleteNativeReviewV2Fp = async ({
  params,
  options,
}: {
  params: DeleteNativeReviewV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<void>> => {
  const localVarAxiosArgs = await deleteNativeReviewV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}
/**
 *
 * @summary 유저의 거주이력 조회
 */
const getNativeResidenceInfoV2Fp = async ({
  params,
  options,
}: {
  params: GetNativeResidenceInfoV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<NativeResidenceInfoResponse>> => {
  const localVarAxiosArgs = await getNativeResidenceInfoV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}
/**
 *
 * @summary 토박이 리뷰 단건 조회
 */
const getNativeReviewByIdV2Fp = async ({
  params,
  options,
}: {
  params: GetNativeReviewByIdV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<AggregatedNativeReviewResponse>> => {
  const localVarAxiosArgs = await getNativeReviewByIdV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}
/**
 *
 * @summary 유저가 작성한 토박이 리뷰 조회
 */
const getNativeReviewByUserV2Fp = async ({
  params,
  options,
}: {
  params: GetNativeReviewByUserV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<GetNativeReviewByUserResponse>> => {
  const localVarAxiosArgs = await getNativeReviewByUserV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}
/**
 *
 * @summary 토박이 리뷰 조회
 */
const getNativeReviewV2Fp = async ({
  params,
  options,
}: {
  params: GetNativeReviewV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<GetNativeReviewV2200Response>> => {
  const localVarAxiosArgs = await getNativeReviewV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}
/**
 *
 * @summary 유저의 토박이 정보 조회
 */
const getNativeUserInfoV2Fp = async ({
  params,
  options,
}: {
  params: GetNativeUserInfoV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<NativeUserInfoResponse>> => {
  const localVarAxiosArgs = await getNativeUserInfoV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}
/**
 *
 * @summary 토박이 리뷰 생성
 */
const saveNativeReviewV2Fp = async ({
  params,
  options,
}: {
  params: SaveNativeReviewV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<AggregatedNativeReviewResponse>> => {
  const localVarAxiosArgs = await saveNativeReviewV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}
/**
 *
 * @summary 유저가 본인이 작성한 토박이 리뷰 수정
 */
const updateNativeReviewV2Fp = async ({
  params,
  options,
}: {
  params: UpdateNativeReviewV2Params
  options?: AxiosRequestConfig
}): Promise<AxiosResponse<AggregatedNativeReviewResponse>> => {
  const localVarAxiosArgs = await updateNativeReviewV2AxiosParamCreator(params, options)

  return createRequest({ axiosArgs: localVarAxiosArgs })
}

export const createNativeUserInfoV2 = ({
  params,
  options,
}: {
  params: CreateNativeUserInfoV2Params
  options?: AxiosRequestConfig
}) => {
  return createNativeUserInfoV2Fp({ params, options })
}
export const deleteNativeReviewV2 = ({
  params,
  options,
}: {
  params: DeleteNativeReviewV2Params
  options?: AxiosRequestConfig
}) => {
  return deleteNativeReviewV2Fp({ params, options })
}
export const getNativeResidenceInfoV2 = ({
  params,
  options,
}: {
  params: GetNativeResidenceInfoV2Params
  options?: AxiosRequestConfig
}) => {
  return getNativeResidenceInfoV2Fp({ params, options })
}
export const getNativeReviewByIdV2 = ({
  params,
  options,
}: {
  params: GetNativeReviewByIdV2Params
  options?: AxiosRequestConfig
}) => {
  return getNativeReviewByIdV2Fp({ params, options })
}
export const getNativeReviewByUserV2 = ({
  params,
  options,
}: {
  params: GetNativeReviewByUserV2Params
  options?: AxiosRequestConfig
}) => {
  return getNativeReviewByUserV2Fp({ params, options })
}
export const getNativeReviewV2 = ({
  params,
  options,
}: {
  params: GetNativeReviewV2Params
  options?: AxiosRequestConfig
}) => {
  return getNativeReviewV2Fp({ params, options })
}
export const getNativeUserInfoV2 = ({
  params,
  options,
}: {
  params: GetNativeUserInfoV2Params
  options?: AxiosRequestConfig
}) => {
  return getNativeUserInfoV2Fp({ params, options })
}
export const saveNativeReviewV2 = ({
  params,
  options,
}: {
  params: SaveNativeReviewV2Params
  options?: AxiosRequestConfig
}) => {
  return saveNativeReviewV2Fp({ params, options })
}
export const updateNativeReviewV2 = ({
  params,
  options,
}: {
  params: UpdateNativeReviewV2Params
  options?: AxiosRequestConfig
}) => {
  return updateNativeReviewV2Fp({ params, options })
}
