import Request from './Request'
import type { Review, ReviewableStatus, ReviewApiDto, UserReviewListResponse } from './types/BizReview'

export type ReviewType = 'BUSINESS_PROFILE' | 'PRODUCT' | 'RESERVATION' | 'RESERVATIONv2'

export interface Payload {
  business_account_id: string
  tag_ids: number[]
  content: string
  picture_ids?: string[]
  region_id: string
  reservation_id?: string
  review_type?: ReviewType
}

export default class BizReviewLegacyApi extends Request {
  async create(payload: Payload) {
    return this.req.post<ReviewApiDto<Review>>(`/api/legacy/reviews`, payload, {
      headers: this.getUserAuthHeader(),
    })
  }

  async update(reviewId: string, payload: Payload) {
    return this.req.put<ReviewApiDto<Review>>(`/api/legacy/reviews/${reviewId}`, payload, {
      headers: this.getUserAuthHeader(),
    })
  }

  async reviewableStatus(businessAccountId: string) {
    return this.req.get<ReviewApiDto<ReviewableStatus>>(
      `/api/legacy/business_accounts/${businessAccountId}/reviewable`,
      {
        headers: this.getUserAuthHeader(),
      }
    )
  }

  async isReviewedByMe(businessAccountId: string) {
    return this.req.get<ReviewApiDto<{ review_id: string | null }>>(
      `/api/legacy/business_accounts/${businessAccountId}/reviewed_by_me`,
      {
        headers: this.getUserAuthHeader(),
      }
    )
  }

  async getItem(reviewId: string) {
    return this.req.get<ReviewApiDto<Review>>(`/api/legacy/reviews/${reviewId}`, { headers: this.getUserAuthHeader() })
  }

  like = async (bizId: string, reviewId: string) => {
    return await this.req.post(
      `/api/legacy/reviews/${reviewId}/likes`,
      {},
      {
        headers: this.getUserAuthHeader(),
        params: {
          business_account_id: bizId,
        },
      }
    )
  }
  unlike = async (bizId: string, reviewId: string) => {
    return await this.req.delete(`/api/legacy/reviews/${reviewId}/likes`, {
      headers: this.getUserAuthHeader(),
      params: {
        business_account_id: bizId,
      },
    })
  }
  getLikedByMeByReviewIds = async (review_ids: string[]) => {
    return await this.req.get<{
      data: { data: { review_id: string; liked_by_me: boolean }[] }
      status: number
      timestamp: string
    }>(`/api/legacy/user_profiles/reviews/liked_by_me`, {
      params: { review_ids },
      paramsSerializer: (params: { review_ids: string[] }) => {
        return new URLSearchParams(params.review_ids.map((id) => ['review_ids', id])).toString()
      },
      headers: this.getUserAuthHeader(),
    })
  }
  getUserReviewList = async (userId: string, pageToken?: string | null) => {
    return await this.req.get<ReviewApiDto<UserReviewListResponse>>(`/api/legacy/user_profile/${userId}/reviews`, {
      headers: this.getUserAuthHeader(),
      params: { page_token: pageToken },
    })
  }
}
